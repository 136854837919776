import React, { useEffect, useRef, useState } from 'react';
import { Image, Pressable, View } from 'react-native';

import Talk from 'talkjs';
import { useAtomValue } from 'jotai';

import { v4 as uuidv4 } from 'uuid';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { random } from 'lodash';
import { customerAtom } from '../hooks/customerAtoms';
import { TALKJS_APP_ID } from '../../env';
import { Text } from '../core-ui';
import { AllScreensNavigationProp } from '../navigation/types';
import { orderCurrentlyTrackedAtom } from '../hooks/orderTrackingAtoms';
import { hubAtom } from '../hooks/hubsAtoms';
import { branding } from '../branding';

const uniqueId = random(0, 10000000);

export default function ConversationScene() {
  const customer = useAtomValue(customerAtom);
  const { navigate } = useNavigation<AllScreensNavigationProp<'HomeTab'>>();
  const orderCurrentlyTracked = useAtomValue(orderCurrentlyTrackedAtom);
  const hub = useAtomValue(hubAtom);

  const [talkLoaded, markTalkLoaded] = useState(false);
  const chatboxEl = useRef(null);
  const insets = useSafeAreaInsets();

  useEffect(() => {
    Talk.ready.then(() => markTalkLoaded(true));

    if (talkLoaded) {
      // Safe to use the SDK here
      const me = {
        id: uuidv4(),
        name: `Customer (No Account) ${uniqueId}`,
        email: `${uniqueId}@teddy.nz`,
        role: 'Customer',
        custom: {
          hub: hub?.id || 'no hub',
          brand: branding.name,
        },
      };

      if (customer) {
        me.id = customer.id;
        me.name = `${customer.firstName} ${customer.lastName}`;
        me.email = customer.email;
        me.custom = {
          hub: hub?.id || 'no hub',
          brand: branding.name,
        };

        if (orderCurrentlyTracked) {
          // Turn gid://shopify/Order/123?key=xxxx into 123
          const orderId = orderCurrentlyTracked.id.split('/')[4].split('?')[0];
          me.id = orderId;
        }
      }

      const supportMember = {
        id: '987654325',
        name: 'support',
        email: 'support@teddy.nz',
        photoUrl:
          'https://teddy-public.s3.ap-southeast-2.amazonaws.com/teddy-logo.png',
        welcomeMessage: orderCurrentlyTracked
          ? "*ORDER RECEIVED* \nHang tight, we're on it!"
          : 'Hi there!',
        role: 'default',
      };

      const currentUser = new Talk.User(me);
      const supportMemberUser = new Talk.User(supportMember);

      const session = new Talk.Session({
        appId: TALKJS_APP_ID,
        me: currentUser,
      });

      const conversationId = Talk.oneOnOneId(currentUser, supportMemberUser);
      const conversation = session.getOrCreateConversation(conversationId);
      conversation.setParticipant(currentUser);
      conversation.setParticipant(supportMemberUser);
      const custom = {
        today: new Date().toISOString().split('T')[0],
        brand: branding.name,
      };
      conversation.setAttributes({
        custom,
      });

      const chatbox = session.createChatbox({ showChatHeader: false });
      chatbox.select(conversation);
      chatbox.mount(chatboxEl.current);
    }
  }, [talkLoaded]);

  return (
    <View style={{ flex: 1, paddingBottom: insets.bottom }}>
      <View
        style={{
          backgroundColor: 'white',
          width: '100%',
          padding: 30,
          maxHeight: 80,
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            fontSize: 22,
          }}
          weight="bold"
        >
          {orderCurrentlyTracked ? 'Your Order' : `Ask ${branding.displayName}`}
        </Text>
      </View>
      <Pressable
        onPress={() => {
          navigate('TabNavigator');
        }}
        style={{
          width: 28,
          height: 28,
          borderRadius: 14,
          backgroundColor: 'rgba(0,0,0,.05)',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: insets.top + 20,
          right: 20,
        }}
      >
        <Image
          source={require('../../assets/images/x.png')}
          style={{ width: 22, height: 22 }}
        />
      </Pressable>
      <View style={{ flex: 1 }}>
        <View style={{ backgroundColor: '#fff', flex: 1 }} ref={chatboxEl} />
      </View>
    </View>
  );
}
