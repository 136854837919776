import { Pressable, Text, TextProps } from 'react-native';

import { CustomTheme } from '../constants/theme';

const pressableWithFeedback = ({ pressed }: { pressed: boolean }) =>
  pressed ? { opacity: 0.6 } : { opacity: 1 };

function Link({ onPress, ...restProps }: TextProps) {
  return (
    <Pressable style={pressableWithFeedback} onPress={onPress} hitSlop={10}>
      <Text
        {...restProps}
        style={[
          restProps.style,
          {
            color: CustomTheme.colors.primary,
            textDecorationStyle: 'solid',
            textDecorationLine: 'underline',
          },
        ]}
      />
    </Pressable>
  );
}

export default Link;
