import { GetOrderHistory_customer_orders_edges_node_lineItems as OrderHistoryLineItems } from '../generated/server/GetOrderHistory';
import { checkoutCreate_checkoutCreate_checkout_lineItems as CartCreateLineItems } from '../generated/server/checkoutCreate';
import { checkoutDiscountCodeApplyV2_checkoutDiscountCodeApplyV2_checkout_lineItems as DiscountApplyLineItems } from '../generated/server/checkoutDiscountCodeApplyV2';
import { checkoutLineItemsReplace_checkoutLineItemsReplace_checkout_lineItems as CartReplaceLineItems } from '../generated/server/checkoutLineItemsReplace';
import { LineItem } from '../types/types';

type CompatibleType =
  | CartCreateLineItems
  | CartReplaceLineItems
  | DiscountApplyLineItems
  | OrderHistoryLineItems;

// the code below this is like that because somehow the CartCreateLineItems edges not detected
type MyEdges = CompatibleType['edges'];
type MyEdge = MyEdges[0] | OrderHistoryLineItems['edges'][0];

function getEdges(i: CompatibleType): Array<MyEdge> {
  return i.edges;
}

export function mapToLineItems(lineItems: CompatibleType): Array<LineItem> {
  return getEdges(lineItems).map(({ node }): LineItem => {
    const { quantity, title, variant } = node;
    let image = '';

    let variantID = '';
    let variants = '';
    let quantityAvailable = 0;

    let priceAfterDiscount = 0;
    let originalPrice = 0;

    if (variant) {
      const {
        id,
        selectedOptions,
        compareAtPriceV2,
        priceV2,
        quantityAvailable: stockAvailable,
      } = variant;
      quantityAvailable = stockAvailable ?? 0;
      const priceUsed = Number(priceV2.amount);
      const compareAtPriceUsed = Number(
        compareAtPriceV2 ? compareAtPriceV2.amount : 0,
      );

      if (compareAtPriceV2) {
        priceAfterDiscount = compareAtPriceV2 ? priceUsed : 0;
        originalPrice = compareAtPriceUsed;
      } else {
        priceAfterDiscount = priceUsed;
        originalPrice = priceUsed;
      }

      variantID = id;
      const allVariants = selectedOptions.map(
        ({ name, value }) => `${name} ${value}`,
      );
      variants = allVariants.join(', ');
      if (variant.image) {
        image = variant.image.url;
      }
    }
    return {
      variant: variants,
      variantID,
      title,
      image,
      originalPrice,
      priceAfterDiscount,
      quantity,
      quantityAvailable,
    };
  });
}
