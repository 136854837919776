export const COLORS = {
  white: '#FFFFFF',
  darkWhite: '#FAFAFA',
  black: '#2D2D2D',
  solidBlack: '#000000',
  red: '#FF5852',
  orange: '#FF5C5C',
  grey: '#A4A4A4',
  priceGrey: '#C4C4C4',
  lightGrey: '#E8E8E8',
  neutralGrey: '#F4F6F9',
  primaryColor: '#0080FF',
  stepIndicatorLabel: '#0458BD',
  stepIndicator: '#0050B6',
  orderStatusDelivered: '#63CE05',
  orderStatusOnProcess: '#64B1FF',
  wishlist: '#FF4A88',
  transparent: 'transparent',
  inactive: '#AAAAAA',
  teddy: '#FF0010',
  border: '#DDDDDD',
  grey5: '#8D8E92',
  grey6: '#212437',
  grey7: '#070707',
  grey8: '#333333',
  neutral6: '#A5ABB3',
  neutralDisabled: '#9098A1',
};
