import {
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
  useLazyQuery,
  useApolloClient,
  makeVar,
} from '@apollo/client';

import { useShopifyAuth } from '../../helpers/useShopifyAuth';
import {
  AddToShoppingCart,
  AddToShoppingCartVariables,
} from '../../generated/client/AddToShoppingCart';
import {
  GetShoppingCart,
  GetShoppingCart_shoppingCart_items,
} from '../../generated/client/GetShoppingCart';
import { ResetShoppingCart } from '../../generated/client/ResetShoppingCart';

import { addToShoppingCartResolver } from '../../graphql/resolvers/addToShoppingCartResolver';
import { resetShoppingCartResolver } from '../../graphql/resolvers/resetShoppingCartResolver';
import { setShoppingCartIDResolver } from '../../graphql/resolvers/setShoppingCartIDResolver';
import { setShoppingCartResolver } from '../../graphql/resolvers/setShoppingCart';
import {
  ShoppingCartUpdateShippingLine,
  ShoppingCartUpdateShippingLineVariables,
} from '../../generated/server/ShoppingCartUpdateShippingLine';
import {
  ShoppingCartCreate,
  ShoppingCartCreateVariables,
} from '../../generated/server/ShoppingCartCreate';
import {
  ShoppingCartCustomerAssociate,
  ShoppingCartCustomerAssociateVariables,
} from '../../generated/server/ShoppingCartCustomerAssociate';
import {
  ShoppingCartDiscountCodeApply,
  ShoppingCartDiscountCodeApplyVariables,
} from '../../generated/server/ShoppingCartDiscountCodeApply';
import {
  ShoppingCartDiscountCodeRemove,
  ShoppingCartDiscountCodeRemoveVariables,
} from '../../generated/server/ShoppingCartDiscountCodeRemove';
import {
  ShoppingCartReplaceItem,
  ShoppingCartReplaceItemVariables,
} from '../../generated/server/ShoppingCartReplaceItem';
import {
  ShoppingCartUpdateAddress,
  ShoppingCartUpdateAddressVariables,
} from '../../generated/server/ShoppingCartUpdateAddress';
import {
  CheckoutWithToken,
  CheckoutWithTokenVariables,
} from '../../generated/server/CheckoutWithToken';
import {
  PollCompletedPayment,
  PollCompletedPaymentVariables,
} from '../../generated/server/PollCompletedPayment';
import {
  PollCompletedCheckout,
  PollCompletedCheckoutVariables,
} from '../../generated/server/PollCompletedCheckout';
import {
  ShoppingCartUpdateNote,
  ShoppingCartUpdateNoteVariables,
} from '../../generated/server/ShoppingCartUpdateNote';
import { SetShoppingCartIDVariables } from '../../generated/client/SetShoppingCartID';
import { SetShoppingCartVariables } from '../../generated/client/SetShoppingCart';
import {
  POLL_COMPLETED_CHECKOUT,
  CHECKOUT_CREATE,
  CHECKOUT_CUSTOMER_ASSOCIATE_V2,
  CHECKOUT_DISCOUNT_CODE_APPLY_V2,
  CHECKOUT_DISCOUNT_REMOVE,
  CHECKOUT_LINE_ITEMS_REPLACE,
  CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2,
  CHECKOUT_SHIPPING_LINE_UPDATE,
  CHECKOUT_ATTRIBUTES_UPDATE_V2,
  CHECKOUT_COMPLETE_WITH_TOKENIZED_PAYMENT_V3,
  POLL_COMPLETED_PAYMENT,
} from '../../graphql/server/checkout';
import {
  SHOPPING_CART,
  RESET_SHOPPING_CART,
} from '../../graphql/client/shoppingCartQueries';

const localLineItems = makeVar<Array<GetShoppingCart_shoppingCart_items>>([]);

function useLocalResetCartMutation() {
  const client = useApolloClient();

  return useMutation<ResetShoppingCart>(RESET_SHOPPING_CART, {
    onCompleted: () => {
      resetShoppingCartResolver({ client: client });
    },
  });
}

function useLocalAddToCart(
  options?: MutationHookOptions<AddToShoppingCart, AddToShoppingCartVariables>,
) {
  const client = useApolloClient();
  const addToCart = ({
    variables,
  }: {
    variables: AddToShoppingCartVariables;
  }) => {
    const shoppingCart = addToShoppingCartResolver({}, variables, client);
    options?.onCompleted &&
      options.onCompleted(<AddToShoppingCart>{
        addToShoppingCart: shoppingCart,
      });
  };
  // const addToCart = (data: Record<string, unknown>) => {
  //   client.writeQuery({
  //     query: ADD_TO_SHOPPING_CART,
  //     data: data,
  //   });
  //   options?.onCompleted && options.onCompleted({ addToShoppingCart: [data] });
  // };
  // let [addToCart, { loading }] = useMutation<
  //   AddToShoppingCart,
  //   AddToShoppingCartVariables
  // >(ADD_TO_SHOPPING_CART, { ...options });
  const loading = false;
  return { addToCart, loading };
}

function useLocalShoppingCartQuery(
  options?: QueryHookOptions<GetShoppingCart>,
) {
  return useQuery<GetShoppingCart>(SHOPPING_CART, {
    // This is weird
    onCompleted: (data) => {
      localLineItems(data.shoppingCart.items);
    },
    ...options,
  });
}

function useLocalSetShoppingCartID() {
  const client = useApolloClient();

  const setLocalShoppingCartID = ({
    variables,
  }: {
    variables: SetShoppingCartIDVariables;
  }) => {
    setShoppingCartIDResolver({}, variables, { client: client });
  };
  // let [setShoppingCartID, { loading }] = useMutation<
  //   SetShoppingCartID,
  //   SetShoppingCartIDVariables
  // >(SET_SHOPPING_CART_ID);
  const loading = false;

  return { setLocalShoppingCartID, loading };
}

function useLocalSetShoppingCart() {
  const client = useApolloClient();
  const setLocalShoppingCart = ({
    variables,
  }: {
    variables: SetShoppingCartVariables;
  }) => {
    setShoppingCartResolver({}, variables, client);
  };
  // let [setShoppingCart, { loading }] = useMutation<
  //   SetShoppingCart,
  //   SetShoppingCartVariables
  // >(SET_SHOPPING_CART);
  const loading = false;

  return { setLocalShoppingCart, loading };
}

function useCheckoutLineItemsReplaceMutation(
  options?: MutationHookOptions<
    ShoppingCartReplaceItem,
    ShoppingCartReplaceItemVariables
  >,
) {
  return useMutation<ShoppingCartReplaceItem, ShoppingCartReplaceItemVariables>(
    CHECKOUT_LINE_ITEMS_REPLACE,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
}

function useCheckoutCreateMutation(
  options: MutationHookOptions<ShoppingCartCreate, ShoppingCartCreateVariables>,
) {
  return useMutation<ShoppingCartCreate, ShoppingCartCreateVariables>(
    CHECKOUT_CREATE,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
}

function useCheckoutShippingAddressUpdateV2Mutation(
  options: MutationHookOptions<
    ShoppingCartUpdateAddress,
    ShoppingCartUpdateAddressVariables
  >,
) {
  return useMutation<
    ShoppingCartUpdateAddress,
    ShoppingCartUpdateAddressVariables
  >(CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2, { ...options });
}

function useCheckoutShippingLineUpdateMutation(
  options: MutationHookOptions<
    ShoppingCartUpdateShippingLine,
    ShoppingCartUpdateShippingLineVariables
  >,
) {
  return useMutation<
    ShoppingCartUpdateShippingLine,
    ShoppingCartUpdateShippingLineVariables
  >(CHECKOUT_SHIPPING_LINE_UPDATE, { ...options });
}

function useCheckoutAttributesUpdateV2Mutation(
  options: MutationHookOptions<
    ShoppingCartUpdateNote,
    ShoppingCartUpdateNoteVariables
  >,
) {
  return useMutation<ShoppingCartUpdateNote, ShoppingCartUpdateNoteVariables>(
    CHECKOUT_ATTRIBUTES_UPDATE_V2,
    { ...options },
  );
}

function useCheckoutCustomerAssociateV2Mutation(
  options?: MutationHookOptions<
    ShoppingCartCustomerAssociate,
    ShoppingCartCustomerAssociateVariables
  >,
) {
  return useMutation<
    ShoppingCartCustomerAssociate,
    ShoppingCartCustomerAssociateVariables
  >(CHECKOUT_CUSTOMER_ASSOCIATE_V2, { ...options });
}

function useCheckoutDiscountCodeApplyV2Mutation(
  options?: MutationHookOptions<
    ShoppingCartDiscountCodeApply,
    ShoppingCartDiscountCodeApplyVariables
  >,
) {
  return useMutation<
    ShoppingCartDiscountCodeApply,
    ShoppingCartDiscountCodeApplyVariables
  >(CHECKOUT_DISCOUNT_CODE_APPLY_V2, {
    ...options,
  });
}

function useCheckoutDiscountRemoveMutation(
  options?: MutationHookOptions<
    ShoppingCartDiscountCodeRemove,
    ShoppingCartDiscountCodeRemoveVariables
  >,
) {
  return useMutation<
    ShoppingCartDiscountCodeRemove,
    ShoppingCartDiscountCodeRemoveVariables
  >(CHECKOUT_DISCOUNT_REMOVE, {
    ...options,
  });
}

function useCheckoutCompleteWithTokenizedPaymentV3Mutation(
  options?: MutationHookOptions<CheckoutWithToken, CheckoutWithTokenVariables>,
) {
  return useMutation<CheckoutWithToken, CheckoutWithTokenVariables>(
    CHECKOUT_COMPLETE_WITH_TOKENIZED_PAYMENT_V3,
    {
      ...options,
    },
  );
}

function useCheckoutPollingLazyQuery(
  options?: MutationHookOptions<
    PollCompletedCheckout,
    PollCompletedCheckoutVariables
  >,
) {
  return useLazyQuery<PollCompletedCheckout, PollCompletedCheckoutVariables>(
    POLL_COMPLETED_CHECKOUT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      ...options,
    },
  );
}

function usePaymentPollingLazyQuery(
  options?: MutationHookOptions<
    PollCompletedPayment,
    PollCompletedPaymentVariables
  >,
) {
  return useLazyQuery<PollCompletedPayment, PollCompletedPaymentVariables>(
    POLL_COMPLETED_PAYMENT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: 750,
      ...options,
    },
  );
}

const useShoppingCartFlow = () => {
  const { authToken } = useShopifyAuth();
  const { setLocalShoppingCartID } = useLocalSetShoppingCartID();
  const [shoppingCartCustomerAssociate] =
    useCheckoutCustomerAssociateV2Mutation();

  const checkoutMutation = useCheckoutCreateMutation({
    onCompleted: async ({ checkoutCreate }) => {
      if (checkoutCreate && checkoutCreate.checkout) {
        localLineItems([]);
        await setLocalShoppingCartID({
          variables: { id: checkoutCreate.checkout.id },
        });
        if (authToken) {
          await shoppingCartCustomerAssociate({
            variables: {
              checkoutId: checkoutCreate.checkout.id,
              customerAccessToken: authToken,
            },
          });
        }
      }
    },
  });

  const getCartQuery = useLocalShoppingCartQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: async ({ shoppingCart }) => {
      localLineItems(shoppingCart.items);
    },
  });

  const addToCartMutation = useLocalAddToCart({
    onCompleted: async ({ addToShoppingCart }) => {
      // showToast(11000);
      localLineItems(addToShoppingCart.items);
    },
  });

  return {
    checkoutMutation,
    getCartQuery,
    addToCartMutation,
  };
};

export {
  useLocalResetCartMutation,
  //useAddToCart,
  useLocalShoppingCartQuery,
  // useLocalSetShoppingCart, // <-- 'ShoppingCartScene'
  // useLocalSetShoppingCartID,
  usePaymentPollingLazyQuery,
  useCheckoutPollingLazyQuery,
  useCheckoutCreateMutation,
  useCheckoutCustomerAssociateV2Mutation,
  useCheckoutDiscountCodeApplyV2Mutation,
  useCheckoutDiscountRemoveMutation,
  useCheckoutLineItemsReplaceMutation,
  useCheckoutShippingAddressUpdateV2Mutation,
  useCheckoutShippingLineUpdateMutation,
  useCheckoutAttributesUpdateV2Mutation,
  useCheckoutCompleteWithTokenizedPaymentV3Mutation,

  // hmm?
  useShoppingCartFlow,
  localLineItems,
};
