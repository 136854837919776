import React from 'react';

import { useDimensions } from '../../helpers/dimensions';

export const OrderTrackingWebView = ({
  trackingUrlToRender,
}: {
  trackingUrlToRender: string;
}) => {
  const { width } = useDimensions();

  return (
    <iframe
      src={trackingUrlToRender}
      title="tracking"
      height="464"
      width={width - 80}
      style={{
        borderRadius: 10,
        overflow: 'hidden',
        borderWidth: 1,
        alignSelf: 'center',
        borderColor: 'rgba(0,0,0,.075)',
      }}
    />
  );
};
