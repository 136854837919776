import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider as ThemeProvider } from 'react-native-paper';
import { connectAuthEmulator } from 'firebase/auth';
import { connectFunctionsEmulator } from 'firebase/functions';

import { USE_FIREBASE_EMULATORS, FIREBASE_EMULATORS_HOST } from '../env';
import { CustomTheme } from './constants/theme';
import { ShopifyAuthProvider } from './helpers/useShopifyAuth';
import { RootNavigator } from './navigation/RootNavigator';
import { OnboardingProvider } from './helpers/useOnboarding';

import { client } from './graphql/client';
import {
  firebaseWebAuth,
  firebaseWebFunctions,
} from './hooks/useFirebaseAuth.web';

if (
  USE_FIREBASE_EMULATORS &&
  FIREBASE_EMULATORS_HOST &&
  firebaseWebAuth &&
  firebaseWebFunctions
) {
  connectAuthEmulator(
    firebaseWebAuth,
    `http://${FIREBASE_EMULATORS_HOST}:9099`,
    { disableWarnings: true },
  );
  connectFunctionsEmulator(
    firebaseWebFunctions,
    `${FIREBASE_EMULATORS_HOST}`,
    5001,
  );
  // firestore().useEmulator(`${FIREBASE_EMULATORS_HOST}`, 8080);
}

const App = () => {
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={CustomTheme}>
          <OnboardingProvider>
            <ShopifyAuthProvider>
              <RootNavigator />
            </ShopifyAuthProvider>
          </OnboardingProvider>
        </ThemeProvider>
      </ApolloProvider>
    </GestureHandlerRootView>
  );
};

export default App;
