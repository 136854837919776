import React, { useCallback, useState } from 'react';
import {
  Alert,
  FlatList,
  Platform,
  Pressable,
  StyleSheet,
  View,
  TextInput as NativeTextInput,
  Image,
  StatusBar,
} from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { HelperText } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as yup from 'yup';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { random, startsWith } from 'lodash';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import {
  AllScreensRouteProp,
  AllScreensNavigationProp,
} from '../../navigation/types';
import { USE_FIREBASE_EMULATORS } from '../../../env';
import FormLayout from '../../components/FormLayout';
import { HintText } from '../../components/Typography';
import { globalStyles, typography } from '../../constants/theme';
import { trackEvent } from '../../analytics';
import { SimpleButton } from '../../components/SimpleButton';

import {
  COUNTRY_CODES_WITH_DIAL_CODES_AND_FLAGS,
  ICountryCode,
  NEW_ZEALAND_WITH_DIAL_CODE_AND_FLAG,
} from '../../constants/countryCode';
import TextInput from '../../components/TextInput';
import { useDimensions } from '../../helpers/dimensions';
import { Text } from '../../core-ui';
import { branding } from '../../branding';
import { triggerAlert } from '../../utils/alert';

// Note: although the UI and messaging says we must enter a New Zealand mobile phone number, we also allow Australian
// mobile phone numbers so that engineers based in Australia can test the app.
const NZ_AU_MOBILE_PHONE_VALIDATION_REGEX = /0(2|4)[\d]{7,9}/;
const NZ_AU_MOBILE_PHONE_WITHOUT_ZERO_VALIDATION_REGEX = /2[\d]/;

type mobileNumberSchemaType = 'new-zealand' | 'international';
const schemaMobileNumber = (schemaType: mobileNumberSchemaType) => {
  return schemaType === 'new-zealand'
    ? yup
        .string()
        .trim()
        .required('Mobile phone number is required.')
        .test(
          'localMobilePhoneFormat',
          'Enter a valid mobile phone number',
          (value) => {
            if (!value) {
              return false;
            }

            const valueWithoutWhitespace = value.replace(/\s/g, '');

            if (valueWithoutWhitespace[0] === '2') {
              return NZ_AU_MOBILE_PHONE_WITHOUT_ZERO_VALIDATION_REGEX.test(
                valueWithoutWhitespace,
              );
            }

            return NZ_AU_MOBILE_PHONE_VALIDATION_REGEX.test(
              valueWithoutWhitespace,
            );
          },
        )
    : yup.string().trim().required('Mobile phone number is required.');
};

type PhoneInputForm = {
  phoneNumber: string;
};

const NzPhoneInputFormSchema: yup.SchemaOf<PhoneInputForm> = yup.object({
  phoneNumber: schemaMobileNumber('new-zealand'),
});

const InternationalPhoneInputFormSchema: yup.SchemaOf<PhoneInputForm> =
  yup.object({
    phoneNumber: schemaMobileNumber('international'),
  });

export const PhoneInputScene = () => {
  const { params } = useRoute<AllScreensRouteProp<'PhoneInput'>>();
  const { navigate } = useNavigation<AllScreensNavigationProp<'PhoneInput'>>();

  const [selectedCountry, setSelectedCountry] = useState<ICountryCode>(
    NEW_ZEALAND_WITH_DIAL_CODE_AND_FLAG,
  );
  const { width } = useDimensions();

  useFocusEffect(
    useCallback(() => {
      if (params.flowType === 'fromLanding') {
        StatusBar.setBarStyle('dark-content');
        if (Platform.OS === 'android') {
          StatusBar.setTranslucent(true);
          StatusBar.setBackgroundColor('rgba(0,0,0,0)');
          changeNavigationBarColor('#fafafa', true, false);
        }
      } else {
        if (Platform.OS !== 'android') {
          StatusBar.setBarStyle('light-content');
        }
      }
    }, []),
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PhoneInputForm>({
    mode: 'onBlur',
    resolver:
      selectedCountry.name !== 'New Zealand'
        ? async (data, context, options) => {
            return yupResolver(InternationalPhoneInputFormSchema)(
              data,
              context,
              options,
            );
          }
        : async (data, context, options) => {
            return yupResolver(NzPhoneInputFormSchema)(data, context, options);
          },
    defaultValues: {
      phoneNumber: USE_FIREBASE_EMULATORS
        ? '021 ' + random(1000000, 9999999)
        : '',
    },
  });

  const height = useDimensions().height - 50;

  const convertLocalMobilePhoneNumberToE164 = (phoneNumber: string): string => {
    const phoneNumberParsed = phoneNumber.replace(/\s/g, '');

    if (startsWith(phoneNumberParsed, '0')) {
      return `${selectedCountry.dial_code}${phoneNumberParsed.slice(1)}`;
    }

    return `${selectedCountry.dial_code}${phoneNumberParsed}`;
  };
  const onSubmit: SubmitHandler<PhoneInputForm> = ({ phoneNumber }) => {
    navigate('CodeInput', {
      phoneNumber: convertLocalMobilePhoneNumberToE164(phoneNumber),
      flowType: params.flowType,
    });
  };

  const countrySelectionsDismissedTranslateY = height;
  const countrySelectionsBottomSheetTranslateY = useSharedValue(
    countrySelectionsDismissedTranslateY,
  );
  const countrySelectionsAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: countrySelectionsBottomSheetTranslateY.value,
        },
      ],
    };
  });
  const showCountrySelections = () => {
    countrySelectionsBottomSheetTranslateY.value = withSpring(0, {
      mass: 0.4,
    });
  };
  const dismissCountrySelections = () => {
    countrySelectionsBottomSheetTranslateY.value = withTiming(
      countrySelectionsDismissedTranslateY,
      {
        duration: 300,
      },
    );
  };

  const onError: SubmitErrorHandler<PhoneInputForm> = () => {
    triggerAlert('Please provide a valid mobile phone number');
    trackEvent('shown_phone_number_form_error');
  };

  const CountrySelect = () => {
    const [countries, setCountries] = useState(
      COUNTRY_CODES_WITH_DIAL_CODES_AND_FLAGS,
    );

    return (
      <Animated.View
        style={[
          countrySelectionsAnimatedStyle,
          {
            bottom: 0,
            right: 0,
            position: 'absolute',
            zIndex: 2,
            width: '100%',
            padding: 30,
            backgroundColor: '#fff',
            height,
          },
        ]}
      >
        <TextInput
          style={{
            marginBottom: 0,
            flexGrow: 1,
          }}
          placeholder={'Search country'}
          onChangeText={(text) => {
            if (text.length === 0) {
              setCountries(COUNTRY_CODES_WITH_DIAL_CODES_AND_FLAGS);
              return;
            }

            const filteredCountries =
              COUNTRY_CODES_WITH_DIAL_CODES_AND_FLAGS.filter((country) => {
                return country.name.toLowerCase().includes(text.toLowerCase());
              });

            setCountries(filteredCountries);
          }}
          blurOnSubmit={true}
          returnKeyType="search"
          returnKeyLabel="search"
          clearButtonMode="always"
          searchIcon={true}
        />
        <FlatList
          style={{ marginVertical: 10 }}
          data={countries}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => {
            return (
              <Pressable
                onPress={() => {
                  trackEvent('change_phone_country_code', {
                    country: item.name,
                  });
                  setSelectedCountry(item);
                  dismissCountrySelections();
                }}
                style={{
                  flexDirection: 'row',
                  height: 50,
                  alignItems: 'center',
                  borderBottomColor: 'rgba(0,0,0,0.1)',
                  borderBottomWidth: 1,
                }}
              >
                <View style={{ width: 50 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight:
                        selectedCountry.name === item.name ? '700' : undefined,
                    }}
                  >
                    {item.dial_code}
                  </Text>
                </View>
                <View style={{ width: 40 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight:
                        selectedCountry.name === item.name ? '700' : undefined,
                    }}
                  >
                    {' '}
                    {item.emoji}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight:
                        selectedCountry.name === item.name ? '700' : undefined,
                    }}
                  >
                    {item.name}{' '}
                  </Text>
                </View>
              </Pressable>
            );
          }}
        />
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <SimpleButton
            width={200}
            color="rgba(24,24,24,1)"
            bgColor="rgba(0,0,0,.05)"
            onPress={dismissCountrySelections}
          >
            Back
          </SimpleButton>
        </View>
      </Animated.View>
    );
  };

  return (
    <SafeAreaView style={[globalStyles.fullFlex, { overflow: 'hidden' }]}>
      <FormLayout>
        <View style={styles.container}>
          <Image
            source={branding.logoGraphic}
            style={{
              width: 170,
              height: 60,
              resizeMode: 'contain',
              alignSelf: 'center',
            }}
          />
          {/* <Image
            source={require('../../../assets/images/sms-bubble.png')}
            style={{
              width: 170,
              height: 60,
              resizeMode: 'contain',
              alignSelf: 'center',
              marginTop: 20,
            }}
          /> */}
          <View style={globalStyles.centeredColumn}>
            <Text
              weight="bold"
              style={{
                textAlign: 'center',
                fontSize: 24,
                lineHeight: 28,
                marginTop: 20,
              }}
            >
              What's your number?
            </Text>
            <Text style={{ fontSize: 16, lineHeight: 20, marginTop: 32 }}>
              You will receive a one-time
            </Text>
            <Text style={{ fontSize: 16, lineHeight: 20 }}>
              code via SMS to
            </Text>
            <Text style={{ fontSize: 16, lineHeight: 20 }}>
              your mobile number.
            </Text>
          </View>

          <View
            style={[
              globalStyles.rowSpacedBetween,
              {
                marginTop: 40,
                paddingHorizontal: 3,
                marginBottom: 6,
                alignItems: 'center',
              },
            ]}
          >
            <Text style={typography.label}>Mobile phone</Text>
            <HintText style={globalStyles.my5}>e.g., 021 123 4567</HintText>
          </View>

          {/* <HintText style={globalStyles.my5}>Format: 021 123 4567</HintText> */}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            name="phoneNumber"
            render={({ field: { onChange, onBlur, value } }) => (
              // <TextInputMask
              //   onBlur={onBlur}
              //   onChangeText={onChange}
              //   value={value}
              //   mask={NZ_AU_PHONE_INPUT_MASK}
              //   placeholder="021 123 4567"
              //   keyboardType="phone-pad"
              //   returnKeyType="done"
              //   autoCorrect={false}
              //   autoComplete="off"
              //   style={{
              //     fontSize: 28,
              //     textAlign: 'auto',
              //     borderWidth: 1,
              //     borderColor: '#ddd',
              //     borderRadius: 6,
              //     paddingHorizontal: 20,
              //     paddingVertical: 16,
              //   }}
              // />
              <NativeTextInput
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}
                // mask={NZ_AU_PHONE_INPUT_MASK}
                placeholder="021 123 4567"
                keyboardType="numeric"
                returnKeyType="done"
                autoCorrect={false}
                autoComplete="off"
                style={{
                  fontSize: 24,
                  textAlign: 'auto',
                  borderWidth: 1,
                  borderColor: '#ddd',
                  borderRadius: 6,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                }}
              />
            )}
          />
          <View style={{ margin: 15, alignItems: 'flex-end' }}>
            <Pressable onPress={showCountrySelections}>
              <Text style={{ fontSize: 16, color: 'rgba(0,0,0,0.5)' }}>
                {selectedCountry.emoji} {selectedCountry.dial_code}{' '}
                {selectedCountry.name}
              </Text>
            </Pressable>
          </View>
          <HelperText
            type="info"
            visible={!!errors.phoneNumber}
            padding="none"
            style={[
              typography.smallLabel,
              { marginTop: 0, textAlign: 'center' },
            ]}
          >
            {errors.phoneNumber?.message}
          </HelperText>
        </View>
      </FormLayout>

      <View style={styles.disclaimerContainer}>
        <HintText style={globalStyles.centeredText}>
          By giving your number, you're agreeing it's all good for us to contact
          you on it. Checkout our mobile terms of service.
        </HintText>
      </View>

      <CountrySelect />

      <View style={styles.buttonContainer}>
        <SimpleButton
          // icon={({ color }) => (
          //   <MaterialIcons name="arrow-right-alt" size={32} color={color} />
          // )}
          //mode="contained"
          onPress={handleSubmit(onSubmit, onError)}
          //style={styles.nextButton}
          width={width - 60}
        >
          Get verification code
        </SimpleButton>

        {params.flowType === 'fromLanding' ? (
          <View style={{ marginTop: 10 }}>
            <SimpleButton
              //mode="contained"
              onPress={() => {
                navigate('AddressEntry', {
                  flowType: 'fromLanding',
                });

                trackEvent('skipped_signup_on_landing');
              }}
              bgColor="rgba(0,0,0,.0)"
              color="rgba(44,44,44,1)"
              width={width - 60}
            >
              Skip
            </SimpleButton>
          </View>
        ) : (
          <View style={{ height: 40 }} />
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    height: '100%',
    paddingTop: Platform.select({
      ios: 10,
      android: 30,
      web: 30,
    }),
  },
  buttonContainer: {
    padding: 20,
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disclaimerContainer: {
    paddingVertical: 0,
    paddingHorizontal: 40,
    textAlign: 'center',
    opacity: 0.7,
    //borderWidth: 1,
  },
});
