import { useNavigation } from '@react-navigation/native';
import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { Image, Platform, Pressable, useWindowDimensions } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { Text } from '../core-ui';
import { orderCurrentlyTrackedAtom } from '../hooks/orderTrackingAtoms';
import { currentRouteNameAtom } from '../hooks/navigationAtom';
import { AllScreensNavigationProp } from '../navigation/types';
import { hubAtom } from '../hooks/hubsAtoms';

export const TabBarHoverElement = () => {
  const hub = useAtomValue(hubAtom);
  const { width } = useWindowDimensions();
  const currentRouteName = useAtomValue(currentRouteNameAtom);
  const [orderCurrentlyTracked, setOrderCurrentlyTracked] = useAtom(
    orderCurrentlyTrackedAtom,
  );
  const translateY = useSharedValue(0);
  const opacity = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      transform: [{ translateY: translateY.value }],
    };
  });

  const defaultChatCallToActions = [
    'Need help?',
    'Got feedback?',
    'Any Suggestions?',
  ];
  const hubCallToActions = hub?.chat_ctas.split(',').filter((s) => s) || [];
  const chatCallToActions = hubCallToActions.length
    ? hubCallToActions
    : defaultChatCallToActions;
  const hideOnRoutes = ['Checkout', 'Cart', 'Landing', 'OrderComplete'];
  const isOnRouteWhereShouldHide = hideOnRoutes.includes(
    currentRouteName || '',
  );

  // Iterate through help labels, change every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (orderCurrentlyTracked) {
        return;
      }
      hide();
      setTimeout(() => {
        if (!isOnRouteWhereShouldHide) {
          show();
        }
      }, 300);
    }, 15000);
    return () => clearInterval(interval);
  }, [
    isOnRouteWhereShouldHide,
    orderCurrentlyTracked?.id,
    chatCallToActions.length,
  ]);

  // Reset order currently tracked after 1 h
  useEffect(() => {
    if (orderCurrentlyTracked?.expectedTime) {
      const expiresAfterMinutes = 30;
      const currentDate = +new Date();
      const hasExpired =
        currentDate - orderCurrentlyTracked.expectedTime >
        1000 * 60 * expiresAfterMinutes;
      if (hasExpired) {
        setOrderCurrentlyTracked(null);
      }
    }
  }, [orderCurrentlyTracked?.confirmedAt, currentRouteName]);

  useEffect(() => {
    if (isOnRouteWhereShouldHide) {
      hide();
    } else {
      show();
    }
  }, [isOnRouteWhereShouldHide]);

  function hide() {
    translateY.value = withSpring(30);
    opacity.value = withTiming(0, { duration: 140 });
  }

  function show() {
    translateY.value = withDelay(2000, withSpring(0, { mass: 0.6 }));
    opacity.value = withDelay(2050, withTiming(1, { duration: 120 }));
  }

  if (!hub) {
    return null;
  }

  return (
    <Animated.View
      style={[
        {
          width,
          position: 'absolute',
          // This number is magic, sorry
          right: -16,
          top: -40,
        },
        animatedStyle,
      ]}
    >
      {orderCurrentlyTracked ? <OrderTrackingDisplay /> : <LiveChatButton />}
    </Animated.View>
  );
};

const OrderTrackingDisplay = () => {
  const navigation = useNavigation<AllScreensNavigationProp<'AddressEntry'>>();
  return (
    <Pressable
      style={{
        position: 'absolute',
        backgroundColor: 'red',
        width: 180,
        height: 32,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        //bottom: 4,
        right: Platform.OS === 'web' ? 25 : 10,
        elevation: 6,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3.84,
      }}
      onPress={() => {
        navigation.navigate('OrderTracking');
      }}
    >
      <Image
        style={{
          width: 20,
          height: 20,
          marginRight: 0,
        }}
        resizeMethod="resize"
        resizeMode="cover"
        source={require('../../assets/images/map-marker-white.png')}
      />
      <Text
        style={{ marginLeft: 4, color: 'white', lineHeight: 14, fontSize: 14 }}
        weight="bold"
      >
        Track your order
      </Text>
    </Pressable>
  );
};

const LiveChatButton = () => {
  const navigation = useNavigation<AllScreensNavigationProp<'AddressEntry'>>();
  return (
    <Pressable
      onPress={() => {
        navigation.navigate('TrackingAndConversation');
      }}
      style={{
        position: 'absolute',
        //top: ,
        right: Platform.OS === 'web' ? 25 : 10,
        height: 30,
        borderRadius: 6,
        width: 130,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#eee',
        // paddingLeft: 30,
        // paddingRight: 10,
        elevation: 6,
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 3.84,
      }}
    >
      <Image
        style={{
          width: 24,
          height: 24,
          marginRight: 8,
          // position: 'absolute',
          // left: 6,
        }}
        resizeMethod="resize"
        resizeMode="cover"
        source={require('../../assets/images/chatBubbles.png')}
      />
      <Text
        // weight="bold"
        style={{
          //color: 'white',
          //borderWidth: 1,
          textAlign: 'center',
          flexGrow: 0,
          lineHeight: 14,
          fontSize: 14,
          overflow: 'hidden',
          maxWidth: 170,
        }}
        numberOfLines={1}
      >
        Live Chat
      </Text>
    </Pressable>
  );
};
