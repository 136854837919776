/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Mostly reponsible for boostraping the Shopify integration
 *
 * TODO:
 * - Move more logic into checkoutAtoms
 * - Remove context and use jotai instead
 */
import { useApolloClient } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import auth from '@react-native-firebase/auth';
import functions from '@react-native-firebase/functions';
import { Alert, Platform } from 'react-native';
import { subDays } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import { httpsCallable } from 'firebase/functions';

import {
  GetCustomerData_customer,
  GetCustomerData_customer_orders_edges_node,
  GetCustomerData_customer_orders_edges_node_lineItems_edges_node_variant,
} from '../generated/server/GetCustomerData';
import { FETCH_CUSTOMER_DATA } from '../graphql/server/auth';
import useFirebaseAuth from '../hooks/useFirebaseAuth';
import { identify, trackEvent } from '../analytics';
import {
  checkoutIdAtom,
  initiateShopifyCheckoutAtom,
  setQuantityOfLineItemAtom,
  resetCheckoutAtom,
  saveAndSetDefaultAddressToShopifyCustomerAtom,
  lineItemsInCartAtom,
} from '../hooks/checkoutAtoms';
import {
  addressAtom,
  setAddressAtom,
  setAddressLatLngAtom,
} from '../hooks/addressAtoms';
import {
  allProductsAtom,
  availableProductsAtom,
  setRecentPurchasedLineItemsIdAtom,
} from '../hooks/productsAtoms';
import {
  customerAccessTokenAtom,
  customerAccessTokenExpiresatAtomWithStorage,
  persistedCustomerAccessTokenAtom,
  setPersistedCustomerAccessTokenAtom,
  removePersistedCustomerAccessTokenAtom,
  setCustomerAccessTokenAtom,
  setCustomerAtom,
  firebaseAuthErrorAtom,
} from '../hooks/customerAtoms';
import { log, logErrorException } from '../utils/LoggingUtils';
import useLazyQueryReturningPromise from '../utils/useLazyQueryReturningPromise';

import { branding } from '../branding';
import { triggerAlert } from '../utils/alert';
import {
  firebaseWebAuth,
  firebaseWebFunctions,
} from '../hooks/useFirebaseAuth.web';

type Context = {
  isAuthenticated: boolean;
  authToken: string;
  logOut: () => Promise<void>;
};

type Props = {
  children: JSX.Element;
};

const ShopifyAuthContext = createContext<Context>({
  isAuthenticated: false,
  authToken: '',
  logOut: () => Promise.resolve(),
});

function checkShouldGetNewCustomerAccessToken(expiresAt: string) {
  if (!expiresAt) {
    return true;
  }

  const nowDate = new Date();
  const expiresAtDate = new Date(expiresAt);

  const customerAccessTokenExpiringSoonDate = subDays(expiresAtDate, 7);

  return nowDate > customerAccessTokenExpiringSoonDate;
}

export function ShopifyAuthProvider(props: Props) {
  const client = useApolloClient();
  const { isAuthenticated: isAuthenticatedWithFirebase } = useFirebaseAuth();

  const [hasLoggedOutThisSession, setHasLoggedOutThisSession] = useState(false);
  const setCustomer = useSetAtom(setCustomerAtom);
  const [customerAccessToken] = useAtom(customerAccessTokenAtom);
  const setCustomerAccessToken = useSetAtom(setCustomerAccessTokenAtom);
  const customerAccessTokenExpiresAt = useAtomValue(
    loadable(customerAccessTokenExpiresatAtomWithStorage),
  );
  const setCustomerAccessTokenExpiresAt = useSetAtom(
    // useSetAtom avoids suspense value
    customerAccessTokenExpiresatAtomWithStorage,
  );
  const resetCheckout = useSetAtom(resetCheckoutAtom);
  const setfirebaseAuthError = useSetAtom(firebaseAuthErrorAtom);

  // Customer fetch
  const fetchCustomerData = useLazyQueryReturningPromise(FETCH_CUSTOMER_DATA);

  /* Jotai State - New and good */
  const [checkoutId] = useAtom(checkoutIdAtom);
  const lineItemsInCart = useAtomValue(lineItemsInCartAtom);
  const setQuantityOfLineItem = useSetAtom(setQuantityOfLineItemAtom);
  const address = useAtomValue(addressAtom);
  const setAddress = useSetAtom(setAddressAtom);
  const setAddressLatLng = useSetAtom(setAddressLatLngAtom);
  const saveAndSetDefaultAddressToShopify = useSetAtom(
    saveAndSetDefaultAddressToShopifyCustomerAtom,
  );

  const [, initiateShopifyCheckout] = useAtom(initiateShopifyCheckoutAtom);
  const [allProducts] = useAtom(loadable(allProductsAtom));
  const [availableProducts] = useAtom(loadable(availableProductsAtom));

  const setRecentPurchasedLineItemsId = useSetAtom(
    setRecentPurchasedLineItemsIdAtom,
  );

  const [persistedCustomerAccessToken] = useAtom(
    loadable(persistedCustomerAccessTokenAtom),
  );
  const setPersistedCustomerAccessToken = useSetAtom(
    setPersistedCustomerAccessTokenAtom,
  );
  const removePersistedCustomerAccessToken = useSetAtom(
    removePersistedCustomerAccessTokenAtom,
  );

  const logOut = useCallback(async () => {
    setCustomerAccessToken('');
    removePersistedCustomerAccessToken();
    setCustomer(null);
    setAddress(null);
    setAddressLatLng(null);
    setRecentPurchasedLineItemsId([]);
    trackEvent('log_out');
    resetCheckout();

    if (Platform.OS === 'web') {
      await firebaseWebAuth?.signOut();
    } else {
      await auth().signOut();
    }

    await client.resetStore();
    setHasLoggedOutThisSession(true); // See comment below
  }, []);

  /**
   * Load persisted Shopify Customer Access Token
   */
  useEffect(() => {
    if (persistedCustomerAccessToken.state === 'hasError') {
      logErrorException(
        'Error loading persisted customer access token',
        persistedCustomerAccessToken.error,
      );
    }

    if (persistedCustomerAccessToken.state !== 'hasData') {
      return;
    }

    if (persistedCustomerAccessToken.data !== null) {
      setCustomerAccessToken(persistedCustomerAccessToken.data);
    }
  }, [persistedCustomerAccessToken.state]);

  /**
   * Shopify Checkout ID generation
   * Whenever a checkout ID is not present, we generate a new one
   */
  useEffect(() => {
    if (!checkoutId) {
      initiateShopifyCheckout();
    }
  }, [checkoutId]);

  /**
   * Customer Access Token Generation
   * When Firebase Auth is detected (via SMS verification), we generate a Shopify Customer Access Token
   * Note: this will trigger `hydrateShopifyCustomerData`
   */
  useEffect(() => {
    async function generateShopifyCustomerAccessToken() {
      if (!isAuthenticatedWithFirebase) {
        return;
      }

      if (allProducts.state !== 'hasData') {
        return;
      }

      if (
        persistedCustomerAccessToken.state !== 'hasData' &&
        persistedCustomerAccessToken.state !== 'hasError'
      ) {
        return;
      }

      if (customerAccessTokenExpiresAt.state !== 'hasData') {
        return;
      }

      const existingCustomerAccessToken =
        persistedCustomerAccessToken.state === 'hasData'
          ? persistedCustomerAccessToken.data
          : null;
      const customerAccessTokenExpiresAtValue =
        customerAccessTokenExpiresAt.data;
      const hasExistedTokenExpired = checkShouldGetNewCustomerAccessToken(
        customerAccessTokenExpiresAtValue,
      );

      if (
        !hasLoggedOutThisSession && // This is a unfortunate bit of extra logic needed because `persistedCustomerAccessToken.data` is not 'resettable'
        existingCustomerAccessToken &&
        !hasExistedTokenExpired
      ) {
        return;
      }

      try {
        /* Firebase Request */
        const generateShopifyCustomerAccessTokenResult =
          Platform.OS === 'web' && firebaseWebFunctions
            ? await httpsCallable(
                firebaseWebFunctions,
                'generateShopifyCustomerAccessToken',
              )()
            : await functions().httpsCallable(
                'generateShopifyCustomerAccessToken',
              )();

        const {
          accessToken: newCustomerAccessToken,
          expiresAt: newCustomerAccessTokenExpiresAt,
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
        } = generateShopifyCustomerAccessTokenResult.data.customerAccessToken;

        /* Set customer state */
        setCustomerAccessToken(newCustomerAccessToken); // <-- For local access
        setCustomerAccessTokenExpiresAt(newCustomerAccessTokenExpiresAt); // <-- Persisted in asyncstorage via jotai
        setPersistedCustomerAccessToken(
          newCustomerAccessToken, // <-- Persisted in OS keychain
        );
      } catch (error: unknown) {
        triggerAlert(
          'Unable to authenticate',
          `An unknown error occurred. Please try again, and if the problem persists, contact ${branding.displayName}.`,
        );
        trackEvent('authentication_error_shown', {
          error: JSON.stringify(error),
        });
        setfirebaseAuthError(true);
        await logOut();
      }
    }
    generateShopifyCustomerAccessToken();
  }, [
    // Note: other dependencies are deliberately excluded as we only want to run this effect when the user becomes
    // authenticated with Firebase.
    isAuthenticatedWithFirebase,
    allProducts.state,
    customerAccessTokenExpiresAt.state,
    persistedCustomerAccessToken.state,
  ]);

  /**
   * Hydrate Customer, Address + Last Order
   * Waits for Shopify Customer Access Token to be available, then fetches and hydrates
   * customer, address and last order
   */
  useEffect(() => {
    async function hydrateShopifyCustomerData() {
      if (!isAuthenticatedWithFirebase) {
        return;
      }

      if (allProducts.state !== 'hasData') {
        return;
      }

      if (availableProducts.state !== 'hasData') {
        return;
      }

      if (!customerAccessToken) {
        return;
      }

      log('[useShopifyAuth] Hydrating Shopify Started', customerAccessToken);

      const customerDataResponse = await client.query({
        query: FETCH_CUSTOMER_DATA,
        variables: {
          accessToken: customerAccessToken,
        },
      });

      const customer: GetCustomerData_customer =
        customerDataResponse.data.customer;

      if (!customer) {
        logErrorException(
          new Error(
            'Failed to find Shopify Customer record matching the Customer Access Token.',
          ),
        );

        // Automatically log the user out if we fail to fetch the Shopify Customer record. We assume this failure is
        // because the Shopify Customer has been deleted within the Shopify admin dashboard.
        await logOut();

        return;
      }
      const defaultAddress = customer?.defaultAddress;
      // console.log(
      //   'hydrateShopifyCustomerData - Existing address found on log in',
      //   defaultAddress,
      // );

      // console.log(
      //   'hydrateShopifyCustomerData - Hydrated Shopify Customer Data',
      // );
      identify(customer.id);

      setCustomer({
        id: customer.id,
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        email: customer.email || '',
        phone: customer.phone || '',
        referralCode: customer.referralCode?.value || '',
        acceptsMarketing: customer.acceptsMarketing,
      });
      //console.log('hydrateShopifyCustomerData - address in state', address);

      // Existing sign up with address fetched from Shopify API
      // (Disabled in favour of using the address from persistence)
      if (!address && defaultAddress) {
        // console.log(
        //   'hydrateShopifyCustomerData - Hydrated Shopify Customer Address',
        // );
        // console.log('LOADING ADDRESS FROM SHOPYFY', defaultAddress.address1);
        // setAddress({
        //   address1: defaultAddress.address1,
        //   address2: '',
        //   city: defaultAddress.city,
        //   country: defaultAddress.country,
        //   firstName: defaultAddress.firstName,
        //   lastName: defaultAddress.lastName,
        //   phone: defaultAddress.phone,
        //   province: defaultAddress.province,
        //   zip: defaultAddress.zip,
        // });
      }

      // Recently purchased line items Ids
      const getRecentlyPurchasedLineItemsIdsSortByQuantity = (
        orders: GetCustomerData_customer_orders_edges_node[],
      ) => {
        const allLineItems = [
          ...orders
            .map((order) => order.lineItems.edges)
            .flat()
            .map((lineItem) => {
              return {
                title: lineItem.node.title,
                variant: lineItem.node.variant,
                quantity: lineItem.node.quantity,
              };
            }),
        ];

        const lineItemsByVariantId = allLineItems.reduce(
          (
            acc: Record<
              string,
              {
                title: string;
                variant?: GetCustomerData_customer_orders_edges_node_lineItems_edges_node_variant | null;
                quantity: number;
              }
            >,
            lineItem,
          ) => {
            const variantId = lineItem.variant?.id || '';
            if (acc[variantId]) {
              const existingLineItem = acc[variantId];
              const existingQuantity = existingLineItem.quantity || 0;
              const newQuantity = lineItem.quantity + existingQuantity;

              if (newQuantity > existingQuantity) {
                acc[variantId].quantity = newQuantity;
              }
            } else {
              acc[variantId] = lineItem;
            }
            return acc;
          },
          {},
        );

        const lineItems = Object.values(lineItemsByVariantId);

        return lineItems
          .sort((a, b) => b.quantity - a.quantity)
          .map((item) => item.variant?.product.id);
      };

      const recentPurchasedLineItemsIds =
        getRecentlyPurchasedLineItemsIdsSortByQuantity(
          customer.orders.edges.map((orderEdges) => orderEdges.node),
        );

      setRecentPurchasedLineItemsId(recentPurchasedLineItemsIds as string[]);

      // New sign up
      if (address && !defaultAddress) {
        saveAndSetDefaultAddressToShopify();
      }

      // Load existing checkout from customer account
      // [Disabled because this creates confusion]
      if (customer.lastIncompleteCheckout && lineItemsInCart.length === 0) {
        const lastIncompleteCheckoutCreatedAt = new Date(
          customer.lastIncompleteCheckout.createdAt,
        );

        // first order is the latest order as reverse is parsed in on FETCH_CUSTOMER_DATA graphql query
        const lastOrderProcessedAt = customer.orders?.edges?.[0].node
          ?.processedAt
          ? new Date(customer.orders?.edges?.[0].node?.processedAt)
          : undefined;

        if (
          lastOrderProcessedAt &&
          lastOrderProcessedAt > lastIncompleteCheckoutCreatedAt
        ) {
          return;
        }

        const items: { quantity: number; variantID: string }[] =
          customer.lastIncompleteCheckout.lineItems.edges.map(({ node }) => {
            const { quantity, variant } = node;
            const variantID = variant?.id || '';
            return { quantity, variantID };
          });

        for (const item of items) {
          const itemExists = allProducts.data.find(
            (product) => product.variants.nodes[0].id === item.variantID,
          );

          if (itemExists) {
            setQuantityOfLineItem({
              variantID: item.variantID,
              quantity: item.quantity,
              queueSync: true,
            });
          }
        }

        trackEvent('last_incomplete_checkout_loaded', { count: items.length });
      }

      log('[useShopifyAuth] Hydrating Shopify Completed', customerAccessToken);
    }

    hydrateShopifyCustomerData();
  }, [
    isAuthenticatedWithFirebase,
    customerAccessToken,
    allProducts.state,
    availableProducts.state,
  ]);

  // TODO: replace this context with jotai
  const context = useMemo(
    () => ({
      isAuthenticated: !!customerAccessToken,
      authToken: customerAccessToken,
      logOut,
    }),
    [customerAccessToken, logOut],
  );

  return (
    <ShopifyAuthContext.Provider value={context}>
      {props.children}
    </ShopifyAuthContext.Provider>
  );
}

export function useShopifyAuth() {
  return useContext(ShopifyAuthContext);
}
