import { atom } from 'jotai';
import type { AppRouter } from 'teddy-next';
import type { inferRouterOutputs } from '@trpc/server';
import { atomFamily } from 'jotai/utils';

export type InventoryRecord =
  inferRouterOutputs<AppRouter>['inventory'][number];

export const inventoryRecordsAtom = atom<InventoryRecord[]>([]);
export const inventoryRecordFamily = atomFamily(
  ({ shopifyId }: { shopifyId: string }) => {
    return atom<InventoryRecord | undefined>(undefined);
  },
  (a, b) => {
    return a.shopifyId === b.shopifyId;
  },
);
