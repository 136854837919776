import React, { useCallback, useRef, useState } from 'react';
import { TextInput, View } from 'react-native';

import { useAtomValue, useSetAtom } from 'jotai';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { CategoryList } from '../../core-ui';
import { AllScreensNavigationProp } from '../../navigation/types';
import { AlcoholConditionsBottomSheet } from '../../components/AlcoholConditionsBottomSheet';
import { CategoryItem } from '../../types/types';
import {
  categoriesCollectionsAtom,
  searchTopCollectionListingsAtom,
  searchBottomCollectionListingsAtom,
} from '../../hooks/collectionsAtoms';
import { ProductSearch } from '../../components/ProductSearch';
import { CollectionHorizontalScroller } from '../../components/CollectionHorizontalScroller';
import { PromoContent } from '../../components/PromoContent';
import { BasketRecommendations } from '../../components/BasketRecommendations';
import { refreshRecommendationsAtom } from '../../hooks/basketRecommendationsAtoms';
import { branding } from '../../branding';

export default function SearchScene() {
  const { navigate } = useNavigation<AllScreensNavigationProp<'Search'>>();
  const categoriesCollections = useAtomValue(categoriesCollectionsAtom);
  const [onAlcoholConfirmPress, setOnAlcoholConfirmPress] = useState<
    (() => void) | undefined
  >(undefined);
  const [isAlcoholConditionsModalVisible, setIsAlcoholConditionsModalVisible] =
    useState(false);
  const searchRef = useRef<TextInput>(null);
  const refreshRecommendations = useSetAtom(refreshRecommendationsAtom);

  // Focus search on focus
  useFocusEffect(
    useCallback(() => {
      searchRef.current?.focus();
      refreshRecommendations();
    }, []),
  );
  const searchTopScreenFeaturedCollections = useAtomValue(
    searchTopCollectionListingsAtom,
  );
  const searchBottomScreenFeaturedCollections = useAtomValue(
    searchBottomCollectionListingsAtom,
  );

  return (
    <ProductSearch showBackButton={false} ref={searchRef}>
      <PromoContent placement="search-screen-1" />
      {searchTopScreenFeaturedCollections.map((collection) => {
        return (
          <View key={collection.id} style={{ marginBottom: 20 }}>
            <CollectionHorizontalScroller
              collection={collection}
              placement="top-search-screen"
            />
          </View>
        );
      })}
      <PromoContent placement="search-screen-2" />

      <BasketRecommendations placement="search-screen" />

      <PromoContent placement="search-screen-3" />
      <View style={{ padding: 9 }}>
        <CategoryList
          listKey="categories"
          categories={categoriesCollections.map((collection) => {
            return {
              __type: 'Collection',
              id: collection.id,
              title: collection.title,
              handle: collection.handle,
              cursor: '',
              image: collection.image?.url,
              tags: collection.tags,
            };
          })}
          onSelect={(collection: CategoryItem) => {
            const containsAlcohol =
              collection.tags?.includes('contains_alcohol');
            const navigateToCategory = () =>
              navigate('ProductCollection', {
                collectionHandle: collection.handle,
                collectionTitle: collection.title,
                navigationLevel: 1,
              });
            if (containsAlcohol && branding.categoryAlcoholConditionsEnabled) {
              setIsAlcoholConditionsModalVisible(true);
              setOnAlcoholConfirmPress(() => navigateToCategory);
              return;
            }

            navigateToCategory();
          }}
        />
      </View>

      <PromoContent placement="search-screen-4" />

      {searchBottomScreenFeaturedCollections.map((collection) => {
        return (
          <View key={collection.id} style={{ marginBottom: 20 }}>
            <CollectionHorizontalScroller
              collection={collection}
              placement="bottom-search-screen"
            />
          </View>
        );
      })}
      <AlcoholConditionsBottomSheet
        isVisible={isAlcoholConditionsModalVisible}
        onDismiss={() => setIsAlcoholConditionsModalVisible(false)}
        onConditionsAccepted={() => {
          onAlcoholConfirmPress?.();
          setIsAlcoholConditionsModalVisible(false);
        }}
      />
    </ProductSearch>
  );
}
