import React from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ActivityIndicator, List } from 'react-native-paper';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { useAtomValue } from 'jotai';
import {
  AllScreensNavigationProp,
  AllScreensRouteProp,
} from '../navigation/types';
import { OrderItem, PaymentDetails } from '../components';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import formatDateTime from '../helpers/formatDateTime';
import useCurrencyFormatter from '../hooks/api/useCurrencyFormatter';
import { PaymentDetailsProps } from '../types/types';
import { useOrder } from '../hooks/api/useOrderHistory';
import { mapToLineItems } from '../helpers/mapToLineItems';
import { t } from '../helpers/translate';
import { HORIZONTAL_GUTTER } from '../constants/theme';
import { COLORS } from '../constants/colors';
import { PromoContent } from '../components/PromoContent';
import { orderCurrentlyTrackedAtom } from '../hooks/orderTrackingAtoms';

type ListIconItemProps = {
  title: string;
  icon: string;
};
const ListIconItem = ({ title, icon }: ListIconItemProps) => {
  return (
    <List.Item
      title={title}
      titleStyle={styles.deliveryInfoItem}
      style={{ paddingHorizontal: 0 }}
      left={() => (
        <Icon
          color={COLORS.solidBlack}
          name={icon}
          size={24}
          style={{
            top: 2,
          }}
        />
      )}
    />
  );
};

export default function OrderDetailsScene() {
  const {
    params: { orderId, live },
  } = useRoute<AllScreensRouteProp<'OrderDetails'>>();

  const { data, loading } = useOrder({ variables: { orderId } });
  const orderCurrentlyTracked = useAtomValue(orderCurrentlyTrackedAtom);
  const isOrderBeingTracked = orderCurrentlyTracked?.id === orderId;

  const formatCurrency = useCurrencyFormatter();

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  //GetOrder_order_Order
  const order = data?.order;

  // eslint-disable-next-line no-underscore-dangle
  if (order?.__typename !== 'Order') {
    return null;
  }

  if (
    order === undefined ||
    order === null ||
    order.subtotalPriceV2 === undefined
  ) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }

  const paymentData: Array<PaymentDetailsProps> = [
    // {
    //   name: t('Items'),
    //   value: formatCurrency(Number(order.subtotalPriceV2?.amount)),
    // },
    {
      name: t('Delivery fee'),
      value:
        order.totalShippingPriceV2.amount === 0
          ? t('Free')
          : formatCurrency(Number(order.totalShippingPriceV2.amount)),
    },
    // {
    //   name: 'GST',
    //   value: formatCurrency(Number(order.totalTaxV2?.amount)),
    // },
    {
      name: t('Total'),
      value: formatCurrency(Number(order.totalPriceV2.amount)),
    },
  ];

  const lineItems = mapToLineItems(order.lineItems);
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        contentContainerStyle={[
          styles.container,
          Platform.select({ web: { paddingTop: 24 } }),
        ]}
      >
        <PromoContent placement="confirmation-screen-1" />
        <View
          style={{
            paddingVertical: 18,
            paddingHorizontal: 18,
            borderBottomWidth: 1,
            borderBottomColor: 'rgba(0,0,0,.035)',
          }}
        >
          {live ? (
            <Text
              style={{ fontSize: 22, marginBottom: 10, paddingVertical: 16 }}
              weight="bold"
            >
              Order Confirmed
            </Text>
          ) : null}

          <Text
            style={{
              lineHeight: 30,
              fontSize: FONT_SIZE.small,
              opacity: 0.4,

              marginLeft: 1,
            }}
          >
            ID #{order.orderNumber}
          </Text>
        </View>

        <View style={styles.deliveryInfo}>
          <View style={styles.deliveryInfoInner}>
            <ListIconItem
              title={`${order.shippingAddress?.address1}, ${order.shippingAddress?.city}, ${order.shippingAddress?.zip}`}
              icon="map-marker-outline"
            />

            {isOrderBeingTracked ? (
              <ListIconItem
                title={`${orderCurrentlyTracked.expectedTimeDisplayString}`}
                icon="calendar-clock"
              />
            ) : (
              <ListIconItem
                title={`Placed: ${formatDateTime(order.processedAt)}`}
                icon="shopping-outline"
              />
            )}
          </View>
        </View>
        <View style={styles.yourOrder}>
          <View style={styles.yourOrderInner}>
            {lineItems.map((item) => (
              <OrderItem
                orderItem={item}
                containerStyle={styles.orderItem}
                key={item.variantID}
              />
            ))}
          </View>
        </View>
        <View style={styles.orderSummary}>
          <PaymentDetails data={paymentData} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  orderItem: {
    paddingVertical: 12,
  },
  deliveryInfo: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,.035)',
  },
  deliveryInfoInner: {
    marginHorizontal: HORIZONTAL_GUTTER,
    paddingBottom: 12,
  },
  sectionTitle: {
    fontFamily: FONT_FAMILY.BOLD,
    marginTop: 15,
    marginBottom: 10,
  },
  deliveryInfoItem: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
  },
  yourOrder: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,.035)',
  },
  yourOrderInner: {
    marginHorizontal: HORIZONTAL_GUTTER,
    paddingBottom: 12,
  },
  orderSummary: {
    marginHorizontal: HORIZONTAL_GUTTER,
    paddingBottom: 12,
  },
});
