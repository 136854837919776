import React from 'react';

import { useNavigation } from '@react-navigation/native';

import { useAtomValue } from 'jotai';
import { OccasionList } from '../../core-ui';
import { CollectionSectionHeader } from '../../components/CollectionSectionHeader';
import { useAlcoholicCollection } from '../../hooks/useAlcoholicCollection';
import { AllScreensNavigationProp } from '../../navigation/types';
import { occasionsCollectionsAtom } from '../../hooks/collectionsAtoms';
import { ProductSearch } from '../../components/ProductSearch';
import { t } from '../../helpers/translate';

const OccasionsCollectionScene = () => {
  const occasions = useAtomValue(occasionsCollectionsAtom);

  const { promptUserIfAlcoholicCollection } = useAlcoholicCollection();

  const { navigate } =
    useNavigation<AllScreensNavigationProp<'OccasionsCollection'>>();

  return (
    <ProductSearch>
      <CollectionSectionHeader title={t('Moments')} />
      <OccasionList
        listKey="occasions"
        categories={occasions.map((collection) => {
          return {
            __type: 'Collection',
            id: collection.id,
            title: collection.title,
            handle: collection.handle,
            cursor: '',
            image: collection.image?.url,
            tags: collection.tags,
          };
        })}
        onSelect={(collection) => {
          promptUserIfAlcoholicCollection(collection, () => {
            navigate('ProductCollection', {
              collectionHandle: collection.handle,
              collectionTitle: collection.title,
              navigationLevel: 1,
            });
          });
        }}
      />
    </ProductSearch>
  );
};

export default OccasionsCollectionScene;
