import React from 'react';
import { Alert, Platform, Pressable, StyleSheet, View } from 'react-native';

import { useAtomValue, useSetAtom } from 'jotai';
import { defaultButton, defaultButtonLabel } from '../../../constants/theme';
import { inventoryRecordFamily } from '../../../hooks/inventoryAtoms';
import { Product } from '../../../hooks/productsAtoms';
import {
  lineItemAtomFamily,
  setQuantityOfLineItemAtom,
} from '../../../hooks/checkoutAtoms';
import { trackEvent } from '../../../analytics';
import { Text } from '../../../core-ui';
import { branding } from '../../../branding';
import { triggerHaptic } from '../../../utils/haptic';
import { triggerAlert } from '../../../utils/alert';

export type AddToCartProps = {
  product: Product;
};

export function AddToCart({ product }: AddToCartProps) {
  const inventoryRecordAtom = inventoryRecordFamily({ shopifyId: product.id });
  const inventoryRecord = useAtomValue(inventoryRecordAtom);
  const isUnavailable =
    inventoryRecord?.count === 0 ||
    inventoryRecord?.status === 'TemporarilyUnavailable';
  const quantityAvailable = inventoryRecord?.count || 0;
  const variant = product.variants.nodes?.[0];
  const variantId = variant?.id;

  const lineItem = useAtomValue(
    lineItemAtomFamily({ variantID: variantId || '' }),
  );
  const { quantity } = lineItem;
  const setQuantityOfLineItem = useSetAtom(setQuantityOfLineItemAtom);
  const isItemInCart = quantity > 0;

  const addButtonAction = async () => {
    triggerHaptic();
    setQuantityOfLineItem({
      variantID: variantId || '',
      quantity: 1,
      queueSync: true,
    });
    trackEvent('add_to_cart', {
      variantId,
      title: product?.title,
      view: 'details',
    });
  };

  const onIncrease = async () => {
    triggerHaptic();
    if (quantityAvailable < quantity + 1) {
      triggerAlert(
        'Sorry, we only have ' + inventoryRecord?.count + ' left in stock.',
      );

      return;
    }

    setQuantityOfLineItem({
      variantID: variantId || '',
      quantity: lineItem.quantity + 1,
      queueSync: true,
    });
    trackEvent('adjust_cart_quantity_from_details', {
      variantId,
      title: product?.title,
      quantity: lineItem.quantity + 1,
      increase: true,
    });
  };

  const onDecrease = async () => {
    triggerHaptic();
    setQuantityOfLineItem({
      variantID: variantId || '',
      quantity: lineItem.quantity - 1,
      queueSync: true,
    });
    trackEvent('adjust_cart_quantity_from_details', {
      variantId,
      title: product?.title,
      quantity: lineItem.quantity - 1,
      increase: false,
    });
  };

  const buttonLabel = () => {
    if (!product.id) {
      return 'Unavailable';
    }
    if (isUnavailable) {
      return 'Sold out';
    }

    return 'Add to Cart';
  };

  return (
    <View style={styles.bottomIconContainer}>
      <Pressable
        style={[
          defaultButton,
          styles.flex,
          {
            flexDirection: 'row',
            backgroundColor: branding.buttonBackgroundColor,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            padding: 14,
          },
        ]}
        onPress={addButtonAction}
      >
        <Text
          style={[
            defaultButtonLabel,
            {
              flex: 1,
              flexGrow: 2,
              alignSelf: 'center',
              color: branding.buttonTextColor,
            },
            { textAlign: quantity >= 1 ? 'left' : 'center' },
          ]}
        >
          {buttonLabel()}
        </Text>
        {isItemInCart && (
          <>
            <Pressable
              onPress={() => {
                onDecrease();
              }}
              hitSlop={10}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: 'white',
                paddingHorizontal: 2,
                backgroundColor: '#fff',
                width: 32,
                height: 32,
                borderRadius: 17,
              }}
            >
              <Text
                style={{
                  color: '#333',
                  fontSize: 21,
                  lineHeight: 22,
                  top: 1,
                }}
              >
                –
              </Text>
            </Pressable>

            <Text
              weight="bold"
              style={{
                color: branding.buttonTextColor,
                marginHorizontal: 15,
                fontSize: 16,
              }}
            >
              {quantity}
            </Text>
            <Pressable
              onPress={() => {
                onIncrease();
              }}
              hitSlop={10}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: 'white',
                paddingHorizontal: 2,
                backgroundColor: '#fff',
                width: 32,
                height: 32,
                borderRadius: 17,
              }}
            >
              <Text
                style={{
                  color: '#333',
                  fontSize: 21,
                  lineHeight: 22,
                  top: 1,
                }}
              >
                +
              </Text>
            </Pressable>
          </>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  bottomIconContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
