import { StyleSheet, Text } from 'react-native';
import React from 'react';

import { FONT_FAMILY } from '../constants/fonts';

type Props = {
  title: string;
};

const HeaderTitle = ({ title }: Props) => {
  return <Text style={styles.title}>{title}</Text>;
};

export default HeaderTitle;

const styles = StyleSheet.create({
  title: {
    fontFamily: FONT_FAMILY.BOLD,
    textTransform: 'uppercase',
  },
});
