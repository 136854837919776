import {
  DefaultOptions,
  ApolloClient,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client';
import { persistCache, AsyncStorageWrapper } from 'apollo3-cache-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';
import { RetryLink } from '@apollo/client/link/retry';

import { STOREFRONT_ACCESS_TOKEN, STOREFRONT_API_URL } from '../../env';

import { cache } from './cache';
import { SHOPPING_CART } from './client/shoppingCartQueries';
import {
  GET_AUTHENTICATED_USER,
  GET_RECENT_SEARCH,
  GET_SHOPIFY_AUTH_DATA,
} from './client/clientQueries';

const httpLink = new HttpLink({
  uri: STOREFRONT_API_URL,
});

// Waiting on https://github.com/DiederikvandenB/apollo-link-sentry/pull/431
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    headers: {
      'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
    },
  }));

  return forward(operation);
});

const rateLimitPassToRetryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      if (
        data.errors.find(
          (value) =>
            value.message ===
            'Too many requests. Please try again in a few seconds',
        )
      ) {
        throw new Error('GraphQL Operational Error');
      }
    }
    return data;
  });
});

const retryLink = new RetryLink({ delay: { initial: 1000 } });

const localErrorLink = onError(
  ({ operation, response, graphQLErrors, networkError, forward }) => {
    console.error('Error with GraphQL query');
    console.error(operation);
    console.error(response);

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  },
);

async function setupPersistCache() {
  await persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
    debug: true,
  });
}

function setupApolloClient() {
  setupPersistCache();

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
    query: {
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  let linkChain: Array<ApolloLink> = [];

  if (__DEV__) {
    linkChain = [
      authLink,
      localErrorLink,
      retryLink,
      rateLimitPassToRetryLink,
      httpLink,
    ];
  } else {
    linkChain = [
      authLink,
      new SentryLink(),
      retryLink,
      rateLimitPassToRetryLink,
      httpLink,
    ];
  }

  // TODO: Check if this function is working.
  // This function does not seem to do what is expected, possibly because it's using GraphQL queries rather than
  // mutations?
  const writeInitialData = async () => {
    cache.writeQuery({
      query: GET_AUTHENTICATED_USER,
      data: {
        authenticatedUser: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
      },
    });

    cache.writeQuery({
      query: GET_SHOPIFY_AUTH_DATA,
      data: {
        shopifyAuthData: {
          expiresAt: '',
        },
      },
    });

    cache.writeQuery({
      query: SHOPPING_CART,
      data: {
        shoppingCart: {
          id: '',
          items: [],
        },
      },
    });

    cache.writeQuery({
      query: GET_RECENT_SEARCH,
      data: {
        recentSearch: [],
      },
    });
  };

  const apolloClient = new ApolloClient({
    connectToDevTools: true,
    // name: 'react-native-client',
    cache: cache,
    defaultOptions: defaultOptions,
    link: from(linkChain),
  });

  writeInitialData();

  apolloClient.onResetStore(writeInitialData);

  return apolloClient;
}

export const client = setupApolloClient();
