import { useAtom } from 'jotai';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

import { COLORS } from '../constants/colors';
import { FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import { getFullName } from '../helpers/getFullName';
import { t } from '../helpers/translate';
import { addressAtom } from '../hooks/addressAtoms';

type Props = {
  onEditPressed: () => void;
};

export default function CheckoutAddress({ onEditPressed }: Props) {
  const [address] = useAtom(addressAtom);

  if (!address) {
    return (
      <View
        style={{
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    );
  }
  const { firstName, lastName } = address;

  const fullName = getFullName(firstName || undefined, lastName || undefined);
  const addressLines = [
    address.address1,
    `${address.city}, ${address.province} ${address.zip}`,
  ];
  return (
    <View style={styles.rowFlex}>
      <View style={styles.textContainer}>
        <View style={styles.nameText}>
          <Text style={styles.label}>{fullName}</Text>
          <TouchableOpacity onPress={onEditPressed}>
            <Text style={[styles.label, styles.textCapitalized]}>
              {t('Edit')}
            </Text>
          </TouchableOpacity>
        </View>
        {addressLines.map((item) =>
          item ? (
            <Text key={item} style={[styles.address, styles.opacity]}>
              {item}
            </Text>
          ) : (
            <Text>{t('No Addresses To Display')}</Text>
          ),
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  textContainer: { flex: 1, paddingBottom: 12 },
  rowFlex: { flexDirection: 'row' },
  label: {
    fontSize: FONT_SIZE.medium,
    lineHeight: LINE_HEIGHT.medium,
    color: COLORS.black,
  },
  textCapitalized: {
    textTransform: 'uppercase',
  },
  nameText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  address: {
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
    color: COLORS.black,
    marginTop: 6,
  },
  opacity: {
    opacity: 0.6,
  },
});
