import { branding } from '../branding';

export const FONT_FAMILY = {
  REGULAR: branding.font.regular,
  BOLD: branding.font.bold,
};

export const FONT_SIZE = {
  extraSmall: 12,
  small: 14,
  medium: 16,
  large: 18,
  extraLarge: 24,
  giant: 30,
};

export const LINE_HEIGHT = {
  extraSmall: 14,
  small: 16,
  medium: 18,
  large: 20,
  extraLarge: 26,
  giant: 32,
};
