/**
 * Manages state between Shopify and React state via jotai Atoms
 *
 * Shopify Lifecycle:
 * - `createCheckout`
 * Called as soon as app boots
 *
 * - `checkoutCustomerAssociateV2`
 * Called when customer logs in, or customer is logged in and item is added
 *
 * - `checkoutShippingAddressUpdateV2`
 * Called automatically when address is set and after lineitems are sync'd (line items are required). Also updates shipping lines
 *
 * - `checkoutShippingLineUpdate`
 * Updates shipping option (Turbo, Pool)
 *
 * - `checkoutAttributesUpdateV2`
 * Updates order note
 *
 * - `checkoutLineItemsReplace`
 * Syncs line items
 *
 * - `checkoutDiscountCodeApplyV2` `checkoutDiscountCodeRemove`
 * Sync discount code
 *
 * - `checkoutCompleteWithTokenizedPaymentV3`
 * Completes payment and checkout
 */

/* eslint-disable no-underscore-dangle */
import { atom, Getter, Setter } from 'jotai';
import { atomFamily, selectAtom } from 'jotai/utils';
import numeral, { Numeral } from 'numeral';
import { debounce, isEqual } from 'lodash';

import { formatISO } from 'date-fns';
import { client } from '../graphql/client';
import { LineItem, ShippingRate } from '../types/types';
import { COUNTRY_DATA } from '../constants/defaultValues';
import {
  checkoutCreate,
  checkoutCreateVariables,
  checkoutCreate_checkoutCreate_checkout as CheckoutCreate,
} from '../../src/generated/server/checkoutCreate';
import {
  checkoutDiscountCodeApplyV2,
  checkoutDiscountCodeApplyV2Variables,
  checkoutDiscountCodeApplyV2_checkoutDiscountCodeApplyV2_checkout as CheckoutDiscountCodeApplyV2,
} from '../../src/generated/server/checkoutDiscountCodeApplyV2';
import {
  checkoutDiscountCodeRemove,
  checkoutDiscountCodeRemoveVariables,
} from '../../src/generated/server/checkoutDiscountCodeRemove';
import {
  checkoutCustomerAssociateV2,
  checkoutCustomerAssociateV2Variables,
  //checkoutCustomerAssociateV2_checkoutCustomerAssociateV2_checkout as CheckoutCustomerAssociateV2,
} from '../../src/generated/server/checkoutCustomerAssociateV2';
import {
  checkoutShippingLineUpdate,
  checkoutShippingLineUpdateVariables,
  checkoutShippingLineUpdate_checkoutShippingLineUpdate_checkout as CheckoutShippingLineUpdate,
} from '../../src/generated/server/checkoutShippingLineUpdate';
import {
  checkoutShippingAddressUpdateV2,
  checkoutShippingAddressUpdateV2Variables,
} from '../../src/generated/server/checkoutShippingAddressUpdateV2';
import {
  checkoutAttributesUpdateV2,
  checkoutAttributesUpdateV2Variables,
} from '../../src/generated/server/checkoutAttributesUpdateV2';
import {
  CustomerAddNewAddress,
  CustomerAddNewAddressVariables,
} from '../../src/generated/server/CustomerAddNewAddress';
import {
  CustomerSetDefaultAddress,
  CustomerSetDefaultAddressVariables,
} from '../../src/generated/server/CustomerSetDefaultAddress';
import {
  CHECKOUT_CREATE,
  CHECKOUT_CUSTOMER_ASSOCIATE_V2,
  CHECKOUT_LINE_ITEMS_REPLACE,
  CHECKOUT_DISCOUNT_CODE_APPLY_V2,
  CHECKOUT_DISCOUNT_REMOVE,
  CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2,
  CHECKOUT_SHIPPING_LINE_UPDATE,
  CHECKOUT_ATTRIBUTES_UPDATE_V2,
} from '../graphql/server/checkout';
import {
  CUSTOMER_ADD_NEW_ADDRESS,
  CUSTOMER_SET_DEFAULT_ADDRESS,
} from '../graphql/server/customerAddress';
import { log, logError } from '../utils/LoggingUtils';
import { retryQuery } from '../utils/retryQuery';
import { mapToLineItems } from '../helpers/mapToLineItems';
import { trackEvent } from '../analytics';
import { alertNetworkError } from '../utils/NetworkErrorAlert';
import {
  customerAccessTokenAtom,
  hasCustomerProvidedPersonalDetailsAtom,
} from './customerAtoms';
import { allProductsAtom } from './productsAtoms';
import { addressAtom } from './addressAtoms';
import { inventoryRecordsAtom } from './inventoryAtoms';
import {
  expectedStandardDeliveryTimeAtom,
  expectedTimeDisplayStringAtom,
  expectedTurboDeliveryTimeAtom,
  isScheduledAtom,
  scheduledSlotAtom,
} from './scheduledTimeAtoms';
import { hubAtom } from './hubsAtoms';

/**
 * Shows a UI element with state displayed for debugging
 */
const isCheckoutDebugEnabledAtom = atom(false);

/**
 * Checkout ID
 */
const checkoutIdAtom = atom<string | null>(null);

/**
 * LineItems
 */
type LineItemMetadataProperties = {
  alcoholBeerOrCiderUnitCount: number | null;
  alcoholWineBottleCount: number | null;
  alcoholSpiritUnitCount: number | null;
};

const lineItemAtomFamily = atomFamily(
  ({ variantID }: { variantID: LineItem['variantID'] }) => {
    return atom(
      {
        variantID,
        variant: '',
        productId: '',
        title: '',
        priceAfterDiscount: 0,
        originalPrice: 0,
        image: '',
        quantity: 0,
        alcoholBeerOrCiderUnitCount: 0,
        alcoholWineBottleCount: 0,
        alcoholSpiritUnitCount: 0,
      },

      // ⚠️ Populate LineItem on write
      // NB: ideally should be done in the `get` function of the atom, but it's not possible to do async operations there
      (
        get,
        set,
        properties: Partial<LineItem & LineItemMetadataProperties>,
      ) => {
        const products = get(allProductsAtom);
        const product = products.find(
          (product) => product.variants.nodes[0].id === variantID,
        );
        if (!product) {
          logError('unknown product', { variantID });
          return;
        }

        const variant = product.variants.nodes[0];
        set(lineItemAtomFamily({ variantID }), {
          variantID,
          variant: '', //variant.title,
          productId: product.id,
          title: product.title,
          priceAfterDiscount: variant.priceV2.amount,
          originalPrice: variant.priceV2.amount,
          image: product.images.nodes[0]?.url,
          quantity: 0,
          alcoholWineBottleCount: product.alcoholWineBottleCount?.value
            ? Number(product.alcoholWineBottleCount?.value)
            : 0,
          alcoholBeerOrCiderUnitCount: product.alcoholBeerOrCiderUnitCount
            ?.value
            ? Number(product.alcoholBeerOrCiderUnitCount?.value)
            : 0,
          alcoholSpiritUnitCount: product.alcoholSpiritUnitCount?.value
            ? Number(product.alcoholSpiritUnitCount?.value)
            : 0,
          ...properties,
        });
      },
    );
  },
  (a, b) => {
    return a.variantID === b.variantID;
  },
);
const lineItemsVariantIdsInCartAtom = atom<LineItem['variantID'][]>([]); // NB: used as source of truth
const lineItemsInCartAtom = atom(
  (get) => {
    const variantIds = get(lineItemsVariantIdsInCartAtom);
    return variantIds.map((variantId) =>
      get(lineItemAtomFamily({ variantID: variantId })),
    );
  },
  (get, set, lineItems: LineItem[]) => {
    const variantIds = lineItems.map((lineItem) => lineItem.variantID);
    set(lineItemsVariantIdsInCartAtom, variantIds);

    lineItems.forEach((lineItem) => {
      set(lineItemAtomFamily({ variantID: lineItem.variantID }), lineItem);
    });
  },
);
const emptyLineItemsInCartAtom = atom(null, (get, set) => {
  trackEvent('empty_cart');
  get(lineItemsVariantIdsInCartAtom).forEach((id) => {
    set(setQuantityOfLineItemAtom, {
      variantID: id,
      quantity: 0,
      queueSync: false,
    });
  });
});
const setQuantityOfLineItemAtom = atom(
  null,
  (
    get,
    set,
    {
      variantID,
      quantity,
      queueSync, // if true, it will sync the line items with Shopify
    }: { variantID: string; quantity: number; queueSync: boolean },
  ) => {
    const lineItemAtom = lineItemAtomFamily({ variantID });
    set(lineItemAtom, { quantity });

    if (quantity) {
      const lineItemsVariantIds = get(lineItemsVariantIdsInCartAtom);
      if (!lineItemsVariantIds.includes(variantID)) {
        set(lineItemsVariantIdsInCartAtom, [...lineItemsVariantIds, variantID]);
      }
    } else {
      const lineItemsVariantIds = get(lineItemsVariantIdsInCartAtom);
      set(
        lineItemsVariantIdsInCartAtom,
        lineItemsVariantIds.filter((id) => id !== variantID),
      );
    }

    //const discountCodeIsValid = get(discountCodeIsValidAtom);
    set(isTotalPriceCalculatingAtom, true);

    if (queueSync) {
      localIdempotencyKey++;
      debouncedSyncLineItemsWithShopifyAtom(set);
    }
  },
);
const lineItemsTotalQuantityAtom = atom((get) => {
  const lineItemVariantIDs = get(lineItemsVariantIdsInCartAtom);
  return lineItemVariantIDs.reduce((acc, variantID) => {
    return acc + get(lineItemAtomFamily({ variantID })).quantity;
  }, 0);
});

const unavailableLineItemsAtom = atom((get) => {
  // Identify line items with no available inventory
  const lineItems = get(lineItemsInCartAtom);
  const inventory = get(inventoryRecordsAtom);

  const unavailableLineItems = lineItems
    .map((lineItem) => {
      const inventoryRecord = inventory.find(
        (record) =>
          `gid://shopify/Product/${record.product_shopify_id}` ===
          lineItem.productId,
      );
      let countToRemove = 0;

      if (!inventoryRecord || inventoryRecord.status !== 'Available') {
        countToRemove = lineItem.quantity;
      } else {
        const count = inventoryRecord.count;
        if (count < lineItem.quantity) {
          countToRemove = lineItem.quantity - count;
        }
      }

      return {
        countToRemove,
        lineItem,
      };
    })
    .filter(({ countToRemove }) => countToRemove > 0);

  return unavailableLineItems;
});

/**
 * Discount code
 */
const discountCodeAtom = atom('');
const discountCodeIsValidAtom = atom(false);
const isDiscountCodeSyncingAtom = atom(false);
const discountCodeErrorMessageAtom = atom('');

/**
 * ShippingLine/Delivery
 */
const shippingRateAtom = atom<ShippingRate | null>(null);
const shippingRateTitleAtom = selectAtom(shippingRateAtom, (rate) => {
  return rate?.title || '';
});
const updateShippingRateAtom = atom(
  null,
  (get, set, rate: ShippingRate | null) => {
    set(shippingRateAtom, rate);
    set(isTotalPriceCalculatingAtom, true);
    debouncedSyncShippingLineWithShopifyAtom(set);
  },
);
const availableShippingRatesAtom = atom<ShippingRate[]>([]);

/**
 * Note
 */
const noteAtom = atom('', (get, set, note: string) => {
  set(noteAtom, note);
  debouncedSyncNoteWithShopifyAtom(set);
});

/**
 * Totals
 */
const shippingPriceAtom = atom<Numeral>(numeral(0));
const lineItemsDiscountTotalAtom = atom<Numeral>(numeral(0));
const shippingDiscountTotalAtom = atom<Numeral>(numeral(0));

const discountTotalAtom = atom<Numeral>((get) => {
  const lineItemsDiscountTotal = get(lineItemsDiscountTotalAtom);
  const shippingDiscountTotal = get(shippingDiscountTotalAtom);
  const discount = lineItemsDiscountTotal
    .clone()
    .add(shippingDiscountTotal.value() || 0);

  return discount;
});

const lineItemsTotalPriceAtom = atom<Numeral>((get) => {
  const lineItemVariantIDs = get(lineItemsVariantIdsInCartAtom);
  const amount = lineItemVariantIDs.reduce((acc, variantID) => {
    const lineItem = get(lineItemAtomFamily({ variantID }));
    return acc.add(
      numeral(lineItem.priceAfterDiscount).multiply(lineItem.quantity).value(),
    );
  }, numeral(0));

  return amount;
});
const totalPriceLessDeliveryPriceAtom = atom<Numeral>((get) => {
  const totalPrice = get(totalPriceAtom);
  const deliveryPriceValue = get(shippingPriceAtom).value() || 0;

  if (deliveryPriceValue > (totalPrice?.value() || 0)) {
    return numeral(0);
  }

  return totalPrice.clone().subtract(deliveryPriceValue);
});
const subtotalPriceAtom = atom<Numeral>(numeral(0));

const totalTaxAtom = atom<Numeral>((get) => {
  const GST_RATE = 0.15;
  return numeral(get(totalPriceAtom)).multiply(GST_RATE);
});
const isTotalPriceCalculatingAtom = atom(false);
const totalPriceAtom = atom<Numeral>(numeral(0.0));

let localIdempotencyKey = 0;

const syncLineItemsAndAddShippingAddressRequests: Promise<unknown>[] = [];

// Define a function that bundle multiple requests into one request and returns a promise that will be
// resolved when all the requests have been executed and resolved
const bundleRequests = (cb: () => Promise<unknown>): void => {
  // Add the asynchronous function to the queue
  syncLineItemsAndAddShippingAddressRequests.push(cb());
};

const isSyncingLineItemsWithShopifyAtom = atom<boolean>(false);
const syncLineItemsWithShopifyAtom = atom(null, async (get, set) => {
  bundleRequests(async () => {
    const checkoutId = get(checkoutIdAtom);
    if (!checkoutId) {
      return;
    }
    set(isTotalPriceCalculatingAtom, true);
    set(isSyncingLineItemsWithShopifyAtom, true); // this will only be reset in the associate shipping line atom. When every line items syncs are done.
    const lineItems = get(lineItemsInCartAtom);

    try {
      const variables = {
        checkoutID: checkoutId, //  🥹
        lineItems: lineItems.map((lineItem) => ({
          variantId: lineItem.variantID,
          quantity: lineItem.quantity,
        })),
        country: COUNTRY_DATA.countryCode,
      };
      log('[SHOPIFY API] CHECKOUT_LINE_ITEMS_REPLACE stated');
      const requestLocalIdempotencyKey = Number(localIdempotencyKey);
      const response = await client.mutate({
        mutation: CHECKOUT_LINE_ITEMS_REPLACE,
        variables,
      });

      log('[SHOPIFY API] CHECKOUT_LINE_ITEMS_REPLACE completd');

      if (!response.data.checkoutLineItemsReplace.checkout) {
        logError('syncLineItemsWithShopifyAtom unexpected response', response);

        // Monkey patch fix
        if (
          response.data.checkoutLineItemsReplace.userErrors?.[0]?.message ===
          'Checkout does not exist'
        ) {
          set(checkoutIdAtom, null);
        }

        return;
      }

      // Rudimentary way to prioritise local state and prevent stale server state
      // overwriting fresh local state when rapidly changing quantities
      if (requestLocalIdempotencyKey === localIdempotencyKey) {
        _setCheckoutStateAtomsFromShopifyResponse(
          response.data.checkoutLineItemsReplace.checkout,
          get,
          set,
        );
      } else {
        trackEvent('localIdempotencyKey');
      }

      // Associate customer with checkout - this is a safety net, as the logic is not bullet proof
      const hasAssociatedCustomerWithShopifyCheckout = get(
        hasAssociatedCustomerWithShopifyCheckoutAtom,
      );
      if (!hasAssociatedCustomerWithShopifyCheckout) {
        await set(associateCustomerWithShopifyCheckoutAtom);
      }
    } catch (e) {
      alertNetworkError();
      logError('syncLineItemsWithShopifyAtom error', e);
    }
  });
});

const syncDiscountCodeWithShopifyAtom = atom(null, async (get, set) => {
  const checkoutId = get(checkoutIdAtom);
  const discountCode = get(discountCodeAtom);
  if (!checkoutId) {
    return;
  }
  set(isDiscountCodeSyncingAtom, true);
  set(isTotalPriceCalculatingAtom, true);

  const lineItems = get(lineItemsInCartAtom);

  try {
    const variables = {
      checkoutId: checkoutId, //  🥹
      lineItems: lineItems.map((lineItem) => ({
        variantId: lineItem.variantID,
        quantity: lineItem.quantity,
      })),
      discountCode,
      country: COUNTRY_DATA.countryCode,
    };

    // Add discount code
    if (discountCode !== '') {
      log('[SHOPIFY API] CHECKOUT_DISCOUNT_CODE_APPLY_V2 started');
      const response = await client.mutate<
        checkoutDiscountCodeApplyV2,
        checkoutDiscountCodeApplyV2Variables
      >({
        mutation: CHECKOUT_DISCOUNT_CODE_APPLY_V2,
        variables,
      });
      log('[SHOPIFY API] CHECKOUT_DISCOUNT_CODE_APPLY_V2 completed');

      const checkoutUserErrors =
        response?.data?.checkoutDiscountCodeApplyV2?.checkoutUserErrors || [];

      if (checkoutUserErrors.length) {
        set(discountCodeIsValidAtom, false);
        if (
          checkoutUserErrors[0].message ===
          'Discount code Unable to find a valid discount matching the code entered'
        ) {
          set(discountCodeErrorMessageAtom, 'Invalid code');
        } else {
          set(discountCodeErrorMessageAtom, checkoutUserErrors[0].message);
        }
      } else {
        set(discountCodeIsValidAtom, true);
        set(discountCodeErrorMessageAtom, '');
      }

      const checkoutPayload =
        response.data?.checkoutDiscountCodeApplyV2?.checkout;
      if (checkoutPayload) {
        _setCheckoutStateAtomsFromShopifyResponse(checkoutPayload, get, set);
      } else {
        logError('no checkout payload', response);
      }
    } else {
      log('[SHOPIFY API] CHECKOUT_DISCOUNT_REMOVE started');
      const response = await client.mutate<
        checkoutDiscountCodeRemove,
        checkoutDiscountCodeRemoveVariables
      >({
        mutation: CHECKOUT_DISCOUNT_REMOVE,
        variables,
      });
      log('[SHOPIFY API] CHECKOUT_DISCOUNT_REMOVE completed');

      const checkoutPayload =
        response.data?.checkoutDiscountCodeRemove?.checkout;

      if (checkoutPayload) {
        _setCheckoutStateAtomsFromShopifyResponse(checkoutPayload, get, set);
      } else {
        logError('no checkout payload', response);
      }

      set(discountCodeIsValidAtom, false);
      set(discountCodeErrorMessageAtom, '');
    }
  } catch (e) {
    alertNetworkError();
    logError('syncDiscountCodeWithShopifyAtom errors', e);
  }
  set(isDiscountCodeSyncingAtom, false);
  set(isTotalPriceCalculatingAtom, false);
});
const syncShippingLineWithShopifyAtom = atom(null, async (get, set) => {
  const checkoutId = get(checkoutIdAtom);
  if (!checkoutId) {
    return;
  }
  const shippingRate = get(shippingRateAtom);
  if (!shippingRate) {
    return;
  }

  set(isTotalPriceCalculatingAtom, true);
  try {
    const variables = {
      checkoutId: checkoutId, //  🥹
      shippingRateHandle: shippingRate.handle,
      country: COUNTRY_DATA.countryCode,
    };
    log('[SHOPIFY API] CHECKOUT_SHIPPING_LINE_UPDATE started');
    const response = await client.mutate<
      checkoutShippingLineUpdate,
      checkoutShippingLineUpdateVariables
    >({
      mutation: CHECKOUT_SHIPPING_LINE_UPDATE,
      variables,
    });
    log('[SHOPIFY API] CHECKOUT_SHIPPING_LINE_UPDATE completed');

    const checkout = response.data?.checkoutShippingLineUpdate?.checkout;
    if (checkout) {
      _setCheckoutStateAtomsFromShopifyResponse(checkout, get, set);
    } else {
      logError('syncShippingLineWithShopifyAtom - no checkout payload', {
        response,
        variables,
      });
    }
  } catch (e) {
    alertNetworkError();
    logError('syncShippingLineWithShopifyAtom errors', e);
  }
  set(isTotalPriceCalculatingAtom, false);
});

const noteToSendToShopifyAtom = atom((get) => {
  const note = get(noteAtom);
  const scheduledSlot = get(scheduledSlotAtom);
  const scheduledTimeDisplay = get(expectedTimeDisplayStringAtom);
  const isScheduled = get(isScheduledAtom);

  let noteWithSlotAttached =
    note + (isScheduled ? ' scheduled' : '') + ' ' + scheduledTimeDisplay;

  if (isScheduled && scheduledSlot) {
    noteWithSlotAttached =
      (note ? note + '\n\n' : '') +
      'SCHEDULED\n' +
      scheduledTimeDisplay +
      '\n\n[Expected Time:' +
      formatISO(scheduledSlot) +
      ']';
  }

  return noteWithSlotAttached;
});

const syncNoteWithShopifyAtom = atom(null, async (get, set) => {
  const checkoutId = get(checkoutIdAtom);
  if (!checkoutId) {
    return;
  }

  const note = get(noteToSendToShopifyAtom);
  const scheduledTimeDisplay = get(expectedTimeDisplayStringAtom);
  const scheduledSlot = get(scheduledSlotAtom);
  const isScheduled = get(isScheduledAtom);
  const shippingRateTitle = get(shippingRateTitleAtom);
  const expectedStandardDeliveryTime = get(expectedStandardDeliveryTimeAtom);
  const expectedTurboDeliveryTime = get(expectedTurboDeliveryTimeAtom);
  const hub = get(hubAtom);

  try {
    const variables = {
      checkoutId: checkoutId, //  🥹
      input: {
        note: note,
        customAttributes:
          isScheduled && scheduledSlot
            ? [
                {
                  key: 'scheduled',
                  value: scheduledTimeDisplay,
                },
                {
                  key: 'expected_time',
                  value: formatISO(scheduledSlot),
                },
                {
                  key: 'hub_id',
                  value: hub?.id ?? '',
                },
              ]
            : [
                {
                  key: 'hub_id',
                  value: hub?.id ?? '',
                },
                {
                  key: 'expected_time',
                  value: shippingRateTitle.includes('Standard')
                    ? formatISO(expectedStandardDeliveryTime)
                    : formatISO(expectedTurboDeliveryTime),
                },
              ],
      },
    };
    log('[SHOPIFY API] CHECKOUT_ATTRIBUTES_UPDATE_V2 started');
    await client.mutate<
      checkoutAttributesUpdateV2,
      checkoutAttributesUpdateV2Variables
    >({
      mutation: CHECKOUT_ATTRIBUTES_UPDATE_V2,
      variables,
    });
    log('[SHOPIFY API] CHECKOUT_ATTRIBUTES_UPDATE_V2 completed');
  } catch (e) {
    alertNetworkError();
    logError('syncNoteWithShopifyAtom errors', e);
  }
});
const debouncedSyncNoteWithShopifyAtom = debounce(
  (set) => {
    set(syncNoteWithShopifyAtom);
  },
  350,
  { leading: false, trailing: true },
);
const debouncedSyncShippingLineWithShopifyAtom = debounce(
  (set) => {
    set(syncShippingLineWithShopifyAtom);
  },
  250,
  { leading: false, trailing: true },
);
const debouncedSyncLineItemsWithShopifyAtom = debounce(
  (set) => {
    // 'set' is passed in order to call the atom's set function
    set(syncLineItemsWithShopifyAtom);
  },
  350,
  { leading: false, trailing: true },
);

// Load and Setup
let isInitiating = false;
const initiateShopifyCheckoutAtom = atom(null, async (get, set) => {
  const initiateCheckout = async () => {
    const checkoutId = get(checkoutIdAtom);
    if (checkoutId) {
      return;
    }

    if (isInitiating) {
      return;
    }
    isInitiating = true;

    try {
      log('[SHOPIFY API] CHECKOUT_CREATE start');
      const response = await client.mutate<
        checkoutCreate,
        checkoutCreateVariables
      >({
        mutation: CHECKOUT_CREATE,
        variables: {
          checkoutCreateInput: {},
          country: COUNTRY_DATA.countryCode,
        },
      });
      log('[SHOPIFY API] CHECKOUT_CREATE completed');
      const newCheckoutId = response.data?.checkoutCreate?.checkout?.id;
      if (newCheckoutId) {
        set(checkoutIdAtom, newCheckoutId);
      } else {
        logError('no checkout id returned from Shopify', response);
      }

      //set(associateCustomerWithShopifyCheckoutAtom);
    } catch (e) {
      alertNetworkError();
      logError('initiateShopifyCheckoutAtom errors', e);
    }
  };

  await retryQuery(initiateCheckout, 'initiate checkout query');
  isInitiating = false;
});
const hasAssociatedCustomerWithShopifyCheckoutAtom = atom<boolean>(false);
const associateCustomerWithShopifyCheckoutAtom = atom(
  null,
  async (get, set) => {
    const checkoutId = get(checkoutIdAtom);
    const customerAccessToken = get(customerAccessTokenAtom);

    const hasCustomerProvidedPersonalDetails = get(
      hasCustomerProvidedPersonalDetailsAtom,
    );

    if (!hasCustomerProvidedPersonalDetails) {
      return;
    }

    if (!checkoutId) {
      return;
    }

    if (!customerAccessToken) {
      return;
    }

    try {
      log('[SHOPIFY API] CHECKOUT_CUSTOMER_ASSOCIATE_V2 start');
      const response = await client.mutate<
        checkoutCustomerAssociateV2,
        checkoutCustomerAssociateV2Variables
      >({
        mutation: CHECKOUT_CUSTOMER_ASSOCIATE_V2,
        variables: {
          checkoutId,
          customerAccessToken,
        },
      });
      log('[SHOPIFY API] CHECKOUT_CUSTOMER_ASSOCIATE_V2 complete');
      set(hasAssociatedCustomerWithShopifyCheckoutAtom, true);
      //set(checkoutIdAtom, response.data.checkoutCustomerAssociate.checkout.id);
    } catch (e) {
      alertNetworkError();
      logError('associateCustomerWithCheckoutAtom errors', e);
    }
  },
);

const isAssociatingAddressWithShopifyCheckoutAtom = atom<boolean>(false);
const hasAssociatedAddressWithShopifyCheckoutAtom = atom<boolean>(false);
const associateShippingAddressWithShopifyCheckoutAtom = atom(
  null,
  async (get, set) => {
    const checkoutId = get(checkoutIdAtom);
    if (!checkoutId) {
      return;
    }
    const shippingAddress = get(addressAtom);
    if (!shippingAddress) {
      return;
    }

    const lineItems = get(lineItemsVariantIdsInCartAtom);
    // Shopify API requires at least one line item to associate an address
    if (lineItems.length === 0) {
      return;
    }

    const availableShippingRates = get(availableShippingRatesAtom);

    await Promise.all(syncLineItemsAndAddShippingAddressRequests);
    set(isSyncingLineItemsWithShopifyAtom, false);

    try {
      const variables = {
        checkoutId: checkoutId, //  🥹
        shippingAddress,
        country: COUNTRY_DATA.countryCode,
      };
      log('[SHOPIFY API] CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2 start');
      set(isAssociatingAddressWithShopifyCheckoutAtom, true);

      // slow connection
      // await new Promise((resolve) => setTimeout(resolve, 10000));

      const response = await client.mutate<
        checkoutShippingAddressUpdateV2,
        checkoutShippingAddressUpdateV2Variables
      >({
        mutation: CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2,
        variables,
      });
      log('[SHOPIFY API] CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2 complete');
      set(isAssociatingAddressWithShopifyCheckoutAtom, false);

      // Update shipping rates, now that we have the address
      const rawAvailableShippingRates =
        response.data?.checkoutShippingAddressUpdateV2?.checkout
          ?.availableShippingRates?.shippingRates || [];

      const newAvailableShippingRates = rawAvailableShippingRates.map(
        (rate) => ({
          handle: rate.handle,
          title: rate.title,
          price: rate.priceV2.amount,
        }),
      );

      const selectedShippingLineFromShopifyResponse =
        response.data?.checkoutShippingAddressUpdateV2?.checkout?.shippingLine;

      const shippingRate = get(shippingRateAtom);
      const haveRatesChanged = !isEqual(
        newAvailableShippingRates,
        availableShippingRates,
      );

      if (haveRatesChanged) {
        set(availableShippingRatesAtom, newAvailableShippingRates);
      }

      // Set first option as default
      if (
        (!shippingRate && newAvailableShippingRates.length) ||
        haveRatesChanged ||
        !selectedShippingLineFromShopifyResponse
      ) {
        set(updateShippingRateAtom, newAvailableShippingRates[0]);
      }
    } catch (e) {
      alertNetworkError();
      logError('syncShippingAddressWithShopifyAtom errors', e);
    }
    set(hasAssociatedAddressWithShopifyCheckoutAtom, true);
  },
);
const resetCheckoutAtom = atom(null, (get, set) => {
  set(checkoutIdAtom, null);
  set(emptyLineItemsInCartAtom);
  set(shippingRateAtom, null);
  set(noteAtom, '');
  set(hasAssociatedAddressWithShopifyCheckoutAtom, false);
  set(hasAssociatedCustomerWithShopifyCheckoutAtom, false);
  //set(initiateShopifyCheckoutAtom);
});

const saveAndSetDefaultAddressToShopifyCustomerAtom = atom(
  null,
  async (get) => {
    bundleRequests(async () => {
      const address = get(addressAtom);
      if (!address) {
        //console.log('skipped saving address to shopify because no address');
        return;
      }
      const customerAccessToken = get(customerAccessTokenAtom);
      if (!customerAccessToken) {
        //console.log('skipped saving address to shopify because no CAT');
        return;
      }

      try {
        const variables = {
          address,
          customerAccessToken,
        };
        log('[SHOPIFY API] CUSTOMER_ADD_NEW_ADDRESS started');
        const response = await client.mutate<
          CustomerAddNewAddress,
          CustomerAddNewAddressVariables
        >({
          mutation: CUSTOMER_ADD_NEW_ADDRESS,
          variables,
        });
        log('[SHOPIFY API] CUSTOMER_ADD_NEW_ADDRESS ended');

        if (response.data?.customerAddressCreate?.customerAddress?.id) {
          log('[SHOPIFY API] CUSTOMER_SET_DEFAULT_ADDRESS started');
          await client.mutate<
            CustomerSetDefaultAddress,
            CustomerSetDefaultAddressVariables
          >({
            mutation: CUSTOMER_SET_DEFAULT_ADDRESS,
            variables: {
              customerAccessToken,
              addressId:
                response.data?.customerAddressCreate?.customerAddress?.id,
            },
          });
          log('[SHOPIFY API] CUSTOMER_SET_DEFAULT_ADDRESS started');
        }
      } catch (e) {
        alertNetworkError();
        logError('syncShippingAddressWithShopifyAtom errors', e);
      }
    });
  },
);

async function _setCheckoutStateAtomsFromShopifyResponse(
  {
    id,
    lineItems: lineItemsFromGraphQLResponse,
    paymentDueV2,
    shippingLine,
    subtotalPriceV2,
    shippingDiscountAllocations,
  }: Partial<{
    id: string;
    lineItems: CheckoutCreate['lineItems'];
    paymentDueV2: CheckoutCreate['paymentDueV2'];
    shippingLine: CheckoutShippingLineUpdate['shippingLine'];
    subtotalPriceV2: CheckoutCreate['subtotalPriceV2'];
    shippingDiscountAllocations: CheckoutDiscountCodeApplyV2['shippingDiscountAllocations'];
  }>, // | CheckoutReplace | CheckoutDiscountApply,
  get: Getter,
  set: Setter,
) {
  if (id) {
    set(checkoutIdAtom, id);
  }
  if (paymentDueV2?.amount) {
    set(totalPriceAtom, numeral(+paymentDueV2.amount));
  }
  if (shippingLine?.priceV2.amount) {
    set(shippingPriceAtom, numeral(+shippingLine.priceV2.amount));
  }
  if (subtotalPriceV2?.amount) {
    set(subtotalPriceAtom, numeral(+subtotalPriceV2.amount));
  }

  if (shippingDiscountAllocations !== undefined) {
    if (shippingDiscountAllocations.length > 0) {
      set(
        shippingDiscountTotalAtom,
        numeral(shippingDiscountAllocations[0].allocatedAmount.amount),
      );
    } else {
      set(shippingDiscountTotalAtom, numeral(0));
    }
  }

  const lineItemsDiscountTotal = get(lineItemsDiscountTotalAtom);

  if (lineItemsFromGraphQLResponse) {
    const newLineItemsDiscountTotal = lineItemsFromGraphQLResponse.edges.reduce(
      (acc, edge) => {
        const { discountAllocations } = edge.node;
        const discountTotal = discountAllocations.reduce(
          (acc, allocation) => acc.add(+allocation.allocatedAmount.amount),
          numeral(0),
        );
        return acc.add(discountTotal.value());
      },
      numeral(0),
    );
    set(lineItemsDiscountTotalAtom, newLineItemsDiscountTotal);
    const lineItems: LineItem[] = mapToLineItems(lineItemsFromGraphQLResponse);

    if (lineItems.length === 0 && get(lineItemsInCartAtom).length > 0) {
      logError('line items from response is empty when basket not empty');
      trackEvent('line items from response is empty when basket not empty');
    } else {
      lineItems.forEach((lineItem) => {
        set(lineItemAtomFamily({ variantID: lineItem.variantID }), lineItem);
      });
    }
  }

  await Promise.all(syncLineItemsAndAddShippingAddressRequests);
  set(isTotalPriceCalculatingAtom, false);

  const isDiscountCodeValid = get(discountCodeIsValidAtom);

  if (isDiscountCodeValid && numeral(lineItemsDiscountTotal).value() === 0) {
    set(syncDiscountCodeWithShopifyAtom);
  }
}

export {
  isCheckoutDebugEnabledAtom,

  // Checkout Initiation
  checkoutIdAtom,
  associateCustomerWithShopifyCheckoutAtom,

  // Discounts
  discountCodeAtom,
  discountCodeIsValidAtom,
  discountCodeErrorMessageAtom,
  isDiscountCodeSyncingAtom,

  // Line items
  lineItemAtomFamily,
  lineItemsVariantIdsInCartAtom,
  lineItemsInCartAtom,
  lineItemsTotalQuantityAtom,
  setQuantityOfLineItemAtom,
  syncLineItemsWithShopifyAtom,
  unavailableLineItemsAtom,

  // Delivery
  saveAndSetDefaultAddressToShopifyCustomerAtom,
  associateShippingAddressWithShopifyCheckoutAtom,
  shippingRateAtom,
  shippingRateTitleAtom,
  updateShippingRateAtom,
  availableShippingRatesAtom,

  // Note
  noteAtom,
  syncNoteWithShopifyAtom, // Used to sync expected time slot

  // Totals
  lineItemsTotalPriceAtom,
  shippingPriceAtom,
  lineItemsDiscountTotalAtom,
  shippingDiscountTotalAtom,
  discountTotalAtom,
  totalTaxAtom,
  totalPriceLessDeliveryPriceAtom,
  subtotalPriceAtom,
  totalPriceAtom,

  // Shopify API Sychronisation
  isTotalPriceCalculatingAtom,

  // Direct manipulation of Shopify API
  syncDiscountCodeWithShopifyAtom,

  // Initiate Setup and load. Customer -> Address -> Available Shipping Rates -> Shipping Line
  initiateShopifyCheckoutAtom,
  resetCheckoutAtom,
  emptyLineItemsInCartAtom,

  // For debugging
  hasAssociatedAddressWithShopifyCheckoutAtom,
  hasAssociatedCustomerWithShopifyCheckoutAtom,
  isAssociatingAddressWithShopifyCheckoutAtom,
  isSyncingLineItemsWithShopifyAtom,
  noteToSendToShopifyAtom,
};
