import React, { useState } from 'react';
import {
  Image,
  Platform,
  Pressable,
  View,
  useWindowDimensions,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Text } from '../core-ui';
import { SimpleButton } from '../components/SimpleButton';
import { branding } from '../branding';
import { triggerHaptic } from '../utils/haptic';
import { BottomSheetModal } from './BottomSheetModal';

type Props = {
  isVisible: boolean;
  onConditionsAccepted?: () => void;
  onDismiss: () => void;
};

export function AlcoholConditionsBottomSheet({
  isVisible,
  onConditionsAccepted,
  onDismiss,
}: Props) {
  const insets = useSafeAreaInsets();
  const { height } = useWindowDimensions();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <BottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingHorizontal: 32,
          paddingBottom: 22 + insets.bottom,
        }}
      >
        <View
          style={{
            minHeight: height - 300,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            style={{
              width: 130,
              height: 130,
              borderWidth: 0,
              marginTop: -30,
              marginBottom: -10,
            }}
            source={require('../../assets/images/alcoholWarning.png')}
          />
          <Text weight="bold" style={{ fontSize: 22, marginBottom: 20 }}>
            Alcohol Deliveries
          </Text>

          <Text
            style={{
              fontSize: 16,
              lineHeight: 20,
              marginBottom: 16,
            }}
          >
            You must:
          </Text>
          <Text
            weight="bold"
            style={{
              fontSize: 16,
              lineHeight: 20,
              marginBottom: 16,
            }}
          >
            • Be present & sober on delivery
          </Text>
          <Text
            weight="bold"
            style={{
              fontSize: 16,
              lineHeight: 20,
              marginBottom: 16,
            }}
          >
            • Provide valid +18 ID on delivery
          </Text>

          <View
            style={{
              marginTop: 20,
              borderWidth: 2,
              borderColor: 'rgba(255,200,58,1)',
              backgroundColor: 'rgba(255,200,58,.2)',
              padding: 14,
              paddingHorizontal: 20,
              borderRadius: 6,
            }}
          >
            <Text
              style={{
                fontSize: 13,
                lineHeight: Platform.select({ default: 14, android: 16 }),
                textAlign: 'center',
                marginBottom: 5,
              }}
            >
              You cannot order more than
            </Text>
            <Text
              style={{
                fontSize: 13,
                lineHeight: Platform.select({ default: 14, android: 16 }),
                textAlign: 'center',
              }}
            >
              {branding.alcoholLimitDescription}
            </Text>
          </View>

          <View
            style={{
              marginTop: 20,
              padding: 20,
              borderRadius: 6,
            }}
          >
            <Text
              style={{
                fontSize: 13,
                lineHeight: Platform.select({ default: 19, android: 16 }),
                textAlign: 'center',
                opacity: 0.7,
              }}
            >
              You may be charged a $10 restocking fee if delivery cannot occur.
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: Platform.select({ ios: 20, android: 20, web: 50 }),
          }}
        >
          <Pressable
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 20,
              justifyContent: 'center',
            }}
            onPress={() => {
              setIsChecked(!isChecked);
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderRadius: 4,
                width: 30,
                height: 30,
                marginRight: 12,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Image
                style={{
                  width: 20,
                  height: 20,
                  borderWidth: 0,
                  opacity: isChecked ? 1 : 0,
                  //borderWidth: 1,
                }}
                source={require('../../assets/images/checkmark--dark.png')}
              />
            </View>

            <Text style={{ fontSize: 15, lineHeight: 15 }}>
              I understand and am 18 or over
            </Text>
          </Pressable>

          <View style={{ alignItems: 'center' }}>
            <SimpleButton
              onPress={() => {
                if (!isChecked) {
                  triggerHaptic('notificationWarning');
                  return;
                }
                triggerHaptic('notificationSuccess');
                onConditionsAccepted?.();
              }}
              width={260}
            >
              Continue
            </SimpleButton>
          </View>

          {Platform.select({ android: true, web: true }) && (
            <View
              style={{
                marginTop: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <SimpleButton
                onPress={() => {
                  onDismiss();
                }}
                color="rgba(24,24,24,1)"
                bgColor="rgba(0,0,0,0)"
                width={260}
              >
                Cancel
              </SimpleButton>
            </View>
          )}
        </View>
      </View>
    </BottomSheetModal>
  );
}
