import React, { useEffect } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { WebView } from 'react-native-webview';
import { useNavigation, useRoute } from '@react-navigation/native';

import { Text } from '../core-ui';
import { useLocalResetCartMutation } from '../hooks/api/useCheckout';
import {
  AllScreensNavigationProp,
  AllScreensRouteProp,
} from '../navigation/types';

export default function WebScene() {
  const { navigate, setOptions } =
    useNavigation<AllScreensNavigationProp<'WebView'>>();
  const {
    params: { type, webUrl },
  } = useRoute<AllScreensRouteProp<'WebView'>>();

  const [resetShoppingCart] = useLocalResetCartMutation();

  let title: string;

  switch (type) {
    case 'policy':
      title = 'Privacy Policy';
      break;
    case 'terms':
      title = 'Terms of Use';
      break;
    case 'support':
      title = 'Support';
      break;
    case 'license':
      title = 'License';
      break;
    case 'notice':
      title = 'Notice Board';
      break;
    default:
      title = 'Payment';
  }
  useEffect(() => {
    setOptions({
      title,
    });
  });

  return webUrl ? (
    <SafeAreaView style={styles.flex}>
      <WebView
        style={styles.container}
        source={{ uri: webUrl }}
        originWhitelist={['*']}
        onShouldStartLoadWithRequest={({ url }) => {
          if (url.endsWith('thank_you')) {
            resetShoppingCart();
            navigate('OrderPlacedConfirmation', { orderNumber: '' });
            return false;
          }
          return true;
        }}
        startInLoadingState={true}
        renderLoading={() => <ActivityIndicator style={styles.center} />}
      />
    </SafeAreaView>
  ) : (
    <SafeAreaView style={styles.text}>
      <Text>{t('Please check your connection.')}</Text>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    marginVertical: 24,
  },
  center: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
