import React, { forwardRef } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { FlatGrid, FlatGridProps } from 'react-native-super-grid';
import { CategoryItem as CategoryItemType } from '../types/types';
import CategoryItem from './CategoryItem';

type BaseProps = FlatGridProps<CategoryItemType>;
type Props = Omit<BaseProps, 'data' | 'renderItem' | 'numColumns'> & {
  containerStyle?: StyleProp<ViewStyle>;
  categories: Array<CategoryItemType>;
  onSelect: (category: CategoryItemType) => void;
};

const CategoryList = forwardRef<FlatGrid, Props>((props, ref) => {
  const { containerStyle, categories, onSelect, ...otherprops } = props;

  return (
    <FlatGrid
      ref={ref}
      showsHorizontalScrollIndicator={false}
      maxItemsPerRow={2}
      data={categories}
      style={{ marginTop: 4 }}
      renderItem={({ item }) => (
        <CategoryItem
          item={item}
          onSelect={onSelect}
          containerStyle={containerStyle}
        />
      )}
      keyExtractor={(item) => item.id}
      {...otherprops}
    />
  );
});

export default CategoryList;
