import { View, StyleSheet } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { useAtomValue } from 'jotai';

import { format } from 'date-fns';
import { hubAtom } from '../hooks/hubsAtoms';
import {
  isCurrentlyOpenAtom,
  todaysOpenTimesAtom,
} from '../hooks/scheduledTimeAtoms';
import { Text } from '../core-ui';
import { isTurboDeliveryAvailableToAddressAtom } from '../hooks/addressAtoms';
import { branding } from '../branding';

export default function DeliveryTime() {
  const hub = useAtomValue(hubAtom);
  const todaysOpeningTime = useAtomValue(todaysOpenTimesAtom);
  const isCurrentlyOpen = useAtomValue(isCurrentlyOpenAtom);
  const [displayStep, setDisplayStep] = useState<1 | 2>(1);
  const timeout = useRef<NodeJS.Timeout>();
  const isTurboAvailable = useAtomValue(isTurboDeliveryAvailableToAddressAtom);

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setDisplayStep(displayStep === 1 ? 2 : 1);
    }, 7200);
  }, [displayStep]);

  const opensAtString = `Opens ${format(
    todaysOpeningTime.openTime,
    'h:mmaaa',
  )}`;

  const closesAtString = `until ${format(
    todaysOpeningTime.closeTime,
    'h:mmaaa',
  )}`;

  if (!hub) {
    return null;
  }

  if (hub.status !== 'active' || !isCurrentlyOpen) {
    return (
      <View style={styles.container}>
        {displayStep === 1 ? (
          <State title="Closed" subtitle={opensAtString} colour="#FFC107" />
        ) : (
          <State title="Scheduling" subtitle="Available" colour="#FFC107" />
        )}
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {displayStep === 1 ? (
        <State title="Open" subtitle={closesAtString} colour="#4CAF50" />
      ) : (
        <State
          title="Delivering"
          subtitle={
            isTurboAvailable
              ? `in ${hub?.minimum_wait_time} mins`
              : 'within 2 hours'
          }
          colour="#4CAF50"
        />
      )}
    </View>
  );
}

const State = ({
  title,
  subtitle,
  colour,
}: {
  title: string;
  subtitle?: string;
  colour: string;
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        padding: 8,
      }}
    >
      <View
        style={{
          height: 12,
          width: 12,
          borderRadius: 6,
          backgroundColor: colour,
          marginRight: 10,
          marginLeft: 4,
        }}
      />
      <View>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              color: branding.deliveryTimeTextColor,
              fontSize: 13,
              lineHeight: 13,
              //borderColor: 'white',
              width: 94,
              //borderWidth: 1,
              borderColor: 'white',
            }}
            weight="bold"
            numberOfLines={2}
          >
            {title}
          </Text>
        </View>

        {subtitle ? (
          <Text
            style={{
              color: branding.deliveryTimeTextColor,
              fontSize: 11,
              lineHeight: 11,
              marginTop: 3,
            }}
          >
            {subtitle}
          </Text>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: branding.deliveryTimeBackgroundColor,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 140,
    height: 44,
  },
});
