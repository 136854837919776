import superjson from 'superjson';
import { createTRPCProxyClient, httpLink } from '@trpc/client';
import type { AppRouter } from 'teddy-next';
import { TRPC_URL } from '../../env';

export const client = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpLink({
      url: TRPC_URL,
    }),
  ],
});
