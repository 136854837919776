import React, {
  createContext,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import { AllowedSuburb } from '../hooks/useAllowedSuburbs';

type Context = {
  suburb: AllowedSuburb | null;
  setSuburb: React.Dispatch<SetStateAction<AllowedSuburb | null>>;
};

type Props = {
  children: JSX.Element;
};

const OnboardingContext = createContext<Context>({
  suburb: null,
  setSuburb: () => null,
});

export function OnboardingProvider(props: Props) {
  const [suburb, setSuburb] = useState<AllowedSuburb | null>(null);

  const context = useMemo(
    () => ({
      suburb,
      setSuburb,
    }),
    [suburb, setSuburb],
  );

  return (
    <OnboardingContext.Provider value={context}>
      {props.children}
    </OnboardingContext.Provider>
  );
}

export function useOnboarding() {
  return useContext(OnboardingContext);
}
