import { useAtom } from 'jotai';
// import numeral from 'numeral';
import React from 'react';
import { StyleSheet, View, ActivityIndicator } from 'react-native';

import { FancyPriceDisplay } from '../../../components/FancyPriceDisplay';
import { COLORS } from '../../../constants/colors';
import { FONT_FAMILY, FONT_SIZE } from '../../../constants/fonts';
import { Text } from '../../../core-ui';
import {
  isTotalPriceCalculatingAtom,
  // discountTotalAtom,
  // lineItemsInCartAtom,
  subtotalPriceAtom,
} from '../../../hooks/checkoutAtoms';
import { PaymentDetailsProps } from '../../../types/types';

export function ShoppingCartTotal() {
  const [subtotalPrice] = useAtom(subtotalPriceAtom);
  const [isTotalPriceCalculating] = useAtom(isTotalPriceCalculatingAtom);
  // const [lineItemsInCart] = useAtom(lineItemsInCartAtom);
  // const [discountTotal] = useAtom(discountTotalAtom);

  // const subtotal =
  //   lineItemsInCart.reduce((acc, lineItem) => {
  //     return acc + lineItem.quantity * lineItem.originalPrice;
  //   }, 0) - (discountTotal.value() ?? 0);

  const paymentData: PaymentDetailsProps & { isSyncing: boolean } = {
    name: 'Total',
    value: subtotalPrice.format('$0,0.00'),
    isSyncing: isTotalPriceCalculating,
  };

  return (
    <View style={styles.container}>
      <View
        style={[styles.innerPaymentDetailsContainer, styles.border]}
        key={paymentData.name}
      >
        <Text style={styles.total}>{paymentData.name}</Text>

        {isTotalPriceCalculating ? (
          <ActivityIndicator size="small" style={{ opacity: 0.35 }} />
        ) : (
          <FancyPriceDisplay
            weight="bold"
            style={{ fontSize: 18, lineHeight: 18 }}
            isSyncing={paymentData.isSyncing}
          >
            {paymentData.value}
          </FancyPriceDisplay>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 18,
  },
  innerPaymentDetailsContainer: {
    paddingVertical: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  total: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: FONT_SIZE.large,
    lineHeight: FONT_SIZE.large,
    //borderWidth: 1,
  },
  border: {
    borderTopWidth: 1,
    paddingVertical: 16,
    borderTopColor: COLORS.lightGrey,
  },
});
