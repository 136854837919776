import { atom } from 'jotai';
import { range } from 'lodash';
import {
  addDays,
  addHours,
  addMinutes,
  format,
  max,
  roundToNearestMinutes,
  startOfDay,
  startOfHour,
} from 'date-fns';
import { ShippingRate } from '../types/types';
import { branding } from '../branding';
import {
  availableShippingRatesAtom,
  shippingRateTitleAtom,
} from './checkoutAtoms';
import { hubAtom, weeksOpeningHoursAtom } from './hubsAtoms';
import { isTurboDeliveryAvailableToAddressAtom } from './addressAtoms';

export type AvailableDate = {
  date: Date;
  slots: Date[];
  openTime: Date;
  closeTime: Date;
};

export const availableDatesAtom = atom<AvailableDate[]>((get) => {
  const currentTime = get(currentTimeAtom);
  const daysInTheFuture = 2;

  // Remove first slot
  const weeksOpeningHours = get(weeksOpeningHoursAtom);
  const now = new Date();

  return range(0, daysInTheFuture)
    .map((dayInTheFuture) => {
      const date = addDays(startOfDay(now), dayInTheFuture);
      const openHours =
        weeksOpeningHours?.[date.getDay() as keyof typeof weeksOpeningHours];
      const openTime = new Date(+date);
      const closeTime = new Date(+date);

      openTime.setHours(openHours?.openHour || 9, openHours?.openMinutes || 0);
      closeTime.setHours(
        openHours?.closeHour || 21,
        openHours?.closeMinutes || 0,
      );

      const startTime = max([openTime, startOfHour(addHours(currentTime, 3))]);
      const slots = [];

      while (startTime < closeTime) {
        slots.push(new Date(startTime));
        startTime.setHours(startTime.getHours() + 1);
      }

      return {
        date,
        slots,
        openTime,
        closeTime,
      };
    })
    .filter(({ slots }) => {
      return slots.length;
    });
});

export const todaysOpenTimesAtom = atom((get) => {
  const currentTime = get(currentTimeAtom);
  const weeksOpeningHours = get(weeksOpeningHoursAtom);
  const todaysOpeningHours =
    weeksOpeningHours?.[
      new Date(+currentTime).getDay() as keyof typeof weeksOpeningHours
    ];
  const openTime = new Date(+currentTime);
  const closeTime = new Date(+currentTime);

  openTime.setHours(todaysOpeningHours?.openHour || 9);
  openTime.setMinutes(todaysOpeningHours?.openMinutes || 0);
  closeTime.setHours(todaysOpeningHours?.closeHour || 21);
  closeTime.setMinutes(todaysOpeningHours?.closeMinutes || 0);

  return {
    openTime,
    closeTime,
  };
});

const scheduledSlotAtom = atom<Date | undefined>(undefined);

export const expectedTimeAtom = atom((get) => {
  const shippingRateTitle = get(shippingRateTitleAtom);
  const scheduledSlot = get(scheduledSlotAtom);
  const isScheduled = get(isScheduledAtom);
  const expectedStandardDeliveryTime = get(expectedStandardDeliveryTimeAtom);
  const expectedTurboDeliveryTime = get(expectedTurboDeliveryTimeAtom);

  if (shippingRateTitle.includes('Standard')) {
    if (isScheduled && scheduledSlot) {
      return scheduledSlot;
    }
    return expectedStandardDeliveryTime;
  }

  if (shippingRateTitle.includes('Turbo')) {
    return expectedTurboDeliveryTime;
  }
});

// Shown on confirmation screen
const expectedTimeDisplayStringAtom = atom((get) => {
  const shippingRateTitle = get(shippingRateTitleAtom);
  const scheduledSlot = get(scheduledSlotAtom);
  const isScheduled = get(isScheduledAtom);
  const expectedStandardDeliveryTime = get(expectedStandardDeliveryTimeAtom);
  const expectedTurboDeliveryTime = get(expectedTurboDeliveryTimeAtom);

  if (shippingRateTitle.includes('Standard')) {
    // Scheduled
    if (isScheduled && scheduledSlot) {
      return `Between ${format(scheduledSlot, 'haaa')}–${format(
        addHours(scheduledSlot, 1),
        'haaa, EEE d MMM',
      )}`;
    }

    // Standard
    return `Expected before ${format(expectedStandardDeliveryTime, 'h:mmaaa')}`;
  }

  // Turbo
  if (shippingRateTitle.includes('Turbo')) {
    return `Expected before ${format(expectedTurboDeliveryTime, 'h:mmaaa')}`;
  }

  return '';
});

const firstAvailableScheduledSlotAtom = atom((get) => {
  const availableDates = get(availableDatesAtom);
  const firstAvailableDate = availableDates.find((date) => {
    return date.slots.length;
  });

  return firstAvailableDate?.slots[0];
});

const isScheduledAtom = atom(false);

const currentTimeAtom = atom(new Date());

const isCurrentlyOpenAtom = atom((get) => {
  const currentTime = get(currentTimeAtom);
  const { openTime, closeTime } = get(todaysOpenTimesAtom);
  return +currentTime >= +openTime && +currentTime <= +closeTime;
});

const expectedTurboDeliveryTimeAtom = atom((get) => {
  const hub = get(hubAtom);
  const currentTime = get(currentTimeAtom);
  const waitTime = hub?.minimum_wait_time ? +hub.minimum_wait_time : 30;

  // Round to nearest 5 minutes
  const expectedTime = addMinutes(currentTime, waitTime);
  const roundedTime = roundToNearestMinutes(expectedTime, {
    nearestTo: 5,
    roundingMethod: 'ceil',
  });

  return roundedTime;
});

const expectedTurboDeliveryWaitTimeStringAtom = atom((get) => {
  const hub = get(hubAtom);
  const waitTime = hub?.minimum_wait_time ? +hub.minimum_wait_time : 30;

  return `${waitTime} min`;
});

const expectedStandardDeliveryTimeAtom = atom((get) => {
  const currentTime = get(currentTimeAtom);

  // Round to nearest 5 minutes
  const expectedTime = addMinutes(
    currentTime,
    branding.standardDeliveryWaitTime,
  );
  const roundedTime = roundToNearestMinutes(expectedTime, {
    nearestTo: 5,
    roundingMethod: 'ceil',
  });

  return roundedTime;
});

const expectedStandardDeliveryWaitTimeStringAtom = atom(() => {
  const waitTime =
    branding.standardDeliveryWaitTime === 180
      ? '3 hours'
      : branding.standardDeliveryWaitTime === 120
      ? '2 hours'
      : '30 minutes';

  return `${waitTime}`;
});

// Standard delivery is available up to 1 hour before close time
const isStandardDeliveryAvailableAtom = atom((get) => {
  const currentTime = get(currentTimeAtom);
  const { openTime, closeTime } = get(todaysOpenTimesAtom);

  return +currentTime >= +openTime && +currentTime <= +addHours(closeTime, -1);
});

// Turbo delivery is available when the store is open
const isTurboDeliveryAvailableAtom = atom((get) => {
  const isOpen = get(isCurrentlyOpenAtom);
  const isTurboDeliveryAvailableToAddress = get(
    isTurboDeliveryAvailableToAddressAtom,
  );

  return isOpen && isTurboDeliveryAvailableToAddress;
});

type DeliveryOption = {
  title: 'Turbo' | 'Standard' | 'Scheduled';
  subtitle: string;
  displayTime: string;
  price: number;
  shopifyShippingRate: ShippingRate;
  isAvailable: boolean;
};

const availableDeliveryOptionsAtom = atom((get) => {
  const isScheduled = get(isScheduledAtom);
  const scheduledSlot = get(scheduledSlotAtom);
  const isTurboDeliveryAvailable = get(isTurboDeliveryAvailableAtom);
  const isTurboDeliveryAvailableToAddress = get(
    isTurboDeliveryAvailableToAddressAtom,
  );
  const isStandardDeliveryAvailable = get(isStandardDeliveryAvailableAtom);
  const expectedStandardDeliveryWaitTime = get(
    expectedStandardDeliveryWaitTimeStringAtom,
  );
  const expectedStandardDeliveryTime = get(expectedStandardDeliveryTimeAtom);
  const expectedTurboWaitTimeString = get(
    expectedTurboDeliveryWaitTimeStringAtom,
  );
  const expectedTurboDeliveryTime = get(expectedTurboDeliveryTimeAtom);
  const availableShippingRates = get(availableShippingRatesAtom);
  const shopifyStandardShippingRate = availableShippingRates.find((rate) =>
    rate.title.includes('Standard'),
  );
  const shopifyTurboShippingRate = availableShippingRates.find((rate) =>
    rate.title.includes('Turbo'),
  );

  const deliveryOptions: DeliveryOption[] = [];

  if (shopifyTurboShippingRate) {
    const subtitle =
      expectedTurboWaitTimeString +
      ' ' +
      shopifyTurboShippingRate.title.split(' ').slice(1).join(' ');

    const turboDeliveryOption = {
      title: 'Turbo' as const,
      subtitle,
      displayTime: isTurboDeliveryAvailable
        ? `Delivered by ${format(expectedTurboDeliveryTime, 'h:mmaaa')}`
        : isTurboDeliveryAvailableToAddress
        ? 'Currently Closed'
        : 'Unavailable at your address',
      price: shopifyTurboShippingRate?.price || 0,
      isAvailable: isTurboDeliveryAvailable,
      shopifyShippingRate: shopifyTurboShippingRate,
    };

    deliveryOptions.push(turboDeliveryOption);
  }

  if (shopifyStandardShippingRate) {
    const subtitle =
      expectedStandardDeliveryWaitTime +
      ' ' +
      shopifyStandardShippingRate.title.split(' ').slice(1).join(' ');
    const standardDeliveryOption = {
      title: 'Standard' as const,
      subtitle,
      displayTime: isStandardDeliveryAvailable
        ? `Delivered by ${format(expectedStandardDeliveryTime, 'h:mmaaa')}`
        : 'Currently Closed',
      isAvailable: isStandardDeliveryAvailable,
      price: shopifyStandardShippingRate?.price || 0,
      shopifyShippingRate: shopifyStandardShippingRate,
    };

    deliveryOptions.push(standardDeliveryOption);
  }

  if (shopifyStandardShippingRate) {
    const subtitle = shopifyStandardShippingRate.title
      .replace('1-2hrs - ', '') // Temp workaround
      .split(' ')
      .slice(1)
      .join(' ');
    const scheduledOption = {
      title: 'Scheduled' as const,
      subtitle,
      displayTime:
        isScheduled && scheduledSlot
          ? `${format(scheduledSlot, 'EEE haaa')}–${format(
              addHours(scheduledSlot, 1),
              'haaa, d MMM',
            )}`
          : 'Choose a time slot',
      isAvailable: true,
      price: shopifyStandardShippingRate?.price || 0,
      shopifyShippingRate: shopifyStandardShippingRate,
    };
    deliveryOptions.push(scheduledOption);
  }

  // Sort list by `isAvalable`
  deliveryOptions.sort((a, b) => {
    if (a.isAvailable && !b.isAvailable) {
      return -1;
    }
    if (!a.isAvailable && b.isAvailable) {
      return 1;
    }
    return 0;
  });

  return deliveryOptions;
});

export {
  currentTimeAtom,
  scheduledSlotAtom,
  isScheduledAtom,
  isCurrentlyOpenAtom,
  isTurboDeliveryAvailableAtom,
  expectedStandardDeliveryTimeAtom,
  isStandardDeliveryAvailableAtom,
  expectedTurboDeliveryTimeAtom,
  availableDeliveryOptionsAtom,
  firstAvailableScheduledSlotAtom,
  expectedTimeDisplayStringAtom,
  DeliveryOption,
};
