import React from 'react';
import { Button } from 'react-native-paper';
import { Platform } from 'react-native';
import { FONT_FAMILY } from '../constants/fonts';
import { branding } from '../branding';

export type ButtonProps = React.ComponentProps<typeof Button>;
export type PillButtonProps = ButtonProps;
export const PillButton = ({ ...rest }: PillButtonProps) => {
  return (
    <Button
      style={{
        backgroundColor: branding.buttonBackgroundColor,
        borderRadius: 18,
      }}
      labelStyle={{
        color: branding.buttonTextColor,
        fontFamily: FONT_FAMILY.BOLD,
        fontSize: 12,
        lineHeight: 12,
        top: Platform.select({ android: 1, ios: 0 }),
        textTransform: 'none',
        padding: 0,
      }}
      {...rest}
    />
  );
};
