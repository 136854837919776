import React, { useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Text } from '../../core-ui';
import { mapToLineItems } from '../../helpers/mapToLineItems';
import { OrderItem } from '../../components';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../../constants/fonts';
import { GetOrder_order_Order } from '../../generated/server/GetOrder';
import useCurrencyFormatter from '../../hooks/api/useCurrencyFormatter';
import { OrderItemCompact } from '../../components/OrderItemCompact';

export const OrderTrackingSummary = React.memo(
  ({ order }: { order: GetOrder_order_Order }) => {
    const formatCurrency = useCurrencyFormatter();
    const [expanded, setExpanded] = useState(false);
    const lineItems = mapToLineItems(order.lineItems);
    const deliveryFee =
      order.totalShippingPriceV2.amount === 0
        ? t('Free')
        : formatCurrency(Number(order.totalShippingPriceV2.amount));
    const total = formatCurrency(Number(order.totalPriceV2.amount));

    return (
      <View>
        <View
          style={{
            alignItems: expanded ? 'flex-start' : 'center',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 10,
            paddingHorizontal: 20,
            //borderWidth: 1,
          }}
        >
          <Text
            style={{
              fontSize: 11,
              textAlign: 'center',
              opacity: 0.4,
            }}
          >
            DELIVERING TO
          </Text>
          <View
            style={{
              marginTop: 8,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <Image
              source={require('../../../assets/images/map-marker.png')}
              style={{
                width: 14,
                height: 14,
                marginRight: 6,
                overflow: 'visible',
              }}
            /> */}
            <Text style={{ lineHeight: 18 }}>
              {order.shippingAddress?.address1}
            </Text>
          </View>
        </View>

        {!expanded && (
          <>
            <ScrollView
              style={{
                flexDirection: 'row',
                paddingLeft: 20,
                paddingRight: 20,
                paddingVertical: 22,
                alignContent: 'center',
                alignSelf: 'center',
              }}
              contentContainerStyle={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
              }}
              horizontal
              showsHorizontalScrollIndicator={false}
            >
              {lineItems.map((item) => (
                <OrderItemCompact
                  orderItem={item}
                  key={item.variantID}
                  size={lineItems.length < 4 ? 94 : 66}
                />
              ))}
            </ScrollView>
          </>
        )}

        {expanded && (
          <>
            <View
              style={{
                marginHorizontal: 24,
              }}
            >
              {lineItems.map((item) => (
                <OrderItem
                  orderItem={item}
                  containerStyle={{
                    paddingVertical: 12,
                  }}
                  key={item.variantID}
                />
              ))}
            </View>
            <View
              style={{
                paddingVertical: 12,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 20,
              }}
            >
              <Text
                style={{
                  fontSize: FONT_SIZE.small,
                  lineHeight: LINE_HEIGHT.small,
                  fontFamily: FONT_FAMILY.REGULAR,
                }}
              >
                Delivery Fee
              </Text>
              <Text
                style={{
                  fontSize: FONT_SIZE.small,
                  lineHeight: LINE_HEIGHT.small,
                  fontFamily: FONT_FAMILY.REGULAR,
                }}
              >
                {deliveryFee}
              </Text>
            </View>
            <View
              style={{
                paddingVertical: 18,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 20,
                borderBottomColor: 'rgba(0,0,0,.035)',
              }}
            >
              <Text
                style={{
                  fontFamily: FONT_FAMILY.BOLD,
                  fontSize: FONT_SIZE.large,
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  fontFamily: FONT_FAMILY.BOLD,
                  fontSize: FONT_SIZE.large,
                }}
              >
                {total}
              </Text>
            </View>
          </>
        )}
        <Pressable
          onPress={() => {
            setExpanded(!expanded);
          }}
          hitSlop={10}
          style={{
            padding: 1,
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              width: 200,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                opacity: 0.3,
                fontSize: 12,
                lineHeight: 12,
              }}
            >
              {expanded ? 'Hide Details' : 'See Details'}
            </Text>
            <Image
              style={{
                width: 18,
                height: 18,
                opacity: 0.3,
                transform: [
                  {
                    rotate: expanded ? '0deg' : '180deg',
                  },
                ],
              }}
              source={require('../../../assets/images/chevron.png')}
            />
          </View>
        </Pressable>
      </View>
    );
  },
);
