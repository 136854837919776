import { useCallback } from "react";
import { useApolloClient } from "@apollo/client";

/**
 * Similar to Apollo's useLazyQuery, except that this returns a Promise when called, so that you can use async/await or
 * Promise chaining when calling the function (i.e. when executing the query).
 *
 * See https://github.com/apollographql/react-apollo/issues/3499#issuecomment-539346982.
 *
 * Usage:
 * const executeMyQuery = useLazyQueryReturningPromise(SOME_QUERY);
 * const result = await executeMyQuery({ someId: someValue });
 */
function useLazyQueryReturningPromise(query) {
  const client = useApolloClient();

  return useCallback(
    variables => {
      return client.query({
        query,
        variables,
        fetchPolicy: "network-only",
      });
    },
    [client, query],
  );
}

export default useLazyQueryReturningPromise;
