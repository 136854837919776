import React, { useRef, useState } from 'react';
import {
  Image,
  KeyboardAvoidingView,
  View,
  TextInput as NativeTextInput,
  Platform,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Text, TextInput } from '../core-ui';
import { trackEvent } from '../analytics';
import { branding } from '../branding';
import { BottomSheetModal } from './BottomSheetModal';
import { SimpleButton } from './SimpleButton';

type Props = {
  isVisible: boolean;
  onSubmit: () => void;
  onDismiss: () => void;
};
export function SuggestProductBottomSheet({
  onSubmit,
  isVisible,
  onDismiss,
}: Props) {
  const [textInputValue, setTextInputValue] = useState('');
  const defaultButtonLabel = 'Submit Suggestion';
  const [buttonLabel, setButtonLabel] = useState(defaultButtonLabel);
  const textInput = useRef<NativeTextInput>(null);
  const inset = useSafeAreaInsets();

  return (
    <BottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 0,
          paddingTop: 0,
          paddingBottom: 22 + inset.bottom,
        }}
      >
        <KeyboardAvoidingView
          behavior="position"
          pointerEvents="box-none"
          style={{
            alignItems: 'center',
            width: '100%',
          }}
          keyboardVerticalOffset={Platform.select({
            default: 80,
            android: -160,
          })}
        >
          <Image
            style={{
              width: 130,
              height: 130,
              borderWidth: 0,
              marginTop: -30,
              marginBottom: -10,
              alignSelf: 'center',
            }}
            source={branding.questionGraphic}
          />
          <Text
            weight="bold"
            style={{
              fontSize: 22,
              lineHeight: 25,
              marginBottom: 20,
              textAlign: 'center',
            }}
          >
            Suggest a product
          </Text>

          <Text style={{ textAlign: 'center', marginBottom: 5 }}>
            Can't find what you're after?
          </Text>

          <Text style={{ marginBottom: 20, textAlign: 'center' }}>
            Let us know!
          </Text>

          <TextInput
            ref={textInput}
            placeholder="Enter name of product"
            value={textInputValue}
            onChangeText={(text) => {
              setTextInputValue(text);
            }}
            style={{ padding: 14, width: 240, alignSelf: 'center' }}
          />
          <View style={{ marginTop: 20 }}>
            <SimpleButton
              onPress={() => {
                trackEvent('suggest_product', {
                  product_name: textInputValue,
                });
                setButtonLabel('Thanks!');

                setTimeout(() => {
                  onSubmit();
                  setTextInputValue('');
                  setButtonLabel(defaultButtonLabel);
                }, 740);
              }}
              width={260}
            >
              {buttonLabel}
            </SimpleButton>
          </View>

          {Platform.select({ android: true }) && (
            <View style={{ marginTop: 10 }}>
              <SimpleButton
                onPress={() => {
                  onDismiss();
                }}
                color="rgba(24,24,24,1)"
                bgColor="rgba(0,0,0,0)"
                width={260}
              >
                Cancel
              </SimpleButton>
            </View>
          )}
        </KeyboardAvoidingView>
      </View>
    </BottomSheetModal>
  );
}
