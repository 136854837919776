import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { FlatGrid, FlatGridProps } from 'react-native-super-grid';

import { CategoryItem as CategoryItemType } from '../types/types';

import OccassionItem from './OccasionItem';

type BaseProps = FlatGridProps<CategoryItemType>;

type Props = Omit<BaseProps, 'data' | 'renderItem' | 'numColumns'> & {
  containerStyle?: StyleProp<ViewStyle>;
  categories: Array<CategoryItemType>;
  onSelect: (category: CategoryItemType) => void;
};

export default function OccasionList(props: Props) {
  const { containerStyle, categories, onSelect, ...otherprops } = props;

  return (
    <FlatGrid
      style={styles.wrapper}
      showsHorizontalScrollIndicator={false}
      maxItemsPerRow={2}
      data={categories}
      renderItem={({ item }) => (
        <OccassionItem
          item={item}
          onSelect={onSelect}
          containerStyle={containerStyle}
        />
      )}
      spacing={10}
      keyExtractor={(item) => item.id}
      contentContainerStyle={styles.flatlistContainer}
      {...otherprops}
    />
  );
}

const styles = StyleSheet.create({
  flatlistContainer: {},
  wrapper: {
    //marginHorizontal: 6,
    //borderWidth: 1,
  },
});
