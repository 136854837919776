import { CategoryItem } from '../types/types';
import { triggerAlert } from '../utils/alert';

export const useAlcoholicCollection = () => {
  function promptUserIfAlcoholicCollection(
    collection: CategoryItem,
    onCompletion: () => void,
  ) {
    const containsAlcohol = collection.tags?.includes('contains_alcohol');
    if (containsAlcohol) {
      triggerAlert(
        'Easy tiger!',
        "Before you can purchase alcohol, we need to know if you're over 18",
        [
          { text: 'No' },
          {
            text: 'Yes',
            onPress: () => {
              onCompletion();
            },
          },
        ],
      );
      return;
    }
    onCompletion();
  }

  return { promptUserIfAlcoholicCollection };
};
