import React, { useCallback } from 'react';
import {
  Image,
  Platform,
  Pressable,
  ScrollView,
  StatusBar,
  View,
  useWindowDimensions,
} from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAtomValue } from 'jotai';
import { Text } from '../core-ui';
import { useOrderBeingTracked } from '../hooks/useOrderBeingTracked';
import { AllScreensNavigationProp } from '../navigation/types';
import { PromoContent } from '../components/PromoContent';
import { orderCurrentlyTrackedAtom } from '../hooks/orderTrackingAtoms';
import { NotificationPermissionAlert } from '../components/NotificationsPermissionAlert';
import { OrderTrackingConversation } from './OrderTrackingScene/OrderTrackingConversation';
import { OrderTrackingSummary } from './OrderTrackingScene/OrderTrackingSummary';
import { OrderTrackingWebView } from './OrderTrackingScene/OrderTrackingWebView';

type DeliveryJobStatus = '' | 'open' | 'dispatched' | 'underway' | 'completed';

export function OrderTrackingScene() {
  // Adjust status bar
  useFocusEffect(
    useCallback(() => {
      if (Platform.OS === 'ios') {
        StatusBar.setBarStyle('dark-content');
      }
    }, []),
  );

  return <OrderTracking />;
}

const OrderTracking = React.memo(() => {
  const { data, loading } = useOrderBeingTracked(); // From Shopify API
  const { width } = useWindowDimensions();
  const orderCurrentlyTracked = useAtomValue(orderCurrentlyTrackedAtom); // Local state
  let deliveryJobStatus: DeliveryJobStatus = '';

  if (loading) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CloseButton />
        <ActivityIndicator />
      </View>
    );
  }

  //GetOrder_order_Order
  const order = data?.order;

  // eslint-disable-next-line no-underscore-dangle
  if (order?.__typename === 'Order') {
    deliveryJobStatus =
      (order.customAttributes.find((attr) => attr.key === 'delivery_job_status')
        ?.value as DeliveryJobStatus) || '';
  }

  // eslint-disable-next-line no-underscore-dangle
  if (order?.__typename !== 'Order') {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CloseButton />
        <ActivityIndicator />
      </View>
    );
  }

  if (
    order === undefined ||
    order === null ||
    order.subtotalPriceV2 === undefined
  ) {
    return (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator color="#ddd" />
      </View>
    );
  }

  const trackingUrl = order.customAttributes.find(
    (attr) => attr.key === 'delivery_job_tracking_link',
  )?.value;

  return (
    <>
      <CloseButton />
      <ScrollView
        style={{
          borderWidth: 0,
          flexGrow: 1,
        }}
        contentContainerStyle={{
          borderWidth: 0,
          paddingTop: 60,
        }}
      >
        <View
          style={{
            borderWidth: 0,
            marginTop: 'auto',
            flexDirection: 'column',
            paddingBottom: 40,
          }}
        >
          <Image
            style={{
              width: 70,
              height: 70,
              marginTop: 20,
              marginBottom: 10,
              alignSelf: 'center',
              opacity: 0.8,
            }}
            source={require('../../assets/images/tick.png')}
          />
          <Text
            style={{
              fontSize: 22,
              textAlign: 'center',
              paddingBottom: 0,
            }}
            weight="bold"
          >
            {
              {
                '': 'Order Received',
                open: 'Order Received',
                dispatched: 'Order Received',
                underway: 'Out for Delivery',
                completed: 'Order Delivered!',
              }[deliveryJobStatus]
            }
          </Text>

          <View style={{ marginVertical: 20, borderWidth: 0 }}>
            <OrderTrackingSummary order={order} />
          </View>

          {deliveryJobStatus !== 'completed' &&
            deliveryJobStatus !== 'underway' && (
              <View
                style={{
                  padding: 20,
                  paddingVertical: 20,
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginBottom: 30,
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: 'rgba(0,0,0,.075)',
                  marginHorizontal: 20,
                  flexDirection: 'row',
                }}
              >
                <Image
                  source={require('../../assets/images/clock.png')}
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 10,
                  }}
                />
                <Text weight="bold">
                  {orderCurrentlyTracked?.expectedTimeDisplayString}
                </Text>
              </View>
            )}

          <NotificationPermissionAlert />

          <View style={{ padding: 20 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <Image
                source={require('../../assets/images/chatBubbles.png')}
                style={{
                  width: Platform.select({ default: 19, android: 16 }),
                  height: Platform.select({ default: 19, android: 16 }),
                  marginRight: 6,
                  overflow: 'visible',
                }}
              />
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  opacity: 0.7,
                }}
                weight="bold"
              >
                LIVE CHAT
              </Text>
            </View>
            <View
              style={{
                //paddingHorizontal: 10,
                //marginTop: 20,
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,.075)',
                alignSelf: 'center',
                borderRadius: 10,
                width: width - 40,
                overflow: 'hidden',
              }}
            >
              <OrderTrackingConversation orderId={order.id} />
            </View>
          </View>

          <View style={{ marginBottom: 40 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 6,
                overflow: 'visible',
              }}
            >
              <Image
                source={require('../../assets/images/map-marker.png')}
                style={{
                  width: Platform.select({ default: 14, android: 19 }),
                  height: Platform.select({ default: 14, android: 19 }),
                  marginRight: 6,
                  overflow: 'visible',
                }}
                // There's a iOS bug that renders the icon strangely – only in Release mode
                resizeMode={Platform.select({
                  default: 'cover',
                  android: 'contain',
                })}
              />
              <Text
                style={{
                  paddingVertical: 10,
                  textAlign: 'center',
                  fontSize: 12,
                  lineHeight: Platform.select({ default: 14, android: 16 }),
                  opacity: 0.7,
                }}
                weight="bold"
              >
                LIVE TRACKING
              </Text>
            </View>

            <TrackingView
              trackingUrl={trackingUrl}
              width={width}
              deliveryJobStatus={deliveryJobStatus}
            />
          </View>

          <PromoContent placement="confirmation-screen-1" />
          <PromoContent placement="confirmation-screen-2" />
        </View>
      </ScrollView>
    </>
  );
});

function TrackingView({
  trackingUrl,
  deliveryJobStatus,
  width,
}: {
  trackingUrl?: string | null;
  deliveryJobStatus?: string | null;
  width: number;
}) {
  let trackingUrlToRender = trackingUrl;

  // Onfleet Fix - Onfleet's tracking view requires manual refreshing
  // Attach the status to the URL to trigger a refresh
  if (trackingUrl && trackingUrlToRender?.includes('onf.lt')) {
    trackingUrlToRender = trackingUrlToRender + '?' + deliveryJobStatus;
  }

  return trackingUrlToRender ? (
    <OrderTrackingWebView trackingUrlToRender={trackingUrlToRender} />
  ) : (
    <View
      style={{
        height: 358,
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,.075)',
        backgroundColor: 'rgba(0,0,0,.015)',
        width: width - 40,
        alignSelf: 'center',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          opacity: 0.4,
          marginBottom: 10,
          fontSize: 10,
          width: 200,
          textAlign: 'center',
          lineHeight: 14,
        }}
      >
        TRACKING WILL APPEAR WHEN YOUR ORDER IS OUT FOR DELIVERY
      </Text>
      {/* <ActivityIndicator
        color="#eee"
        style={{
          transform: [{ scale: 0.75 }],
        }}
      /> */}
    </View>
  );
}

function CloseButton() {
  const { navigate } =
    useNavigation<AllScreensNavigationProp<'OrderTracking'>>();
  const inset = useSafeAreaInsets();
  return (
    <Pressable
      onPress={() => {
        navigate('TabNavigator');
        navigate('Home');
      }}
      hitSlop={20}
      style={{
        width: 28,
        height: 28,
        borderRadius: 14,
        backgroundColor: 'rgba(0,0,0,.05)',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 1,
        top: Platform.select({
          default: inset.top + 16,
          android: inset.top + 10,
        }),
        right: 20,
      }}
    >
      <Image
        source={require('../../assets/images/x.png')}
        style={{ width: 22, height: 22 }}
      />
    </Pressable>
  );
}
