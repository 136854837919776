import React from 'react';

import { useAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import { Polygon } from 'react-native-maps';

import { getLatLngsFromHubGeoJsonProperties } from '../../api/DeliveryZones';
import { hubsAtom } from '../../hooks/hubsAtoms';

export const GeoJsonFeaturesFromHubDeliveryZones = () => {
  const [hubs] = useAtom(loadable(hubsAtom));

  if (hubs.state !== 'hasData') {
    return null;
  }

  // We display the 'standard restricted delivery zones' because this zone
  // will be the bigger of the zones
  return (
    <>
      {hubs.data.map((hub) => {
        if (
          hub.standard_restricted_delivery_zones === null ||
          getLatLngsFromHubGeoJsonProperties(hub).length === 0
        ) {
          return null;
        }
        return (
          <Polygon
            key={hub.id}
            coordinates={getLatLngsFromHubGeoJsonProperties(hub)}
            fillColor={'rgba(0,128,255)'}
            strokeColor={'rgba(0,128,255)'}
            strokeWidth={2}
          />
        );
      })}

      {hubs.data.map((hub) => {
        if (
          hub.delivery_zones === null ||
          getLatLngsFromHubGeoJsonProperties(hub).length === 0
        ) {
          return null;
        }
        return (
          <Polygon
            key={hub.id}
            coordinates={getLatLngsFromHubGeoJsonProperties(hub)}
            fillColor={'rgba(0,128,255)'}
            strokeColor={'rgba(0,128,255)'}
            strokeWidth={2}
          />
        );
      })}
    </>
  );
};
