import { useEffect, useMemo } from 'react';
import {
  Animated,
  Keyboard,
  Platform,
  EmitterSubscription,
} from 'react-native';

export function useKeyboardListener(keyboardVerticalOffset = 0) {
  let keyboardHeight = useMemo(() => new Animated.Value(0), []);

  useEffect(() => {
    const handleKeyboardShow = (event: {
      duration: any;
      endCoordinates: { height: number };
    }) => {
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: event.endCoordinates.height + keyboardVerticalOffset,
        useNativeDriver: true,
      }).start();
    };
    const handleKeyboardHide = (event: { duration: any }) => {
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: 0,
        useNativeDriver: true,
      }).start();
    };

    let subscriptions: Array<EmitterSubscription>;

    if (Platform.OS === 'ios') {
      subscriptions = [
        Keyboard.addListener('keyboardWillShow', handleKeyboardShow),
        Keyboard.addListener('keyboardWillHide', handleKeyboardHide),
      ];
    } else {
      subscriptions = [
        Keyboard.addListener('keyboardDidShow', handleKeyboardShow),
        Keyboard.addListener('keyboardDidHide', handleKeyboardHide),
      ];
    }
    return () => {
      subscriptions.forEach((s) => s.remove());
    };
  }, [keyboardHeight, keyboardVerticalOffset]);

  return { keyboardHeight };
}
