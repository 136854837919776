import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import {
  Alert,
  Image,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { IconButton } from 'react-native-paper';

import { trackEvent } from '../analytics';
import { COLORS } from '../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import useCurrencyFormatter from '../hooks/api/useCurrencyFormatter';
import {
  lineItemAtomFamily,
  setQuantityOfLineItemAtom,
} from '../hooks/checkoutAtoms';
import { inventoryRecordFamily } from '../hooks/inventoryAtoms';
import { LineItem as LineItemType } from '../types/types';
import { triggerHaptic } from '../utils/haptic';
import { triggerAlert } from '../utils/alert';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  variantID: LineItemType['variantID'];
  unavailable: boolean;
};

export function EditableLineItem({
  containerStyle,
  variantID,
  unavailable,
}: Props) {
  const lineItemAtom = lineItemAtomFamily({ variantID });
  const [lineItem] = useAtom(lineItemAtom);
  const [, setQuantityOfLineItem] = useAtom(setQuantityOfLineItemAtom);

  const inventoryRecordAtom = inventoryRecordFamily({
    shopifyId: lineItem.productId,
  });
  const inventoryRecord = useAtomValue(inventoryRecordAtom);

  const { title, image, priceAfterDiscount, originalPrice, quantity } =
    lineItem;
  const formatCurrency = useCurrencyFormatter();
  const quantityAvailable = inventoryRecord?.count || 0;
  const [mainTitle, subtitle] = title.split(' | ');

  return (
    <View
      style={[
        styles.container,
        containerStyle,
        { borderBottomWidth: 1, borderColor: 'rgba(0,0,0,.05)' },
      ]}
    >
      <View style={styles.imageContainer}>
        {image ? (
          <Image
            source={{
              uri: image,
            }}
            style={styles.image}
          />
        ) : null}
      </View>

      <View style={styles.infoContainer}>
        <Text weight="normal" style={styles.productTitle}>
          {mainTitle}
        </Text>
        {subtitle ? (
          <Text weight="normal" style={styles.subtitle}>
            {subtitle}
          </Text>
        ) : null}

        {/* Quantity Control */}
        <View
          style={{
            flexDirection: 'row',
            //justifyContent: 'center',
            alignItems: 'center',
            marginTop: 12,
            marginBottom: 12,
          }}
        >
          <Pressable
            onPress={() => {
              triggerHaptic();
              setQuantityOfLineItem({
                variantID,
                quantity: quantity - 1,
                queueSync: true,
              });
              trackEvent('adjust_cart_quantity_from_cart', {
                variantId: variantID,
                title,
                quantity: quantity - 1,
                increase: false,
              });
            }}
            hitSlop={10}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: 'white',
              paddingHorizontal: 2,
              backgroundColor: '#111',
              width: 32,
              height: 32,
              borderRadius: 17,
            }}
          >
            <Text
              style={{
                color: 'white',
                fontSize: 20,
                lineHeight: 20,
                top: 0.5,
                borderColor: 'red',
                height: 20,
                width: 20,
                textAlign: 'center',
              }}
            >
              -
            </Text>
          </Pressable>
          <Text
            style={{
              marginHorizontal: 14,
              fontSize: 14,
              lineHeight: 14,
              width: 18,
              //borderWidth: 1,
              textAlign: 'center',
            }}
          >
            {quantity}
          </Text>
          <Pressable
            hitSlop={10}
            onPress={() => {
              if (quantityAvailable < quantity + 1) {
                triggerHaptic('notificationWarning');

                triggerAlert(
                  'Sorry, we only have ' +
                    inventoryRecord?.count +
                    ' left in stock.',
                );

                return;
              }
              triggerHaptic('notificationSuccess');
              setQuantityOfLineItem({
                variantID,
                quantity: quantity + 1,
                queueSync: true,
              });
              trackEvent('adjust_cart_quantity_from_cart', {
                variantId: variantID,
                title,
                quantity: quantity + 1,
                increase: true,
              });
            }}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: 'white',
              paddingHorizontal: 2,
              backgroundColor: '#111',
              width: 32,
              height: 32,
              borderRadius: 17,
            }}
          >
            <Text
              style={{
                color: 'white',
                fontSize: 20,
                lineHeight: 20,
                top: 1.5,
                borderColor: 'red',
                height: 20,
                width: 20,
                textAlign: 'center',
              }}
            >
              +
            </Text>
          </Pressable>
        </View>
      </View>

      <View style={styles.amountContainer}>
        <IconButton
          style={styles.remove}
          icon={require('../../assets/images/delete_sweep.png')}
          color={COLORS.neutral6}
          onPress={() => {
            // 0 removes item from cart
            setQuantityOfLineItem({ variantID, quantity: 0, queueSync: true });
            trackEvent('remove_from_cart', {
              variantId: variantID,
              title,
            });
          }}
        />
        <View style={styles.price}>
          {unavailable ? (
            <Image
              style={{
                width: 18,
                height: 18,
                marginRight: 5,
              }}
              source={require('../../assets/images/exclamation-mark.png')}
            />
          ) : null}

          <View>
            <Text style={styles.discountedPrice}>
              {priceAfterDiscount && priceAfterDiscount > 0
                ? formatCurrency(priceAfterDiscount * quantity)
                : formatCurrency(originalPrice * quantity)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 6,
    flexDirection: 'row',
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    maxWidth: 80,
    backgroundColor: COLORS.neutralGrey,
    borderRadius: 5,
    padding: 6,
    marginRight: 8,
  },
  image: {
    width: 72,
    height: 72,
  },
  infoContainer: {
    flex: 1,
    justifyContent: 'space-evenly',
    paddingHorizontal: 10,
  },
  amountContainer: {
    flex: 1,
    alignItems: 'flex-end',
    maxWidth: 70,
  },
  price: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  remove: {
    alignSelf: 'flex-end',
    right: -10,
  },
  productTitle: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.medium,
    //borderWidth: 1,
  },
  subtitle: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 10,
    lineHeight: 10,
    marginTop: 6,
    opacity: 0.7,
  },
  discountedPrice: {
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
  },
});
