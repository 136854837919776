import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';

import Talk from 'talkjs';
import { useAtomValue } from 'jotai';
import { useFocusEffect } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native-paper';
import { customerAtom } from '../../hooks/customerAtoms';
import { TALKJS_APP_ID } from '../../../env';
import { hubAtom } from '../../hooks/hubsAtoms';
import { branding } from '../../branding';

export const OrderTrackingConversation = memo(
  ({ orderId }: { orderId: string }) => {
    const customer = useAtomValue(customerAtom);
    const hub = useAtomValue(hubAtom);

    const [talkLoaded, markTalkLoaded] = useState(false);
    const chatboxEl = useRef(null);

    useEffect(() => {
      Talk.ready.then(() => markTalkLoaded(true));

      if (talkLoaded) {
        if (customer) {
          const me = {
            id: orderId,
            name: `${customer.firstName} ${customer.lastName}`,
            email: customer.email,
            role: 'Customer',
            custom: {
              hub: hub?.id || 'no hub',
              brand: branding.name,
            },
          };

          const supportMember = {
            id: '987654325',
            name: 'support',
            email: 'support@teddy.nz',
            photoUrl:
              'https://teddy-public.s3.ap-southeast-2.amazonaws.com/teddy-logo.png',
            welcomeMessage: 'Have a question? Let us know',
            role: 'default',
          };

          const currentUser = new Talk.User(me);
          const supportMemberUser = new Talk.User(supportMember);

          const session = new Talk.Session({
            appId: TALKJS_APP_ID,
            me: currentUser,
          });
          const conversationId = orderId.split('/')[4].split('?')[0];
          const conversation = session.getOrCreateConversation(conversationId);

          conversation.setParticipant(currentUser);
          conversation.setParticipant(supportMemberUser);

          const custom = {
            today: new Date().toISOString().split('T')[0],
            brand: branding.name,
          };
          conversation.setAttributes({
            custom,
          });

          const chatbox = session.createChatbox({ showChatHeader: false });
          chatbox.select(conversation);
          chatbox.mount(chatboxEl.current);
        }
      }
    }, [talkLoaded]);

    if (!customer) {
      return null;
    }

    return (
      <View style={{ height: 380 }}>
        <Loader />

        <View style={{ flex: 1 }}>
          <View style={{ backgroundColor: '#fff', flex: 1 }} ref={chatboxEl} />
        </View>
      </View>
    );
  },
);

const Loader = () => {
  const [showLoader, setShowLoader] = useState(true);

  // We can't detect when TalkJS webview has finished loading, so we show
  // the hider for a 2 seconds then hide it on screen view
  useFocusEffect(
    useCallback(() => {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 1300);
      return () => clearTimeout(timeout);
    }, []),
  );
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        height: '100%',
        width: '100%',
        zIndex: 100,
        display: showLoader ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ActivityIndicator color="#ddd" />
    </View>
  );
};
