import React from 'react';
import { View, Pressable } from 'react-native';

import MapView from 'react-native-maps';

import { Text } from '../../core-ui';
import { Hub } from '../../hooks/hubsAtoms';

export const HubInformation = ({
  hub,
  isFirst,
  onPress,
  isSelected,
}: {
  hub: Hub;
  map: MapView | null;
  isFirst: boolean;
  isSelected: boolean;
  onPress: () => void;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        padding: 10,
        width: 180,
        backgroundColor: 'rgba(0,0,0,0.04)',
        borderColor: 'rgba(0,0,0,.1)',
        marginRight: 10,
        borderRadius: 6,
        marginLeft: isFirst ? 14 : 0,
        flexDirection: 'row',
        alignItems: 'center',
        opacity: isSelected ? 1 : 0.55,
      }}
    >
      <View
        style={{
          width: 14,
          height: 14,
          borderRadius: 7,
          backgroundColor: hub.status === 'active' ? '#00C853' : '#FFC83A',
          marginLeft: 4,
          marginRight: 10,
        }}
      />
      <View>
        <Text
          style={{
            opacity: hub.status === 'active' ? 1 : 0.5,
          }}
        >
          {hub.name}
        </Text>
        <Text
          style={{
            marginTop: 4,
            marginRight: 25,
            fontSize: 10,
            lineHeight: 12,
            opacity: hub.status === 'active' ? 0.7 : 0.5,
          }}
          numberOfLines={1}
        >
          {hub.message}
        </Text>
      </View>
    </Pressable>
  );
};
