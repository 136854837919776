import { useCallback, useEffect, useState } from 'react';
import { NextOrObserver, User, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { FIREBASE_WEB_CONFIG } from '../../env';

export const firebaseWebApp = initializeApp(FIREBASE_WEB_CONFIG);
export const firebaseWebAuth = getAuth(firebaseWebApp);
export const firebaseWebFunctions = getFunctions(firebaseWebApp);
export const firebaseAnalytics = getAnalytics(firebaseWebApp);

export default function useFirebaseAuth() {
  const [loading, setLoading] = useState(true);
  const [userId, setCurrentUserId] = useState<string | null>(null);
  const [user, setCurrentUser] = useState<NextOrObserver<User | null> | null>(
    null,
  );

  const onFirebaseAuthStateChanged = useCallback(
    (firebaseUser) => {
      if (firebaseUser) {
        setCurrentUserId(firebaseUser.uid);
        setCurrentUser(firebaseUser);
      } else {
        setCurrentUserId(null);
        setCurrentUser(null);
      }

      setLoading(false);
    },
    [setLoading, setCurrentUserId, setCurrentUser],
  );

  useEffect(() => {
    const subscriber = firebaseWebAuth.onAuthStateChanged(
      onFirebaseAuthStateChanged,
    );

    return subscriber;
  }, [onFirebaseAuthStateChanged]);

  return {
    isAuthenticated: !!userId,
    userId,
    user,
    loading,
  };
}
