import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { CodeInputScene } from '../scenes/Authentication/CodeInputScene';
import { PhoneInputScene } from '../scenes/Authentication/PhoneInputScene';
import PersonalDetailsScene from '../scenes/Authentication/PersonalDetailsScene';
import { SmsAuthenticationModalStackScreens } from './types';

const RootStack =
  createNativeStackNavigator<SmsAuthenticationModalStackScreens>();

export function SmsAuthenticationNavigator() {
  return (
    <RootStack.Navigator
      id="SmsAuthentication"
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="PhoneInput"
    >
      <RootStack.Screen name="PhoneInput" component={PhoneInputScene} />
      <RootStack.Screen name="CodeInput" component={CodeInputScene} />
      <RootStack.Screen
        name="PersonalDetails"
        component={PersonalDetailsScene}
      />
    </RootStack.Navigator>
  );
}
