import { gql } from '@apollo/client';

export const GET_CONFIG = gql`
  query GetConfig {
    pageByHandle(handle: "config") {
      id
      show_timer: metafield(namespace: "config", key: "show_timer") {
        value
      }
      timer_value: metafield(namespace: "config", key: "timer_value") {
        value
      }
      allowed_suburbs: metafield(namespace: "config", key: "allowed_suburbs") {
        value
      }
      waitlist_url: metafield(namespace: "config", key: "waitlist_url") {
        value
      }
    }
  }
`;
