import React, { useEffect } from 'react';
import notifee from '@notifee/react-native';
import { Platform, Pressable, View, Linking, Image } from 'react-native';
import messaging, {
  FirebaseMessagingTypes,
} from '@react-native-firebase/messaging';
import { trackEvent } from '../analytics';
import { branding } from '../branding';
import { Text } from '../core-ui';
import { requestIosUserPermission } from '../utils/homescreenLandingPrompt';

export const NotificationPermissionAlert = () => {
  const [status, setStatus] = React.useState<
    FirebaseMessagingTypes.AuthorizationStatus | undefined
  >(undefined);

  useEffect(() => {
    messaging()
      .hasPermission()
      .then((status) => setStatus(status));
  }, []);

  if (
    status === undefined ||
    status === messaging.AuthorizationStatus.AUTHORIZED ||
    status === messaging.AuthorizationStatus.PROVISIONAL
  ) {
    return null;
  }

  if (
    Platform.OS === 'ios' &&
    status === messaging.AuthorizationStatus.NOT_DETERMINED
  ) {
    return (
      <NotificationView
        title="Enable notifications?"
        description="Get updates on your orders"
        buttonLabel="Turn on notifications"
        onButtonPress={() => {
          (async () => {
            const result = await requestIosUserPermission();
            setStatus(result);
            trackEvent('inline_notification_permission_prompt_result', {
              result:
                result === messaging.AuthorizationStatus.AUTHORIZED ||
                messaging.AuthorizationStatus.PROVISIONAL
                  ? 'granted'
                  : 'denied',
            });
          })();
        }}
      />
    );
  }

  return (
    <NotificationView
      title="Enable notifications?"
      description="Get updates on your orders"
      buttonLabel="Turn on notifications"
      onButtonPress={() => {
        (async () => {
          Linking.openSettings();
          trackEvent('inline_notification_permission_opened_settings');
        })();
      }}
    />
  );
};

function NotificationView({
  title,
  description,
  buttonLabel,
  onButtonPress,
}: {
  title: string;
  description: string;
  buttonLabel: string;
  onButtonPress: () => void;
}) {
  return (
    <View
      style={{
        justifyContent: 'space-around',
        flex: 1,
        paddingVertical: 18,
        paddingHorizontal: 18,
        marginHorizontal: 20,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.05)',
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderRadius: 12,
      }}
    >
      <Image
        style={{
          width: 32,
          height: 32,
          alignSelf: 'center',
          marginBottom: 12,
        }}
        source={require('../../assets/images/exclamation-mark.png')}
      />
      <Text style={{ textAlign: 'center', fontSize: 14 }} weight="bold">
        {title}
      </Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 13,
          lineHeight: 15,
          marginTop: 12,
        }}
      >
        {description}
      </Text>

      <Pressable
        style={{
          backgroundColor: branding.buttonBackgroundColor,
          borderRadius: 4,
          padding: 8,
          paddingVertical: 12,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 16,
        }}
        onPress={onButtonPress}
      >
        <Text
          style={{
            color: branding.buttonTextColor,
          }}
          weight="bold"
        >
          {buttonLabel}
        </Text>
      </Pressable>
    </View>
  );
}
