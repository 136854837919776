import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { useAtomValue } from 'jotai';
import { FONT_FAMILY } from '../constants/fonts';
import { AllScreensNavigationProp } from '../navigation/types';
import { addressAtom } from '../hooks/addressAtoms';

export default function DeliveryPicker() {
  const { navigate } =
    useNavigation<AllScreensNavigationProp<'AddressEntry'>>();
  const loading = false;
  const address = useAtomValue(addressAtom);

  const handlePress = () => {
    navigate('AddressEntry', { flowType: 'fromHome' });
  };

  return (
    <TouchableOpacity style={styles.container} onPress={handlePress}>
      <View
        style={[
          styles.address,
          {
            width: 160,
          },
        ]}
      >
        <Image
          source={require('../../assets/images/map-marker.png')}
          style={{
            width: 17,
            height: Platform.select({ ios: 17, android: 20, web: 20 }),
            overflow: 'visible',
            marginRight: 8,
            marginLeft: 4,
          }}
        />
        {!address && !loading && (
          <Text style={styles.noAddressText}>Find your address</Text>
        )}
        {!!address && (
          <Text style={styles.addressText} numberOfLines={2}>
            {address.address1}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressText: {
    fontSize: 14,
    lineHeight: 16,
  },
  noAddressText: {
    fontSize: 14,
    fontFamily: FONT_FAMILY.BOLD,
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
  },
  address: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
