import { atom } from 'jotai';
import { branding } from '../branding';
import { lineItemsInCartAtom } from './checkoutAtoms';

const { beerOrCiderBottleLimit, wineBottleLimit, spiritBottleLimit } = branding;

const wineBottleCountInCartAtom = atom((get) => {
  const lineItems = get(lineItemsInCartAtom);
  return lineItems.reduce((count, lineItem) => {
    return count + lineItem.alcoholWineBottleCount * lineItem.quantity;
  }, 0);
});

const beerOrCiderCountInCartAtom = atom((get) => {
  const lineItems = get(lineItemsInCartAtom);
  return lineItems.reduce((count, lineItem) => {
    return count + lineItem.alcoholBeerOrCiderUnitCount * lineItem.quantity;
  }, 0);
});

const spiritCountInCartAtom = atom((get) => {
  const lineItems = get(lineItemsInCartAtom);
  return lineItems.reduce((count, lineItem) => {
    return count + lineItem.alcoholSpiritUnitCount * lineItem.quantity;
  }, 0);
});

const cartContainsAlcoholAtom = atom((get) => {
  return (
    get(wineBottleCountInCartAtom) > 0 ||
    get(beerOrCiderCountInCartAtom) > 0 ||
    get(spiritCountInCartAtom) > 0
  );
});

const cartExceedsAlcoholLimitAtom = atom((get) => {
  const beerOrCiderCountInCart = get(beerOrCiderCountInCartAtom);
  const wineBottleCountInCart = get(wineBottleCountInCartAtom);
  const spiritCountInCart = get(spiritCountInCartAtom);

  if (beerOrCiderCountInCart > beerOrCiderBottleLimit) {
    return true;
  }
  if (wineBottleCountInCart > wineBottleLimit) {
    return true;
  }

  if (spiritCountInCart > spiritBottleLimit) {
    return true;
  }

  return false;
});

export {
  cartExceedsAlcoholLimitAtom,
  beerOrCiderCountInCartAtom,
  wineBottleCountInCartAtom,
  cartContainsAlcoholAtom,
};
