import React, { useEffect, useState, useCallback, useRef } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { StyleSheet, View, Alert, Platform, Image } from 'react-native';
import { HelperText } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import auth, { FirebaseAuthTypes } from '@react-native-firebase/auth';

import {
  Auth,
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth';
import { isNil } from 'lodash';

import { useNavigation } from '@react-navigation/native';
import { useAtom, useAtomValue } from 'jotai';
import Link from '../../components/Link';
import CodeInput from '../../components/CodeInput';
import { HintText } from '../../components/Typography';
import FormLayout from '../../components/FormLayout';

import { globalStyles, typography } from '../../constants/theme';
import { timeEvent, trackEvent } from '../../analytics';
import {
  SmsAuthenticationModalStackScreens,
  AllScreensNavigationProp,
} from '../../navigation/types';
import {
  customerAccessTokenAtom,
  customerAtom,
  firebaseAuthErrorAtom,
  hasCustomerProvidedPersonalDetailsAtom,
} from '../../hooks/customerAtoms';
import { SimpleButton } from '../../components/SimpleButton';
import { useDimensions } from '../../helpers/dimensions';
import { Text } from '../../core-ui';
import { TeddyLoaderIndicator } from '../../components/TeddyLoaderIndicator';
import { branding } from '../../branding';
import { triggerAlert } from '../../utils/alert';
import { firebaseWebAuth } from '../../hooks/useFirebaseAuth.web';

type CodeInputForm = {
  verificationCode: string;
};

const CodeInputFormSchema: yup.SchemaOf<CodeInputForm> = yup.object({
  verificationCode: yup
    .string()
    .length(6, 'Enter the 6 digit code we sent to your mobile phone.')
    .required('Enter the 6 digit code we sent to your mobile phone.'),
});

type Props = NativeStackScreenProps<
  SmsAuthenticationModalStackScreens,
  'CodeInput'
>;
export const CodeInputScene = ({
  route: {
    params: { phoneNumber, flowType },
  },
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(CodeInputFormSchema),
    defaultValues: {
      verificationCode: '',
    },
  });

  const navigation = useNavigation<AllScreensNavigationProp<'CodeInput'>>();
  const { width } = useDimensions();

  const [firebaseAuthError, setFirebaseAuthError] = useAtom(
    firebaseAuthErrorAtom,
  );
  const [verificationError, setVerificationError] = useState('');
  const setVerificationCode = (newValue: string) =>
    setValue('verificationCode', newValue);
  const verificationCode = watch('verificationCode');
  const [firebaseConfirmation, setFirebaseConfirmation] = useState<
    FirebaseAuthTypes.ConfirmationResult | ConfirmationResult | null
  >(null);

  const customer = useAtomValue(customerAtom);
  const hasCustomerProvidedPersonalDetails = useAtomValue(
    hasCustomerProvidedPersonalDetailsAtom,
  );
  const customerAccessToken = useAtomValue(customerAccessTokenAtom);
  const [hasCodeSubmissionSucceeded, setHasCodeSubmissionSucceeded] =
    useState(false);
  const requestNewVerificationCode = useCallback(async () => {
    timeEvent('verification_code_sent');
    timeEvent('verification_code_overall_time');

    if (Platform.OS !== 'web') {
      try {
        const confirmationResult = await auth().signInWithPhoneNumber(
          phoneNumber,
        );
        trackEvent('verification_code_sent');
        setFirebaseConfirmation(confirmationResult);
      } catch (error: unknown) {
        if (error instanceof Error) {
          triggerAlert(error.message);
        }
        trackEvent('verification_code_error', {
          message: error instanceof Error ? error.message : 'unknown error',
        });
      }
    } else {
      const applicationVerifier = new RecaptchaVerifier(
        'captcha-container',
        {
          size: 'invisible',
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // console.log('start captcha!!!!');
          },
        },
        firebaseWebAuth as Auth,
      );

      const confirmationResult = await signInWithPhoneNumber(
        firebaseWebAuth as Auth,
        phoneNumber,
        applicationVerifier,
      );

      setFirebaseConfirmation(confirmationResult);
    }
  }, [phoneNumber]);

  useEffect(() => {
    requestNewVerificationCode();
  }, [requestNewVerificationCode]);

  useEffect(() => {
    setVerificationError('');
  }, [verificationCode, firebaseConfirmation]);

  // Recover from firebase auth error
  useEffect(() => {
    if (firebaseAuthError) {
      navigation.goBack();
      setFirebaseAuthError(false);
    }
  }, [firebaseAuthError]);

  // Loading state. Customer is fetched in `useAuth` implementation.
  const isFetchingCustomerState =
    hasCodeSubmissionSucceeded && customer === null;

  // Handle submission
  useEffect(() => {
    if (hasCustomerProvidedPersonalDetails === null) {
      return;
    }

    if (hasCustomerProvidedPersonalDetails) {
      if (flowType === 'fromShoppingCart') {
        navigation.navigate('TabNavigator');
        navigation.navigate('Checkout');
      } else if (flowType === 'fromProfile') {
        navigation.navigate('TabNavigator');
        navigation.navigate('Profile');
      } else {
        navigation.replace('AddressEntry', {
          flowType: 'fromLanding',
        });
      }
    } else {
      navigation.replace('PersonalDetails', {
        flowType: flowType,
        isEditMode: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerAccessToken,
    customer,
    hasCustomerProvidedPersonalDetails,
    navigation,
  ]);

  const onSubmit: SubmitHandler<CodeInputForm> = async (values) => {
    if (hasCodeSubmissionSucceeded) {
      // `onSubmit` is being called twice for some reason. This is bandaid to prevent code submitted twice
      // and logging an false "auth failure" event.
      return;
    }

    trackEvent('verification_code_submit');
    timeEvent('verification_code_confirmed');
    try {
      if (!isNil(firebaseConfirmation)) {
        await firebaseConfirmation.confirm(
          values.verificationCode.replace(/\s/g, ''),
        );
        /* Code submission succeeded. */
        trackEvent('verification_code_confirmed');
        trackEvent('verification_code_overall_time');
        setHasCodeSubmissionSucceeded(true);
      } else {
        setVerificationError('Please request a new code.');
      }
    } catch (error: unknown) {
      /* Code submission failed. */
      setVerificationError(
        'The code you entered is incorrect.\nDouble check the 6 digit code we sent to your mobile phone and try again.',
      );

      trackEvent('submit_verification_code_error', {
        message: error instanceof Error ? error.message : 'unknown error',
      });
    }
  };

  const onError: SubmitErrorHandler<CodeInputForm> = () => {
    triggerAlert(
      'To continue, enter the 6 digit code we sent to your mobile phone.',
    );
    trackEvent('verification_code_submit_error_shown');
  };

  const handleContactUsPress = async () => {
    try {
      navigation.navigate('TrackingAndConversation');
      //await Linking.openURL('https://support.teddy.nz');
    } catch (error: unknown) {
      triggerAlert(
        'Error',
        `Unable to launch mail client. Please send an email to support@teddy.nz.`,
      );
    }
    trackEvent('verification_code_contact_pressed');
  };

  if (isFetchingCustomerState) {
    return (
      <SafeAreaView style={globalStyles.fullFlex}>
        <View
          style={[
            globalStyles.fullFlex,
            { justifyContent: 'center', borderWidth: 0 },
          ]}
        >
          <TeddyLoaderIndicator />
          <Text
            weight="bold"
            style={{
              fontSize: 14,
              lineHeight: 18,
              borderWidth: 0,
              textAlign: 'center',
              opacity: 0.5,
              marginTop: 5,
              marginBottom: 100,
              alignSelf: 'center',
            }}
          >
            Loading Account
          </Text>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={globalStyles.fullFlex}>
      <FormLayout>
        <View style={styles.container}>
          <Image
            source={branding.logoGraphic}
            style={{
              width: 170,
              height: 60,
              resizeMode: 'contain',
              alignSelf: 'center',
            }}
          />
          {/* <StateDebug /> */}
          <View
            style={[
              globalStyles.centeredColumn,
              globalStyles.mb50,
              { borderWidth: 0 },
            ]}
          >
            {/* Firebase web captcha only needed for web */}
            {Platform.OS === 'web' ? (
              <View nativeID="captcha-container" />
            ) : null}

            <Text
              weight="bold"
              style={{
                textAlign: 'center',
                fontSize: 24,
                lineHeight: 28,
                marginTop: 20,
              }}
            >
              Enter Code
            </Text>
            <Text style={{ fontSize: 16, lineHeight: 20, marginTop: 32 }}>
              Enter the 6 digit code we
            </Text>
            <Text style={{ fontSize: 16, lineHeight: 20 }}>
              sent to your mobile number
            </Text>
            <Text
              weight="bold"
              style={{ fontSize: 18, lineHeight: 18, marginTop: 4 }}
            >
              {phoneNumber}
            </Text>
          </View>

          <View style={{ width: '90%', alignSelf: 'center' }}>
            <Controller
              control={control}
              name="verificationCode"
              render={({ field: { onChange, value } }) => (
                <CodeInput
                  onChangeText={onChange}
                  cellCount={6}
                  error={!!errors.verificationCode}
                  value={value}
                  setValue={setVerificationCode}
                  onFulfill={handleSubmit(onSubmit, onError)}
                />
              )}
            />

            {!!errors.verificationCode && (
              <View style={globalStyles.mt15}>
                <HelperText
                  type="error"
                  visible
                  style={[typography.smallLabel, globalStyles.centeredText]}
                >
                  {errors.verificationCode?.message}
                </HelperText>
              </View>
            )}

            {!!verificationError && (
              <View style={globalStyles.mt15}>
                <HelperText
                  type="error"
                  visible
                  style={[typography.smallLabel, globalStyles.centeredText]}
                >
                  {verificationError}
                </HelperText>
              </View>
            )}
          </View>

          <View style={[globalStyles.centeredColumn, globalStyles.mt30]}>
            <ResendCodeButton onPress={requestNewVerificationCode} />
          </View>
        </View>
      </FormLayout>

      <View style={styles.footer}>
        <View style={styles.buttonContainer}>
          <SimpleButton
            onPress={handleSubmit(onSubmit, onError)}
            width={width - 60}
          >
            Next
          </SimpleButton>
          <View
            style={{
              marginTop: 7,
            }}
          >
            <SimpleButton
              onPress={() => !isSubmitting && navigation.goBack()}
              width={width - 60}
              bgColor={'rgba(0,0,0,0)'}
              color={'#333'}
            >
              Go Back
            </SimpleButton>
          </View>
        </View>

        <View style={[globalStyles.hCenteredRow, globalStyles.mt30]}>
          <HintText>Having trouble? </HintText>
          <Link style={typography.hintText} onPress={handleContactUsPress}>
            Contact us
          </Link>
        </View>
      </View>
    </SafeAreaView>
  );
};

function ResendCodeButton({ onPress }: { onPress: () => void }) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  useEffect(() => {
    if (secondsRemaining > 0) {
      timeoutRef.current = setInterval(() => {
        setSecondsRemaining(secondsRemaining - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [secondsRemaining]);

  return (
    <>
      {secondsRemaining === 0 ? (
        <>
          <HintText>Didn&apos;t receive a code? </HintText>
          <Link
            style={typography.hintText}
            onPress={() => {
              trackEvent('verification_code_request_new');
              setSecondsRemaining(60);
              onPress();
            }}
          >
            Request a new code
          </Link>
        </>
      ) : (
        <>
          <Text
            style={{ fontWeight: 'bold', fontSize: 12, marginBottom: 6 }}
            weight="bold"
          >
            New code sent!
          </Text>
          <Text
            style={{
              fontSize: 11,
              textAlign: 'center',
              marginBottom: 4,
              color: '#666',
            }}
          >
            Still haven&apos;t received a code?
          </Text>
          <Text
            style={{
              fontSize: 11,
              textAlign: 'center',
              color: '#666',
            }}
          >
            You will be able to request a new code shortly
          </Text>
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    padding: 20,
    justifyContent: 'space-between',
    height: '100%',
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    padding: 20,
  },
});
