import { QueryHookOptions, useQuery } from '@apollo/client';

import { GetConfig } from '../../generated/server/GetConfig';
import { GET_CONFIG } from '../../graphql/server/config';

export default function useConfig(options?: QueryHookOptions<GetConfig>) {
  return useQuery<GetConfig>(GET_CONFIG, {
    fetchPolicy: 'network-only',
    ...options,
  });
}
