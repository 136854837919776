import {
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import React from 'react';

import { CategoryItem as CategoryItemType } from '../types/types';
import { COLORS } from '../constants/colors';
import { ImageWrapper } from '../components/ImageWrapper';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  item: CategoryItemType;
  onSelect: (category: CategoryItemType) => void;
};

export default function CategoryItem({
  item,
  onSelect,
  containerStyle,
}: Props) {
  return (
    <TouchableOpacity
      style={[
        styles.categoryItemContainer,
        styles.borderRadius,
        containerStyle,
      ]}
      onPress={() => onSelect(item)}
    >
      {item.image && (
        <ImageWrapper
          source={{ uri: item?.image, priority: 'normal' }}
          style={{
            borderRadius:
              Platform.OS === 'web' ? 10 : styles.borderRadius.borderRadius,
            height: '100%',
            width: '100%',
            aspectRatio: 1.777,
          }}
        />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  categoryItemContainer: {
    flex: 1,
    minWidth: 80,
    backgroundColor: COLORS.neutralGrey,
  },
  borderRadius: {
    borderRadius: 10,
  },
});
