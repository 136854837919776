import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, ScrollView, View, Platform } from 'react-native';
import { IconButton } from 'react-native-paper';

import { useDimensions } from '../helpers/dimensions';
import { Collection } from '../hooks/collectionsAtoms';
import { addCategoryBrowsedAtom } from '../hooks/basketRecommendationsAtoms';
import {
  Product,
  availableProductsAtom,
  recentPurchasedLineItemsAtom,
} from '../hooks/productsAtoms';
import { trackEvent } from '../analytics';
import { collectionActiveFiltersAtomFamily } from '../hooks/collectionWithFilterAtom';
import { branding } from '../branding';
import { COLORS } from '../constants/colors';
import { CollectionSectionHeader } from './CollectionSectionHeader';
import ProductItem from './ProductItem';

const Loader = ({ width }: { width: number }) => {
  const height = width * 2.285;
  return (
    <View
      style={{
        width,
        height,
        padding: 6,
        paddingVertical: 12,
        borderWidth: 0,
      }}
    >
      <View
        style={{
          marginBottom: 10,
          width: '100%',
          backgroundColor: COLORS.neutralGrey,
          borderRadius: 5,
          aspectRatio: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          source={branding.emblemGraphic}
          style={{
            width: 110 * 0.6,
            height: 110 * 0.6,
          }}
        />
      </View>
      <View
        style={{
          height: 16,
          marginBottom: 4,
          width: 50,
          backgroundColor: COLORS.neutralGrey,
          borderRadius: 3,
        }}
      />
      <View
        style={{
          height: 14,
          width: '100%',
          backgroundColor: COLORS.neutralGrey,
          marginBottom: 4,
          borderRadius: 3,
        }}
      />
      <View
        style={{
          height: 14,
          width: '100%',
          backgroundColor: COLORS.neutralGrey,
          borderRadius: 3,
        }}
      />

      <View
        style={{
          marginTop: 'auto',
          height: 30,
          width: '100%',
          backgroundColor: '#333',
          borderRadius: 5,
        }}
      />
    </View>
  );
};

export const ProductHorizontalScrollerLoaderPlaceholder = ({
  listingNumberVisible,
}: {
  listingNumberVisible: number;
}) => {
  const { width: screenWidth } = useDimensions();
  const width = screenWidth / listingNumberVisible;

  return (
    <ScrollView
      style={{ flexDirection: 'row', paddingLeft: 14 }}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      <Loader width={width} />
      <Loader width={width} />
      <Loader width={width} />
      <Loader width={width} />
    </ScrollView>
  );
};

export const ProductHorizontalScroller = ({
  products,
  placement,
  //width,
  listingNumberVisible,
  showLoader,
  productsQueueSync,
  collectionTitle,
  parentCollectionHandle,
  isDarkBackground,
}: {
  products: Product[];
  placement: string;
  collectionTitle: string;
  //width: number;
  listingNumberVisible: number;
  parentCollectionHandle?: string;
  showLoader?: boolean;
  productsQueueSync?: boolean;
  isDarkBackground?: boolean;
}) => {
  const flatListRef = useRef<FlatList | null>(null);
  const addCategoryBrowsed = useSetAtom(addCategoryBrowsedAtom);

  const { width: screenWidth } = useDimensions();
  const width = screenWidth / listingNumberVisible;

  const regexp = /android|iphone|kindle|ipad/i;
  const isDesktop =
    Platform.OS !== 'web'
      ? false
      : !regexp.test(navigator.userAgent.toLowerCase());

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Fire addCategoryBrowsed(collectionTitle); when scrolled
  useEffect(() => {
    if (hasScrolled && collectionTitle) {
      const sanitisedCollectionTitle = collectionTitle.replace('[AI] ', '');
      addCategoryBrowsed(sanitisedCollectionTitle); // GPT should need to know the [AI] part
      trackEvent('subcollection_scrolled', {
        collectionTitle: collectionTitle,
      });
    }
  }, [hasScrolled]);

  if (showLoader) {
    return (
      <ProductHorizontalScrollerLoaderPlaceholder
        listingNumberVisible={listingNumberVisible}
      />
    );
  }

  return (
    <View style={{ position: 'relative' }}>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        data={products}
        onScroll={(e) => {
          if (e.nativeEvent.contentOffset.x > 100) {
            setHasScrolled(true);
          }
        }}
        renderItem={({ item: product, index }) => {
          return (
            <View
              key={product.id}
              style={{
                width: width,
                marginLeft: index === 0 ? 14 : 0,
              }}
            >
              <ProductItem
                product={product}
                numColumns={1}
                placement={placement}
                collectionTitle={collectionTitle}
                queueSync={productsQueueSync}
                isDarkBackground={isDarkBackground}
                parentCollectionHandle={parentCollectionHandle}
              />
            </View>
          );
        }}
        keyExtractor={(item) => item.id}
        initialNumToRender={5}
        getItemLayout={(data, index) => ({
          length: width,
          offset: width * index,
          index,
        })}
        ref={flatListRef}
      />
      {Platform.OS === 'web' && isDesktop && products.length >= 3 && (
        <>
          <View
            style={{
              position: 'absolute',
              left: -25,
              justifyContent: 'center',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <IconButton
              icon="chevron-left"
              color={'#000'}
              onPress={() => {
                if (currentIndex < 0) {
                  setCurrentIndex(0);
                  return;
                }

                flatListRef.current?.scrollToIndex({
                  index: currentIndex - 3 <= -1 ? 0 : currentIndex - 3,
                  animated: true,
                });

                setCurrentIndex(currentIndex - 3 <= -1 ? 0 : currentIndex - 3);
              }}
              size={40}
              style={{ backgroundColor: 'rgba(255,255,255,0.35)' }}
              rippleColor={'rgba(255,255,255,0.25)'}
            />
          </View>

          <View
            style={{
              position: 'absolute',
              right: -25,
              justifyContent: 'center',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <IconButton
              icon="chevron-right"
              color={'#000'}
              onPress={() => {
                if (currentIndex > products.length - 1) {
                  setCurrentIndex(products.length - 1);
                  return;
                }

                flatListRef.current?.scrollToIndex({
                  index:
                    currentIndex + 3 < products.length - 1
                      ? currentIndex + 3
                      : products.length - 1,
                  animated: true,
                });

                setCurrentIndex(
                  currentIndex + 3 < products.length - 1
                    ? currentIndex + 3
                    : products.length - 1,
                );
              }}
              size={40}
              style={{ backgroundColor: 'rgba(255,255,255,0.35)' }}
              rippleColor={'rgba(255,255,255,0.25)'}
            />
          </View>
        </>
      )}
    </View>
  );
};

const CollectionHorizontalScroller = ({
  collection,
  parentCollection,
  placement,
  productsQueueSync,
  isDarkBackground,
  onShowAllPress,
}: {
  collection: Collection;
  parentCollection?: Collection;
  placement: string;
  productsQueueSync?: boolean;
  isDarkBackground?: boolean;
  onShowAllPress?: () => void;
}) => {
  const { activeFilters, availableProducts } = useAtomValue(
    collectionActiveFiltersAtomFamily(
      parentCollection ? parentCollection.handle : collection.handle,
    ),
  );

  const allProducts = useAtomValue(availableProductsAtom);
  const isLoadingInventory = allProducts.length === 0;
  const productsInCollection = collection.products.nodes
    .filter((product) => {
      if (activeFilters.length === 0) {
        //console.log('No active filters', collection.title);
        return true;
      }
      const notFiltered = availableProducts.some(
        (availableProduct) => availableProduct.id === product.id,
      );
      //console.log('Collection filtering', collection.title, notFiltered);

      return notFiltered;
    })
    .map((product) => {
      const productInCollection = allProducts.find(
        (productInProducts) => productInProducts.id === product.id,
      );
      return productInCollection;
    })
    .filter((product) => product) as Product[];

  const listingNumberVisible = collection.listingNumberVisible || 2.8;
  if (productsInCollection.length === 0 && !isLoadingInventory) {
    return null;
  }

  return (
    <>
      <View style={{ paddingLeft: 10, marginTop: 10 }}>
        <CollectionSectionHeader
          title={collection.title}
          isDarkBackground={isDarkBackground}
          onShowAllPress={onShowAllPress}
        />
      </View>
      <ProductHorizontalScroller
        collectionTitle={collection.title}
        parentCollectionHandle={parentCollection?.handle}
        products={productsInCollection}
        placement={placement}
        listingNumberVisible={listingNumberVisible}
        productsQueueSync={productsQueueSync}
        showLoader={isLoadingInventory}
        isDarkBackground={isDarkBackground}
      />
    </>
  );
};

const RecentPurchasedHorizontalScroller = ({
  placement,
}: {
  placement: string;
}) => {
  const [recentPurchasedLineItems] = useAtom(
    loadable(recentPurchasedLineItemsAtom),
  );
  const listingNumberVisible = 3.5;
  const collectionTitle = 'Recently Purchased';

  if (recentPurchasedLineItems.state !== 'hasData') {
    return null;
  }

  if (recentPurchasedLineItems.data.length !== 0) {
    return (
      <View style={{ marginBottom: 20 }}>
        <View style={{ paddingLeft: 10 }}>
          <CollectionSectionHeader title={collectionTitle} />
        </View>
        <ProductHorizontalScroller
          collectionTitle={collectionTitle}
          products={recentPurchasedLineItems.data}
          placement={placement}
          listingNumberVisible={listingNumberVisible}
        />
      </View>
    );
  }

  return null;
};

export { CollectionHorizontalScroller, RecentPurchasedHorizontalScroller };
