import { useCallback } from 'react';

import { DECIMAL_CURRENCIES } from '../../constants/decimalCurrencies';
import formatNumber from '../../helpers/formatNumber';
import { COUNTRY_DATA } from '../../constants/defaultValues';

// Bad
export default function useCurrencyFormatter() {
  const formatCurrency = useCallback((value: number) => {
    const { currencyCode, currencySymbol } = COUNTRY_DATA;
    const decimalDigit = DECIMAL_CURRENCIES[currencyCode] || 2;

    // TODO: Need to find a way to give decimal digits based on country
    return currencySymbol + formatNumber(value, decimalDigit);
  }, []);

  return formatCurrency;
}
