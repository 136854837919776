import React, { forwardRef, useEffect, useState } from 'react';
import { View, Image, ViewStyle, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { Text, TextInput, Button } from '../../core-ui';
import { useDimensions } from '../../helpers/dimensions';
import { FONT_FAMILY } from '../../constants/fonts';
import { MailingAddressInput } from '../../generated/server/globalTypes';
import { trackEvent } from '../../analytics';

export const AddressConfirmationCard = forwardRef(
  (
    {
      animatedStyle,
      address,
      onConfirmPress,
      onNextPress,
      deliveryAreasLinkComponent,
      validationState,
    }: {
      animatedStyle: Animated.AnimateStyle<ViewStyle>;
      address: MailingAddressInput;
      onConfirmPress: (specificNumber: string) => void;
      onNextPress: () => void;
      deliveryAreasLinkComponent: React.ReactNode;
      validationState:
        | 'WELCOME'
        | 'EMPTY'
        | 'INZONE'
        | 'OUTSIDE'
        | 'CONFIRMING'
        | 'SAVING'
        | 'SAVED';
    },
    ref: any,
  ) => {
    const dimensions = useDimensions();
    const [specificNumber, setSpecificNumber] = useState('');
    const insets = useSafeAreaInsets();

    const expanded =
      validationState === 'CONFIRMING' ||
      validationState === 'SAVING' ||
      validationState === 'SAVED';

    const formOpacity = useSharedValue(0);
    const formTranslateY = useSharedValue(0);
    const formAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: formOpacity.value,
        transform: [
          {
            translateY: formTranslateY.value,
          },
        ],
      };
    });

    const confirmButtonOpacity = useSharedValue(1);
    const confirmButtonScale = useSharedValue(1);
    const confirmButtonAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: confirmButtonOpacity.value,
        transform: [
          {
            scale: confirmButtonScale.value,
          },
        ],
      };
    });

    useEffect(() => {
      if (expanded) {
        formOpacity.value = withTiming(1, { duration: 600 });
      } else {
        formOpacity.value = 0;
      }
    }, [expanded]);

    useEffect(() => {
      if (validationState === 'SAVING' || validationState === 'SAVED') {
        confirmButtonScale.value = withSpring(0.97, { mass: 0.4 });
        confirmButtonOpacity.value = withTiming(0.3, { duration: 100 });
      } else {
        confirmButtonScale.value = 1;
        confirmButtonOpacity.value = 1;
      }
    }, [validationState]);

    return (
      <Animated.View
        ref={ref}
        style={[
          animatedStyle,
          {
            backgroundColor: 'white',
            padding: 20,
            paddingVertical: 20,
            marginHorizontal: 10,
            borderRadius: 10,
            position: 'absolute',
            width: dimensions.width - 20,
            bottom:
              insets.bottom + Platform.select({ android: 50, default: 30 }), //  Fix for Android transparent nav being weird
            alignContent: 'center',
            alignItems: 'center',
            zIndex: expanded ? 10 : 0, // render on top of the "overlay" when expanded
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            source={require('../../../assets/images/map-marker.png')}
            style={{
              width: 46 * 0.4,
              height: 60 * 0.4,
              marginRight: 10,
            }}
          />
          <Text
            style={{
              fontFamily: FONT_FAMILY.REGULAR,
              fontSize: 14,
            }}
          >
            YOUR DELIVERY ADDRESS
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            marginBottom: 4,
            borderRadius: 5,
            borderColor: 'rgba(0,0,0,.1)',
            paddingVertical: 20,
          }}
        >
          <Text
            weight="bold"
            style={{
              borderWidth: 0,
              fontSize: 18,
              lineHeight: 20,
              textAlign: 'center',
              paddingHorizontal: 10,
            }}
          >
            {address.address1}, {address.city}
          </Text>
        </View>

        <Animated.View
          style={[
            formAnimatedStyle,
            {
              width: '100%',
              marginTop: 'auto',
              marginBottom: 24,
            },
            Platform.select({
              web: {
                position: expanded ? 'relative' : 'absolute',
              },
            }),
          ]}
        >
          <Text
            style={{
              paddingVertical: 4,
              fontSize: 13,
              width: '100%',
              lineHeight: 19,
            }}
          >
            Unit / Apartment / Floor
          </Text>
          <TextInput
            autoCapitalize="none"
            autoCorrect={false}
            placeholder="(Optional)"
            value={specificNumber}
            onChangeText={(text) => {
              const specificNumber = text.slice(0, 20);
              setSpecificNumber(specificNumber);
            }}
            style={{
              borderWidth: 1,
              padding: 14,
              fontSize: 16,
              width: '100%',
              lineHeight: 16,
            }}
          />
        </Animated.View>

        {expanded && (
          <Animated.View
            style={[
              confirmButtonAnimatedStyle,
              { width: '100%', alignItems: 'center' },
            ]}
          >
            <View style={{ width: '100%' }}>
              <Button
                onPress={() => {
                  onConfirmPress(specificNumber);
                  trackEvent('address_confirmed_button_pressed');
                }}
              >
                Confirm
              </Button>
            </View>
            {deliveryAreasLinkComponent}
          </Animated.View>
        )}

        {!expanded && (
          <View
            style={{
              width: '100%',
              alignItems: 'center',
            }}
          >
            <View style={{ width: '100%' }}>
              <Button onPress={onNextPress}>Next</Button>
            </View>
            {deliveryAreasLinkComponent}
          </View>
        )}
      </Animated.View>
    );
  },
);
