import { TextProps, Text } from 'react-native';

import { COLORS } from '../constants/colors';
import { typography } from '../constants/theme';

export function HintText({ style, ...restProps }: TextProps) {
  return (
    <Text
      style={[typography.hintText, { color: COLORS.black }, style]}
      {...restProps}
    />
  );
}
