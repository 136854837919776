import { gql } from '@apollo/client';

export const SET_SHOPIFY_AUTH_DATA = gql`
  mutation SetShopifyAuthData($shopifyAuthData: ShopifyAuthDataInput!) {
    setShopifyAuthData(shopifyAuthData: $shopifyAuthData) @client {
      expiresAt
    }
  }
`;

export const GET_SHOPIFY_AUTH_DATA = gql`
  query GetShopifyAuthData {
    shopifyAuthData @client {
      expiresAt
    }
  }
`;

export const SET_AUTHENTICATED_USER = gql`
  mutation SetAuthenticatedUser($user: AuthenticatedUserInput!) {
    setAuthenticatedUser(user: $user) @client {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_AUTHENTICATED_USER = gql`
  query GetAuthenticatedUser {
    authenticatedUser @client {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const SET_RECENT_SEARCH = gql`
  mutation SetRecentSearch($search: String!) {
    setRecentSearch(search: $search) @client {
      title
    }
  }
`;

export const GET_RECENT_SEARCH = gql`
  query GetRecentSearch {
    recentSearch @client {
      title
    }
  }
`;
