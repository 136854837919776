import { gql } from '@apollo/client';

export const CHECKOUT_CREATE = gql`
  mutation checkoutCreate(
    $checkoutCreateInput: CheckoutCreateInput!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutCreate(input: $checkoutCreateInput) {
      checkoutUserErrors {
        message
      }
      checkout {
        lineItemsSubtotalPrice {
          amount
        }
        id
        currencyCode
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        note
        lineItems(first: 250) {
          edges {
            node {
              id
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              title
              quantity
              variant {
                id
                quantityAvailable
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutID: ID!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutID) {
      userErrors {
        code
        field
        message
      }
      checkout {
        lineItemsSubtotalPrice {
          amount
        }
        id
        currencyCode
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        lineItems(first: 250) {
          edges {
            node {
              id
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              title
              quantity
              variant {
                id
                quantityAvailable
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE_V2 = gql`
  mutation checkoutShippingAddressUpdateV2(
    $checkoutId: ID!
    $shippingAddress: MailingAddressInput!
  ) {
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkoutUserErrors {
        message
      }
      checkout {
        webUrl
        lineItemsSubtotalPrice {
          amount
        }
        id
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        totalPriceV2 {
          amount
        }
        shippingLine {
          priceV2 {
            amount
          }
        }
        requiresShipping
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
            }
            title
          }
        }
        taxesIncluded
      }
    }
  }
`;

export const CHECKOUT_SHIPPING_LINE_UPDATE = gql`
  mutation checkoutShippingLineUpdate(
    $checkoutId: ID!
    $shippingRateHandle: String!
  ) {
    checkoutShippingLineUpdate(
      checkoutId: $checkoutId
      shippingRateHandle: $shippingRateHandle
    ) {
      checkoutUserErrors {
        message
      }
      checkout {
        webUrl
        lineItemsSubtotalPrice {
          amount
        }
        id
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        totalPriceV2 {
          amount
        }
        shippingLine {
          priceV2 {
            amount
          }
        }
        requiresShipping
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
            }
            title
          }
        }
        taxesIncluded
      }
    }
  }
`;

export const CHECKOUT_ATTRIBUTES_UPDATE_V2 = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        note
        customAttributes {
          key
          value
        }
      }
      checkoutUserErrors {
        message
      }
    }
  }
`;

export const CHECKOUT_CUSTOMER_ASSOCIATE_V2 = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
        lineItems(first: 250) {
          edges {
            node {
              title
              quantity
              variant {
                id
                quantityAvailable
              }
            }
          }
        }
      }
      customer {
        id
        firstName
        lastName
      }
      checkoutUserErrors {
        message
      }
    }
  }
`;

export const CHECKOUT_DISCOUNT_CODE_APPLY_V2 = gql`
  mutation checkoutDiscountCodeApplyV2(
    $checkoutId: ID!
    $discountCode: String!
    $country: CountryCode!
  ) @inContext(country: $country) {
    checkoutDiscountCodeApplyV2(
      checkoutId: $checkoutId
      discountCode: $discountCode
    ) {
      checkoutUserErrors {
        code
      }
      checkout {
        webUrl
        lineItemsSubtotalPrice {
          amount
        }
        id
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        totalPriceV2 {
          amount
        }
        taxesIncluded
        shippingDiscountAllocations {
          allocatedAmount {
            amount
          }
        }
        lineItems(first: 250) {
          edges {
            node {
              id
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              title
              quantity
              variant {
                quantityAvailable
                id
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
      checkoutUserErrors {
        message
      }
    }
  }
`;

export const CHECKOUT_DISCOUNT_REMOVE = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        webUrl
        lineItemsSubtotalPrice {
          amount
        }
        id
        subtotalPriceV2 {
          amount
          currencyCode
        }
        paymentDueV2 {
          amount
        }
        totalPriceV2 {
          amount
        }
        taxesIncluded
        shippingDiscountAllocations {
          allocatedAmount {
            amount
          }
        }
        lineItems(first: 250) {
          edges {
            node {
              id
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              title
              quantity
              variant {
                quantityAvailable
                id
                compareAtPriceV2 {
                  amount
                  currencyCode
                }
                priceV2 {
                  amount
                  currencyCode
                }
                selectedOptions {
                  name
                  value
                }
                image {
                  url
                }
              }
            }
          }
        }
      }
      checkoutUserErrors {
        message
      }
    }
  }
`;

// export const GET_DELIVERY_OPTIONS = gql`
//   query GetDeliveryOptions {
//     shoppingCart  {
//       id
//       items {
//         quantity
//         variantId
//       }
//     }
//   }
// `;

export const CHECKOUT_COMPLETE_WITH_TOKENIZED_PAYMENT_V3 = gql`
  mutation checkoutCompleteWithTokenizedPaymentV3(
    $checkoutId: ID!
    $payment: TokenizedPaymentInputV3!
  ) {
    checkoutCompleteWithTokenizedPaymentV3(
      checkoutId: $checkoutId
      payment: $payment
    ) {
      checkout {
        id
        order {
          id
        }
        orderStatusUrl
        paymentDueV2 {
          amount
          currencyCode
        }
        ready
        webUrl
        email
        updatedAt
        requiresShipping
        createdAt
        completedAt
        shippingLine {
          handle
          priceV2 {
            amount
          }
          title
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
      payment {
        id
        errorMessage
        creditCard {
          brand
          expiryMonth
          expiryYear
          firstDigits
          lastName
          firstName
          lastDigits
          maskedNumber
        }
        nextActionUrl
        ready
        test
        idempotencyKey
        transaction {
          amountV2 {
            amount
            currencyCode
          }
          kind
          statusV2
          test
        }
      }
    }
  }
`;

export const POLL_COMPLETED_CHECKOUT = gql`
  query PollCompletedCheckout($checkoutId: ID!) {
    checkout: node(id: $checkoutId) {
      id
      ... on Checkout {
        order {
          id
          financialStatus
          fulfillmentStatus
          orderNumber
          name
          cancelReason
          canceledAt
          processedAt
        }
      }
    }
  }
`;

export const POLL_COMPLETED_PAYMENT = gql`
  query PollCompletedPayment($paymentId: ID!) {
    payment: node(id: $paymentId) {
      ... on Payment {
        id
        amountV2 {
          amount
          currencyCode
        }
        transaction {
          test
          statusV2
          kind
          amountV2 {
            amount
            currencyCode
          }
        }
        checkout {
          id
          completedAt
          note
          order {
            id
            financialStatus
            fulfillmentStatus
            orderNumber
            name
            cancelReason
            canceledAt
            processedAt
          }
        }
        ready
        test
        idempotencyKey
        errorMessage
        creditCard {
          brand
          expiryMonth
          expiryYear
          firstDigits
          firstName
          lastDigits
          lastName
          maskedNumber
        }
      }
    }
  }
`;
