import { View } from 'react-native';
import React, { Suspense, useMemo, useState } from 'react';
import { IconButton } from 'react-native-paper';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useNavigation } from '@react-navigation/native';
import { useAtom } from 'jotai';

import { Text } from '../core-ui';
import {
  CheckoutScene,
  HomeScene,
  OrderDetailsScene,
  OrderHistoryScene,
  ProductCollectionScene,
  SearchScene,
  ShoppingCartScene,
} from '../scenes';
import { ProfileScene } from '../scenes/ProfileScene';
import { COLORS } from '../constants/colors';
import HeaderTitle from '../core-ui/HeaderTitle';
import { getTabBarOptions } from '../helpers/getTabBarOptions';
import { t } from '../helpers/translate';
import { FONT_FAMILY, FONT_SIZE } from '../constants/fonts';
import PersonalDetailsScene from '../scenes/Authentication/PersonalDetailsScene';
import CategoriesCollectionScene from '../scenes/Collection/CategoriesCollectionScene';
import OccasionsCollectionScene from '../scenes/Collection/OccasionsCollectionScene';
import { lineItemsTotalQuantityAtom } from '../hooks/checkoutAtoms';
import { TabBarHoverElement } from '../components/TabBarHoveringElement';
import {
  HomeTabStackScreens,
  SearchTabStackScreens,
  CheckoutTabStackScreens,
  SettingsTabStackScreens,
  TabNavigatorScreens,
  AllScreensNavigationProp,
} from './types';

const HomeStack = createNativeStackNavigator<HomeTabStackScreens>();
function HomeStackScreen() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="Home"
        component={HomeScene}
        options={{
          headerShown: false,
        }}
      />
      <HomeStack.Screen
        name="ProductCollection"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
      <HomeStack.Screen
        name="ProductCollectionLevel2"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
      <HomeStack.Screen
        name="ProductCollectionLevel3"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
      <HomeStack.Screen
        name="CategoriesCollection"
        component={CategoriesCollectionScene}
        options={({ route }) => ({
          title: route.params?.collection.title,
          headerShown: false,
        })}
      />
      <HomeStack.Screen
        name="OccasionsCollection"
        component={OccasionsCollectionScene}
        options={({ route }) => ({
          title: route.params?.collection.title,
          headerShown: false,
        })}
      />
      <HomeStack.Screen
        name="SearchResults"
        component={SearchScene}
        options={() => ({ headerShown: false, animation: 'none' })}
      />
    </HomeStack.Navigator>
  );
}

const SearchStack = createNativeStackNavigator<SearchTabStackScreens>();
function SearchStackScreen() {
  return (
    <SearchStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <SearchStack.Screen
        name="Search"
        component={SearchScene}
        // initialParams={{ searchKeyword: '' }}
      />
      <SearchStack.Screen
        name="ProductCollection"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
      <SearchStack.Screen
        name="ProductCollectionLevel2"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
      <SearchStack.Screen
        name="ProductCollectionLevel3"
        component={ProductCollectionScene}
        options={({ route }) => ({
          headerShown: false,
          title: route.params.collectionHandle,
          headerTitle: () => (
            <HeaderTitle title={route.params.collectionTitle ?? ''} />
          ),
        })}
      />
    </SearchStack.Navigator>
  );
}

const CheckoutStack = createNativeStackNavigator<CheckoutTabStackScreens>();
function CheckoutStackScreen() {
  return (
    <CheckoutStack.Navigator
      screenOptions={{
        headerShown: true,
        title: 'Checkout',
        headerTitle: () => <HeaderTitle title={'Checkout'} />,
      }}
    >
      <CheckoutStack.Screen
        name="Cart"
        component={ShoppingCartScene}
        options={() => {
          return {
            headerShown: false,
          };
        }}
      />
      <CheckoutStack.Screen
        name="Checkout"
        component={CheckoutScene}
        options={{
          headerShown: false,
        }}
      />
      <CheckoutStack.Screen
        name="OrderComplete"
        component={OrderDetailsScene}
        options={() => ({
          headerShown: false,
        })}
      />
    </CheckoutStack.Navigator>
  );
}

const SettingsStack = createNativeStackNavigator<SettingsTabStackScreens>();
function SettingsStackScreen() {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name="Profile"
        component={ProfileScene}
        options={() => ({
          headerLeft: () => null,
          headerShown: false,
          title: 'Account',
          headerTitle: () => <HeaderTitle title="Account" />,
        })}
      />
      <SettingsStack.Screen
        name="PersonalDetails"
        component={PersonalDetailsScene}
        options={() => ({
          headerShown: false,
          presentation: 'modal',
          animation: 'slide_from_bottom',
        })}
      />
      <SettingsStack.Screen
        name="OrderHistory"
        component={OrderHistoryScene}
        options={() => ({
          title: t('Orders'),
          headerTitle: () => <HeaderTitle title="Orders" />,
          cardStyle: {
            backgroundColor: COLORS.darkWhite,
          },
          headerShown: true,
          headerBackTitleVisible: false,
          headerTintColor: COLORS.solidBlack,
          headerBackButtonMenuEnabled: false,
        })}
      />
      <SettingsStack.Screen
        name="OrderDetails"
        component={OrderDetailsScene}
        options={() => ({
          title: t('Order Details'),
          headerTitle: () => <HeaderTitle title="Order Details" />,
          headerBackTitleVisible: false,
          headerTintColor: COLORS.solidBlack,
          headerShown: true,
        })}
      />
    </SettingsStack.Navigator>
  );
}

const { Navigator, Screen } = createBottomTabNavigator<TabNavigatorScreens>();
export function TabNavigator() {
  const { bottom } = useSafeAreaInsets();
  const tabBarOptions = useMemo(() => getTabBarOptions(bottom), [bottom]);
  const { navigate } = useNavigation<AllScreensNavigationProp<'Home'>>();
  const [numberOfItemsInCart] = useAtom(lineItemsTotalQuantityAtom);
  const [selectedTab, setSelectedTab] = useState('HomeTab');

  return (
    <Navigator
      id="Tabs"
      screenOptions={{
        ...tabBarOptions,
      }}
      initialRouteName="HomeTab"
    >
      <Screen
        name="HomeTab"
        component={HomeStackScreen}
        options={{
          headerShown: false,
          tabBarButton: () => {
            return (
              <View
                style={{
                  display: 'flex',
                  flex: 1,
                  marginTop: 0,
                  paddingVertical: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  size={32}
                  icon="home-outline"
                  color={selectedTab === 'HomeTab' ? '#000' : '#ccc'}
                  onPress={() => {
                    navigate('HomeTab');
                    setSelectedTab('HomeTab');
                  }}
                />
              </View>
            );
          },
        }}
      />

      <Screen
        name="SearchTab"
        component={SearchStackScreen}
        options={() => {
          return {
            lazy: false,
            title: 'Search',
            headerShown: false,
            tabBarButton: () => {
              return (
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: 0,
                    paddingVertical: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    size={32}
                    icon="magnify"
                    color={selectedTab === 'SearchTab' ? '#000' : '#ccc'}
                    onPress={() => {
                      navigate('SearchTab');
                      setSelectedTab('SearchTab');
                    }}
                  />
                </View>
              );
            },
          };
        }}
      />

      <Screen
        name="CartTab"
        component={CheckoutStackScreen}
        options={() => {
          return {
            unmountOnBlur: true,
            headerLeft: () => null,
            headerShown: false,
            title: 'Cart',
            tabBarBadge:
              numberOfItemsInCart > 0 ? numberOfItemsInCart : undefined,
            tabBarBadgeStyle: {
              backgroundColor: COLORS.teddy,
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: FONT_SIZE.extraSmall,
              fontFamily: FONT_FAMILY.REGULAR,
            },
            tabBarButton: () => {
              return (
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: 0,
                    paddingVertical: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <View style={{ position: 'relative' }}>
                    <IconButton
                      size={32}
                      icon="shopping-outline"
                      color={selectedTab === 'CartTab' ? '#000' : '#ccc'}
                      onPress={() => {
                        navigate('CartTab');
                        setSelectedTab('CartTab');
                      }}
                    />
                    {numberOfItemsInCart > 0 && (
                      <View
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          backgroundColor: COLORS.teddy,
                          borderRadius: 100,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 17,
                          height: 17,
                        }}
                      >
                        <Text style={{ color: '#fff', fontSize: 9 }}>
                          {numberOfItemsInCart}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              );
            },
          };
        }}
      />

      <Screen
        name="ProfileTab"
        component={SettingsStackScreen}
        options={() => {
          return {
            headerLeft: () => null,
            title: 'Account & Settings',
            headerTitle: () => <HeaderTitle title="Account &amp; Settings" />,
            headerShown: false,
            tabBarButton: () => {
              return (
                <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: 0,
                    paddingVertical: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Suspense fallback={null}>
                    <TabBarHoverElement />
                  </Suspense>

                  <IconButton
                    size={32}
                    icon="account"
                    color={selectedTab === 'ProfileTab' ? '#000' : '#ccc'}
                    onPress={() => {
                      navigate('ProfileTab');
                      setSelectedTab('ProfileTab');
                    }}
                  />
                </View>
              );
            },
          };
        }}
      />
    </Navigator>
  );
}
