import React, { useRef, useState, useEffect } from 'react';
import {
  Pressable,
  StyleSheet,
  ScrollView,
  View,
  Image,
  Platform,
  Linking,
} from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { useAtom } from 'jotai';

import {
  discountCodeAtom,
  setQuantityOfLineItemAtom,
} from '../hooks/checkoutAtoms';
import { AllScreensNavigationProp } from '../navigation/types';
import { useDimensions } from '../helpers/dimensions';
import { allProductsAtom } from '../hooks/productsAtoms';
import { allCollectionsAtom } from '../hooks/collectionsAtoms';
import { Content, contentAtom } from '../hooks/contentAtom';
import { hubAtom } from '../hooks/hubsAtoms';
import { customerAtom } from '../hooks/customerAtoms';
import { trackEvent } from '../analytics';
import { triggerHaptic } from '../utils/haptic';
import { ImageWrapper } from './ImageWrapper';
import { branding } from '../branding';
import { COLORS } from '../constants/colors';

interface IPromoContent {
  placement:
    | 'profile-screen'
    | 'referral-screen'
    | 'home-screen-1'
    | 'home-screen-2'
    | 'home-screen-3'
    | 'home-screen-4'
    | 'search-screen-1'
    | 'search-screen-2'
    | 'search-screen-3'
    | 'search-screen-4'
    | 'cart-screen-1'
    | 'cart-screen-2'
    | 'checkout-screen-1'
    | 'checkout-screen-2'
    | 'confirmation-screen-1'
    | 'confirmation-screen-2';
  placeholderColor?: string;
}

export function PromoContent(
  prop: IPromoContent = {
    placement: 'home-screen-1',
    placeholderColor: '#f4f4f4',
  },
) {
  const { placement, placeholderColor } = prop;

  const { navigate } = useNavigation<AllScreensNavigationProp<'Home'>>();
  const { width } = useDimensions();

  const [, setDiscountCode] = useAtom(discountCodeAtom);
  const [, setQuantityOfLineItem] = useAtom(setQuantityOfLineItemAtom);
  const [allProducts] = useAtom(allProductsAtom);
  const [allCollections] = useAtom(allCollectionsAtom);
  const [content] = useAtom(contentAtom);
  const [hub] = useAtom(hubAtom);
  const [customer] = useAtom(customerAtom);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const scrollViewRef = useRef<ScrollView>(null);
  const [isPressing, setIsPressing] = useState(false);

  // display content based on the hub stated in the airtable
  const contents = content.filter(
    (x: Content) =>
      x.placement === placement &&
      (hub
        ? x.hubs.indexOf(hub?.id) > -1 || x.hubs.length === 0
        : x.hubs.length === 0),
  );

  // Automatically scroll to the next page every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (!scrollViewRef.current || isPressing) {
        return;
      }
      const page = currentPageIndex + 1;
      if (page < contents.length) {
        scrollViewRef.current.scrollTo({
          x: page * (contentWidth + 14),
          animated: true,
        });
        setCurrentPageIndex(page);
      } else {
        scrollViewRef.current.scrollTo({
          x: 0,
          animated: true,
        });

        setCurrentPageIndex(0);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [currentPageIndex, isPressing, contents.length]);

  if (contents.length === 0) {
    return null;
  }

  const contentWidth = width - 26;

  const aspectRatio =
    (contents[0].image.width ?? 1) / (contents[0].image.height ?? 1);

  const onPress = (content: Content) => {
    trackEvent('promo_content_click', {
      placement: content.placement,
      action: content.action,
      action_data: content.action_data,
    });
    if (!content.action_data) {
      return;
    }

    triggerHaptic();

    switch (content.action) {
      case 'no_action':
        break;
      case 'view_product':
        navigate('ProductDetails', { productHandle: content.action_data });
        break;
      case 'view_collection':
        const collection = allCollections.find(
          (collection) => collection.handle === content.action_data,
        );
        if (collection) {
          navigate('ProductCollection', {
            collectionHandle: collection.handle,
            collectionTitle: collection.title,
            navigationLevel: 1,
          });
        }
        break;
      case 'view_referral':
        if (customer) {
          navigate('CustomerReferral');
        } else {
          navigate('CustomerReferral');
          //navigate('Profile');
        }
        break;
      case 'add_discount':
        setDiscountCode(content.action_data);
        break;
      case 'add_product':
        const product = allProducts.find(
          (product) => product.handle === content.action_data,
        );
        if (product) {
          setQuantityOfLineItem({
            variantID: product?.variants.nodes[0].id,
            quantity: 1,
            queueSync: true,
          });
        }
        break;
      case 'view_external_url':
        if (content.action_data) {
          Linking.openURL(content.action_data);
        }
        break;
    }
  };

  return (
    <View
      onTouchStart={() => setIsPressing(true)}
      onTouchEnd={() => setIsPressing(false)}
    >
      <ScrollView
        ref={scrollViewRef}
        horizontal={true}
        pagingEnabled={true}
        scrollEventThrottle={10}
        showsHorizontalScrollIndicator={false}
        snapToInterval={contentWidth}
        onScroll={(event) => {
          const page = Math.round(
            event.nativeEvent.contentOffset.x / contentWidth,
          );
          setCurrentPageIndex(page);
        }}
        contentInset={{ top: 0, left: 0, bottom: 0, right: 14 }}
        style={{
          height: contentWidth / aspectRatio,
          paddingLeft: 14,
          paddingRight: 14,
          marginBottom: 12,
        }}
      >
        {contents.map((content: Content, i: number) => {
          return (
            <PromoContentItem
              key={i}
              isLast={contents.length > 1 && i === contents.length - 1}
              content={content}
              contentWidth={contentWidth}
              aspectRatio={aspectRatio}
              onPress={onPress}
              placeholderColor={placeholderColor}
            />
          );
        })}
      </ScrollView>
    </View>
  );
}

const PromoContentItem = ({
  contentWidth,
  aspectRatio,
  content,
  onPress,
  placeholderColor = COLORS.neutralGrey,
  isLast,
}: {
  contentWidth: number;
  aspectRatio: number;
  content: Content;
  onPress: (content: Content) => void;
  placeholderColor?: string;
  isLast?: boolean;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <View
      style={{
        paddingLeft: Platform.OS === 'web' ? 14 : 0, // scrollview contentInset doesn't work on web
        paddingRight: isLast ? 12 : 0,
        borderWidth: 1, // Border width somehow affects layout
        borderColor: 'transparent',
      }}
    >
      <Pressable
        style={[
          styles.promoWrap,
          {
            width: contentWidth,
            aspectRatio,
            marginRight: isLast ? 12 : 12,
          },
        ]}
        onPress={() => onPress(content)}
      >
        {/* Absolutely fill view */}
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: placeholderColor,
              alignItems: 'center',
              justifyContent: 'center',
              //opacity: isImageLoaded ? 0 : 1,
            },
          ]}
        >
          <Image
            source={branding.emblemGraphic}
            style={{
              width: 91,
              height: 91,
            }}
          />
        </View>
        <ImageWrapper
          source={{ uri: content.image.url }}
          resizeMode={'contain'}
          onLoad={() => setIsImageLoaded(true)}
          style={{
            width: '100%',
            height: '100%',
            aspectRatio,
            opacity: isImageLoaded ? 1 : 0,
          }}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  promoWrap: {
    marginBottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    overflow: 'hidden',
  },
});
