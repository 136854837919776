import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  View,
  TextInput as NativeTextInput,
} from 'react-native';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ActivityIndicator } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { FlatList } from 'react-native-gesture-handler';
import { debounce, some } from 'lodash';
import { trackEvent } from '../analytics';

import { FONT_SIZE } from '../constants/fonts';
import {
  availableProductsAtom,
  productSearchDbAtom,
} from '../hooks/productsAtoms';
import { KeyboardAvoidingView, Text } from '../core-ui';
import { AllScreensNavigationProp } from '../navigation/types';
import { addCategoryBrowsedAtom } from '../hooks/basketRecommendationsAtoms';
import { branding } from '../branding';
import { allCollectionsAtom } from '../hooks/collectionsAtoms';
import { TextInput } from './TextInput';
import ProductItem from './ProductItem';
import { SimpleButton } from './SimpleButton';
import { SuggestProductBottomSheet } from './SuggestProductBottomSheet';

type Props = {
  children: (typeof FlatList.arguments)['ListeHeaderComponent'];
  showBackButton?: boolean;
};

export const ProductSearch = forwardRef<NativeTextInput, Props>(
  ({ children, showBackButton = true }, ref) => {
    const { goBack, navigate } =
      useNavigation<AllScreensNavigationProp<'Search'>>();
    const searchLengthRequirement = 2;
    const [products] = useAtom(loadable(availableProductsAtom));
    const collections = useAtomValue(allCollectionsAtom);
    const productSearchDb = useAtomValue(productSearchDbAtom);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermInputValue, setSearchTermInputValue] = useState('');
    const [productSuggestionShowing, setProductSuggestionShowing] =
      useState(false);

    const scrollViewRef = React.useRef<FlatList>(null);
    const insets = useSafeAreaInsets();

    const addCategoryBrowsed = useSetAtom(addCategoryBrowsedAtom);

    const debouncedChange = useCallback(
      debounce(
        (text) => {
          setSearchTerm(text);
        },
        Platform.select({
          ios: 100,
          android: 100,
        }),
        {
          leading: false,
          trailing: true,
        },
      ),
      [],
    );

    useEffect(() => {
      //scrollViewRef.current?.scrollToOffset({ offset: 0, animated: true });
      const scrollView = scrollViewRef.current;

      if (scrollView) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - FlatGrid abstraction sucks and doesn't have right types
        scrollView.scrollToOffset({ offset: 0, animated: true });
      }

      // Track event after 1 second use debounce
      const timeout = setTimeout(() => {
        if (searchTermInputValue) {
          trackEvent('search_query', {
            query: searchTerm,
          });
          addCategoryBrowsed(searchTermInputValue); // Is fed to GPT, so may be exploited :d
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }, [searchTermInputValue]);

    if (products.state !== 'hasData') {
      return <ActivityIndicator />;
    }

    let productsFound: typeof products.data = [];
    let collectionsFound: {
      id: string;
      handle: string;
      title: string;
      productCount: number;
      matchingPercentage: number;
    }[] = [];

    if (products.state === 'hasData') {
      if (
        searchTerm.length >= searchLengthRequirement &&
        products.state === 'hasData'
      ) {
        const searchResults = productSearchDb
          .search(searchTerm, {
            prefix: true,
          })
          .slice(0, Platform.select({ ios: 80, android: 50 }));

        // Hydrate products from search results
        productsFound = searchResults
          .map((hit) => {
            const product = products.data.find(
              (product) => product.id === hit.id,
            );
            return product;
          })
          .filter((product) => product !== undefined) as typeof products.data;

        collectionsFound = collections
          .filter((collection) => !collection.tags.includes('internal'))
          .map((collection) => {
            const matchingProducts = collection.products.nodes.filter(
              (product) => {
                return some(productsFound, { id: product.id });
              },
            );

            if (matchingProducts.length === 0) {
              return {
                id: collection.id,
                handle: collection.handle,
                title: collection.title,
                productCount: 0,
                matchingPercentage: 0,
              };
            }

            const collectionProducts = collection.products.nodes.filter(
              (product) => {
                return products.data.find(
                  (foundProduct) => foundProduct.id === product.id,
                );
              },
            );
            const matchingPercentage =
              matchingProducts.length / collectionProducts.length;
            return {
              id: collection.id,
              handle: collection.handle,
              title: collection.title,
              productCount: collectionProducts.length,
              matchingPercentage,
            };
          })
          .filter((collection) => collection.matchingPercentage > 0)
          .sort((a, b) => b.matchingPercentage - a.matchingPercentage)
          .slice(0, 4);
      }
    }

    return (
      <KeyboardAvoidingView
        style={{
          borderWidth: 0,
          flex: 1,
          paddingTop: insets.top + 4,
        }}
        behavior="height"
      >
        <View
          style={{
            paddingHorizontal:
              showBackButton && Platform.OS !== 'web' ? 32 : 16,
            marginBottom: 12,
            width: '100%',
            zIndex: 100,
            borderColor: 'rgba(0,0,0,0.1)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Back button */}
          {showBackButton ? (
            <Pressable
              style={{
                borderWidth: 0,
                width: 32,
                height: 32,
                marginRight: 8,
              }}
              onPress={goBack}
            >
              <Image
                source={require('../../assets/images/chevron.png')}
                style={{
                  width: 32,
                  height: 32,
                  opacity: 0.5,
                  transform: [{ rotate: '270deg' }],
                }}
              />
            </Pressable>
          ) : null}

          <TextInput
            style={Platform.select({
              android: {
                marginBottom: 0,
                flexGrow: 1,
                height: 45,
                fontSize: FONT_SIZE.extraSmall,
              },
              ios: {
                marginBottom: 0,
                flexGrow: 1,
              },
            })}
            placeholder={branding.searchPlaceholderText}
            onChangeText={(text) => {
              setSearchTermInputValue(text);
              debouncedChange(text.slice(0, 34));
            }}
            blurOnSubmit={true}
            returnKeyType="search"
            returnKeyLabel="search"
            clearButtonMode="always"
            value={searchTermInputValue}
            searchIcon={true}
            ref={ref}
          />

          {searchTerm.length >= searchLengthRequirement ? (
            <View
              style={{
                position: 'absolute',
                bottom: -40,
                paddingHorizontal: 14,
                paddingVertical: 10,
                flexDirection: 'row',
                width: Dimensions.get('window').width - 16 * 2,
                alignSelf: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(250,250,250,1)',
                borderRadius: 6,
                overflow: 'hidden',
              }}
            >
              {productsFound.length === 0 ? (
                <View
                  style={{
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      lineHeight: 13,
                    }}
                    numberOfLines={1}
                  >
                    Nothing found for
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      lineHeight: 13,
                      maxWidth: 200,
                    }}
                    weight="bold"
                    numberOfLines={1}
                  >
                    {' '}
                    {'“' + searchTerm + '”'}
                  </Text>
                </View>
              ) : (
                <>
                  <Text
                    weight="bold"
                    style={{
                      fontSize: 11,
                      lineHeight: 12,
                      textAlign: 'center',
                      borderWidth: 0,
                      fontStyle: 'italic',
                      overflow: 'visible',
                      marginRight: 10,
                      opacity: 0.8,
                    }}
                    numberOfLines={1}
                  >
                    {'“' + searchTerm + '”'}
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 12,
                      textAlign: 'center',
                      borderWidth: 0,
                      opacity: 0.3,
                      marginLeft: 'auto',
                      overflow: 'visible',
                    }}
                  >
                    {productsFound.length} products
                  </Text>
                </>
              )}
            </View>
          ) : null}
        </View>
        <FlatList
          ref={scrollViewRef}
          data={productsFound}
          keyExtractor={(item) => item.id}
          numColumns={3}
          columnWrapperStyle={{
            paddingHorizontal: 10,
            paddingBottom: 16,
          }}
          renderItem={({ item }) => {
            return (
              <ProductItem
                numColumns={3}
                product={item}
                placement="search-screen"
                collectionTitle="search-results"
              />
            );
          }}
          ListHeaderComponent={
            searchTerm.length >= searchLengthRequirement ? (
              <>
                <View style={{ marginTop: 34 }} />
                <View>
                  {collectionsFound.map((collection) => {
                    return (
                      <Pressable
                        key={collection.id}
                        onPress={() => {
                          trackEvent('search_result_collection', {
                            collectionHandle: collection.handle,
                            searchTerm,
                          });
                          navigate('ProductCollection', {
                            collectionHandle: collection.handle,
                            collectionTitle: collection.title,
                            navigationLevel: 1,
                          });
                        }}
                        style={{
                          marginHorizontal: 16,
                          marginVertical: 2,
                          padding: 8,
                          paddingHorizontal: 16,
                          //borderWidth: 1,
                          borderRadius: 4,
                          backgroundColor: 'rgba(250,250,250,1)',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text>{collection.title}</Text>
                        {/* <Text style={{ opacity: 0.2 }}>
                          {collection.productCount}
                        </Text> */}
                      </Pressable>
                    );
                  })}
                </View>
              </>
            ) : null
          }
          ListFooterComponent={
            <>
              {searchTerm.length >= searchLengthRequirement ? (
                <View
                  style={{
                    marginTop: 30,
                    marginBottom: 20,
                    paddingHorizontal: 30,
                    paddingTop: 10,
                    paddingBottom: 24,
                    backgroundColor: 'rgba(250,250,250,1)',
                    alignItems: 'center',
                    borderRadius: 6,
                    width: Dimensions.get('window').width - 16 * 2,
                    alignSelf: 'center',
                  }}
                >
                  <Image
                    style={{
                      width: 100,
                      height: 100,
                    }}
                    source={branding.questionGraphic}
                  />
                  <Text
                    style={{
                      textAlign: 'center',
                      marginHorizontal: 30,
                      fontSize: 14,
                      lineHeight: 14,
                      marginBottom: 20,
                    }}
                    weight="bold"
                  >
                    Can't find what you need?
                  </Text>
                  <SimpleButton
                    fontSize={12}
                    onPress={() => {
                      setProductSuggestionShowing(true);
                      trackEvent('opened_product_suggestion', {
                        searchTerm,
                      });
                    }}
                  >
                    Suggest a product
                  </SimpleButton>
                </View>
              ) : null}
              <SuggestProductBottomSheet
                isVisible={productSuggestionShowing}
                onDismiss={() => {
                  setProductSuggestionShowing(false);
                }}
                onSubmit={() => {
                  setProductSuggestionShowing(false);
                }}
              />
              {children}
            </>
          }
        />
      </KeyboardAvoidingView>
    );
  },
);
