import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { DefaultTheme as PaperDefaultTheme } from 'react-native-paper';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import { StackNavigationOptions } from '@react-navigation/stack';
import {
  Theme as ReactNavTheme,
  DefaultTheme as ReactNavDefaultTheme,
} from '@react-navigation/native';

import { COLORS } from './colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from './fonts';

export const HORIZONTAL_GUTTER = 16;

export const ColorTheme: ReactNativePaper.ThemeColors = {
  ...PaperDefaultTheme.colors,
  primary: COLORS.primaryColor,
  secondary: COLORS.white,
  accent: COLORS.primaryColor,
  border: COLORS.border,
  background: COLORS.white,
};

export const ReactNavigationTheme: ReactNavTheme = {
  ...ReactNavDefaultTheme,
  colors: {
    ...ReactNavDefaultTheme.colors,
    background: 'rgb(255, 255, 255)',
  },
};

const DefaultFonts: ReactNativePaper.ThemeFonts = {
  ...PaperDefaultTheme.fonts,
  regular: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontWeight: 'normal',
  },
  // medium: {
  //   fontFamily: FONT_FAMILY.MEDIUM,
  //   fontWeight: '500',
  // },
  bold: {
    fontFamily: FONT_FAMILY.BOLD,
    fontWeight: '700',
  },
  // italic: {
  //   fontFamily: FONT_FAMILY.ITALIC,
  //   fontWeight: 'normal',
  // },
};

export const CustomTheme: ReactNativePaper.Theme = {
  ...PaperDefaultTheme,
  fonts: DefaultFonts,
  colors: ColorTheme,
  roundness: 6,
  isRTL: false, // experimental
};

export const mainSceneHeaderOptions: StackNavigationOptions = {
  cardStyle: {
    backgroundColor: COLORS.white,
  },
  headerMode: 'screen',
  headerTitleStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.large,
    lineHeight: FONT_SIZE.large * 1.5,
  },
  headerBackTitleVisible: false,
  headerLeftContainerStyle: {
    marginLeft: 8,
  },
  headerStyle: {
    elevation: 1,
  },
  headerTitleAlign: 'center',
  headerShown: false,
  headerLeftLabelVisible: false,
};

export const tabBarHeight = 56;

export const tabBarOptions: BottomTabNavigationOptions = {
  tabBarActiveTintColor: COLORS.black,
  tabBarInactiveTintColor: COLORS.inactive,
  tabBarLabelPosition: 'below-icon',
  tabBarLabelStyle: {
    fontFamily: FONT_FAMILY.REGULAR,
  },
  tabBarStyle: {
    height: tabBarHeight,
  },
  // tabBarItemStyle: { flex: 1, marginTop: 0, paddingVertical: 0 },
  tabBarItemStyle: { display: 'none' },
  tabBarShowLabel: false,
  headerShown: false,
};

export const defaultButton: StyleProp<ViewStyle> = {
  elevation: 0,
  height: 58,
  display: 'flex',
  justifyContent: 'center',
};

export const defaultButtonLabel: StyleProp<TextStyle> = {
  fontSize: FONT_SIZE.medium,
  lineHeight: LINE_HEIGHT.medium,
  fontFamily: FONT_FAMILY.BOLD,
  fontWeight: '600',
};

export const flatTextInputContainerStyle: StyleProp<ViewStyle> = {
  height: 75,
  marginBottom: 26,
};

export const flatTextInputStyle: StyleProp<ViewStyle> = { height: 50 };

export const outlinedTextInput: StyleProp<ViewStyle> = { height: 58 };

export const textInputLabel: StyleProp<TextStyle> = {
  fontSize: FONT_SIZE.small,
  lineHeight: LINE_HEIGHT.small,
};

export const textCtaStyle: StyleProp<TextStyle> = {
  fontSize: FONT_SIZE.medium,
  lineHeight: LINE_HEIGHT.medium,
  fontFamily: FONT_FAMILY.BOLD,
  fontWeight: '600',
  textAlign: 'center',
};

export const globalStyles = {
  fullFlex: {
    flex: 1,
    backgroundColor: COLORS.white,
  },
  row: {
    flexDirection: 'row' as const,
  },
  redBorder: {
    borderWidth: 1,
    borderColor: 'red',
  },
  centeredColumn: {
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
  },
  hCenteredRow: {
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
  },
  vCenteredRow: {
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
  },
  fullWidth: {
    width: '100%',
  },
  rowSpacedBetween: {
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
  },
  centeredText: {
    textAlign: 'center' as const,
  },
  mt5: {
    marginTop: 5,
  },
  mt10: {
    marginTop: 10,
  },
  mt15: {
    marginTop: 15,
  },
  mt20: {
    marginTop: 20,
  },
  mt25: {
    marginTop: 25,
  },
  mt30: {
    marginTop: 30,
  },
  mt45: {
    marginTop: 45,
  },
  mt60: {
    marginTop: 60,
  },
  mb10: {
    marginBottom: 10,
  },
  mb25: {
    marginBottom: 25,
  },
  mb30: {
    marginBottom: 30,
  },
  mb40: {
    marginBottom: 40,
  },
  mb50: {
    marginBottom: 50,
  },
  mb60: {
    marginBottom: 60,
  },
  my5: {
    marginVertical: 5,
  },
  my15: {
    marginVertical: 15,
  },
  my30: {
    marginVertical: 30,
  },
  me5: {
    marginEnd: 5,
  },
  me10: {
    marginEnd: 10,
  },
  me15: {
    marginEnd: 15,
  },
  ms5: {
    marginStart: 5,
  },
  ms10: {
    marginStart: 10,
  },
  ms15: {
    marginStart: 15,
  },
  ms20: {
    marginStart: 20,
  },
  ms25: {
    marginStart: 25,
  },
};

export const typography = {
  heading: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 32,
    lineHeight: 38,
  },
  h2: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 26,
    lineHeight: 40,
    letterSpacing: -0.8,
  },
  label: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 16,
    lineHeight: 18,
    marginLeft: 2,
  },
  tinyLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 14,
    lineHeight: 14,
  },
  microLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 12,
    lineHeight: 16,
  },
  nanoLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 10,
    lineHeight: 16,
  },
  smallLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 14,
    lineHeight: 22,
  },
  largeLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 26,
  },
  body: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 16,
    lineHeight: 16,
  },
  smallBody: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 16,
    lineHeight: 28,
  },
  subtitle: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 14,
    lineHeight: 18,
  },
  largeBody: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 32,
    lineHeight: 40,
  },
  bodyBold: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 20,
    lineHeight: 28,
  },
  hintTextBold: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 16,
    lineHeight: 24,
  },
  hintText: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 12,
    lineHeight: 22,
    opacity: 0.6,
  },
  smallHintText: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: 14,
    lineHeight: 24,
  },
  buttonLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 20,
    lineHeight: 28,
    letterSpacing: -0.2,
  },
  optionLabel: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: 18,
    lineHeight: 28,
    letterSpacing: -0.2,
  },
};
