import React from 'react';
import { View, Image, Pressable, ViewStyle, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Animated from 'react-native-reanimated';
import { Text, Button } from '../../core-ui';
import { useDimensions } from '../../helpers/dimensions';
import { branding } from '../../branding';

export function OutOfZoneCard({
  animatedStyle,
  deliveryAreasLinkComponent,
  onGetNotifiedPress,
  onClosePress,
}: {
  animatedStyle: Animated.AnimateStyle<ViewStyle>;
  deliveryAreasLinkComponent: React.ReactNode;
  onGetNotifiedPress: () => void;
  onClosePress: () => void;
}) {
  const dimensions = useDimensions();
  const insets = useSafeAreaInsets();
  return (
    <Animated.View
      style={[
        animatedStyle,
        {
          backgroundColor: '#F2F2F2',
          padding: 20,
          paddingVertical: 30,
          marginHorizontal: 10,
          borderRadius: 10,
          position: 'absolute',
          width: dimensions.width - 20,
          bottom: insets.bottom + 50,
          zIndex: 10,
        },
      ]}
    >
      <Pressable
        onPress={onClosePress}
        hitSlop={30}
        style={{ position: 'absolute', top: 10, right: 10 }}
      >
        <Image
          style={{ width: 28, height: 28 }}
          source={require('../../../assets/images/x.png')}
        />
      </Pressable>
      <View
        style={{
          padding: 40,
          paddingTop: 20,
          paddingBottom: 50,
          justifyContent: 'center',
          alignItems: 'center',
          //borderWidth: 1,
        }}
      >
        <Image
          source={branding.warningGraphic}
          style={{ borderWidth: 0, height: 180, width: 180 }}
        />
        <Text
          weight="bold"
          style={{ fontSize: 20, marginBottom: 14, textAlign: 'center' }}
        >
          We don't deliver here yet
        </Text>
        <Text style={{ textAlign: 'center', lineHeight: 18 }}>
          Sorry, {branding.displayName} is expanding and aims to reach you soon!
        </Text>
      </View>
      <Button onPress={onGetNotifiedPress}>Get notified</Button>
      <View style={{ alignItems: 'center' }}>{deliveryAreasLinkComponent}</View>
    </Animated.View>
  );
}
