import React from 'react';
import {
  Image,
  StyleSheet,
  TextInput as NativeTextInput,
  TextInputProps,
  View,
  Platform,
} from 'react-native';

import { typography } from '../constants/theme';

type Props = TextInputProps & {
  searchIcon?: boolean;
};

export const TextInput = React.forwardRef<NativeTextInput, Props>(
  (props, ref) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: '#bbb',
          borderRadius: 6,
          paddingRight: 12,
          flexDirection: 'row',
          alignItems: 'center',
          ...Platform.select({
            web: {
              display: 'flex',
              flex: 1,
            },
          }),
        }}
      >
        {props.searchIcon && (
          <Image
            source={require('../../assets/images/searchImage.png')}
            style={{
              height: 23,
              width: 23,
              marginLeft: 14,
            }}
          />
        )}
        <NativeTextInput
          ref={ref}
          {...props}
          style={[
            typography.body,
            styles.textInput,
            props.style ? props.style : {},
          ]}
          placeholderTextColor="#a0a0a0"
        />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  textInput: {
    paddingVertical: 14,
    paddingHorizontal: 12,
    // fontSize: 14,
    lineHeight: 18,
    flexShrink: 1,
    flexGrow: 1,
  },
});

export default TextInput;
