import React, { useState } from 'react';
import {
  Image,
  Pressable,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { useAtom, useAtomValue } from 'jotai';

import { useNavigation } from '@react-navigation/native';

import { COLORS } from '../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import { priceAfterDiscount } from '../helpers/priceAfterDiscount';
import { Product } from '../hooks/productsAtoms';
import { defaultButtonLabel } from '../constants/theme';
import useCurrencyFormatter from '../hooks/api/useCurrencyFormatter';
import {
  lineItemAtomFamily,
  setQuantityOfLineItemAtom,
} from '../hooks/checkoutAtoms';
import { inventoryRecordFamily } from '../hooks/inventoryAtoms';
import { trackEvent } from '../analytics';
import { AllScreensNavigationProp } from '../navigation/types';
import { branding } from '../branding';
import { triggerHaptic } from '../utils/haptic';
import { triggerAlert } from '../utils/alert';
import {
  ActiveCollectionCheckboxFilter,
  ActiveCollectionScaleFilter,
  collectionActiveFiltersAtomFamily,
} from '../hooks/collectionWithFilterAtom';
import { ImageWrapper } from './ImageWrapper';
import { ProductBadge } from './ProductBadge';

type Props = {
  product: Product;
  containerStyle?: StyleProp<ViewStyle>;
  numColumns?: number;
  placement: string;
  collectionTitle: string;
  queueSync?: boolean;
  isDarkBackground?: boolean;
  parentCollectionHandle?: string;
};

function ProductItem(props: Props) {
  const {
    product: { id: shopifyId, title, images, variants, handle, filters },
    containerStyle,
    numColumns = 1,
    placement,
    collectionTitle,
    queueSync,
    isDarkBackground,
    parentCollectionHandle,
  } = props;

  const { navigate } = useNavigation<AllScreensNavigationProp<'Home'>>();
  const variant = variants.nodes?.[0];
  const variantId = variant?.id;
  const price = Number(variant?.priceV2.amount || 0);
  const compareAtPrice = Number(variant?.compareAtPriceV2?.amount || 0);
  const discount = compareAtPrice
    ? Math.round(100 - (price / compareAtPrice) * 100)
    : 0;
  const afterDiscount = priceAfterDiscount(price, 0);
  const formatCurrency = useCurrencyFormatter();
  const [imageLoaded, setImageLoaded] = useState(false);

  const lineItemAtom = lineItemAtomFamily({ variantID: variantId });
  const [lineItem] = useAtom(lineItemAtom);
  const [, setQuantityOfLineItem] = useAtom(setQuantityOfLineItemAtom);

  const inventoryRecordAtom = inventoryRecordFamily({ shopifyId });
  const [inventoryRecord] = useAtom(inventoryRecordAtom);
  const isUnavailable =
    inventoryRecord?.count === 0 ||
    inventoryRecord?.status === 'TemporarilyUnavailable';

  const quantity = lineItem?.quantity ?? 0;

  const onAddToCart = async () => {
    if (quantity >= 1) {
      return; // preventing quantity to reset when user click on the button after added to cart.
    }
    triggerHaptic();

    setQuantityOfLineItem({
      variantID: variantId,
      quantity: 1,
      queueSync: queueSync || false,
    });
    trackEvent('add_to_cart', {
      title,
      variantId,
      placement,
      collectionTitle,
    });
  };

  const onChangeQuantity = async (quantityToAdjust: number) => {
    const quantityToSet = quantity + quantityToAdjust;
    if (quantityToSet > (inventoryRecord?.count ?? 0)) {
      triggerHaptic('notificationWarning');

      triggerAlert(
        'Sorry, we only have ' + inventoryRecord?.count + ' left in stock.',
      );

      trackEvent('attempt_to_add_more_than_available', {
        title,
        variantId,
        quantity: quantityToSet,
        increase: quantityToAdjust > 0,
        placement,
        collectionTitle,
      });
      return;
    }
    triggerHaptic();

    setQuantityOfLineItem({
      variantID: variantId,
      quantity: quantityToSet,
      queueSync: queueSync || false,
    });
    trackEvent('adjust_cart_quantity_from_listing', {
      title,
      variantId,
      quantity: quantityToSet,
      increase: quantityToAdjust > 0,
      placement,
      collectionTitle,
    });
  };

  const onPress = () => {
    triggerHaptic();
    navigate('ProductDetails', { productHandle: handle });
    trackEvent('view_product_details', {
      title,
      variantId,
      placement,
      collectionTitle,
    });
  };

  const [mainTitle, subtitle] = title.split(' | ');
  //console.log('render', mainTitle);

  return (
    <TouchableOpacity
      onLayout={(layout) => {
        layout.nativeEvent.layout.width;
      }}
      style={[
        containerStyle,
        {
          flex: 1 / numColumns,
          padding: 6,
          paddingVertical: 12,
        },
      ]}
      onPress={isUnavailable ? undefined : onPress}
    >
      {/* Image */}
      <View
        style={{
          marginBottom: 10,
          width: '100%',
          backgroundColor: COLORS.neutralGrey,
          borderRadius: 5,
          aspectRatio: 1,
        }}
      >
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            source={branding.emblemGraphic}
            style={{
              width: 110 * 0.6,
              height: 110 * 0.6,
            }}
          />
        </View>
        {images.nodes?.[0]?.url ? (
          <ImageWrapper
            fallback={true}
            onLoad={() => setImageLoaded(true)}
            style={{
              width: '100%',
              backgroundColor: COLORS.neutralGrey,
              aspectRatio: 1,
              borderRadius: 5,
              opacity: imageLoaded ? 1 : 0,
            }}
            source={{ uri: images.nodes?.[0].url }}
          />
        ) : null}
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 0,
          position: 'absolute',
          top: 8,
          right: 4,
        }}
      >
        <ProductBadge product={props.product} size="small" />
      </View>

      {/* {discount && discount > 0 ? (
        <DiscountBadge value={discount} containerStyle={styles.discountBox} />
      ) : null} */}

      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          weight="bold"
          style={{
            marginRight: 8,
            fontSize: 12,
            color: isDarkBackground ? '#fff' : COLORS.black,
            paddingHorizontal: 2,
            lineHeight: FONT_SIZE.small,
          }}
        >
          {discount && discount > 0
            ? formatCurrency(afterDiscount)
            : formatCurrency(price)}
        </Text>
        {discount && discount > 0 ? (
          <Text
            style={{
              fontSize: 10,
              lineHeight: 10,
              color: isDarkBackground ? '#fff' : 'black',
              textDecorationLine: 'line-through',
            }}
          >
            {formatCurrency(compareAtPrice)}
          </Text>
        ) : null}
      </View>
      <Text
        numberOfLines={3}
        style={{
          fontSize: FONT_SIZE.extraSmall,
          fontFamily: FONT_FAMILY.REGULAR,
          lineHeight: LINE_HEIGHT.small,
          paddingHorizontal: 2,
          paddingTop: 6,
          color: isDarkBackground ? '#fff' : 'black',
        }}
      >
        {mainTitle}
      </Text>
      <Text
        numberOfLines={1}
        style={{
          fontSize: 10,
          lineHeight: 10,
          fontFamily: FONT_FAMILY.REGULAR,
          paddingTop: 3,
          paddingBottom: 6,
          opacity: 0.7,
          paddingHorizontal: 2,
          marginTop: 'auto',
          color: isDarkBackground ? '#fff' : 'black',
        }}
      >
        {subtitle}
      </Text>
      {parentCollectionHandle ? (
        <FiltersSummary
          filters={filters}
          parentCollectionHandle={parentCollectionHandle}
        />
      ) : null}

      <Pressable
        style={[
          {
            flexDirection: 'row',
            backgroundColor: branding.secondaryButtonBackgroundColor,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 5,
            height: 30,
          },
          isUnavailable && styles.disabledButton,
        ]}
        disabled={isUnavailable}
        onPress={isUnavailable ? undefined : onAddToCart}
      >
        <>
          {/* Quantity increase/decrease */}
          {quantity > 0 ? (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Pressable
                onPress={() => onChangeQuantity(-1)}
                hitSlop={10}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingHorizontal: 2,
                  marginLeft: 4,
                }}
              >
                <Text style={{ color: 'white', fontSize: 18, lineHeight: 18 }}>
                  -
                </Text>
              </Pressable>
              <Text
                style={{
                  color: 'white',
                  marginHorizontal: 10,
                  fontSize: 12,
                  lineHeight: 12,
                  width: 24,
                  textAlign: 'center',
                }}
                weight="bold"
              >
                {quantity}
              </Text>
              <Pressable
                hitSlop={10}
                onPress={() => onChangeQuantity(1)}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderColor: 'white',
                  paddingHorizontal: 2,
                  top: 0.5,
                  marginRight: 4,
                }}
              >
                <Text style={{ color: 'white', fontSize: 20, lineHeight: 20 }}>
                  +
                </Text>
              </Pressable>
            </View>
          ) : (
            <Text
              style={[
                defaultButtonLabel,
                {
                  flex: 1,
                  flexGrow: 2,
                  alignSelf: 'center',
                  color: branding.secondaryButtonTextColor,
                  fontSize: FONT_SIZE.extraSmall,
                  lineHeight: FONT_SIZE.extraSmall,
                  textAlign: 'center',
                  top: 0.5,
                },
              ]}
            >
              {isUnavailable ? 'Back soon!' : 'Add'}
            </Text>
          )}
        </>
      </Pressable>
    </TouchableOpacity>
  );
}

export default React.memo(ProductItem, (prevProps, nextProps) => {
  return (
    prevProps.product.id === nextProps.product.id &&
    prevProps.numColumns === nextProps.numColumns &&
    prevProps.placement === nextProps.placement &&
    prevProps.collectionTitle === nextProps.collectionTitle
  );
});

const FiltersSummary = React.memo(
  ({
    parentCollectionHandle,
    filters,
  }: {
    parentCollectionHandle: string;
    filters: Product['filters'];
  }) => {
    const { activeFilters } = useAtomValue(
      collectionActiveFiltersAtomFamily(parentCollectionHandle),
    );

    const matchingScaleFilters = activeFilters
      .map((activeFilter) => ({
        filterState: activeFilter,
        filter: filters.find((filter) => filter.id === activeFilter.id),
      }))
      .filter(
        (filter) =>
          filter.filter &&
          filter.filter.displayType === 'scale' &&
          // Special cases
          filter.filter.id !== 'price' &&
          filter.filter.id !== 'abv',
      );

    return (
      <View style={{ marginVertical: 8 }}>
        {matchingScaleFilters.map(({ filter, filterState }) => {
          return (
            <ScaleFilterDisplay
              key={filter.id}
              filterState={filterState}
              filter={filter}
            />
          );
        })}
      </View>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.parentCollectionHandle === nextProps.parentCollectionHandle &&
      prevProps.filters.length === nextProps.filters.length
    );
  },
);

const ScaleFilterDisplay = React.memo(
  ({
    filterState,
    filter,
  }: {
    filterState: ActiveCollectionCheckboxFilter | ActiveCollectionScaleFilter;
    filter: Product['filters'][0];
  }) => {
    const [width, setWidth] = useState(0);
    if (filterState.displayType !== 'scale') {
      return null;
    }

    let title = filter.title;

    if (filter.value > 5 && filter.label2) {
      title = filter.label2;
    }

    if (filter.value <= 5 && filter.label1) {
      title = filter.label1;
    }

    return (
      <View
        style={{
          marginVertical: 6,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              fontSize: 11,
            }}
          >
            {filter.label1}
          </Text>
          <Text
            style={{
              fontSize: 11,
            }}
          >
            {filter.label2}
          </Text>
        </View>
        <View>
          <View
            style={{
              width: '100%',
              height: 2,
              backgroundColor: branding.buttonBackgroundColor,
              marginVertical: 6,
            }}
            onLayout={(layout) => {
              setWidth(layout.nativeEvent.layout.width);
            }}
          />
          <View
            style={{
              width: 8,
              height: 8,
              borderRadius: 4,
              position: 'absolute',
              backgroundColor: branding.buttonBackgroundColor,
              left: (filter.value / 10) * width,
              bottom: 3,
            }}
          />
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  discountBox: {
    position: 'absolute',
    top: 14,
    right: 12,
  },
  discountedPrice: {
    fontSize: 10,
    lineHeight: 10,
    color: '#444',
    textDecorationLine: 'line-through',
  },
  disabledButton: {
    backgroundColor: COLORS.neutralDisabled,
    opacity: 0.7,
  },
});
