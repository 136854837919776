import { atom } from 'jotai';
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'teddy-next';
import _, { uniq } from 'lodash';
import { client } from '../api/trpc';
import { lineItemsInCartAtom } from './checkoutAtoms';
import { productSearchDbAtom, availableProductsAtom } from './productsAtoms';
import { branding } from '../branding';

export type Product = inferRouterOutputs<AppRouter>['products'][number];
export type Recommendation =
  inferRouterOutputs<AppRouter>['basketRecommendations'][number] & {
    products: Product[];
  };

export const areRecommendationsLoadingAtom = atom(false);
const dismissedRecommendationsAtom = atom<Recommendation[]>([]);
export const basketRecommendationsAtom = atom<Recommendation[]>([]);

export const categoriesBrowsedAtom = atom<string[]>([]);
export const addCategoryBrowsedAtom = atom(null, (get, set, cat: string) => {
  const categoriesBrowsed = get(categoriesBrowsedAtom);
  const newCategories = uniq([...categoriesBrowsed, cat])
    .reverse()
    .slice(0, 10)
    .reverse();
  set(categoriesBrowsedAtom, newCategories);
});

export const dismissAndRefreshRecommendationsAtom = atom(null, (get, set) => {
  const existingDimissedRecommendations = get(dismissedRecommendationsAtom);
  const currentRecommendations = get(basketRecommendationsAtom);
  set(
    dismissedRecommendationsAtom,
    [...existingDimissedRecommendations, ...currentRecommendations]
      .reverse()
      .slice(0, 10)
      .reverse(),
  );
  set(refreshRecommendationsAtom);
});

export const refreshRecommendationsAtom = atom(null, (get, set) => {
  set(basketRecommendationsAtom, []);
  set(loadMoreBasketRecommendationsAtom);
});
export const loadMoreBasketRecommendationsAtom = atom(
  null,
  async (get, set) => {
    const dismissedRecommendations = get(dismissedRecommendationsAtom);
    const rawLineItems = get(lineItemsInCartAtom);

    const productSearchDb = get(productSearchDbAtom);
    const allProducts = await get(availableProductsAtom, {
      unstable_promise: true, // some fuckery with random promise is being thrown while pending on state --> More here: https://github.com/pmndrs/jotai/discussions/1143
    });
    const areRecommendationsLoading = get(areRecommendationsLoadingAtom);
    const lineItems = rawLineItems.map((lineItem) => {
      return {
        title: lineItem.title,
        quantity: lineItem.quantity,
      };
    });
    if (areRecommendationsLoading) {
      return;
    }

    set(areRecommendationsLoadingAtom, true);
    const payload = {
      titlesOfExistingRecommendations: dismissedRecommendations.map(
        (r) => r.title,
      ),
      lineItems,
      createdAt: new Date().toISOString(),
      categoriesBrowsed: get(categoriesBrowsedAtom),
      brand: branding.name,
    };

    try {
      const recommendations = await client.basketRecommendations.query(payload);

      const recommendationsWithProducts = recommendations.map(
        (recommendation) => {
          const recommendationBatches = [] as Product[][];

          for (const searchTerm of recommendation.searchTerms) {
            const searchResults = productSearchDb.search(searchTerm, {
              boost: { search_keywords: 1, title: 0.25, productType: 3 },
            });

            // Hydrate products from search results
            const productsFound = searchResults
              .map((hit) => {
                return allProducts.find((product) => product.id === hit.id);
              })
              .filter((product) => product) as typeof allProducts;

            recommendationBatches.push(productsFound);
          }
          const zippedProducts = _.zip(...recommendationBatches);
          const products = _.chain(zippedProducts)
            .flatten()
            .filter((p) => !!p)
            .uniqBy('id')
            .value() as typeof allProducts;

          return {
            ...recommendation,
            products,
          };
        },
      );

      // Fitler items with no products
      const newRecommendations = recommendationsWithProducts.filter(
        (recommendation) => recommendation.products.length > 0,
      );

      set(basketRecommendationsAtom, newRecommendations);
      set(areRecommendationsLoadingAtom, false);
    } catch (err) {
      set(areRecommendationsLoadingAtom, false);
      return;
    }
  },
);
