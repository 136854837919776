import { BRAND } from '../env';

export type BrandingVariables = {
  name: string;
  displayName: string;
  webTitle: string;
  webStorefrontUrl: string;
  buttonTextColor: string;
  buttonBackgroundColor: string;
  secondaryButtonTextColor: string;
  secondaryButtonBackgroundColor: string;
  deliveryTimeTextColor: string;
  deliveryTimeBackgroundColor: string;
  landingScreenBackgroundColor: string;
  landingScreenLoaderColor: string;
  notificationScreenBackgroundColor: string;
  font: {
    regular: string;
    bold: string;
  };
  searchPlaceholderText: string;
  tabBadgeBackgroundColor: string;
  mapMarkerColor: string;
  landingScreenGraphic: any;
  landingScreenLogoGraphic: any;
  logoGraphic: any;
  logoLightGraphic: any;
  emblemGraphic: any;
  shoppingCartGraphic: any;
  signUpGraphic: any;
  warningGraphic: any;
  questionGraphic: any;
  tradingName: string;
  appLinkUrl: string;
  alcoholLimitDescription: string;
  requiresAgeVerification: boolean;
  licenseUrl: string;
  websiteDomain: string;
  standardDeliveryWaitTime: 120 | 180;
  selectionAccentColor: string;
  beerOrCiderBottleLimit: number;
  wineBottleLimit: number;
  spiritBottleLimit: number;
  categoryAlcoholConditionsEnabled: boolean;
  iosAppStoreUrl: string;
  androidPlayStoreUrl: string;
};
type BrandSlug = 'teddy' | 'gooddrop' | 'dev';

const config: Record<BrandSlug, BrandingVariables> = {
  gooddrop: {
    name: 'gooddrop',
    displayName: 'GoodDrop',
    webTitle: 'GoodDrop - Fast Liquor Delivery',
    webStorefrontUrl: 'store.gooddrop.co.nz',
    font: {
      regular: 'SourceSansPro-Regular',
      bold: 'Faro-DisplayLucky',
    },
    searchPlaceholderText: 'Search “Speights”, “Red wine”',
    buttonTextColor: '#4B4794',
    buttonBackgroundColor: '#CDBCF9',
    secondaryButtonTextColor: '#fff',
    secondaryButtonBackgroundColor: '#23504E',
    landingScreenBackgroundColor: '#23504E',
    landingScreenLoaderColor: '#ffffff',
    notificationScreenBackgroundColor: '#CDBCF9',
    // deliveryTimeTextColor: '#3D7472',
    // deliveryTimeBackgroundColor: '#D6FFED',
    deliveryTimeTextColor: '#333',
    deliveryTimeBackgroundColor: '#f8f8f8',
    tabBadgeBackgroundColor: '#4B4794',
    mapMarkerColor: '#4B4794',
    landingScreenGraphic: require('../assets/images/gooddrop/landing-screen-graphic.png'),
    landingScreenLogoGraphic: require('../assets/images/gooddrop/landing-screen-logo-graphic.png'),
    logoGraphic: require('../assets/images/gooddrop/logo.png'),
    logoLightGraphic: require('../assets/images/gooddrop/logo--light.png'),
    emblemGraphic: require('../assets/images/gooddrop/emblem.png'),
    shoppingCartGraphic: require('../assets/images/gooddrop/shopping-cart-graphic.png'),
    signUpGraphic: require('../assets/images/gooddrop/signup-graphic.png'),
    warningGraphic: require('../assets/images/gooddrop/warning.png'),
    questionGraphic: require('../assets/images/gooddrop/question.png'),
    tradingName: 'Better Beverage Distribution Limited',
    appLinkUrl: 'https://gooddrop.co.nz',
    alcoholLimitDescription:
      '48 beers/ciders/RTDs and/or 4 bottles of wine and/or 2 bottles of spirits.',
    requiresAgeVerification: true,
    licenseUrl:
      'https://teddy-public.s3.ap-southeast-2.amazonaws.com/gooddrop-license.png',
    websiteDomain: 'https://gooddrop.co.nz',
    standardDeliveryWaitTime: 180,
    selectionAccentColor: '#CDBCF9',
    beerOrCiderBottleLimit: 48,
    wineBottleLimit: 4,
    spiritBottleLimit: 2,
    categoryAlcoholConditionsEnabled: false,
    iosAppStoreUrl:
      'https://apps.apple.com/nz/app/good-drop-fast-liquor-delivery/id6451089498',
    androidPlayStoreUrl:
      'https://play.google.com/store/apps/details?id=nz.co.gooddrop',
  },
  teddy: {
    name: 'teddy',
    displayName: 'Teddy',
    webTitle: 'Teddy - Groceries in minutes',
    webStorefrontUrl: 'shop.teddy.nz',
    font: {
      regular: 'SharpGrotesk-Regular',
      bold: 'SharpGrotesk-Bold',
    },
    searchPlaceholderText: 'Search “Milk”, “Duck Island”',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#0080FF',
    landingScreenBackgroundColor: '#FF0010',
    landingScreenLoaderColor: '#fff',
    notificationScreenBackgroundColor: '#FF0010',
    secondaryButtonTextColor: '#fefefe',
    secondaryButtonBackgroundColor: '#222',
    deliveryTimeTextColor: '#fefefe',
    deliveryTimeBackgroundColor: '#222',
    tabBadgeBackgroundColor: '#FF0010',
    mapMarkerColor: '#FF0010',
    landingScreenGraphic: require('../assets/images/teddy/landing-screen-graphic.png'),
    landingScreenLogoGraphic: require('../assets/images/teddy/landing-screen-logo-graphic.png'),
    logoGraphic: require('../assets/images/teddy/logo.png'),
    logoLightGraphic: require('../assets/images/teddy/logo--light.png'),
    emblemGraphic: require('../assets/images/teddy/emblem.png'),
    shoppingCartGraphic: require('../assets/images/teddy/shopping-cart-graphic.png'),
    signUpGraphic: require('../assets/images/teddy/signup-graphic.png'),
    warningGraphic: require('../assets/images/teddy/warning.png'),
    questionGraphic: require('../assets/images/teddy/question.png'),
    tradingName: 'Teddy Technologies Limited',
    appLinkUrl: 'https://teddy.app.link/',
    alcoholLimitDescription: '24 beers/ciders and/or two bottles of wine.',
    requiresAgeVerification: false,
    licenseUrl:
      'https://teddy-public.s3.ap-southeast-2.amazonaws.com/teddy-license-new.jpeg',
    websiteDomain: 'https://teddy.nz',
    standardDeliveryWaitTime: 120,
    selectionAccentColor: '#0080FF',
    beerOrCiderBottleLimit: 24,
    wineBottleLimit: 2,
    spiritBottleLimit: 0,
    categoryAlcoholConditionsEnabled: true,
    iosAppStoreUrl:
      'https://apps.apple.com/us/app/teddy-groceries-delivered/id1637363377',
    androidPlayStoreUrl:
      'https://play.google.com/store/apps/details?id=nz.teddy.shop',
  },
  dev: {
    name: 'dev',
    displayName: 'Teddy',
    webTitle: 'Teddy - Groceries in minutes',
    webStorefrontUrl: 'shop.teddy.nz',
    font: {
      regular: 'SharpGrotesk-Regular',
      bold: 'SharpGrotesk-Bold',
    },
    searchPlaceholderText: 'Search “Milk”, “Duck Island”',
    buttonTextColor: '#FFFFFF',
    buttonBackgroundColor: '#0080FF',
    landingScreenBackgroundColor: '#FF0010',
    landingScreenLoaderColor: '#fff',
    notificationScreenBackgroundColor: '#FF0010',
    secondaryButtonTextColor: '#fefefe',
    secondaryButtonBackgroundColor: '#222',
    deliveryTimeTextColor: '#fefefe',
    deliveryTimeBackgroundColor: '#222',
    tabBadgeBackgroundColor: '#FF0010',
    mapMarkerColor: '#FF0010',
    landingScreenGraphic: require('../assets/images/teddy/landing-screen-graphic.png'),
    landingScreenLogoGraphic: require('../assets/images/teddy/landing-screen-logo-graphic.png'),
    logoGraphic: require('../assets/images/teddy/logo.png'),
    logoLightGraphic: require('../assets/images/teddy/logo.png'),
    emblemGraphic: require('../assets/images/teddy/emblem.png'),
    shoppingCartGraphic: require('../assets/images/teddy/shopping-cart-graphic.png'),
    signUpGraphic: require('../assets/images/teddy/signup-graphic.png'),
    warningGraphic: require('../assets/images/teddy/warning.png'),
    questionGraphic: require('../assets/images/teddy/question.png'),
    tradingName: '[TRADING NAME]',
    appLinkUrl: 'https://teddy.app.link/',
    alcoholLimitDescription: '24 beers/ciders and/or two bottles of wine.',
    requiresAgeVerification: false,
    licenseUrl:
      'https://teddy-public.s3.ap-southeast-2.amazonaws.com/gooddrop-license.png',
    websiteDomain: 'https://teddy.nz',
    standardDeliveryWaitTime: 120,
    selectionAccentColor: '#0080FF',
    beerOrCiderBottleLimit: 24,
    wineBottleLimit: 2,
    spiritBottleLimit: 0,
    categoryAlcoholConditionsEnabled: true,
    iosAppStoreUrl:
      'https://apps.apple.com/us/app/teddy-groceries-delivered/id1637363377',
    androidPlayStoreUrl:
      'https://play.google.com/store/apps/details?id=nz.teddy.shop',
  },
};

export const branding = config[BRAND as BrandSlug];
