import React from 'react';
import { Image, View } from 'react-native';

import { Text } from '../core-ui';
import { LineItem } from '../types/types';

type Props = {
  orderItem: LineItem;
  size: number;
};

export function OrderItemCompact(props: Props) {
  const { image } = props.orderItem;
  return (
    <View
      style={{
        marginRight: 18,
      }}
    >
      <View>
        <Image
          source={{
            uri: image,
          }}
          style={{
            width: props.size,
            height: props.size,
          }}
        />
        <View
          style={{
            width: 24,
            height: 24,
            backgroundColor: '#222',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <Text
            style={{ color: 'white', fontSize: 12, lineHeight: 12 }}
            weight="bold"
          >
            {props.orderItem.quantity}
          </Text>
        </View>
      </View>
    </View>
  );
}
