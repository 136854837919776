/* eslint-disable react/jsx-key */
import React, { useCallback, useState } from 'react';
import {
  Platform,
  SafeAreaView,
  FlatList,
  StatusBar,
  StyleSheet,
  TouchableOpacity,
  View,
  Pressable,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAtomValue, useSetAtom } from 'jotai';
import { COLORS } from '../constants/colors';
import { CategoryList, OccasionList, SearchInput, Text } from '../core-ui';
import { CategoryItem } from '../types/types';
import { PromoContent } from '../components/PromoContent';
import { CollectionSectionHeader } from '../components/CollectionSectionHeader';
import { AllScreensNavigationProp } from '../navigation/types';
import DeliveryPicker from '../components/DeliveryPicker';
import DeliveryTime from '../components/DeliveryTime';
import { AlcoholConditionsBottomSheet } from '../components/AlcoholConditionsBottomSheet';
import { t } from '../helpers/translate';
import {
  featuredCategoriesCollectionsAtom,
  featuredOccasionsCollectionsAtom,
  homeTopScreenCollectionListingsAtom,
  homeBottomScreenCollectionListingsAtom,
} from '../hooks/collectionsAtoms';
import {
  CollectionHorizontalScroller,
  RecentPurchasedHorizontalScroller,
} from '../components/CollectionHorizontalScroller';
import { useHomescreenLandingPrompt } from '../utils/homescreenLandingPrompt';
import { refreshRecommendationsAtom } from '../hooks/basketRecommendationsAtoms';
import { BasketRecommendations } from '../components/BasketRecommendations';
import { branding } from '../branding';

function HomeScene() {
  const { navigate } = useNavigation<AllScreensNavigationProp<'HomeTab'>>();
  const insets = useSafeAreaInsets();

  const refreshRecommendations = useSetAtom(refreshRecommendationsAtom);
  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle('dark-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        StatusBar.setBackgroundColor('rgba(0,0,0,0)');
        changeNavigationBarColor('#fafafa', true, false);
      }

      refreshRecommendations();
    }, []),
  );

  const [onAlcoholConfirmPress, setOnAlcoholConfirmPress] = useState<
    (() => void) | undefined
  >(undefined);
  const [isAlcoholConditionsModalVisible, setIsAlcoholConditionsModalVisible] =
    useState(false);

  const featuredCategoriesCollections = useAtomValue(
    featuredCategoriesCollectionsAtom,
  );
  const featuredOccasionsCollections = useAtomValue(
    featuredOccasionsCollectionsAtom,
  );
  const homeTopScreenFeaturedCollections = useAtomValue(
    homeTopScreenCollectionListingsAtom,
  );
  const homeBottomScreenFeaturedCollections = useAtomValue(
    homeBottomScreenCollectionListingsAtom,
  );

  useHomescreenLandingPrompt();

  return (
    <SafeAreaView style={styles.flex}>
      {/* Header */}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: 14,
          paddingTop: 4,
          paddingBottom: 8,
          borderBottomWidth: 1,
          borderColor: 'rgba(0,0,0,.1)',
          marginTop: Platform.select({
            android: insets.top,
            default: 0,
          }),
        }}
      >
        <DeliveryPicker />
        <DeliveryTime />
      </View>
      <FlatList
        // List elements in array to render as flatlist
        data={[
          <View style={{ paddingHorizontal: 6, width: '100%' }}>
            <TouchableOpacity
              onPress={() => navigate('Search')}
              activeOpacity={1}
            >
              <View style={styles.searchInputContainer}>
                <SearchInput
                  pointerEvents="none"
                  placeholder={branding.searchPlaceholderText}
                  editable={false}
                  style={styles.searchInput}
                />
              </View>
            </TouchableOpacity>
          </View>,
          <PromoContent placement="home-screen-1" />,
          <PromoContent placement="home-screen-2" />,
          <RecentPurchasedHorizontalScroller placement="home-screen" />,
          ...homeTopScreenFeaturedCollections.map((collection) => {
            return (
              <View key={collection.id} style={{ marginBottom: 20 }}>
                <CollectionHorizontalScroller
                  collection={collection}
                  placement="home-screen-top"
                />
              </View>
            );
          }),
          <View style={{ paddingHorizontal: 8 }}>
            <CollectionSectionHeader
              title={t('Categories')}
              onShowAllPress={() => {
                navigate('CategoriesCollection', {
                  collection: { title: 'Categories' },
                });
              }}
            />
            <CategoryList
              listKey="categories"
              categories={featuredCategoriesCollections.map((collection) => {
                return {
                  __type: 'Collection',
                  id: collection.id,
                  title: collection.title,
                  handle: collection.handle,
                  cursor: '',
                  image: collection.image?.url,
                  tags: collection.tags,
                };
              })}
              onSelect={(collection: CategoryItem) => {
                const containsAlcohol =
                  collection.tags?.includes('contains_alcohol');
                const navigateToCategory = () =>
                  navigate('ProductCollection', {
                    collectionHandle: collection.handle,
                    collectionTitle: collection.title,
                    navigationLevel: 1,
                  });

                if (
                  containsAlcohol &&
                  branding.categoryAlcoholConditionsEnabled
                ) {
                  setIsAlcoholConditionsModalVisible(true);
                  setOnAlcoholConfirmPress(() => navigateToCategory);
                  return;
                }

                navigateToCategory();
              }}
            />
          </View>,

          featuredOccasionsCollections.length > 0 ? (
            <View style={{ paddingHorizontal: 8 }}>
              <CollectionSectionHeader
                title={t('Moments')}
                onShowAllPress={() => {
                  navigate('OccasionsCollection', {
                    collection: { title: 'Moments' },
                  });
                }}
              />
              <OccasionList
                listKey="occasions"
                categories={featuredOccasionsCollections.map((collection) => {
                  return {
                    __type: 'Collection',
                    id: collection.id,
                    title: collection.title,
                    handle: collection.handle,
                    cursor: '',
                    image: collection.image?.url,
                    tags: collection.tags,
                  };
                })}
                onSelect={(collection: CategoryItem) => {
                  const containsAlcohol =
                    collection.tags?.includes('contains_alcohol');
                  const navigateToCategory = () =>
                    navigate('ProductCollection', {
                      collectionHandle: collection.handle,
                      collectionTitle: collection.title,
                      navigationLevel: 1,
                    });

                  if (
                    containsAlcohol &&
                    branding.categoryAlcoholConditionsEnabled
                  ) {
                    setIsAlcoholConditionsModalVisible(true);
                    setOnAlcoholConfirmPress(() => navigateToCategory);
                    return;
                  }

                  navigateToCategory();
                }}
              />
            </View>
          ) : (
            <View />
          ),
          <PromoContent placement="home-screen-3" />,
          <PromoContent placement="home-screen-4" />,
          <BasketRecommendations placement="home-screen" />,
          ...homeBottomScreenFeaturedCollections.map((collection) => {
            return (
              <View key={collection.id} style={{ marginBottom: 20 }}>
                <CollectionHorizontalScroller
                  collection={collection}
                  placement="home-screen-bottom"
                />
              </View>
            );
          }),
          <View style={{ paddingHorizontal: 14, paddingBottom: 60 }}>
            <Pressable
              style={{
                opacity: 0.6,
                width: 240,
                alignSelf: 'center',
              }}
              onPress={() => {
                navigate('ProfileTab');
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  opacity: 0.6,
                  textAlign: 'center',
                  lineHeight: 16,
                  width: 240,
                  alignSelf: 'center',
                }}
              >
                By using this app, you agree to {branding.displayName}'s Terms
                of Service and Privacy Policy
              </Text>
            </Pressable>
          </View>,
        ]}
        numColumns={1}
        style={{ paddingHorizontal: 0, width: '100%' }}
        renderItem={({ item }) => {
          return item;
        }}
      />
      <AlcoholConditionsBottomSheet
        isVisible={isAlcoholConditionsModalVisible}
        onDismiss={() => setIsAlcoholConditionsModalVisible(false)}
        onConditionsAccepted={() => {
          onAlcoholConfirmPress?.();
          setIsAlcoholConditionsModalVisible(false);
        }}
      />
    </SafeAreaView>
  );
}

HomeScene.whyDidYouRender = false;

export default HomeScene;

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    display: 'flex',
    backgroundColor: COLORS.white,
  },
  searchInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 6,
  },
  searchInput: {},
});
