import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Platform } from 'react-native';

import {
  getSavedShopifyCustomerAccessTokenFromKeychain,
  removeSavedShopifyCustomerAccessTokenFromKeychain,
  saveShopifyCustomerAccessTokenToKeychain,
} from '../helpers/authToken';
import { branding } from '../branding';
import { cleverTap } from '../utils/cleverTap';
import { atomStorageHandler } from './helpers/atomStorageHandler';
import { associateCustomerWithShopifyCheckoutAtom } from './checkoutAtoms';
import { addressAtom, setAddressAtom } from './addressAtoms';

const persistedCustomerAccessTokenAtom = atom(async () => {
  if (Platform.OS === 'web') {
    const token = localStorage.getItem('authToken');
    return token;
  }

  const tokenFromKeychain =
    await getSavedShopifyCustomerAccessTokenFromKeychain();

  return tokenFromKeychain;
});

const setPersistedCustomerAccessTokenAtom = atom(
  null,
  (_, __, newToken: string) => {
    if (Platform.OS === 'web') {
      localStorage.setItem('authToken', newToken);
      return;
    }
    saveShopifyCustomerAccessTokenToKeychain(newToken);
  },
);

const removePersistedCustomerAccessTokenAtom = atom(null, (_, __) => {
  if (Platform.OS === 'web') {
    localStorage.removeItem('authToken');
    return;
  }

  removeSavedShopifyCustomerAccessTokenFromKeychain();
});

const hasExistingCustomerStateLoadedAtom = atom(false);

const customerAccessTokenAtom = atom<string>('');
const setCustomerAccessTokenAtom = atom(
  null,
  (get, set, customerAccessToken: string) => {
    set(customerAccessTokenAtom, customerAccessToken);
    if (customerAccessToken !== '') {
      set(associateCustomerWithShopifyCheckoutAtom);
    }
  },
);
const customerAccessTokenExpiresatAtomWithStorage = atomWithStorage<string>(
  'accessKeyExpiresAt',
  '',
  atomStorageHandler<string>(),
);

const notificationPermissionsDeniedTimestampAtom = atomWithStorage<
  number | null
>(
  'notificationPermissionsDeniedTimestamp',
  null,
  atomStorageHandler<number | null>(),
);

const notificationPermissionsPromptedThisSessionAtom = atom<boolean>(false);

export interface Customer {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  referralCode: string;
  acceptsMarketing: boolean;
}

const customerAtom = atom<Customer | null>(null);
const setCustomerAtom = atom(null, (get, set, customer: Customer | null) => {
  set(customerAtom, customer);

  // Address firstName and lastName properties need to be set
  // The sign up flow design means we must create the customer entity before asking for details
  // and Shopify will not allow an empty string for firstName and lastName
  const namePlaceholder = branding.displayName;
  const address = get(addressAtom);

  if (customer && Platform.OS !== 'web') {
    cleverTap.onUserLogin({
      Name: `${customer.lastName} ${customer.firstName}`,
      Identity: customer.phone,
    });
  }

  if (customer && address) {
    set(setAddressAtom, {
      ...address,
      firstName: customer.firstName || namePlaceholder,
      lastName: customer.lastName || namePlaceholder,
    });
  }
});

const hasCustomerProvidedPersonalDetailsAtom = atom<boolean | null>((get) => {
  const customer = get(customerAtom);
  if (customer === null) {
    return null;
  }

  return !!customer.firstName && !!customer.lastName;
});

const firebaseAuthErrorAtom = atom(false);

export {
  hasExistingCustomerStateLoadedAtom,
  persistedCustomerAccessTokenAtom,
  setPersistedCustomerAccessTokenAtom,
  removePersistedCustomerAccessTokenAtom,
  customerAccessTokenAtom,
  setCustomerAccessTokenAtom,
  customerAccessTokenExpiresatAtomWithStorage,
  customerAtom,
  setCustomerAtom,
  hasCustomerProvidedPersonalDetailsAtom,
  notificationPermissionsDeniedTimestampAtom,
  notificationPermissionsPromptedThisSessionAtom,
  firebaseAuthErrorAtom,
};
