/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  View,
  Image,
  Pressable,
  StatusBar,
  Platform,
  Linking,
} from 'react-native';

import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { ActivityIndicator, useTheme } from 'react-native-paper';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import { useFonts } from 'expo-font';
import changeNavigationBarColor from 'react-native-navigation-bar-color';

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { AllScreensNavigationProp } from '../navigation/types';
import { phoneIcon } from '../../assets/images';
import { StateDebug } from '../components/StateDebug';

import { trackEvent } from '../analytics';
import { branding } from '../branding';
import { Button, Text } from '../core-ui';
import useConfig from '../hooks/api/useConfig';
import { contentAtom, startPollingContentAtom } from '../hooks/contentAtom';
import {
  customerAtom,
  notificationPermissionsDeniedTimestampAtom,
} from '../hooks/customerAtoms';
import { allProductsAtom } from '../hooks/productsAtoms';
import { allCollectionsAtom } from '../hooks/collectionsAtoms';
import { hubAtom, hubsAtom } from '../hooks/hubsAtoms';
import {
  setAddressAtom,
  addressLatLngPersistedAtom,
  addressPersistedAtom,
  setAddressLatLngAtom,
} from '../hooks/addressAtoms';
import { isCheckoutDebugEnabledAtom } from '../hooks/checkoutAtoms';
import { logErrorException } from '../utils/LoggingUtils';
import { triggerAlert } from '../utils/alert';

const startLoadTime = +new Date();

export const AppLandingScene = () => {
  const navigation = useNavigation<AllScreensNavigationProp<'Landing'>>();
  const [isChecked, setIsChecked] = useState(false);

  const [fontsLoaded] = useFonts({
    'SharpGrotesk-Regular': require('../../assets/fonts/SharpGroteskBook21-Regular.ttf'),
    'SharpGrotesk-Bold': require('../../assets/fonts/SharpGroteskSmBold21-Regular.ttf'),

    'SourceSansPro-Italic': require('../../assets/fonts/SourceSansPro-Italic.ttf'),
    'SourceSansPro-Bold': require('../../assets/fonts/SourceSansPro-Bold.ttf'),
    'SourceSansPro-SemiBold': require('../../assets/fonts/SourceSansPro-SemiBold.ttf'),
    'SourceSansPro-Regular': require('../../assets/fonts/SourceSansPro-Regular.ttf'),

    'Faro-BoldLucky': require('../../assets/fonts/Faro-BoldLucky.ttf'),
    'Faro-DisplayLucky': require('../../assets/fonts/Faro-DisplayLucky.otf'),
    'MessinaSerif-Bold': require('../../assets/fonts/MessinaSerif-Bold.ttf'),
    'MessinaSerif-Regular': require('../../assets/fonts/MessinaSerif-Regular.ttf'),
    'MessinaSerif-Light': require('../../assets/fonts/MessinaSerif-Light.ttf'),
    'MessinaSerif-Book': require('../../assets/fonts/MessinaSerif-Book.ttf'),
  });

  /* New and good */
  const customer = useAtomValue(customerAtom);
  const hub = useAtomValue(hubAtom);
  const [isReady, setIsReady] = useState(false);
  const [buttonsShowing, setButtonsShowing] = useState(false);
  const [allProducts] = useAtom(loadable(allProductsAtom)); // From TRPC
  const [allCollections] = useAtom(loadable(allCollectionsAtom)); // From TRPC
  const [hubs] = useAtom(loadable(hubsAtom)); // From TRPC
  const [content] = useAtom(loadable(contentAtom)); // From TRPC
  const { data: config } = useConfig({ pollInterval: 60000 });
  const configLoaded = !!config;

  const startPollingContent = useSetAtom(startPollingContentAtom);
  const setAddressLatLng = useSetAtom(setAddressLatLngAtom);
  const [persistedAddressLatLng] = useAtom(
    loadable(addressLatLngPersistedAtom),
  );

  const [notificationPermissionsDeniedTimestamp] = useAtom(
    loadable(notificationPermissionsDeniedTimestampAtom),
  );

  const setAddress = useSetAtom(setAddressAtom);
  const [persistedAddress] = useAtom(loadable(addressPersistedAtom));
  const [showDebugState, setShowDebugState] = useAtom(
    isCheckoutDebugEnabledAtom,
  );

  const [error, setError] = useState<Error | null>(null);

  const { roundness } = useTheme();

  useEffect(() => {
    if (allProducts.state === 'hasError') {
      logErrorException('allProducts.state failed to load', allProducts.error);
      setError(new Error('allProducts.state failed to load'));
    }

    if (allCollections.state === 'hasError') {
      logErrorException(
        'allCollections.state failed to load',
        allCollections.error,
      );
      setError(new Error('allCollections.state failed to load'));
    }

    if (hubs.state === 'hasError') {
      logErrorException('hubs.state failed to load', hubs.error);
      setError(new Error('hubs.state failed to load'));
    }

    if (content.state === 'hasError') {
      logErrorException('content.state failed to load', content.error);
      setError(new Error('content.state failed to load'));
    }
  }, [allProducts.state, allCollections.state, hubs.state, content.state]);

  useEffect(() => {
    startPollingContent();

    if (isReady) {
      return;
    }

    if (!configLoaded) {
      return;
    }

    if (persistedAddressLatLng.state !== 'hasData') {
      return;
    }

    if (notificationPermissionsDeniedTimestamp.state !== 'hasData') {
      return;
    }

    if (persistedAddress.state !== 'hasData') {
      return;
    }

    if (allProducts.state !== 'hasData') {
      return;
    }

    if (allCollections.state !== 'hasData') {
      return;
    }

    if (hubs.state !== 'hasData') {
      return;
    }

    if (content.state !== 'hasData') {
      return;
    }

    setAddress(persistedAddress.data);
    setAddressLatLng(persistedAddressLatLng.data);

    setIsReady(true);
  }, [
    isReady,
    configLoaded,
    hub?.id,
    persistedAddressLatLng.state,
    persistedAddress.state,
    allProducts.state,
    allCollections.state,
    hubs.state,
    content.state,
  ]);

  useEffect(() => {
    if (!isReady) {
      trackEvent('app_launch_started');
      return;
    }
    trackEvent('app_launch_ready', {
      hub_id: hub?.id,
      launch_time_ms: +new Date() - startLoadTime,
    });

    setButtonsShowing(true);
  }, [isReady]);

  // Animated logo in on load with reanimated2
  const logoAnimatedOpacity = useSharedValue(0);
  const logoAnimatedTranslateY = useSharedValue(24);
  const logoAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: logoAnimatedOpacity.value,
      transform: [
        {
          translateY: logoAnimatedTranslateY.value,
        },
      ],
    };
  });
  useEffect(() => {
    logoAnimatedOpacity.value = withDelay(400, withTiming(1));
    logoAnimatedTranslateY.value = withDelay(400, withSpring(0, { mass: 0.4 }));
  }, [logoAnimatedOpacity]);

  const groceriesAnimatedOpacity = useSharedValue(0);
  const groceriesAnimatedTranslateY = useSharedValue(8);
  const groceriesAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: groceriesAnimatedOpacity.value,
      transform: [
        {
          translateY: groceriesAnimatedTranslateY.value,
        },
      ],
    };
  });
  useEffect(() => {
    groceriesAnimatedOpacity.value = withDelay(400, withTiming(1));
    groceriesAnimatedTranslateY.value = withDelay(
      400,
      withSpring(0, { mass: 0.4 }),
    );
  }, [groceriesAnimatedOpacity]);

  const buttonsAnimatedOpacity = useSharedValue(0);
  const buttonsAnimatedScale = useSharedValue(0.7);
  const buttonsAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: buttonsAnimatedOpacity.value,
      transform: [
        {
          scale: buttonsAnimatedScale.value,
        },
      ],
    };
  });

  const activityIndicatorOpacity = useSharedValue(0);
  const activityIndicatorStyle = useAnimatedStyle(() => {
    return {
      opacity: activityIndicatorOpacity.value,
    };
  });
  useEffect(() => {
    if (buttonsShowing) {
      activityIndicatorOpacity.value = withDelay(200, withTiming(0));
      buttonsAnimatedOpacity.value = withDelay(460, withTiming(1));
      buttonsAnimatedScale.value = withDelay(400, withSpring(1, { mass: 0.6 }));
    } else {
      activityIndicatorOpacity.value = withDelay(600, withTiming(1));
    }
  }, [buttonsShowing]);

  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle('light-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        changeNavigationBarColor('transparent', false, false);
      }
    }, []),
  );

  if (!fontsLoaded) {
    return <View />;
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: branding.landingScreenBackgroundColor,
        height: '100vh',
        // width: '100vw',
        display: 'flex',
        zIndex: 10,
      }}
    >
      <View
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Animated.View
          style={[
            logoAnimatedStyle,
            {
              borderWidth: 0,
              width: '100%',
              marginTop: 20,
              zIndex: 10,
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: branding.name === 'gooddrop' ? 80 : 20,
            },
          ]}
        >
          <Pressable
            onLongPress={() => {
              if (__DEV__) {
                setShowDebugState(!showDebugState);
              }
            }}
          >
            <Image
              source={branding.landingScreenLogoGraphic}
              style={{
                width: 350,
                aspectRatio: branding.name === 'teddy' ? 2.948 : 2,
              }}
            />
          </Pressable>
        </Animated.View>

        {showDebugState ? (
          <StateDebug />
        ) : branding.name === 'teddy' ? (
          <Animated.View
            style={[
              groceriesAnimatedStyle,
              {
                borderWidth: 0,
                width: '100%',
                flexGrow: 0,
                flexShrink: 1,
                marginTop: -15,
                marginBottom: -30, // Negative margin to allow shadow on photo to overlap
                justifyContent: 'center',
                alignItems: 'center',
              },
            ]}
          >
            <Image
              source={branding.landingScreenGraphic}
              style={{ width: 350, aspectRatio: 0.84 }}
            />
          </Animated.View>
        ) : null}

        <View
          style={{
            alignSelf: 'center',
            width: '100%',
            paddingHorizontal: 20,
            paddingVertical: 25,
            borderWidth: 0,
          }}
        >
          <Animated.View
            style={[
              activityIndicatorStyle,
              {
                marginBottom: 30,
                justifyContent: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
              },
            ]}
          >
            {!error ? (
              <ActivityIndicator color="white" />
            ) : (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ color: 'white', fontSize: 12 }}>
                  Failed to load!
                </Text>
                <Text style={{ color: 'white', fontSize: 12 }}>
                  Please check your connection and restart the app.
                </Text>
              </View>
            )}
          </Animated.View>
          <Animated.View
            style={[
              buttonsAnimatedStyle,
              {
                width: '100%',
                marginBottom: 40,
              },
            ]}
          >
            <View
              style={{
                display: branding.requiresAgeVerification ? 'flex' : 'none',
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 18,
                  textTransform: 'uppercase',
                }}
                weight="bold"
              >
                You must be 18 or over to continue
              </Text>
              <Pressable
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 38,
                  justifyContent: 'center',
                }}
                onPress={() => {
                  setIsChecked(!isChecked);
                }}
              >
                <View
                  style={{
                    borderWidth: 2,
                    borderRadius: 4,
                    borderColor: 'white',
                    width: 30,
                    height: 30,
                    marginRight: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    style={{
                      width: 60,
                      height: 60,
                      borderWidth: 0,
                      opacity: isChecked ? 1 : 0,
                    }}
                    source={require('../../assets/images/checkmark.png')}
                  />
                </View>

                <Text style={{ fontSize: 16, lineHeight: 16, color: 'white' }}>
                  I am 18 or over
                </Text>
              </Pressable>
            </View>
            <View style={{ borderWidth: 0, marginBottom: 0 }}>
              <Button
                labelStyle={{
                  fontSize: 16,
                  lineHeight: 16,
                }}
                onPress={() => {
                  if (branding.requiresAgeVerification && !isChecked) {
                    triggerAlert(
                      `You must be 18 or over to order. Please check the box to confirm you are 18 or over.`,
                    );
                    return;
                  }
                  // navigate to TabNavigator
                  if (hub) {
                    navigation.replace('TabNavigator');
                  } else {
                    if (customer) {
                      // Rare case where customer has no hub, but has a customer record
                      navigation.replace('AddressEntry', {
                        flowType: 'fromLanding',
                      });
                    } else {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      /* @ts-ignore */
                      navigation.navigate('SmsAuthenticationNonModal', {
                        screen: 'PhoneInput',
                        params: { flowType: 'fromLanding' },
                      });
                    }
                  }
                }}
              >
                Start shopping
              </Button>

              <Pressable
                style={{
                  backgroundColor: 'rgba(0,0,0,0.15)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginVertical: 30,
                  padding: 16,
                  borderRadius: roundness,
                  flexDirection: 'row',
                }}
                onPress={() => {
                  if (window.navigator.userAgent.includes('iPhone' || 'iPad')) {
                    Linking.openURL(branding.iosAppStoreUrl);
                  } else {
                    Linking.openURL(branding.androidPlayStoreUrl);
                  }
                }}
              >
                <Image
                  source={phoneIcon}
                  style={{
                    width: 20,
                    height: 20,
                    top: -2,
                    marginRight: 5,
                  }}
                />
                <Text
                  weight="bold"
                  style={{
                    fontSize: 16,
                    lineHeight: 16,
                    color: 'white',
                  }}
                >
                  Get the app
                </Text>
              </Pressable>
            </View>
          </Animated.View>
        </View>
      </View>
    </SafeAreaView>
  );
};
