import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, Text } from '../core-ui';
import { t } from '../helpers/translate';

type Props = {
  isError: boolean;
  message: string;
  onPressModalButton?: () => void;
  buttonText?: string;
};

export default function ModalBottomSheetMessage({
  isError,
  message,
  onPressModalButton,
  buttonText,
}: Props) {
  return (
    <>
      {/* <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 24,
          marginBottom: 16,
          borderWidth: 1,
          backgroundColor: 'green',
        }}
      >
        {isError ? (
          <Image source={errorImage} style={styles.image} />
        ) : (
          <Image source={successImage} style={styles.image} />
        )}
      </View> */}
      <Text style={styles.message}>{message}</Text>
      {onPressModalButton ? (
        <View style={{ padding: 20, marginTop: 10 }}>
          <Button onPress={onPressModalButton}>
            {buttonText ? t('{buttonText}', { buttonText }) : 'OK'}
          </Button>
        </View>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  message: {
    textAlign: 'center',
    paddingHorizontal: 20,
    lineHeight: 19,
  },
});
