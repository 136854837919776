import * as Keychain from 'react-native-keychain';
import { Result } from 'react-native-keychain';

import { KEYCHAIN_ACCESS_GROUP } from '../../env';

const authTokenName = 'AuthToken';
const accessGroup = KEYCHAIN_ACCESS_GROUP;

export async function saveShopifyCustomerAccessTokenToKeychain(
  userToken: string,
): Promise<boolean | Result> {
  return Keychain.setGenericPassword(authTokenName, userToken, {
    service: authTokenName,
  });
}

export async function getSavedShopifyCustomerAccessTokenFromKeychain(): Promise<
  string | null
> {
  let p = Keychain.getGenericPassword({
    service: authTokenName,
  });

  return p.then((credentials) => {
    if (!credentials) {
      return null;
    }

    return credentials.password;
  });
}

export async function removeSavedShopifyCustomerAccessTokenFromKeychain(): Promise<boolean> {
  return Keychain.resetGenericPassword({
    accessGroup: accessGroup,
    service: authTokenName,
  });
}
