import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useOrder } from './api/useOrderHistory';
import { orderCurrentlyTrackedAtom } from './orderTrackingAtoms';
import { currentTimeAtom } from './scheduledTimeAtoms';

export const useOrderBeingTrackedProgress = () => {
  const currentTime = useAtomValue(currentTimeAtom);
  const [percentageProgress, setPercentageProgress] = useState(0);
  const [secondsToExpectedTime, setSecondsToExpectedTime] = useState(0);

  const orderHook = useOrderBeingTracked();

  const { loading, data } = orderHook;
  const order = data?.order;
  useEffect(() => {
    // Unset the order being tracked when the user leaves the screen
    // if the current time is beyond the scheduled time
    if (loading || order?.__typename !== 'Order') {
      return;
    }
    const expectedTimeString = order.customAttributes.find(
      (attr) => attr.key === 'expected_time',
    )?.value;

    if (!expectedTimeString) {
      return;
    }

    const placedTime = order.processedAt;
    const secondsToExpectedTime =
      (+new Date(expectedTimeString) - +new Date(currentTime)) / 1000;

    const percentage = Math.min(
      1 -
        (+new Date(expectedTimeString) - +new Date(currentTime)) /
          (+new Date(expectedTimeString) - +new Date(placedTime)),
      1,
    );

    setSecondsToExpectedTime(secondsToExpectedTime);
    setPercentageProgress(percentage);
  }, [loading, +currentTime]);

  return { percentageProgress, secondsToExpectedTime };
};

export const useOrderBeingTracked = () => {
  const orderCurrentlyTracked = useAtomValue(orderCurrentlyTrackedAtom);
  const orderId = orderCurrentlyTracked?.id || '';

  return useOrder({
    variables: { orderId },
    pollInterval: 4000,
  });
};
