import React from 'react';
import { Modal, View } from 'react-native';
import { useDimensions } from '../helpers/dimensions';

type Props = {
  isVisible: boolean;
  onConditionsAccepted?: () => void;
  onDismiss?: () => void;
  children?: React.ReactNode;
};
export const BottomSheetModal = ({ isVisible, onDismiss, children }: Props) => {
  const { width, height } = useDimensions();

  return (
    <Modal visible={isVisible} animationType="slide" onDismiss={onDismiss}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <View style={{ width, height }}>{children}</View>
      </View>
    </Modal>
  );
};
