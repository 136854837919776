import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { MailingAddressInput } from '../generated/server/globalTypes';
import { isPointInFeatureCollection } from '../api/DeliveryZones';
import { customerAtom } from './customerAtoms';
import { saveAndSetDefaultAddressToShopifyCustomerAtom } from './checkoutAtoms';
import { setHubAtom, hubsAtom } from './hubsAtoms';
import { atomStorageHandler } from './helpers/atomStorageHandler';

export type LatLng = {
  latitude: number;
  longitude: number;
};
const addressBaseAtom = atom<MailingAddressInput | null>(null);
const addressAtom = atom<MailingAddressInput | null>((get) => {
  const customer = get(customerAtom);
  const address = get(addressBaseAtom);

  if (address === null) {
    return null;
  }

  return {
    ...address,
    firstName: customer?.firstName || address?.firstName || '',
    lastName: customer?.lastName || address?.lastName || '',
  };
});
const setAddressAtom = atom(
  null,
  (_, set, address: MailingAddressInput | null) => {
    set(addressBaseAtom, address);
    set(addressPersistedAtom, address);

    // Associating the Shipping Address here doesn't work and somehow gets unassociated
    //set(hasAssociatedAddressWithShopifyCheckoutAtom, false);
    //set(associateShippingAddressWithShopifyCheckoutAtom);
    set(saveAndSetDefaultAddressToShopifyCustomerAtom);
  },
);
const addressPersistedAtom = atomWithStorage<MailingAddressInput | null>(
  'address-v2',
  null,
  atomStorageHandler<MailingAddressInput | null>(),
);

const addressLatLngAtom = atom<LatLng | null>(null);
const isTurboDeliveryAvailableToAddressAtom = atom<boolean>(false);

const setAddressLatLngAtom = atom(null, (get, set, latLng: LatLng | null) => {
  const hubs = get(hubsAtom);
  let hubToSet = null;
  let isTurboAvailable = false;

  if (latLng !== null) {
    for (const hub of hubs) {
      const deliveryZones = hub.standard_restricted_delivery_zones;
      const turboDeliveryZones = hub.delivery_zones;

      if (deliveryZones === null || hub.status === 'coming') {
        continue;
      }

      const doesHubDeliverToAddress = isPointInFeatureCollection(
        latLng,
        deliveryZones,
      );

      if (doesHubDeliverToAddress) {
        hubToSet = hub;

        isTurboAvailable =
          !!turboDeliveryZones &&
          isPointInFeatureCollection(latLng, turboDeliveryZones);

        break;
      }
    }
  }

  set(setHubAtom, hubToSet);
  set(addressLatLngAtom, latLng);
  set(addressLatLngPersistedAtom, latLng);
  set(isTurboDeliveryAvailableToAddressAtom, isTurboAvailable);
});

// We need to persist this because Shopify does not store LatLng
const addressLatLngPersistedAtom = atomWithStorage<LatLng | null>(
  'addressLatLng-v2',
  null,
  atomStorageHandler<LatLng | null>(),
);
const savedAddressHasLoadedIfExistingAtom = atom(false);

export {
  addressAtom,
  setAddressAtom,
  addressPersistedAtom,
  addressLatLngAtom,
  setAddressLatLngAtom,
  addressLatLngPersistedAtom,
  savedAddressHasLoadedIfExistingAtom,
  isTurboDeliveryAvailableToAddressAtom,
};
