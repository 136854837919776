import React, { useCallback, useEffect, useState } from 'react';
import 'react-native-get-random-values';
import { Image, View } from 'react-native';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';

import { Text } from '../../../core-ui';
import {
  useCheckoutCompleteWithTokenizedPaymentV3Mutation,
  usePaymentPollingLazyQuery,
} from '../../../hooks/api/useCheckout';
import {
  CurrencyCode,
  PaymentTokenType,
} from '../../../generated/server/globalTypes';
import { log, logError } from '../../../utils/LoggingUtils';
import {
  checkoutIdAtom,
  totalPriceAtom,
  isTotalPriceCalculatingAtom,
} from '../../../hooks/checkoutAtoms';
import { addressAtom } from '../../../hooks/addressAtoms';
import { customerAtom } from '../../../hooks/customerAtoms';
import { trackEvent } from '../../../analytics';
import { SimpleButton } from '../../../components/SimpleButton';
import { branding } from '../../../branding';

export default function CheckoutWithCreditCard({
  onCheckoutInProgress,
  onCheckoutComplete,
  onCheckoutSuccess,
  onCheckoutError,
}: {
  onCheckoutInProgress: () => void;
  onCheckoutComplete: () => void;
  onCheckoutSuccess: (orderId: string) => void;
  onCheckoutError: (error: string) => void;
}) {
  // const { createToken } = useStripe();
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutWithToken] =
    useCheckoutCompleteWithTokenizedPaymentV3Mutation();
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuidv4());

  const [customer] = useAtom(customerAtom);
  const customerName = customer?.firstName + ' ' + customer?.lastName;
  const [checkoutId] = useAtom(checkoutIdAtom);
  const [address] = useAtom(addressAtom);
  const [totalPrice] = useAtom(totalPriceAtom);
  const [isTotalCalculating] = useAtom(isTotalPriceCalculatingAtom);

  const [
    pollCompletedPayment,
    { data: completedPaymentData, stopPolling: stopPollingPayment },
  ] = usePaymentPollingLazyQuery();

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function pollTickHandler() {
      log('Payment tick: start', completedPaymentData);

      if (completedPaymentData === undefined) {
        log('Payment tick: payment data is undefined');
        return;
      }

      if (completedPaymentData.payment === null) {
        logError(
          'Payment tick: polling payment did `payment` null',
          completedPaymentData,
        );
        return;
      }

      if (!('ready' in completedPaymentData.payment)) {
        logError('Payment tick: `payment.ready` null', completedPaymentData);
        return;
      }

      if (!completedPaymentData.payment.ready) {
        log('Payment tick: not ready');
        return;
      }

      const orderId = completedPaymentData.payment?.checkout?.order?.id || '';
      const errorMessage = completedPaymentData.payment.errorMessage;
      const status = completedPaymentData.payment.transaction?.statusV2;

      stopPollingPayment();
      setIdempotencyKey(uuidv4());
      log('Payment tick: stop polling');

      if (errorMessage !== null) {
        onCheckoutComplete();
        onCheckoutError(errorMessage);
        return;
      }
      if (status !== 'SUCCESS') {
        onCheckoutComplete();
        onCheckoutError('Payment failed');
      }

      if (status === 'SUCCESS') {
        //onCheckoutComplete();
        onCheckoutSuccess(orderId);

        if (!orderId) {
          logError('polling payment no order id', completedPaymentData);
        }
      }
    },
    [completedPaymentData],
  );

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#000000',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const onPayPressed = useCallback(async () => {
    if (isTotalCalculating) {
      trackEvent('cc_button_pressed_while_sync');
      return;
    }

    onCheckoutInProgress();

    if (!stripe || !elements) {
      return;
    }

    const element = await elements.getElement(CardElement);

    if (!element) {
      return;
    }

    const { error, token } = await stripe.createToken(element, {
      name: customerName,
      currency: 'NZD',
    });

    if (token === undefined) {
      logError('Payment press: `createToken` token undefined ');
      onCheckoutComplete();
      onCheckoutError('Payment failed 2');
      return;
    }

    if (checkoutId === null) {
      logError('Payment press: `checkoutID` undefined');
      onCheckoutComplete();
      onCheckoutError('Payment failed 3');
      return;
    }

    //log(`checkout id ${checkoutId}`);

    const checkoutWithTokenParams = {
      variables: {
        checkoutId: checkoutId,
        payment: {
          paymentAmount: {
            amount: totalPrice.value() || 0,
            currencyCode: CurrencyCode.NZD,
          },
          idempotencyKey: idempotencyKey,
          billingAddress: {
            firstName: address?.firstName || '',
            lastName: address?.lastName || '',
            address1: address?.address1 || '',
            province: address?.province || '',
            country: address?.country || '',
            city: address?.city || '',
            zip: address?.zip || '',
          },
          type: PaymentTokenType.STRIPE_VAULT_TOKEN,
          paymentData: token.id,
        },
      },
    };

    log('`checkoutWithToken` parameters', checkoutWithTokenParams);
    const checkoutResponseRaw = await checkoutWithToken(
      checkoutWithTokenParams,
    );
    log('`checkoutWithToken` reponse', checkoutResponseRaw);

    if (
      checkoutResponseRaw.data === null ||
      checkoutResponseRaw.data === undefined
    ) {
      //setPaymentInProgress(false);
      logError('Payment press: `checkoutWithToken` did not return data', error);
      onCheckoutComplete();
      onCheckoutError('Payment failed 3');
      return;
    }
    const checkoutResponse =
      checkoutResponseRaw.data.checkoutCompleteWithTokenizedPaymentV3;

    if (checkoutResponse && checkoutResponse?.checkoutUserErrors?.length > 0) {
      logError(
        'Payment press: `checkoutWithToken` returned errors',
        checkoutResponse.checkoutUserErrors,
      );
      onCheckoutComplete();
      onCheckoutError(checkoutResponse.checkoutUserErrors[0].message);
      return;
    }

    if (checkoutResponse === null) {
      logError('Payment press: `checkoutWithToken` returned null');
      onCheckoutComplete();
      onCheckoutError('Payment failed 4');
      return;
    }

    const paymentId = checkoutResponse.payment?.id;

    if (paymentId === undefined) {
      logError(
        'Payment press: checkoutWithToken checkoutCompleteWithTokenizedPaymentV3.payment.id returned null',
        checkoutResponse,
      );
      onCheckoutComplete();
      onCheckoutError('Payment failed 5');
      return;
    }

    if (checkoutResponse.checkoutUserErrors.length === 0) {
      log('starting polling');
      await pollCompletedPayment({
        variables: {
          paymentId,
        },
      });
    } else {
      onCheckoutError?.(
        checkoutResponse.checkoutUserErrors
          .map((value: Error) => value.message)
          .join('\n'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkoutId,
    checkoutWithToken,
    // createToken,
    customerName,
    idempotencyKey,
    onCheckoutComplete,
    onCheckoutError,
    onCheckoutInProgress,
    pollCompletedPayment,
  ]);

  if (!checkoutId) {
    return null;
  }
  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingVertical: 6,
        }}
      >
        <Text
          style={{
            opacity: 0.7,
            fontSize: 12,
            marginHorizontal: 4,
            marginBottom: 4,
          }}
        >
          CREDIT / DEBIT CARD NUMBER
        </Text>
      </View>

      <View
        style={{
          borderWidth: 1,
          borderColor: '#dddddd',
          borderStyle: 'solid',
          padding: 10,
        }}
      >
        <CardElement id="card-element" options={cardStyle} />
      </View>

      <Text
        style={{
          fontSize: 10,
          opacity: 0.5,
          marginLeft: 2,
          marginTop: 6,
          marginBottom: 9,
          textAlign: 'center',
        }}
      >
        {branding.tradingName}
      </Text>
      <SimpleButton width={'100%'} onPress={onPayPressed}>
        Pay now
      </SimpleButton>

      <Image
        resizeMode="contain"
        style={{
          height: 40,
          width: 240,
          alignSelf: 'center',
        }}
        source={require('../../../../assets/images/credit-card-logos.png')}
      />
    </View>
  );
}
