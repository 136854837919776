import React from 'react';
import { Image } from 'react-native';

import { useDimensions } from '../../../helpers/dimensions';

export const MediaHeader = ({
  collectionTitle,
}: {
  collectionTitle: string;
}) => {
  const { width } = useDimensions();

  if (collectionTitle !== 'Gin Riblet' && collectionTitle !== 'Bacardi') {
    return null;
  }

  if (collectionTitle === 'Bacardi') {
    return (
      <Image
        source={require('../../../../assets/images/bacardi-logo.png')}
        style={{ width: width, borderWidth: 0, height: 120 }}
        resizeMode="contain"
      />
    );
  }

  return (
    // <View style={{ borderWidth: 0 }}>
    <video
      width={width}
      height={(width * 9) / 16}
      controls
      autoPlay
      style={{ marginBottom: 20 }}
    >
      <source
        src={require('../../../../assets/videos/ginriblet.mp4')}
        type="video/mp4"
      />
      Your browser does not support HTML5 video.
    </video>
  );
};
