import React, { ComponentProps } from 'react';
import {
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { RadioButton as PaperRadioButton } from 'react-native-paper';

import { COLORS } from '../constants/colors';

import Text from './Text';

type Props = {
  style: StyleProp<ViewStyle>;
  size?: number;
  checked?: boolean;
  textStyle?: StyleProp<TextStyle>;
  label?: string;
  numberOfLines?: number;
} & ComponentProps<typeof PaperRadioButton>;

export default function RadioButton(props: Props) {
  const {
    style,
    size,
    checked,
    textStyle,
    label,
    numberOfLines,
    ...radioProps
  } = props;

  return (
    <View style={[styles.container, style]}>
      <PaperRadioButton.Android
        color={COLORS.primaryColor}
        uncheckedColor={COLORS.inactive}
        status={checked ? 'checked' : 'unchecked'}
        {...radioProps}
      />

      <Text style={[styles.text, textStyle]} numberOfLines={numberOfLines}>
        {label}
      </Text>
    </View>
  );
}

RadioButton.Group = PaperRadioButton.Group;

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', paddingBottom: 16 },
  text: { paddingLeft: 10, flex: 1 },
});
