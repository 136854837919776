import React from 'react';
import {
  Keyboard,
  Platform,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type Props = {
  children?: React.ReactNode;
};

const FormLayout = ({ children }: Props) => {
  return (
    <View style={{ flex: 1, width: '100%', height: '100%' }}>
      <KeyboardAwareScrollView
        style={{ flex: 1, alignSelf: 'flex-start', width: '100%' }}
        enableOnAndroid={true}
      >
        {Platform.OS !== 'web' ? (
          <TouchableWithoutFeedback
            style={{ flex: 1 }}
            onPress={Keyboard.dismiss}
          >
            <View style={{ flex: 1, paddingBottom: 50 }}>{children}</View>
          </TouchableWithoutFeedback>
        ) : (
          <View style={{ flex: 1, paddingBottom: 50 }}>{children}</View>
        )}
      </KeyboardAwareScrollView>
    </View>
  );
};

export default FormLayout;
