import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import React, { useCallback, useEffect, useState } from 'react';
import { Image, Platform, StatusBar, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import changeNavigationBarColor from 'react-native-navigation-bar-color';
import {
  lineItemsVariantIdsInCartAtom,
  emptyLineItemsInCartAtom,
  resetCheckoutAtom,
  syncLineItemsWithShopifyAtom,
  unavailableLineItemsAtom,
  setQuantityOfLineItemAtom,
  totalPriceLessDeliveryPriceAtom,
  isTotalPriceCalculatingAtom,
} from '../../hooks/checkoutAtoms';
import { triggerAlert } from '../../utils/alert';
import {
  cartExceedsAlcoholLimitAtom,
  cartContainsAlcoholAtom,
} from '../../hooks/alcoholRestrictionsAtoms';
import {
  customerAtom,
  hasCustomerProvidedPersonalDetailsAtom,
} from '../../hooks/customerAtoms';
import { EditableLineItem } from '../../components/LineItem';
import { Button, ModalBottomSheet, Text } from '../../core-ui';
import { ModalBottomSheetMessage } from '../../components';
import { ScrollViewWithHeader } from '../../components/ScrollViewWithHeader';
import { FONT_FAMILY } from '../../constants/fonts';
import { AllScreensNavigationProp } from '../../navigation/types';

import { hubAtom } from '../../hooks/hubsAtoms';
import { AlcoholConditionsBottomSheet } from '../../components/AlcoholConditionsBottomSheet';
import { AlcoholCartLimitExceededBottomSheet } from '../../components/AlcoholCartLimitExceededBottomSheet';
import { PromoContent } from '../../components/PromoContent';
import { currentTimeAtom } from '../../hooks/scheduledTimeAtoms';
import { addressAtom, setAddressLatLngAtom } from '../../hooks/addressAtoms';
import { trackEvent } from '../../analytics';
import { MarketingEmailOptIn } from '../../components/MarketingEmailOptIn';
import { BasketRecommendations } from '../../components/BasketRecommendations';
import { refreshRecommendationsAtom } from '../../hooks/basketRecommendationsAtoms';
import { branding } from '../../branding';
import { DiscountCodeInput } from './components/DiscountCodeInput';
import { ShoppingCartTotal } from './components/ShoppingCartTotal';
import { EmptyShoppingCart } from './EmptyShoppingCart';

export default function ShoppingCartScene() {
  const { navigate } = useNavigation<AllScreensNavigationProp<'Cart'>>();
  const address = useAtomValue(addressAtom);
  const setAddressLatLng = useSetAtom(setAddressLatLngAtom);
  const refreshRecommendations = useSetAtom(refreshRecommendationsAtom);

  const [lineItemsVariantIds] = useAtom(lineItemsVariantIdsInCartAtom);
  const syncLineItemsWithShopify = useSetAtom(syncLineItemsWithShopifyAtom);
  const [customer] = useAtom(customerAtom);
  const [hasCustomerProvidedPersonalDetails] = useAtom(
    hasCustomerProvidedPersonalDetailsAtom,
  );
  const hub = useAtomValue(hubAtom);
  const setCurrentTime = useSetAtom(currentTimeAtom);
  const emptyLineItemsInCart = useSetAtom(emptyLineItemsInCartAtom);
  const resetCheckout = useSetAtom(resetCheckoutAtom);
  const cartExceedsAlcoholLimit = useAtomValue(cartExceedsAlcoholLimitAtom);
  const cartContainsAlcohol = useAtomValue(cartContainsAlcoholAtom);

  const [unavailableLineItems] = useAtom(unavailableLineItemsAtom);
  const setQuantityOfLineItem = useSetAtom(setQuantityOfLineItemAtom);
  const totalPriceLessDelivery = useAtomValue(totalPriceLessDeliveryPriceAtom);
  const isTotalPriceCalculating = useAtomValue(isTotalPriceCalculatingAtom);
  const shake = useSharedValue(0);

  const shakeAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: shake.value }],
    };
  });

  useEffect(() => {
    if (lineItemsVariantIds.length > 0) {
      syncLineItemsWithShopify(); // when cart scene loaded and only sync if there are items in cart
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [alcoholConditionsModalVisible, setAlcoholConditionsModalVisible] =
    useState(false);
  const [
    alcoholCartLimitExceededModalVisible,
    setAlcoholCartLimitExceededModalVisible,
  ] = useState(false);
  const [alertInventoryModalVisible, setInventoryAlertModalVisible] =
    useState(false);

  const adjustCartForUnavailableLineItems = () => {
    unavailableLineItems.forEach(({ countToRemove, lineItem }) => {
      setQuantityOfLineItem({
        variantID: lineItem.variantID,
        quantity: lineItem.quantity - countToRemove,
        queueSync: true,
      });
    });
  };

  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle('dark-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        StatusBar.setBackgroundColor('rgba(0,0,0,0)');
        changeNavigationBarColor('#fafafa', true, false);
      }

      refreshRecommendations();
    }, []),
  );

  const onCheckoutPress = (
    {
      needsAlcoholConditionsConfirmed,
    }: {
      needsAlcoholConditionsConfirmed: boolean;
    } = {
      needsAlcoholConditionsConfirmed: true,
    },
  ) => {
    if (unavailableLineItems.length > 0) {
      setInventoryAlertModalVisible(true);
      return;
    }

    if (cartExceedsAlcoholLimit) {
      setAlcoholCartLimitExceededModalVisible(true);
      return;
    }

    if (needsAlcoholConditionsConfirmed) {
      setAlcoholConditionsModalVisible(true);
      return;
    }

    // Bandaid fix for customer not having address
    // Some unknown issue (probably network related) results in users reaching this screen without an address set
    // If we detect no address, we redirect to address entry screen
    if (!address) {
      trackEvent('recover_from_no_address');
      setAddressLatLng(null);
      navigate('AddressEntry', { flowType: 'fromHome' });
      return;
    }

    if (!customer || !hasCustomerProvidedPersonalDetails) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      navigate('SmsAuthentication', {
        screen: !customer ? 'PhoneInput' : 'PersonalDetails',
        params: !customer
          ? { flowType: 'fromShoppingCart' }
          : { flowType: 'fromShoppingCart', isEditMode: false },
      });
      return;
    }

    setCurrentTime(new Date()); // Ensure current time is update before checkout
    navigate('Checkout');
  };

  if (lineItemsVariantIds.length === 0) {
    return <EmptyShoppingCart />;
  }
  return (
    <>
      <ScrollViewWithHeader
        headerText="Your Order"
        rightHamburgerMenuActions={[
          {
            label: 'Clear Cart',
            onPress: () => {
              emptyLineItemsInCart();
            },
            onLongPress: () => {
              if (__DEV__) {
                resetCheckout();
              } else {
                emptyLineItemsInCart();
              }
            },
          },
        ]}
      >
        <PromoContent placement="cart-screen-1" />

        {(totalPriceLessDelivery?.value() ?? 0) <
        (hub?.minimum_spending ?? 0) ? (
          <Animated.View
            style={[
              {
                marginHorizontal: 14,
                backgroundColor: 'rgba(255,200,58,.2)',
                padding: 16,
                borderRadius: 6,
              },
              shakeAnimatedStyle,
            ]}
          >
            <View>
              <View
                style={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Image
                  style={{
                    width: 16,
                    height: 16,
                    marginLeft: 2,
                    marginRight: 8,
                  }}
                  source={require('../../../assets/images/exclamation-mark.png')}
                />
                <Text>Minimum order value is ${hub?.minimum_spending}</Text>
              </View>
            </View>
          </Animated.View>
        ) : null}

        <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
          {lineItemsVariantIds.map((variantID, i) => {
            return (
              <EditableLineItem
                key={i}
                variantID={variantID}
                unavailable={
                  unavailableLineItems.find(
                    ({ lineItem }) => lineItem.variantID === variantID,
                  ) !== undefined
                }
              />
            );
          })}

          {cartExceedsAlcoholLimit ? (
            <View
              style={{
                marginTop: 20,
                borderWidth: 2,
                borderColor: 'rgba(255,200,58,1)',
                backgroundColor: 'rgba(255,200,58,.2)',
                padding: 10,
                borderRadius: 6,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  width: 64,
                  height: 64,
                  marginLeft: -10,
                }}
                source={require('../../../assets/images/alcoholWarning.png')}
              />
              <View
                style={{
                  flexGrow: 0,
                  flexShrink: 1,
                  borderWidth: 1,
                  borderColor: 'transparent',
                }}
              >
                <Text
                  weight="bold"
                  style={{
                    borderWidth: 1,
                    borderColor: 'transparent',
                    lineHeight: Platform.select({ default: 14, android: 16 }),
                    marginBottom: 6,
                  }}
                >
                  Alcohol limit exceeded
                </Text>
                <Text
                  style={{
                    borderWidth: 1,
                    marginBottom: 6,
                    borderColor: 'transparent',
                    lineHeight: Platform.select({ default: 14, android: 16 }),
                  }}
                >
                  The max is {branding.alcoholLimitDescription}
                </Text>

                <Text
                  style={{
                    //fontSize: 12,
                    borderWidth: 1,
                    borderColor: 'transparent',
                    lineHeight: Platform.select({ default: 14, android: 16 }),
                  }}
                >
                  Please reduce the alcohol in your cart.
                </Text>
              </View>
            </View>
          ) : null}

          <DiscountCodeInput />
          <ShoppingCartTotal />
          <MarketingEmailOptIn />
        </View>
        <BasketRecommendations
          placement="cart-screen"
          useProductQueueSync={true}
        />
        <PromoContent placement="cart-screen-2" />
        {/* Spacer */}
        {/* <View style={{ paddingHorizontal: 0, paddingVertical: 10 }}>
          {cartScreenCollectionListings.map((collection) => {
            return (
              <View key={collection.id} style={{ marginBottom: 20 }}>
                <CollectionHorizontalScroller
                  collection={collection}
                  placement="cart-screen"
                  productsQueueSync={true}
                />
              </View>
            );
          })}
          <View style={{ height: 200 }} />
        </View> */}
        <View style={{ height: 200 }} />
      </ScrollViewWithHeader>

      <View
        style={{
          flex: 1,
          borderTopWidth: 1,
          borderColor: '#E5E5E5',
          padding: 20,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
        }}
      >
        {/* <View>
          <Text>Debug</Text>
          <Text>
            showing: {alcoholCartLimitExceededModalVisible ? 'y' : 'no'}
          </Text>
          <Text>Beer: {beerOrCiderUnitCountInCart}</Text>
          <Text>Wine: {wineBottleCountInCart}</Text>
          <Text>Contains alc: {cartContainsAlcohol ? 'y' : 'n'}</Text>
          <Text>Exceeding limit: {cartExceedsAlcoholLimit ? 'y' : 'n'}</Text>
        </View> */}
        {hub?.status !== 'active' ? (
          <View
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              source={branding.warningGraphic}
              style={{
                width: 70,
                height: 70,
                margin: -10,
              }}
            />
            <View
              style={{
                alignContent: 'center',
                justifyContent: 'center',
                marginLeft: 10,
              }}
            >
              <Text weight="bold" style={{}}>
                {branding.displayName} is currently closed
              </Text>
              <Text style={{ marginTop: 4 }}>Please come back later</Text>
            </View>
          </View>
        ) : (
          <Button
            labelStyle={{
              fontFamily: FONT_FAMILY.BOLD,
              fontSize: 18,
              lineHeight: 18,
            }}
            onPress={() => {
              if (isTotalPriceCalculating) {
                return;
              }

              if (
                (totalPriceLessDelivery?.value() ?? 0) < hub?.minimum_spending
              ) {
                trackEvent('cart_minimum_order_not_met');
                // animate minimum order value warning
                shake.value = withSequence(
                  withTiming(-2, { duration: 50 }),
                  withRepeat(withTiming(2, { duration: 100 }), 6, true),
                  withTiming(0, { duration: 50 }),
                );

                triggerAlert(
                  `Please add more items to your cart.`,
                  `Minimum order value is $${hub?.minimum_spending}`,
                );
                return;
              }

              onCheckoutPress({
                needsAlcoholConditionsConfirmed: cartContainsAlcohol,
              });
            }}
          >
            Checkout
          </Button>
        )}
      </View>

      <AlcoholConditionsBottomSheet
        isVisible={alcoholConditionsModalVisible}
        onConditionsAccepted={() => {
          setAlcoholConditionsModalVisible(false);
          onCheckoutPress({ needsAlcoholConditionsConfirmed: false });
        }}
        onDismiss={() => setAlcoholConditionsModalVisible(false)}
      />
      <AlcoholCartLimitExceededBottomSheet
        isVisible={alcoholCartLimitExceededModalVisible}
        onDismiss={() => setAlcoholCartLimitExceededModalVisible(false)}
      />

      <ModalBottomSheet
        title={
          unavailableLineItems.length === 1
            ? 'Item no longer available'
            : 'Unavailable Items'
        }
        isModalVisible={alertInventoryModalVisible}
        toggleModal={() => setInventoryAlertModalVisible(false)}
      >
        <ModalBottomSheetMessage
          isError={true}
          message={
            unavailableLineItems.length === 1
              ? `Sorry, ${unavailableLineItems[0].lineItem.title} is no longer available. Your cart will be adjusted.`
              : `Sorry, the following items are no longer available: ${unavailableLineItems
                  .map(({ lineItem }) => lineItem.title)
                  .join(', ')}. Your cart will be adjusted.`
          }
          onPressModalButton={() => {
            adjustCartForUnavailableLineItems();
            setInventoryAlertModalVisible(false);
          }}
          buttonText={'OK'}
        />
      </ModalBottomSheet>
    </>
  );
}
