import { atomWithStorage } from 'jotai/utils';
import { atomStorageHandler } from './helpers/atomStorageHandler';

type Order = {
  id: string;
  total: number;
  confirmedAt: number;
  expectedTime: number;
  expectedTimeDisplayString: string;
};

export const orderCurrentlyTrackedAtom = atomWithStorage<Order | null>(
  'orderIdBeingTrackedAtom',
  null,
  atomStorageHandler<Order | null>(),
);
