import React, { useCallback } from 'react';
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
  Linking,
  StatusBar,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { useAtom } from 'jotai';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ScrollView } from 'react-native-gesture-handler';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { COLORS } from '../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Avatar, Text } from '../core-ui';
import { useShopifyAuth } from '../helpers/useShopifyAuth';
import { useGetShop } from '../hooks/api/useCustomerAddress';

import { HORIZONTAL_GUTTER } from '../constants/theme';
import {
  customerAtom,
  hasCustomerProvidedPersonalDetailsAtom,
} from '../hooks/customerAtoms';
import { AllScreensNavigationProp } from '../navigation/types';
import { SimpleButton } from '../components/SimpleButton';
import { isCheckoutDebugEnabledAtom } from '../hooks/checkoutAtoms';
import { StateDebug } from '../components/StateDebug';
import { PromoContent } from '../components/PromoContent';
import { client } from '../api/trpc';
import { branding } from '../branding';
import { triggerAlert } from '../utils/alert';

export function ProfileScene() {
  const [isCheckoutDebugEnabled, setIsCheckoutDebugEnabled] = useAtom(
    isCheckoutDebugEnabledAtom,
  );
  const [customer] = useAtom(customerAtom);
  const [hasCustomerProvidedPersonalDetails] = useAtom(
    hasCustomerProvidedPersonalDetailsAtom,
  );
  const { navigate } =
    useNavigation<AllScreensNavigationProp<'PersonalDetails'>>();
  const { logOut } = useShopifyAuth();
  const insets = useSafeAreaInsets();

  const fullName =
    customer && customer.firstName && customer.lastName
      ? `${customer.firstName} ${customer.lastName}`
      : 'Customer';

  // Change status bar colour on focus
  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle('dark-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        StatusBar.setBackgroundColor('rgba(0,0,0,0)');
        changeNavigationBarColor('#fafafa', true, false);
      }
    }, []),
  );

  async function handlePressLogOut() {
    if (Platform.OS !== 'web') {
      triggerAlert('Log out', 'Are you sure you wish to log out?', [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Log out',
          onPress: async () => {
            await logOut();
          },
        },
      ]);
    } else {
      await logOut();
    }
  }

  function handleJoinOrLogin() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    navigate('SmsAuthentication', {
      screen: !customer ? 'PhoneInput' : 'PersonalDetails',
      params: { flowType: 'fromProfile' },
    });
  }
  if (!customer || !hasCustomerProvidedPersonalDetails) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: 'rgba(240, 240, 240, 1)',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            padding: 24,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StateDebug />
          <Pressable
            onLongPress={() => {
              if (__DEV__) {
                setIsCheckoutDebugEnabled(!isCheckoutDebugEnabled);
              }
            }}
            style={{ justifyContent: 'center', alignItems: 'center' }}
          >
            <Image
              source={branding.signUpGraphic}
              style={{ width: 120, height: 120, marginTop: 30 }}
            />
            <Text
              style={{
                fontFamily: FONT_FAMILY.BOLD,
                fontSize: FONT_SIZE.large,
                lineHeight: LINE_HEIGHT.large,
                marginTop: 8,
                marginBottom: 6,
                textAlign: 'center',
              }}
            >
              Get started
            </Text>
            <Text
              style={{
                fontFamily: FONT_FAMILY.REGULAR,
                fontSize: FONT_SIZE.small,
                lineHeight: LINE_HEIGHT.large,
                color: COLORS.grey6,
                textAlign: 'center',
                marginBottom: 24,
              }}
            >
              Already a member?
            </Text>
          </Pressable>
          {/* {isCheckoutDebugEnabled ? <StateDebug /> : null} */}
          <View style={{ marginTop: 20 }}>
            <SimpleButton
              width={200}
              onPress={() => {
                handleJoinOrLogin();
              }}
            >
              Log in
            </SimpleButton>
          </View>

          <View style={{ marginTop: 14, alignItems: 'center' }}>
            <Text style={{ marginBottom: 14 }}>or</Text>
            <SimpleButton
              width={200}
              color="rgba(24,24,24,1)"
              bgColor="rgba(0,0,0,.05)"
              onPress={() => {
                handleJoinOrLogin();
              }}
            >
              Join
            </SimpleButton>
          </View>

          <View style={{ marginTop: 30 }}>
            <ImportantLinks />
          </View>
        </View>
      </View>
    );
  }
  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: COLORS.white,
        paddingTop: insets.top + 20,
        paddingBottom: 150,
      }}
    >
      {/* {isCheckoutDebugEnabled ? <StateDebug /> : null} */}
      <View
        style={{
          borderBottomColor: COLORS.lightGrey,
          flexDirection: 'row',
          padding: 24,
        }}
      >
        <Avatar
          firstName={customer.firstName}
          lastName={customer.lastName}
          size={60}
        />
        <View
          style={{
            justifyContent: 'center',
            paddingLeft: HORIZONTAL_GUTTER,
            flex: 1,
          }}
        >
          <Text
            style={{
              fontSize: FONT_SIZE.medium,
              lineHeight: LINE_HEIGHT.medium,
              marginBottom: 6,
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            dataSet={{ 'openreplay-obscured': '' }}
          >
            {fullName}
          </Text>
          <Text
            style={{
              color: COLORS.grey5,
              marginBottom: 6,
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            dataSet={{ 'openreplay-obscured': '' }}
          >
            {customer.phone}
          </Text>
          <Text
            style={{
              color: COLORS.grey5,
            }}
            numberOfLines={1}
          >
            {customer.email}
          </Text>
        </View>

        <Pressable
          onPress={() => {
            navigate('PersonalDetails', {
              flowType: 'fromProfile',
              isEditMode: true,
            });
          }}
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
          }}
        >
          <Text
            style={{
              fontSize: FONT_SIZE.medium,
              lineHeight: LINE_HEIGHT.medium,
              color: COLORS.black,
            }}
          >
            EDIT
          </Text>
        </Pressable>
      </View>
      <PromoContent placement="profile-screen" />
      <View
        style={{
          alignItems: 'flex-start',
          paddingHorizontal: 24,
          paddingVertical: 12,
          borderBottomColor: COLORS.lightGrey,
        }}
      >
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => navigate('OrderHistory')}
        >
          <Text style={styles.buttonLabelStyle}>{'Order History'}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.menuItem}
          onPress={() => {
            navigate('TrackingAndConversation');
          }}
        >
          <Text style={styles.buttonLabelStyle}>Support</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.menuItem, { marginBottom: 20 }]}
          onPress={handlePressLogOut}
        >
          <Text style={styles.buttonLabelStyle}>Log out</Text>
        </TouchableOpacity>
      </View>
      <View style={{ height: 20 }} />
      <ImportantLinks />
      <TouchableOpacity
        style={styles.menuItem}
        onPress={() => {
          // Alert to confirm deletion request
          async function handleDeleteAccount() {
            try {
              await client.deleteCustomerRequest.query({
                shopifyCustomerId: customer?.id || 'notfound',
              });
              logOut();
              triggerAlert(
                'Deletion Requested',
                'Sorry to see you go! Our team will be in touch within 24 hours.',
                [
                  {
                    text: 'OK',
                  },
                ],
              );
            } catch (error) {
              console.log(error);
              triggerAlert(
                'Error processing request',
                'Please contact support@teddy.nz for help',
                [
                  {
                    text: 'OK',
                  },
                ],
              );
            }
          }

          triggerAlert(
            'Request Deletion',
            'This will send a data removal request to our customer service team. Please note, this will not resolve account issues.',
            [
              {
                text: 'Cancel',
                style: 'cancel',
              },
              {
                text: 'Delete',
                onPress: handleDeleteAccount,
              },
            ],
          );
        }}
      >
        <Text
          style={[
            styles.buttonLabelStyle,
            { fontSize: 12, color: COLORS.red, textAlign: 'center' },
          ]}
        >
          {'Request Deletion'}
        </Text>
      </TouchableOpacity>
      <View style={{ height: 90 }} />
    </ScrollView>
  );
}

const ImportantLinks = () => {
  const { data: shopData } = useGetShop();
  const { navigate } =
    useNavigation<AllScreensNavigationProp<'PersonalDetails'>>();

  return (
    <View style={{ borderWidth: 0, marginTop: 30, opacity: 0.7 }}>
      <TouchableOpacity
        style={{}}
        onPress={() => {
          if (Platform.OS !== 'web') {
            navigate('WebView', {
              webUrl: shopData?.shop.termsOfService?.url,
              type: 'terms',
            });
          } else {
            Linking.openURL(shopData?.shop.termsOfService?.url);
          }
        }}
      >
        <Text
          style={[
            styles.buttonLabelStyle,
            { fontSize: 12, textAlign: 'center', marginTop: 6 },
          ]}
        >
          Terms of Use
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={{}}
        onPress={() => {
          if (Platform.OS !== 'web') {
            navigate('WebView', {
              webUrl: shopData?.shop.privacyPolicy?.url,
              type: 'policy',
            });
          } else {
            Linking.openURL(shopData?.shop.privacyPolicy?.url);
          }
        }}
      >
        <Text
          style={[
            styles.buttonLabelStyle,
            { fontSize: 12, textAlign: 'center', marginTop: 6 },
          ]}
        >
          Privacy Policy
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={{}}
        onPress={() => {
          if (Platform.OS !== 'web') {
            navigate('WebView', {
              webUrl: branding.licenseUrl,
              type: 'license',
            });
          } else {
            Linking.openURL(branding.licenseUrl);
          }
        }}
      >
        <Text
          style={[
            styles.buttonLabelStyle,
            { fontSize: 12, textAlign: 'center', marginTop: 6 },
          ]}
        >
          Alcohol License
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={{}}
        onPress={() => {
          if (Platform.OS !== 'web') {
            navigate('WebView', {
              webUrl: `${branding.websiteDomain}/notice-board`,
              type: 'notice',
            });
          } else {
            Linking.openURL(`${branding.websiteDomain}/notice-board`);
          }
        }}
      >
        <Text
          style={[
            styles.buttonLabelStyle,
            { fontSize: 12, textAlign: 'center', marginTop: 6 },
          ]}
        >
          Notice Board
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonLabelStyle: {
    fontSize: FONT_SIZE.medium,
    fontFamily: FONT_FAMILY.REGULAR,
    lineHeight: LINE_HEIGHT.medium,
  },
  menuItem: {
    marginVertical: 12,
  },
});
