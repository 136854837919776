import React, { Suspense, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { useFlipper } from '@react-navigation/devtools';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useAtomValue, useSetAtom } from 'jotai';

import { trackEvent } from '../analytics';
import { branding } from '../branding';
import { ReactNavigationTheme } from '../constants/theme';
import { AddressEntryScene } from '../scenes/AddressEntryScene';
import { AppLandingScene } from '../scenes/AppLandingScene';
import { hubAtom } from '../hooks/hubsAtoms';
import ConversationScene from '../scenes/ConversationScene';
import ProductDetailsScene from '../scenes/ProductDetails/ProductDetailsScene';
import { currentRouteNameAtom } from '../hooks/navigationAtom';
import { NotificationPermissionScene } from '../scenes/NotificationPermissionScene';
import { CustomerReferralScene } from '../scenes/CustomerReferralScene';
import { OrderTrackingScene } from '../scenes/OrderTrackingScene';
import { SmsAuthenticationNavigator } from './SmsAuthenticationNavigator';
import { TabNavigator } from './TabNavigator';
import { AllScreens } from './types';

export function RootNavigator() {
  if (Platform.OS !== 'web') {
    return <RootNavigationContainer />;
  } else {
    return (
      <Suspense
        fallback={
          <div
            style={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f4f4f4',
            }}
          >
            <div>
              <img src={branding.emblemGraphic} alt="logo" />
            </div>
            <div
              style={{
                display: 'flex',
                height: 20,
                justifyContent: 'end',
                alignItems: 'end',
              }}
            >
              <div className="dotFlashing" />
            </div>
          </div>
        }
      >
        <RootNavigationContainer />
      </Suspense>
    );
  }
}

const RootNavigationContainer = () => {
  const RootStack = createNativeStackNavigator<AllScreens>();
  const navigationContainerRef = useNavigationContainerRef<AllScreens>();
  const hub = useAtomValue(hubAtom);
  const setCurrentRouteName = useSetAtom(currentRouteNameAtom);
  const prevRouteNameRef = React.useRef<string | undefined>(undefined);
  useFlipper(navigationContainerRef);

  // Bandaid fix
  // There is a bug where the addresslatLng is unset, causing the hub to be unset.
  // This is a fix to show the address entry screen if the hub is unset.
  const [hubWasSet, setHubWasSet] = useState(false);

  useEffect(() => {
    if (!hub?.id && !hubWasSet) {
      setHubWasSet(true);
    }

    const currentRouteName =
      navigationContainerRef.current?.getCurrentRoute()?.name;

    if (
      !hub?.id &&
      currentRouteName !== 'AddressEntry' &&
      currentRouteName !== 'Landing' &&
      currentRouteName !== 'ProductDetails'
    ) {
      navigationContainerRef.current?.navigate('AddressEntry', {
        flowType: 'fromLanding',
      });
    }
  }, [hub?.id]);

  return (
    <NavigationContainer
      ref={navigationContainerRef}
      documentTitle={{
        formatter: () => {
          return branding.webTitle;
        },
      }}
      linking={{
        prefixes: [branding.webStorefrontUrl],
        config: {
          initialRouteName: 'Landing',
          screens: {
            Landing: {
              path: '',
            },
            AddressEntry: {
              path: 'address-entry',
            },
            TabNavigator: {
              screens: {
                HomeTab: {
                  path: 'home',
                  screens: {
                    Home: '',
                    ProductCollection: 'product-collection',
                    ProductCollectionLevel2: 'product-collection-level-2',
                    ProductCollectionLevel3: 'product-collection-level-3',
                    CategoriesCollection: 'categories-collection',
                    OccasionsCollection: 'occasions-collection',
                  },
                },
                SearchTab: {
                  path: 'search',
                  screens: {
                    Search: '',
                    ProductCollection: 'product-collection',
                    ProductCollectionLevel2: 'product-collection-level-2',
                    ProductCollectionLevel3: 'product-collection-level-3',
                  },
                },
                CartTab: {
                  path: 'cart',
                  screens: {
                    Cart: '',
                    OrderComplete: 'order-confirmation',
                    Checkout: 'checkout',
                  },
                },
                ProfileTab: {
                  path: 'profile',
                  screens: {
                    Profile: '',
                    OrderHistory: 'order-history',
                  },
                },
              },
            },
            ProductDetails: {
              path: 'product-details',
            },
            CustomerReferral: {
              path: 'customer-referral',
            },
          },
        },
      }}
      onReady={() => {
        if (navigationContainerRef.current) {
          prevRouteNameRef.current =
            navigationContainerRef.current.getCurrentRoute()?.name;
        }
      }}
      onStateChange={async () => {
        const previousRouteName = prevRouteNameRef.current;
        const currentRouteName =
          navigationContainerRef.current?.getCurrentRoute()?.name;
        const analyticsScreenName = currentRouteName;
        const currentRouteParams =
          navigationContainerRef.current?.getCurrentRoute()?.params;

        if (previousRouteName !== currentRouteName) {
          trackEvent('screen_view', {
            screen_name: analyticsScreenName,
            screen_class: analyticsScreenName,
            ...currentRouteParams,
          });
          setCurrentRouteName((currentRouteName as keyof AllScreens) || null);
        }
        prevRouteNameRef.current = currentRouteName;
      }}
      theme={ReactNavigationTheme}
    >
      <RootStack.Navigator
        id="Root"
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Landing"
      >
        {/* Landing Screen */}
        <RootStack.Screen name="Landing" component={AppLandingScene} />
        {/* Address Screen */}
        <RootStack.Screen
          name="AddressEntry"
          component={AddressEntryScene}
          initialParams={{
            flowType: 'fromLanding',
          }}
        />
        {/* Main tab screen */}
        <RootStack.Screen name="TabNavigator" component={TabNavigator} />

        {/* Conversation */}
        <RootStack.Screen
          name="TrackingAndConversation"
          component={ConversationScene}
          options={{
            presentation: 'modal',
            animation: 'slide_from_bottom',
          }}
        />

        {/* Log in /Sign up */}
        <RootStack.Screen
          name="SmsAuthentication"
          component={SmsAuthenticationNavigator}
          options={{
            presentation: 'modal',
            animation: 'slide_from_bottom',
            // presentation: Platform.select({
            //   ios: 'modal',
            //   android: 'modal',
            // }),
          }}
        />

        <RootStack.Screen
          name="SmsAuthenticationNonModal"
          component={SmsAuthenticationNavigator}
        />

        {/* Notification Permission */}
        <RootStack.Screen
          name="NotificationPermission"
          component={NotificationPermissionScene}
          options={() => {
            return {
              animation: 'fade',
              presentation: 'fullScreenModal',
              headerShown: false,
            };
          }}
        />

        {/* Customer Referral */}
        <RootStack.Screen
          name="CustomerReferral"
          component={CustomerReferralScene}
          options={() => {
            return {
              animation: 'fade',
              presentation: 'fullScreenModal',
              headerShown: false,
            };
          }}
        />

        {/* Product Details */}
        <RootStack.Screen
          name="ProductDetails"
          component={ProductDetailsScene}
          options={() => {
            return {
              animation: 'slide_from_bottom',
              presentation: 'fullScreenModal',
              headerShown: false,
            };
          }}
        />

        <RootStack.Screen
          name="OrderTracking"
          component={OrderTrackingScene}
          options={() => {
            return {
              animation: 'slide_from_bottom',
              presentation: 'fullScreenModal',
              //headerShown: false,
            };
          }}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};
