import React, { useCallback, useEffect, useState } from 'react';
import 'react-native-get-random-values';
import {
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  View,
  Keyboard,
  Pressable,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native-paper';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { STRIPE_PUBLISHABLE_KEY } from '../../../env';
import { ModalBottomSheetMessage } from '../../components';
import { Button, ModalBottomSheet, Text } from '../../core-ui';
import CheckoutAddress from '../../components/CheckoutAddress';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../../constants/fonts';
import { logError } from '../../utils/LoggingUtils';
import { t } from '../../helpers/translate';
import { ScrollViewWithHeader } from '../../components/ScrollViewWithHeader';
import {
  totalPriceAtom,
  totalTaxAtom,
  isTotalPriceCalculatingAtom,
  resetCheckoutAtom,
  lineItemsVariantIdsInCartAtom,
  associateShippingAddressWithShopifyCheckoutAtom,
  isCheckoutDebugEnabledAtom,
  associateCustomerWithShopifyCheckoutAtom,
  hasAssociatedCustomerWithShopifyCheckoutAtom,
  shippingPriceAtom,
  discountCodeAtom,
  lineItemsInCartAtom,
} from '../../hooks/checkoutAtoms';
import { EmptyShoppingCart } from '../ShoppingCart/EmptyShoppingCart';
import {
  GooglePurchaseEventItem,
  timeEvent,
  trackEvent,
} from '../../analytics';
import { FancyPriceDisplay } from '../../components/FancyPriceDisplay';

import { PromoContent } from '../../components/PromoContent';
import { AllScreensNavigationProp } from '../../navigation/types';
import { StateDebug } from '../../components/StateDebug';
import { orderCurrentlyTrackedAtom } from '../../hooks/orderTrackingAtoms';
import { hubAtom } from '../../hooks/hubsAtoms';
import {
  expectedTimeAtom,
  expectedTimeDisplayStringAtom,
  isScheduledAtom,
  scheduledSlotAtom,
} from '../../hooks/scheduledTimeAtoms';
import CheckoutWithApplePay from './components/CheckoutWithApplePay';
import CheckoutWithGooglePay from './components/CheckoutWithGooglePay';
import { ShippingRateSelector } from './components/ShippingRateSelector';
import CheckoutWithCreditCard from './components/CheckoutWithCreditCard';
import { NoteInput } from './components/NoteInput';
import { ScheduledTimePicker } from './components/ScheduledTimePicker';

const promise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const CheckoutScene = () => {
  const { navigate, goBack } =
    useNavigation<AllScreensNavigationProp<'Checkout'>>();

  const [showDebugState, setShowDebugState] = useAtom(
    isCheckoutDebugEnabledAtom,
  );
  const isScheduled = useAtomValue(isScheduledAtom);
  const scheduledSlot = useAtomValue(scheduledSlotAtom);
  const expectedTime = useAtomValue(expectedTimeAtom);
  const expectedTimeDisplayString = useAtomValue(expectedTimeDisplayStringAtom);

  const [isScheduledTimePickerVisible, setIsScheduledTimePickerVisible] =
    useState(false);
  const [totalTax] = useAtom(totalTaxAtom);
  const [totalPrice] = useAtom(totalPriceAtom);
  const [shippingPrice] = useAtom(shippingPriceAtom);
  const [discountCode] = useAtom(discountCodeAtom);
  const [isTotalCalculating] = useAtom(isTotalPriceCalculatingAtom);
  const [lineItemsVariantIds] = useAtom(lineItemsVariantIdsInCartAtom);
  const [, resetShoppingCart] = useAtom(resetCheckoutAtom);
  const [hub] = useAtom(hubAtom);
  const [lineItems] = useAtom(lineItemsInCartAtom);

  const [isPaymentSheetVisible, setIsPaymentSheetVisible] = useState(false);
  const [isCreditCardFormVisible, setIsCreditCardFormVisible] = useState(false);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const associateShippingAddressWithShopifyCheckout = useSetAtom(
    associateShippingAddressWithShopifyCheckoutAtom,
  );
  const associateCustomerWithShopifyCheckout = useSetAtom(
    associateCustomerWithShopifyCheckoutAtom,
  );
  const [hasAssociatedCustomerWithShopifyCheckout] = useAtom(
    hasAssociatedCustomerWithShopifyCheckoutAtom,
  );
  const toggleModalVisible = useCallback(
    () => setIsErrorModalVisible(!isErrorModalVisible),
    [isErrorModalVisible],
  );
  const setOrderCurrentlyTrackedAtom = useSetAtom(orderCurrentlyTrackedAtom);

  useFocusEffect(
    useCallback(() => {
      (async () => {
        timeEvent('payment_sheet_opened');
        await associateShippingAddressWithShopifyCheckout();

        if (!hasAssociatedCustomerWithShopifyCheckout) {
          associateCustomerWithShopifyCheckout();
        }

        trackEvent('payment_sheet_opened');

        setTimeout(() => {
          // setIsPaymentSheetVisible(true);
        }, 1250);
      })();

      return () => {
        setIsPaymentSheetVisible(false);
      };

      // Duct Tape fix
      // associateShippingAddressWithShopifyCheckout();
      // if (!hasAssociatedCustomerWithShopifyCheckout) {
      //   associateCustomerWithShopifyCheckout();
      // }
      // // Pop open payment sheet after small delay
      // setTimeout(() => {
      //   setIsPaymentSheetVisible(true);
      // }, 1250);
      // return () => {
      //   setIsPaymentSheetVisible(false);
      // };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsPaymentSheetVisible]),
  );

  const onPressEdit = () => {
    navigate('AddressEntry', { flowType: 'fromCheckout' });
  };

  const closeModal = () => {
    setIsErrorModalVisible(false);
    setModalMessage('');
  };

  const alertComponent = () => (
    <ModalBottomSheet
      title={t('Payment')}
      isModalVisible={isErrorModalVisible}
      toggleModal={toggleModalVisible}
    >
      <ModalBottomSheetMessage
        isError={true}
        message={modalMessage}
        onPressModalButton={closeModal}
        buttonText={t('Close')}
      />
    </ModalBottomSheet>
  );

  const paymentSheetOverhang = 30;
  const paymentSheetYShowingWhenExtended = paymentSheetOverhang * -1;
  const paymentSheetYShowingWhenNotExtended = 0;
  const paymentSheetDismissedTranslateY = 350;
  const paymentSheetTranslateY = useSharedValue(
    paymentSheetDismissedTranslateY,
  );
  const paymentSheetAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: paymentSheetTranslateY.value,
        },
      ],
    };
  });

  const paymentButtonTranslateY = useSharedValue(0);
  const paymentButtonAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: paymentButtonTranslateY.value,
        },
      ],
    };
  });

  useEffect(() => {
    if (isPaymentSheetVisible) {
      paymentSheetTranslateY.value = withDelay(
        200,
        withSpring(paymentSheetYShowingWhenNotExtended, {
          mass: 0.4,
        }),
      );
      paymentButtonTranslateY.value = withTiming(100, { duration: 300 });
    } else {
      paymentSheetTranslateY.value = withTiming(
        paymentSheetDismissedTranslateY,
        { duration: 300 },
      );
      paymentButtonTranslateY.value = withTiming(0, { duration: 300 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentSheetVisible]); // <-- Possibly a problem

  const paymentOptionsOpacity = useSharedValue(1);
  const paymentOptionsScale = useSharedValue(1);
  const paymentOptionsAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: paymentOptionsOpacity.value,
      transform: [
        {
          translateY: paymentOptionsScale.value,
        },
        {
          scale: paymentOptionsScale.value,
        },
      ],
    };
  });

  const creditCardFormOpacity = useSharedValue(0);
  const creditCardFormAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: creditCardFormOpacity.value,
    };
  });

  const paymentProcessingIndicatorOpacity = useSharedValue(0);
  const paymentProcessingIndicatorAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: paymentProcessingIndicatorOpacity.value,
    };
  });

  const overlayOpacity = useSharedValue(0);
  const overlayAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: overlayOpacity.value,
    };
  });

  type PaymentSheetState =
    | 'showing_options'
    | 'showing_credit_card_form'
    | 'processing';
  const paymentSheetState: PaymentSheetState = paymentInProgress
    ? 'processing'
    : isCreditCardFormVisible
    ? 'showing_credit_card_form'
    : 'showing_options';

  // Payment Sheet State
  // Switch between showing the payment options
  useEffect(() => {
    switch (paymentSheetState) {
      case 'showing_options':
        Keyboard.dismiss();
        paymentOptionsOpacity.value = withDelay(
          100,
          withTiming(1, { duration: 200 }),
        );
        paymentOptionsScale.value = withSpring(1, { mass: 0.4 });
        creditCardFormOpacity.value = withTiming(0, { duration: 40 });

        paymentSheetTranslateY.value = withSpring(
          isPaymentSheetVisible
            ? paymentSheetYShowingWhenNotExtended
            : paymentSheetDismissedTranslateY,
          { mass: 0.3 },
        );

        paymentProcessingIndicatorOpacity.value = withTiming(0, {
          duration: 40,
        });
        overlayOpacity.value = withTiming(0, { duration: 200 });
        break;
      case 'showing_credit_card_form':
        paymentOptionsOpacity.value = withTiming(0, { duration: 140 });
        paymentOptionsScale.value = withSpring(0.98, { mass: 0.4 });
        creditCardFormOpacity.value = withDelay(
          0,
          withTiming(1, { duration: 120 }),
        );

        paymentSheetTranslateY.value = withSpring(
          isPaymentSheetVisible
            ? paymentSheetYShowingWhenExtended
            : paymentSheetDismissedTranslateY,
          { mass: 0.3 },
        );

        paymentProcessingIndicatorOpacity.value = withTiming(0, {
          duration: 40,
        });
        overlayOpacity.value = withTiming(1, { duration: 200 });
        break;
      case 'processing':
        Keyboard.dismiss();
        paymentOptionsOpacity.value = withTiming(0, { duration: 140 });
        paymentOptionsScale.value = withSpring(0.98, { mass: 0.4 });
        creditCardFormOpacity.value = withDelay(
          0,
          withTiming(0, { duration: 120 }),
        );

        paymentSheetTranslateY.value = withSpring(
          isPaymentSheetVisible
            ? paymentSheetYShowingWhenExtended
            : paymentSheetDismissedTranslateY,
          { mass: 0.3 },
        );

        paymentProcessingIndicatorOpacity.value = withTiming(1, {
          duration: 40,
        });
        overlayOpacity.value = withTiming(1, { duration: 200 });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSheetState]); // // <-- Possibly causing unncessary re-renders?

  if (lineItemsVariantIds.length === 0) {
    return <EmptyShoppingCart />;
  }

  const onCheckoutInProgress =
    (payment_type: 'APPLE_PAY' | 'STRIPE' | 'GOOGLE_PAY') => () => {
      setPaymentInProgress(true);
      trackEvent('checkout_submitted', {
        total: totalPrice.value(),
        payment_type,
      });
    };
  const onCheckoutComplete = () => {
    setPaymentInProgress(false);
  };
  const onCheckoutSuccess =
    (payment_type: 'APPLE_PAY' | 'STRIPE' | 'GOOGLE_PAY') =>
    async (orderId: string) => {
      navigate('OrderTracking', {
        orderId,
        live: true,
      });
      setPaymentInProgress(false);
      trackEvent('purchase', {
        payment_type,
        transaction_id: orderId,
        value: totalPrice.value(),
        tax: totalTax.value(),
        shipping: shippingPrice.value(),
        currency: 'NZD',
        coupon: discountCode.valueOf() || undefined,
        items: lineItems.map((lineItem): GooglePurchaseEventItem => {
          return {
            item_id: lineItem.variantID,
            item_name: lineItem.title,
            currency: 'NZD',
            discount: lineItem.originalPrice - lineItem.priceAfterDiscount,
            location_id: hub?.id as string,
            price: lineItem.priceAfterDiscount,
            quantity: lineItem.quantity,
          };
        }),
      });
      setOrderCurrentlyTrackedAtom({
        id: orderId,
        total: totalPrice.value() || 0,
        confirmedAt: +new Date(),
        expectedTime: expectedTime ? +expectedTime : 0,
        // Must remember expected time here, because we cannot derive it from the order endpoint because 'note' is unavailable
        expectedTimeDisplayString: expectedTimeDisplayString,
      });
      setTimeout(() => {
        resetShoppingCart();
      }, 400);
    };
  const expectedCheckoutErrors = [
    'Card details not complete',
    'Card was declined',
    'Card number is expired',
    'Security code was not matched by the processor',
    'Your card was declined. Your request was in live mode, but used a known test card',
  ];

  const onCheckoutError =
    (payment_type: 'APPLE_PAY' | 'STRIPE' | 'GOOGLE_PAY') =>
    (error: string) => {
      let errorMessageToDisplay = error;

      // Bandaid fix for payment total changing during checkout
      // It's possible that the total changes during checkout because of a lack of grasp
      // on the network latency. The root cause of this problem needs to be fixed, this gracefully
      // handles the problem for now by allowing customers to reinitiate checkout
      if (
        error.includes(
          'Amount The amount of the payment must match the total of',
        )
      ) {
        errorMessageToDisplay =
          'Looks like something when wrong processing your payment. Please try again.';
      }

      setModalMessage(errorMessageToDisplay);
      toggleModalVisible();

      const eventContext = {
        error: error,
        total: totalPrice.value(),
        payment_type,
      };

      const isErrorExpected = expectedCheckoutErrors.includes(error);
      if (isErrorExpected) {
        trackEvent('checkout_expected_error', eventContext);
      } else {
        trackEvent('checkout_unexpected_error', eventContext);
        logError(error, eventContext); // Sends to sentry
      }
    };

  return (
    <>
      <ScrollViewWithHeader
        headerText="Checkout"
        onBackButtonPress={() => {
          if (paymentInProgress) {
            return;
          }

          if (isCreditCardFormVisible) {
            setIsCreditCardFormVisible(false);
          } else {
            goBack();
          }
        }}
      >
        {alertComponent()}
        <SafeAreaView
          style={{
            flex: 1,
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <PromoContent placement="checkout-screen-1" />
          <View style={{ padding: 20 }}>
            <StateDebug />
            <View style={{ minHeight: 100, borderWidth: 0 }}>
              <CheckoutAddress onEditPressed={onPressEdit} />
            </View>

            <View style={{ minHeight: 230 }}>
              <Text style={styles.heading}>How fast do you need it?</Text>
              <ShippingRateSelector
                onScheduledPress={() => {
                  setIsScheduledTimePickerVisible(true);
                }}
              />
            </View>
            <NoteInput
              onBlur={() => {
                setIsPaymentSheetVisible(true);
              }}
              onFocus={() => {
                setIsPaymentSheetVisible(false);
              }}
            />
          </View>
        </SafeAreaView>

        <PromoContent placement="checkout-screen-2" />
        <View style={{ height: 400 }} />
      </ScrollViewWithHeader>

      {/* Overlay */}
      <Animated.View
        onTouchStart={() => {
          if (paymentInProgress) {
            return;
          }

          // "Back" action
          if (isCreditCardFormVisible) {
            setIsCreditCardFormVisible(false);
            trackEvent('checkout_back_to_payment_options');
          }
        }}
        pointerEvents={
          paymentSheetState === 'showing_options' ? 'none' : 'auto'
        }
        style={[
          overlayAnimatedStyle,
          {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            backgroundColor: 'rgba(0,0,0,.81)',
            zIndex: 2,
          },
        ]}
      />

      {/* Payment Sheet */}
      <KeyboardAvoidingView
        behavior={Platform.select({
          ios: 'position',
          android: undefined,
        })}
        keyboardVerticalOffset={Platform.select({
          ios: -50, // Sorry, magic number, this is finicky. 🧙🏼‍♂️
          android: 0,
        })}
        pointerEvents={isPaymentSheetVisible ? 'auto' : 'none'}
        enabled
        style={{
          zIndex: isPaymentSheetVisible ? 2 : 0,
          borderColor: 'red',
          position: 'absolute',
          bottom: (paymentSheetOverhang + 30) * -1,
          width: '100%',
          justifyContent: 'flex-start',
        }}
      >
        <Animated.View
          style={[
            paymentSheetAnimatedStyle,
            {
              flex: 1,
              padding: 20,
              paddingBottom: 26 + paymentSheetOverhang,
              borderTopWidth: 1,
              borderTopColor: 'rgba(0,0,0,0.1)',
              backgroundColor: 'white',
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: -2,
              },
              shadowOpacity: 0.025,
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 20,
              paddingHorizontal: 3,
            }}
          >
            <Pressable
              onLongPress={() => {
                setShowDebugState(!showDebugState);
              }}
            >
              <Text style={{ fontSize: 16 }} weight="bold">
                Total NZD
              </Text>

              <FancyPriceDisplay
                isSyncing={isTotalCalculating}
                deemphasised={true}
                style={{
                  fontSize: 14,
                  marginTop: 6,
                }}
              >
                Incl. {totalTax.format('$0,0.00')} GST
              </FancyPriceDisplay>
            </Pressable>
            <FancyPriceDisplay
              isSyncing={isTotalCalculating}
              style={{ fontSize: 20 }}
            >
              {totalPrice.format('$0,0.00')}
            </FancyPriceDisplay>
          </View>

          <View style={{ borderWidth: 0, height: 200, overflow: 'hidden' }}>
            {/* Payment Options */}
            <Animated.View
              style={[
                paymentOptionsAnimatedStyle,
                {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: isCreditCardFormVisible ? 0 : 1,
                  justifyContent: 'center',
                  paddingBottom: paymentSheetOverhang + 10,
                },
              ]}
            >
              {/* {Platform.select({
                ios: (
                  <CheckoutWithApplePay
                    onCheckoutInProgress={onCheckoutInProgress('APPLE_PAY')}
                    onCheckoutComplete={onCheckoutComplete}
                    onCheckoutSuccess={onCheckoutSuccess('APPLE_PAY')}
                    onCheckoutError={onCheckoutError('APPLE_PAY')}
                  />
                ),
                android: (
                  <CheckoutWithGooglePay
                    onCheckoutInProgress={onCheckoutInProgress('GOOGLE_PAY')}
                    onCheckoutComplete={onCheckoutComplete}
                    onCheckoutSuccess={onCheckoutSuccess('GOOGLE_PAY')}
                    onCheckoutError={onCheckoutError('GOOGLE_PAY')}
                  />
                ),
              })} */}

              <Button
                style={{
                  borderRadius: Platform.select({
                    ios: 7, // Matches Apple Pay button
                    android: 24, // Matches Google Pay button
                  }),
                  paddingVertical: Platform.select({
                    ios: 9, // Matches Apple Pay button
                    android: 7, // Matches Google Pay button
                  }),
                }}
                labelStyle={{
                  fontSize: 16,
                  lineHeight: 16,
                  fontWeight: 'bold',
                  color: 'white',
                  top: 1,
                }}
                onPress={() => {
                  setIsCreditCardFormVisible(true);
                  trackEvent('checkout_choose_credit_card');
                }}
              >
                Credit Card / Debit Card
              </Button>
            </Animated.View>

            {/* Credit card form */}
            <Animated.View
              style={[
                creditCardFormAnimatedStyle,
                {
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  right: 0,
                  top: 0,
                  zIndex: isCreditCardFormVisible ? 1 : 0,
                },
              ]}
            >
              <Elements stripe={promise}>
                <CheckoutWithCreditCard
                  onCheckoutInProgress={onCheckoutInProgress('STRIPE')}
                  onCheckoutComplete={onCheckoutComplete}
                  onCheckoutSuccess={onCheckoutSuccess('STRIPE')}
                  onCheckoutError={onCheckoutError('STRIPE')}
                />
              </Elements>
            </Animated.View>

            {/* Payment in progress */}
            <Animated.View
              style={[
                paymentProcessingIndicatorAnimatedStyle,
                {
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  right: 0,
                  top: 0,
                  zIndex: paymentInProgress ? 1 : 0,
                  justifyContent: 'center',
                  flex: 1,
                },
              ]}
            >
              <ActivityIndicator />
            </Animated.View>
          </View>
        </Animated.View>
      </KeyboardAvoidingView>

      {/* Payment Button */}
      <Animated.View
        style={[
          paymentButtonAnimatedStyle,
          {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            borderTopWidth: 1,
            borderTopColor: 'rgba(0,0,0,0.1)',
            backgroundColor: 'white',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: -2,
            },
            shadowOpacity: 0.025,
          },
        ]}
      >
        <Button
          style={{
            borderRadius: Platform.select({
              ios: 7, // Matches Apple Pay button
              android: 24, // Matches Google Pay button
              web: 7,
            }),
            paddingVertical: Platform.select({
              ios: 9, // Matches Apple Pay button
              android: 7, // Matches Google Pay button
              web: 9,
            }),
            width: '100%',
          }}
          labelStyle={{
            fontSize: 16,
            lineHeight: 16,
            fontWeight: 'bold',
            color: 'white',
            top: 1,
          }}
          onPress={() => {
            const scheduledSlotRequired =
              isScheduled && scheduledSlot === undefined;
            if (scheduledSlotRequired) {
              setIsScheduledTimePickerVisible(true);
              return;
            }

            setIsPaymentSheetVisible(!isPaymentSheetVisible);

            // To be removed after apple pay and google pay is implemented
            setIsCreditCardFormVisible(true);
          }}
        >
          Pay now
        </Button>
      </Animated.View>

      <ScheduledTimePicker
        isVisible={isScheduledTimePickerVisible}
        onDismiss={() => {
          setIsScheduledTimePickerVisible(false);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  heading: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: FONT_SIZE.large,
    lineHeight: LINE_HEIGHT.large,
    marginTop: 0,
    paddingLeft: 2,
    marginBottom: 16,
  },
});
