import { trackEvent } from '../analytics';
import { triggerAlert } from './alert';
let isVisible = false;
export function alertNetworkError() {
  if (isVisible) {
    return;
  }

  isVisible = true;

  trackEvent('network_error_shown');
  triggerAlert(
    'Network Error',
    'Please check your internet connection and try again',
    [
      {
        text: 'OK',
        onPress: () => {
          isVisible = false;
        },
      },
    ],
  );
}
