import React, { forwardRef, Ref } from 'react';
import { View, Image, Platform } from 'react-native';
import {
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FONT_FAMILY } from '../../constants/fonts';
import { useDimensions } from '../../helpers/dimensions';
import { GOOGLE_MAPS_API_KEY, GOOGLE_MAP_PROXY_URL } from '../../../env';
import { trackEvent } from '../../analytics';

type LatLng = {
  latitude: number;
  longitude: number;
};

export const SearchInput = forwardRef(
  (
    {
      setIsOverlayShowing,
      onPress,
      initialLatLng,
      isBeneathOverlay,
    }: {
      setIsOverlayShowing: (value: boolean) => void;
      onPress: (
        data: GooglePlaceData,
        details: GooglePlaceDetail | null,
      ) => void;
      initialLatLng: LatLng | null;
      isBeneathOverlay: boolean;
    },
    ref: Ref<GooglePlacesAutocompleteRef>,
  ) => {
    //const searchInput = useRef<GooglePlacesAutocompleteRef>(null);
    const dimensions = useDimensions();
    const insets = useSafeAreaInsets();
    return (
      <View
        style={{
          backgroundColor: 'white',
          marginHorizontal: 20,
          borderRadius: 6,
          position: 'absolute',
          width: dimensions.width - 40,
          top: insets.top + 50,
          zIndex: isBeneathOverlay ? 0 : 10,
        }}
      >
        <Image
          style={{
            width: 30,
            height: 30,
            position: 'absolute',
            left: 12,
            top: 14,
          }}
          source={require('../../../assets/images/searchImage.png')}
        />
        <GooglePlacesAutocomplete
          requestUrl={{
            useOnPlatform: 'web', // or "all"
            url: GOOGLE_MAP_PROXY_URL, // or any proxy server that hits https://maps.googleapis.com/maps/api
            headers: {
              // Authorization: `an auth token`, // if required for your proxy
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET',
            },
          }}
          ref={ref}
          // currentLocation={true}
          placeholder="Enter your address"
          textInputProps={{
            onFocus: () => {
              if (Platform.OS !== 'web') {
                setIsOverlayShowing(true);
              }
              trackEvent('address_search_focused');
            },
            onBlur: () => {
              if (Platform.OS !== 'web') {
                setIsOverlayShowing(false);
              }
            },
            spellCheck: false,
            autoCorrect: false,
            clearTextOnFocus: true,
          }}
          styles={{
            container: {
              borderColor: 'rgba(0,0,0,0.5)',
              borderRadius: 4,
            },
            textInputContainer: {
              paddingLeft: 40,
              paddingTop: Platform.OS !== 'web' ? 4 : 8,
              paddingBottom: Platform.OS !== 'web' ? 4 : 0,
              paddingRight: 10,
            },
            textInput: {
              color: '#5d5d5d',
              fontSize: 17,
              fontFamily: FONT_FAMILY.REGULAR,
              margin: 0,
              top: Platform.select({
                // ????
                ios: 3,
                android: 1,
              }),
            },
            row: {},
            listView: {
              borderTopWidth: 1,
              borderColor: 'rgba(0,0,0,.1)',
            },
            description: {
              fontFamily: FONT_FAMILY.REGULAR,
              fontSize: 14,
              lineHeight: 24,
              //top: 2, // Font spacing is odd by default. This is a hack to fix it.
            },
            loader: {
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: 20,
              borderWidth: 1,
            },
          }}
          enableHighAccuracyLocation={true}
          fetchDetails={true}
          onPress={onPress}
          autoFillOnNotFound={true}
          query={{
            location: initialLatLng,
            key: GOOGLE_MAPS_API_KEY,
            language: 'en',
            components: 'country:nz',
            type: 'address',
          }}
        />
      </View>
    );
  },
);
