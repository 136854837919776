import { inferRouterOutputs } from '@trpc/server';
import { atom } from 'jotai';
import { isEqual } from 'lodash';
import { AppRouter } from 'teddy-next';

import { client } from '../api/trpc';
import { retryQuery } from '../utils/retryQuery';

export type Content = inferRouterOutputs<AppRouter>['content'][number];

export const contentAtom = atom<Content[]>([]);

const pollInterval = 90000;

let interval: ReturnType<typeof setInterval> | undefined = undefined;

export const startPollingContentAtom = atom([], async (get, set) => {
  const checkForUpdatedContent = async () => {
    const currentContent = get(contentAtom);
    const updatedContent = await retryQuery(
      () => client.contents.query(),
      'content query',
    );

    if (!isEqual(updatedContent, currentContent)) {
      set(contentAtom, updatedContent);
    }
  };

  await checkForUpdatedContent();

  clearInterval(interval);
  interval = setInterval(async () => {
    await checkForUpdatedContent();
  }, pollInterval);
});
