import React from 'react';
import { Image } from 'react-native';

interface ImageWrapperProps {
  fallback?: boolean;
  onLoad: () => void;
  style: any;
  source: any;
}

export const ImageWrapper = ({
  fallback,
  onLoad,
  style,
  source,
}: ImageWrapperProps) => {
  return <Image onLoad={onLoad} style={style} source={source} />;
};
