import { getAnalytics, logEvent } from 'firebase/analytics';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from '../env';

mixpanel.init(MIXPANEL_TOKEN, { debug: false });

export async function trackEvent(
  name: string,
  params?: Record<string, string | undefined | null | number | boolean>,
) {
  const analytics = getAnalytics();

  if (name === 'screen_view') {
    mixpanel.track(name, params);
    logEvent(analytics, name as string, params);
    return;
  }

  mixpanel.track(name, params);
  logEvent(analytics, name, params);
}

export async function timeEvent(name: string) {
  mixpanel.time_event(name);
}

export async function identify(id: string) {
  mixpanel.identify(id);
}
