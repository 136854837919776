import { atom, Setter, Getter } from 'jotai';
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'teddy-next';
import { isEqual } from 'lodash';

import { client } from '../api/trpc';
import { retryQuery } from '../utils/retryQuery';
import { timeEvent, trackEvent } from '../analytics';
import { inventoryRecordsAtom, inventoryRecordFamily } from './inventoryAtoms';

export const hubsAtom = atom(async () => {
  timeEvent('load_hubs');

  const hubs = await retryQuery(() => client.hubs.query(), 'hubs query');

  trackEvent('load_hubs');
  // Sort alphabetically
  return hubs.sort((a, b) => a.id.localeCompare(b.id));
});

export type Hub = inferRouterOutputs<AppRouter>['hubs'][number];
export type InventoryRecord =
  inferRouterOutputs<AppRouter>['inventory'][number];

let setHubInterval: ReturnType<typeof setInterval> | undefined = undefined;
const pollInterval = 1000 * 40;
export const hubAtom = atom<Hub | null>(null);
export const setHubAtom = atom(null, async (get, set, hub: Hub | null) => {
  setHub(hub, get, set);

  clearInterval(setHubInterval);
  setHubInterval = setInterval(async () => {
    timeEvent('update_hubs');
    const currentHub = get(hubAtom);
    const hubs = await retryQuery(() => client.hubs.query(), 'hubs query');
    trackEvent('update_hubs');
    const updatedHub = hubs.find((h) => h.id === currentHub?.id) || null;

    if (updatedHub && !isEqual(updatedHub, currentHub)) {
      setHub(updatedHub, get, set);
    } else if (currentHub) {
      loadInventory(currentHub, get, set);
    }
  }, pollInterval);
});

async function loadInventory(hub: Hub, get: Getter, set: Setter) {
  timeEvent('load_inventory');

  const inventoryRecords = await retryQuery(
    () =>
      client.inventory.query({
        hub_id: hub.id,
        exclude_unavailable: true,
      }),
    'inventory query',
  );

  trackEvent('load_inventory');
  set(inventoryRecordsAtom, inventoryRecords);

  inventoryRecords.forEach((rawInventoryRecord) => {
    const shopifyId = `gid://shopify/Product/${rawInventoryRecord.product_shopify_id}`;
    const inventoryRecordAtom = inventoryRecordFamily({ shopifyId });
    const inventoryRecord = get(inventoryRecordAtom);

    if (
      inventoryRecord !== undefined &&
      inventoryRecord?.count === rawInventoryRecord.count &&
      inventoryRecord?.status === rawInventoryRecord.status
    ) {
      return;
    }
    set(inventoryRecordAtom, rawInventoryRecord);
  });
}

async function setHub(hub: Hub | null, get: Getter, set: Setter) {
  set(hubAtom, hub);

  // Populate inventory when hub is selected
  if (hub === null) {
    set(inventoryRecordsAtom, []);
    return;
  }
  timeEvent('load_inventory');

  loadInventory(hub, get, set);
}

type DaysOpeningHours = {
  openHour: number;
  openMinutes: number;
  closeHour: number;
  closeMinutes: number;
};

type WeeksOpeningHours = {
  0: DaysOpeningHours;
  1: DaysOpeningHours;
  2: DaysOpeningHours;
  3: DaysOpeningHours;
  4: DaysOpeningHours;
  5: DaysOpeningHours;
  6: DaysOpeningHours;
};

/**
 * Parse a string like "7:30-21:00" into an object with hours and minutes
 * @param openHoursString
 */
const parseOpenHoursString = (openHoursString: string): DaysOpeningHours => {
  let [openingHourString, closeHourString] = openHoursString.split('-');

  // Default to 10-21 if no opening hours are set
  if (!openingHourString) {
    openingHourString = '10:00';
  }

  if (!closeHourString) {
    closeHourString = '21:00';
  }

  let [openingHour, openingMinutes] = openingHourString.split(':');
  let [closingHour, closingMinutes] = closeHourString.split(':');

  if (!openingHour) {
    openingHour = '10';
    console.warn('Invalid opening hours set', openHoursString);
  }
  if (!openingMinutes) {
    openingMinutes = '00';
    //console.warn('Invalid opening hours set', openHoursString);
  }

  if (!closingHour) {
    console.warn('Invalid closing hours set', closingHour);
    closingHour = '21';
  }
  // Matchs 00
  if (!closingMinutes) {
    closingMinutes = '00';
    //console.warn('Invalid opening hours set', openHoursString);
  }

  return {
    openHour: parseInt(openingHour, 10),
    openMinutes: parseInt(openingMinutes, 10),
    closeHour: parseInt(closingHour, 10),
    closeMinutes: parseInt(closingMinutes, 10),
  };
};

export const weeksOpeningHoursAtom = atom<WeeksOpeningHours | undefined>(
  (get) => {
    const hub = get(hubAtom);
    if (!hub) {
      return undefined;
    }

    return {
      0: parseOpenHoursString(hub.monday_open_hours),
      1: parseOpenHoursString(hub.tueday_open_hours), // Typo D:
      2: parseOpenHoursString(hub.wednesday_open_hours),
      3: parseOpenHoursString(hub.thursday_open_hours),
      4: parseOpenHoursString(hub.friday_open_hours),
      5: parseOpenHoursString(hub.saturday_open_hours),
      6: parseOpenHoursString(hub.sunday_open_hours),
    };
  },
);
