import React, { useState } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { addDays, addHours, format } from 'date-fns';

import { Text } from '../../../core-ui';
import { useDimensions } from '../../../helpers/dimensions';
import {
  scheduledSlotAtom,
  availableDatesAtom,
  AvailableDate,
} from '../../../hooks/scheduledTimeAtoms';

import { SimpleButton } from '../../../components/SimpleButton';
import { syncNoteWithShopifyAtom } from '../../../hooks/checkoutAtoms';
import { trackEvent } from '../../../analytics';
import { BottomSheetModal } from '../../../components/BottomSheetModal';
import { branding } from '../../../branding';

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
};
export function ScheduledTimePicker({ isVisible, onDismiss }: Props) {
  const availableDates = useAtomValue(availableDatesAtom);
  const insets = useSafeAreaInsets();

  const [dateInView, setDateInView] = useState(
    availableDates?.[0].date || new Date(),
  );
  const [scheduleSlot, setScheduledSlot] = useAtom(scheduledSlotAtom);
  const syncNote = useSetAtom(syncNoteWithShopifyAtom);
  const slots =
    availableDates.find(
      (date) =>
        format(date.date, 'yyyy-MM-dd') === format(dateInView, 'yyyy-MM-dd'),
    )?.slots || [];

  const { width } = useDimensions();

  return (
    <BottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
      <View
        style={{
          flex: 1,
          borderWidth: 0,
          paddingTop: 14,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          paddingBottom: insets.bottom,
        }}
      >
        <Text
          weight="bold"
          style={{ fontSize: 22, marginBottom: 30, textAlign: 'center' }}
        >
          Delivery Time
        </Text>
        <ScrollView
          style={{
            paddingLeft: 20,
            marginBottom: 0,
            overflow: 'visible',
            flexGrow: 0,
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          {availableDates.map((availableDate, i) => {
            const isSelected =
              format(availableDate.date, 'MMM dd') ===
              format(dateInView, 'MMM dd');
            return (
              <Day
                key={+availableDate.date + i}
                isSelected={isSelected}
                availableDate={availableDate}
                onPress={() => {
                  setDateInView(availableDate.date);
                }}
              />
            );
          })}
        </ScrollView>
        <Text
          weight="bold"
          style={{
            fontSize: 18,
            paddingHorizontal: 22,
            marginTop: 24,
            marginBottom: 12,
          }}
        >
          {format(dateInView, 'EEEE')}
        </Text>
        <ScrollView
          style={{
            width,
            paddingHorizontal: 20,
            //paddingTop: 20,
            paddingBottom: 30,
            //borderWidth: 1,
            //flexGrow: 0,
            flex: 1,
          }}
        >
          {slots?.map((slot, i) => {
            return (
              <TimeSlot
                slot={slot}
                isSelected={!!(scheduleSlot && +scheduleSlot === +slot)}
                onPress={() => {
                  setScheduledSlot(slot);
                  syncNote(); // Syncs expected time to Shopify

                  trackEvent('selected_scheduled_slot', {
                    slot: format(slot, 'yyyy-MM-dd HH:mm'),
                  });
                }}
                key={+slot + i}
              />
            );
          })}
          <View style={{ height: 30 }} />
        </ScrollView>
        <View
          style={{
            alignItems: 'center',
            paddingTop: 12,
            marginTop: 'auto',
          }}
        >
          <SimpleButton
            onPress={() => {
              onDismiss();
            }}
            width={260}
          >
            SCHEDULE
          </SimpleButton>
        </View>
        <View
          style={{
            alignItems: 'center',
            paddingTop: 12,
            marginTop: 'auto',
            marginBottom: 12,
          }}
        >
          <SimpleButton
            onPress={() => {
              onDismiss();
            }}
            bgColor={'#efefef'}
            color={'#333'}
            width={260}
          >
            Cancel
          </SimpleButton>
        </View>
      </View>
    </BottomSheetModal>
  );
}

const Day = ({
  availableDate,
  isSelected,
  onPress,
}: {
  availableDate: AvailableDate;
  isSelected: boolean;
  onPress: () => void;
}) => {
  const { date, openTime, closeTime } = availableDate;
  const isToday = format(date, 'MMM dd') === format(new Date(), 'MMM dd');
  const isTomorrow =
    format(date, 'MMM dd') === format(addDays(new Date(), 1), 'MMM dd');

  let title = format(date, 'EEEE');
  let subtitle = format(date, 'MMM dd');

  if (isToday) {
    title = 'Today';
    subtitle = format(date, 'EEE, MMM dd');
  }

  if (isTomorrow) {
    title = 'Tomorrow';
    subtitle = format(date, 'EEE, MMM dd');
  }

  //   const isSelected =
  //     scheduleTime && format(date, 'MMM dd') === format(scheduleTime, 'MMM dd');

  const { width } = useDimensions();

  return (
    <Pressable
      style={{
        height: 80,
        width: width / 2.5,
        padding: 10,
        borderWidth: 2,
        borderRadius: 8,
        marginRight: 6,
        borderColor: isSelected
          ? branding.selectionAccentColor
          : 'rgba(0,0,0,.1)',
        backgroundColor: isSelected ? 'white' : 'rgba(0,0,0,0.0)',
      }}
      onPress={onPress}
    >
      <Text
        style={{
          marginBottom: 3,
          lineHeight: 14,
          fontSize: 14,
        }}
        weight="bold"
      >
        {title}
      </Text>
      <Text
        style={{
          fontSize: 10,
          lineHeight: 10,
          marginBottom: 12,
          marginLeft: 1,
          marginTop: 1,
          opacity: 0.7,
        }}
      >
        {subtitle}
      </Text>
      <Text
        style={{ fontSize: 11, lineHeight: 11, marginTop: 0, marginBottom: 9 }}
      >
        {format(openTime, 'h:mmaaa')}–{format(closeTime, 'h:mmaaa')}
      </Text>
    </Pressable>
  );
};

const TimeSlot = ({
  slot,
  onPress,
  isSelected,
}: {
  slot: Date;
  onPress: () => void;
  isSelected: boolean;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        //height: 40,
        padding: 10,
        borderWidth: 2,
        width: '100%',
        marginBottom: 8,
        borderRadius: 6,
        borderColor: isSelected
          ? branding.selectionAccentColor
          : 'rgba(0,0,0,.1)',
        backgroundColor: isSelected ? 'white' : 'rgba(0,0,0,0.0)',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Text style={{ lineHeight: 14, fontSize: 14 }}>
        {format(slot, 'hh:mmaaa')} – {format(addHours(slot, 1), 'hh:mmaaa')}
      </Text>

      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          opacity: 0.4,
          borderWidth: 0,
        }}
      >
        available
      </Text>
      {/* <Text>Available</Text> */}
    </Pressable>
  );
};
