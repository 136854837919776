import React from 'react';
import { StyleSheet, View } from 'react-native';

import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { HORIZONTAL_GUTTER } from '../constants/theme';
import { Text } from '../core-ui';
import { PillButton } from './PillButton';
import { COLORS } from '../constants/colors';

type CollectionSectionHeaderProps = {
  title: string;
  onShowAllPress?: () => void;
  isDarkBackground?: boolean;
};
export const CollectionSectionHeader = ({
  title,
  onShowAllPress = undefined,
  isDarkBackground,
}: CollectionSectionHeaderProps): JSX.Element => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 10,
      }}
    >
      <View style={{ flex: 1, flexGrow: 1 }}>
        <Text
          style={{
            marginTop: 10,
            marginBottom: 6,
            paddingLeft: 2,
            fontFamily: FONT_FAMILY.BOLD,
            fontSize: FONT_SIZE.large,
            lineHeight: LINE_HEIGHT.large,
            color: isDarkBackground ? 'white' : 'black',
            opacity: 0.9,
            flex: 2,
            flexGrow: 1,
          }}
        >
          {title}
        </Text>
      </View>
      {onShowAllPress && (
        <View
          style={{
            marginLeft: 20,
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <PillButton onPress={onShowAllPress}>See all</PillButton>
        </View>
      )}
    </View>
  );
};

export const CollectionSectionHeaderLoaderPlaceholder = ({
  title,
}: CollectionSectionHeaderProps): JSX.Element => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        paddingHorizontal: 10,
      }}
    >
      <View
        style={{
          backgroundColor: COLORS.neutralGrey,
          borderRadius: 3,
          width: '65%',
        }}
      >
        <Text
          style={{
            marginTop: 10,
            marginBottom: 6,
            paddingLeft: 2,
            fontFamily: FONT_FAMILY.BOLD,
            fontSize: FONT_SIZE.large,
            lineHeight: LINE_HEIGHT.large,
            opacity: 0,
          }}
        >
          {title}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  subTitle: {
    marginTop: 10,
    marginBottom: 6,
    paddingLeft: 2,
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: FONT_SIZE.large,
    lineHeight: LINE_HEIGHT.large,
  },
});
