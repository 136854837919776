import { useEffect, useState } from 'react';
import { Dimensions, Platform, ScaledSize } from 'react-native';

type DeviceSize = {
  window: ScaledSize;
  screen: ScaledSize;
};

type Dimension = {
  width: number;
  height: number;
};

export enum ScreenSize {
  Small = 1,
  Medium = 2,
  Large = 3,
}

const MAX_SMALL = 380;
const MAX_MEDIUM = 800;

export function useDimensions() {
  const [dimensions, setDimensions] = useState<Dimension>(() => {
    const { width, height } = Dimensions.get('screen');

    if (Platform.OS === 'web' && width > 520) {
      return { width: 520, height };
    }

    return { width, height };
  });

  const { width, height } = dimensions;

  let screenSize: ScreenSize;

  if (width < MAX_SMALL) {
    screenSize = ScreenSize.Small;
  } else if (width < MAX_MEDIUM) {
    screenSize = ScreenSize.Medium;
  } else {
    screenSize = ScreenSize.Large;
  }

  useEffect(() => {
    const onChange = ({ screen }: DeviceSize) => {
      const { width, height } = screen;

      if (Platform.OS === 'web' && width > 520) {
        return { width: 520, height };
      }

      setDimensions({ width, height });
    };
    const subscription = Dimensions.addEventListener('change', onChange);

    return () => {
      subscription.remove();
    };
  }, []);

  return {
    width,
    height,
    screenSize,
    isShortDevice: height < 700,
    isLandscape: width > height,
  };
}
