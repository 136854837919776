import React from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { COLORS } from '../constants/colors';
import { FONT_FAMILY } from '../constants/fonts';

import Text from './Text';

type Props = {
  firstName: string;
  lastName: string;
  containerStyle?: StyleProp<ViewStyle>;
  size: number;
};

export default function Avatar(props: Props) {
  const { firstName, lastName, size, containerStyle } = props;

  const firstNameInitial = firstName ? firstName[0] : '';
  const lastNameInitial = lastName ? lastName[0] : '';

  const avatarInitials =
    firstNameInitial && lastNameInitial
      ? `${firstNameInitial.toUpperCase()}${lastNameInitial.toUpperCase()}`
      : 'XX';

  const avatarSize: StyleProp<ViewStyle> = {
    width: size,
    height: size,
    borderRadius: Math.round(size / 2),
  };

  const textSize: StyleProp<TextStyle> = {
    fontSize: Math.round(size / 2),
    lineHeight: Math.round(size / 2),
    top: Platform.select({
      ios: 2,
      android: 1,
    }),
  };

  return (
    <View style={[styles.avatar, containerStyle, avatarSize]}>
      <Text style={[styles.nameInitial, textSize]}>{`${avatarInitials}`}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  avatar: {
    backgroundColor: COLORS.primaryColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameInitial: {
    color: COLORS.white,
    fontFamily: FONT_FAMILY.REGULAR,
  },
});
