import { atom } from 'jotai';
import type { AppRouter } from 'teddy-next';
import type { inferRouterOutputs } from '@trpc/server';

import { client as trpcClient } from '../api/trpc';
import { timeEvent, trackEvent } from '../analytics';
import { retryQuery } from '../utils/retryQuery';

const TAGS = {
  FEATURED: 'featured',
};

const LISTING_PLACEMENTS = {
  HOME_TOP_SCREEN: 'top-home-screen',
  HOME_BOTTOM_SCREEN: 'bottom-home-screen',
  SEARCH_TOP_SCREEN: 'top-search-screen',
  SEARCH_BOTTOM_SCREEN: 'bottom-search-screen',
  CART_SCREEN: 'cart-screen',
};

/**
 * Collections
 */
export type Collection = inferRouterOutputs<AppRouter>['collections'][number];

const allCollectionsAtom = atom(async () => {
  timeEvent('load_collections');

  const collections = await retryQuery(
    () => trpcClient.collections.query(),
    'collections query',
  );

  trackEvent('load_collections', {
    count: collections.length,
  });

  return collections;
});

const categoriesCollectionsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections.filter((collection) => collection.type === 'category');
});

const occasionsCollectionsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections.filter((collection) => collection.type === 'occasion');
});

const featuredCategoriesCollectionsAtom = atom((get) => {
  const collections = get(categoriesCollectionsAtom);
  return collections.filter((collection) =>
    collection.tags.includes(TAGS.FEATURED),
  );
});

const featuredOccasionsCollectionsAtom = atom((get) => {
  const collections = get(occasionsCollectionsAtom);
  return collections.filter((collection) =>
    collection.tags.includes(TAGS.FEATURED),
  );
});

const homeTopScreenCollectionListingsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections
    .filter(
      (collection) =>
        collection.listingPlacement === LISTING_PLACEMENTS.HOME_TOP_SCREEN,
    )
    .sort((a, b) => {
      return a.listingPriority - b.listingPriority;
    });
});

const homeBottomScreenCollectionListingsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections
    .filter(
      (collection) =>
        collection.listingPlacement === LISTING_PLACEMENTS.HOME_BOTTOM_SCREEN,
    )
    .sort((a, b) => {
      return a.listingPriority - b.listingPriority;
    });
});

const searchTopCollectionListingsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections
    .filter(
      (collection) =>
        collection.listingPlacement === LISTING_PLACEMENTS.SEARCH_TOP_SCREEN,
    )
    .sort((a, b) => {
      return a.listingPriority - b.listingPriority;
    });
});

const searchBottomCollectionListingsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections
    .filter(
      (collection) =>
        collection.listingPlacement === LISTING_PLACEMENTS.SEARCH_BOTTOM_SCREEN,
    )
    .sort((a, b) => {
      return a.listingPriority - b.listingPriority;
    });
});

const cartScreenCollectionListingsAtom = atom((get) => {
  const collections = get(allCollectionsAtom);
  return collections
    .filter(
      (collection) =>
        collection.listingPlacement === LISTING_PLACEMENTS.CART_SCREEN,
    )
    .sort((a, b) => {
      return a.listingPriority - b.listingPriority;
    });
});

export {
  allCollectionsAtom,
  categoriesCollectionsAtom,
  occasionsCollectionsAtom,
  featuredCategoriesCollectionsAtom,
  featuredOccasionsCollectionsAtom,
  homeTopScreenCollectionListingsAtom,
  homeBottomScreenCollectionListingsAtom,
  searchTopCollectionListingsAtom,
  searchBottomCollectionListingsAtom,
  cartScreenCollectionListingsAtom,
};
