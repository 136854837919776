import { useAtomValue } from 'jotai';
import React from 'react';
import { View, Image, ViewStyle, Platform } from 'react-native';
import Animated from 'react-native-reanimated';
import { Text } from '../../core-ui';
import { useDimensions } from '../../helpers/dimensions';
import { isTurboDeliveryAvailableToAddressAtom } from '../../hooks/addressAtoms';
import { hubAtom } from '../../hooks/hubsAtoms';
import { branding } from '../../branding';

export function FixedMapMarker({
  mapMarkerAnimatedStyle,
  mapMarkerPopoverAnimatedStyle,
}: {
  mapMarkerAnimatedStyle?: Animated.AnimateStyle<ViewStyle>;
  mapMarkerPopoverAnimatedStyle?: Animated.AnimateStyle<ViewStyle>;
}) {
  const dimensions = useDimensions();
  const hub = useAtomValue(hubAtom);
  const isTurboDeliveryAvailableToAddress = useAtomValue(
    isTurboDeliveryAvailableToAddressAtom,
  );

  return (
    <View
      pointerEvents="none"
      style={{
        flex: 1,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        opacity: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Animated.View
          style={[
            mapMarkerPopoverAnimatedStyle || {},
            {
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              width: isTurboDeliveryAvailableToAddress ? 116 : 138,
              height: 48,
              top: -10,
            },
          ]}
        >
          <View
            style={{
              borderRadius: 5,
              backgroundColor: 'black',
              margin: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />

          <View style={{ position: 'absolute', bottom: -12 }}>
            <View
              style={{
                width: 12,
                height: 12,
                backgroundColor: 'black',
                top: -8,
                transform: [
                  {
                    rotate: '45deg',
                  },
                ],
              }}
            />
          </View>
          <View
            style={[
              { flexDirection: 'row', alignItems: 'center' },
              Platform.select({ web: { zIndex: 100 } }),
            ]}
          >
            {/** Lightning bolt */}
            <Image
              source={require('../../../assets/images/bolt.png')}
              style={{
                width: 20,
                height: 20,
                marginRight: 6,
                //borderWidth: 1,
                borderColor: 'white',
              }}
            />
            <View>
              <Text
                weight="bold"
                style={{
                  fontSize: 12,
                  lineHeight: 11,
                  marginBottom: 2,
                  color: 'white',
                }}
              >
                Delivering
              </Text>
              <Text
                style={{
                  fontSize: 11,
                  lineHeight: 11,
                  bottom: -1,
                  color: 'white',
                }}
              >
                {isTurboDeliveryAvailableToAddress
                  ? `in ${hub?.minimum_wait_time || '30'} mins`
                  : 'within 2 hours'}
              </Text>
            </View>
          </View>
        </Animated.View>
        {/* Dot */}
        <Animated.View
          style={[
            mapMarkerAnimatedStyle || {},
            {
              width: 24,
              height: 24,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              borderRadius: 15,
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: 'rgba(0, 0, 0, 0.2)',
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 1,
            },
          ]}
        >
          <View
            style={{
              width: 14,
              height: 14,
              backgroundColor: branding.mapMarkerColor,
              borderRadius: 15,
            }}
          />
        </Animated.View>
      </View>

      <View
        style={{
          display: 'none',
          position: 'absolute',
          top: dimensions.height * 0.5 + 40,
          //borderWidth: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,.1)',
          width: '100%',
          padding: 10,
        }}
      >
        <Image source={require('../../../assets/images/drag-hang.png')} />
        <Text style={{ color: 'white', borderWidth: 0 }}>
          POINTPOINT YOUR ADDRESS
        </Text>
      </View>
    </View>
  );
}
