import React, { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
import 'react-native-get-random-values';
import { useAtom } from 'jotai';

import { Text, TextInput } from '../../../core-ui';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../../../constants/fonts';
import { noteAtom } from '../../../hooks/checkoutAtoms';
import { trackEvent } from '../../../analytics';

export function NoteInput({
  onFocus,
  onBlur,
}: {
  onFocus: () => void;
  onBlur: () => void;
}) {
  const [note, setNote] = useAtom(noteAtom);

  return (
    <>
      <Text
        style={{
          fontFamily: FONT_FAMILY.BOLD,
          fontSize: FONT_SIZE.large,
          lineHeight: LINE_HEIGHT.large,
          marginTop: 16,
          marginBottom: 16,
          paddingLeft: 2,
        }}
      >
        Delivery instructions
      </Text>
      <TextInput
        onFocus={onFocus}
        onBlur={onBlur}
        multiline={true}
        numberOfLines={3}
        autoCapitalize="none"
        returnKeyType="done"
        value={note}
        onSubmitEditing={() => {
          Keyboard.dismiss();
        }}
        onChangeText={async (text) => {
          setNote(text.replace('\n', ''));
          trackEvent('checkout_note_updated', { text });
        }}
        keyboardType="default"
        returnKeyLabel="Done"
        containerStyle={{ height: 80 }}
        style={{
          height: 80,
          textAlignVertical: 'top',
          paddingTop: 20,
        }}
      />
    </>
  );
}
