import { Alert, Platform } from 'react-native';

export function triggerAlert(
  message: string,
  title?: string,
  buttons?: {
    text: string;
    style?: 'default' | 'destructive' | 'cancel';
    onPress?: () => void;
  }[],
) {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-alert
    alert(message);
  } else {
    Alert.alert(
      title || message,
      message || '',
      buttons || [
        {
          text: 'OK',
          onPress: () => {},
        },
      ],
    );
  }
}
