/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  View,
  Image,
  ScrollView,
  Pressable,
  KeyboardAvoidingView,
  Linking,
  StatusBar,
  Platform,
} from 'react-native';
import {
  GooglePlaceDetail,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MapView from 'react-native-maps';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
  withDelay,
} from 'react-native-reanimated';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
  findClosestDeliveryHub,
  getLatLngsFromHubGeoJsonProperties,
} from '../api/DeliveryZones';
import {
  AllScreensNavigationProp,
  AllScreensRouteProp,
} from '../navigation/types';
import { Text } from '../core-ui';
import { useDimensions } from '../helpers/dimensions';
import { MailingAddressInput } from '../generated/server/globalTypes';
import {
  addressAtom,
  addressLatLngAtom,
  setAddressAtom,
  setAddressLatLngAtom,
} from '../hooks/addressAtoms';
import { customerAtom, Customer } from '../hooks/customerAtoms';
import { Hub, hubAtom, hubsAtom } from '../hooks/hubsAtoms';
import { trackEvent } from '../analytics';
import { AddressConfirmationCard } from './AddressMapScene/AddressConfirmationCard';
import { OutOfZoneCard } from './AddressMapScene/OutOfZoneCard';
import { FixedMapMarker } from './AddressMapScene/FixedMapMarker';
import { DeliveryAreasLink } from './AddressMapScene/DeliveryAreasLink';
import { HubInformation } from './AddressMapScene/HubInformation';
import { SearchInput } from './AddressMapScene/SearchInput';
import mapStyles from './AddressMapScene/mapStyles.json';
import { GeoJsonFeaturesFromHubDeliveryZones } from './AddressMapScene/GeoJsonFeaturesFromHubDeliveryZones';

type ValidationStates =
  | 'EMPTY'
  | 'INZONE'
  | 'OUTSIDE'
  | 'CONFIRMING'
  | 'SAVING'
  | 'SAVED';

export function AddressEntryScene() {
  const { navigate } =
    useNavigation<AllScreensNavigationProp<'AddressEntry'>>();
  const { params } = useRoute<AllScreensRouteProp<'AddressEntry'>>();

  const flowType = params?.flowType;
  const dimensions = useDimensions();
  const checkoutAddress = useAtomValue(addressAtom);
  const setCheckoutAddress = useSetAtom(setAddressAtom);
  const [address, setAddress] = useState<MailingAddressInput | null>(null);

  const [customer] = useAtom(customerAtom);
  const hub = useAtomValue(hubAtom);
  const insets = useSafeAreaInsets();

  const searchInput = useRef<GooglePlacesAutocompleteRef>(null);
  const map = useRef<MapView>(null);
  const initialLatLng = {
    latitude: -36.86574691851174,
    longitude: 174.74991613716088,
  };

  const hubs = useAtomValue(hubsAtom);
  const [hubInView, setHubInView] = useState<Hub>(hub ?? hubs[0]);

  const addressLatLng = useAtomValue(addressLatLngAtom);
  const setAddressLatLng = useSetAtom(setAddressLatLngAtom);

  let initialState: ValidationStates = 'EMPTY';
  if (addressLatLng) {
    if (hub) {
      initialState = 'INZONE';
    } else {
      initialState = 'OUTSIDE';
    }
  }

  useFocusEffect(
    useCallback(() => {
      setValidationState(initialState);
      StatusBar.setBarStyle('light-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        // This bugs out when navigating back from the HomeScene
        //changeNavigationBarColor('transparent', false, false);
      }

      if (checkoutAddress !== null) {
        setAddress({
          firstName: '',
          lastName: '',
          address1: '',
          address2: '',
          city: '',
          province: '',
          zip: '',
          country: '',
          phone: '',
          ...(checkoutAddress ?? {}),
        });
      }
    }, [initialState]),
  );

  const [validationState, setValidationState] =
    useState<ValidationStates>(initialState);

  const [isOverlayShowing, setIsOverlayShowing] = useState(false);
  const overlayOpacity = useSharedValue(0);
  const overlayAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: overlayOpacity.value,
    };
  });

  const dismissTranslateY = dimensions.height;

  // (Manage animated here to centralise aniamtion logic)
  const addressConfirmationCardRef = useRef<Animated.View>(null);
  const addressConfirmationCardCompactHeight = 260;
  const addressConfirmationCardExpandedHeight = 370;
  const addressConfirmationCardHeight = useSharedValue(
    addressConfirmationCardCompactHeight,
  );
  const addressConfirmationCardTranslateY = useSharedValue(dismissTranslateY);
  const addressConfirmationCardAnimatedStyle = useAnimatedStyle(() => {
    return {
      height: addressConfirmationCardHeight.value,
      transform: [{ translateY: addressConfirmationCardTranslateY.value }],
    };
  });

  const outOfZoneTranslateY = useSharedValue(dismissTranslateY);
  const outOfZoneAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: outOfZoneTranslateY.value }],
    };
  });

  const deliveryZoneInformationTranslateY = useSharedValue(0);
  const deliveryZoneInformationAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: deliveryZoneInformationTranslateY.value }],
    };
  });

  const mapMarkerScale = useSharedValue(0);
  const mapMarkerAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ scale: mapMarkerScale.value }],
    };
  });
  const mapMarkerPopoverDismissedTranslateY = 22;
  const mapMarkerPopoverOpacity = useSharedValue(0);
  const mapMarkerPopoverTranslateY = useSharedValue(
    mapMarkerPopoverDismissedTranslateY,
  );
  const mapMarkerPopoverAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: mapMarkerPopoverOpacity.value,
      transform: [{ translateY: mapMarkerPopoverTranslateY.value }],
    };
  });

  useEffect(() => {
    switch (validationState) {
      case 'EMPTY':
        addressConfirmationCardTranslateY.value = withSpring(dismissTranslateY);
        outOfZoneTranslateY.value = withSpring(dismissTranslateY);
        deliveryZoneInformationTranslateY.value = withDelay(
          0,
          withSpring(0, {
            mass: 0.3,
          }),
        );
        mapMarkerScale.value = withTiming(0, { duration: 50 });
        mapMarkerPopoverOpacity.value = withTiming(0, { duration: 50 });
        mapMarkerPopoverTranslateY.value = withTiming(
          mapMarkerPopoverDismissedTranslateY,
          { duration: 50 },
        );
        zoomToDeliveryZone();
        setIsOverlayShowing(false);
        break;
      case 'OUTSIDE':
        outOfZoneTranslateY.value = withDelay(
          600,
          withSpring(0, {
            mass: 0.3,
          }),
        );

        addressConfirmationCardTranslateY.value = withSpring(dismissTranslateY);
        deliveryZoneInformationTranslateY.value = withSpring(dismissTranslateY);
        mapMarkerScale.value = withTiming(0, { duration: 50 });
        mapMarkerPopoverOpacity.value = withTiming(0, { duration: 50 });
        mapMarkerPopoverTranslateY.value = withTiming(
          mapMarkerPopoverDismissedTranslateY,
          { duration: 50 },
        );
        setTimeout(() => {
          setIsOverlayShowing(true);
        }, 100); // Avoid conflicting with blur
        break;
      case 'INZONE':
        addressConfirmationCardTranslateY.value = withDelay(
          800,
          withSpring(0, {
            mass: 0.3,
          }),
        );
        outOfZoneTranslateY.value = withSpring(dismissTranslateY);
        deliveryZoneInformationTranslateY.value = withSpring(dismissTranslateY);
        mapMarkerScale.value = withDelay(1200, withSpring(1, { mass: 1 }));
        mapMarkerPopoverOpacity.value = withDelay(2000, withTiming(1));
        mapMarkerPopoverTranslateY.value = withDelay(
          2000,
          withSpring(0, { mass: 0.7 }),
        );
        addressConfirmationCardHeight.value = withSpring(
          addressConfirmationCardCompactHeight,
          {
            mass: 0.3,
          },
        );
        setIsOverlayShowing(false);
        break;
      case 'CONFIRMING':
        addressConfirmationCardTranslateY.value = withSpring(-75, {
          mass: 0.3,
        });
        addressConfirmationCardHeight.value = withSpring(
          addressConfirmationCardExpandedHeight,
          {
            mass: 0.4,
          },
        );
        setIsOverlayShowing(true);
        break;
    }
  }, [validationState]);

  useEffect(() => {
    if (!addressLatLng) {
      setValidationState('EMPTY');
      return;
    }
    if (hub) {
      setValidationState('INZONE');
      trackEvent('address_entered_in_zone', {
        ...address,
        ...addressLatLng,
        flowType,
      });
      setIsOverlayShowing(false);
    } else {
      setValidationState('OUTSIDE');
      trackEvent('address_entered_out_of_zone', {
        ...address,
        ...addressLatLng,
        flowType,
      });
    }
  }, [hub?.id, addressLatLng]);

  useEffect(() => {
    if (isOverlayShowing) {
      overlayOpacity.value = withTiming(1);
    } else {
      overlayOpacity.value = withTiming(0);
    }
  }, [isOverlayShowing]);

  function handlePressBack() {
    navigate('TabNavigator');
  }
  const zoomOutOverNz = () => {
    const nzRegion = {
      latitude: -41.2864603,
      longitude: 174.776236,
      latitudeDelta: 20,
      longitudeDelta: 20,
    };

    map.current?.animateToRegion(nzRegion, 1000);
  };

  const reset = useCallback(() => {
    setValidationState('EMPTY');
    searchInput.current?.clear();
    searchInput.current?.blur();
    zoomOutOverNz();
  }, [searchInput.current, setValidationState, zoomOutOverNz]);

  const zoomToDeliveryZone = useCallback(
    (hub?: Hub) => {
      // const deliveryZoneToZoom =
      //   deliveryZone ?? getLatLngsFromHubGeoJsonProperties(hubInView);
      const aucklandRegion = {
        latitude: -36.848461,
        longitude: 174.763336,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };

      const christchurchRegion = {
        latitude: -43.532054,
        longitude: 172.636225,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };

      const queenstownRegion = {
        latitude: -45.031162,
        longitude: 168.662598,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };

      const wellingtonRegion = {
        latitude: -41.28646,
        longitude: 174.776236,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      };

      let selectedRegion = aucklandRegion;

      switch (hub?.id || hubInView?.id) {
        case 'Auckland CBD':
          selectedRegion = aucklandRegion;
          break;
        case 'Christchurch':
          selectedRegion = christchurchRegion;
          break;
        case 'Queenstown':
          selectedRegion = queenstownRegion;
          break;
        case 'Wellington':
          selectedRegion = wellingtonRegion;
          break;
        default:
          selectedRegion = aucklandRegion;
          break;
      }
      map.current?.animateToRegion(selectedRegion);
    },
    [map.current, hubInView?.id],
  );

  return (
    <KeyboardAvoidingView
      // Only avoid in "confirming" state (padding does nothing in other states)
      behavior={validationState === 'CONFIRMING' ? 'height' : 'padding'}
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      {/* Map view */}
      <MapView
        ref={map}
        //provider={PROVIDER_GOOGLE}
        userInterfaceStyle="dark"
        customMapStyle={mapStyles}
        toolbarEnabled={false}
        mapPadding={Platform.select({
          default: {
            top: 0,
            right: 0,
            bottom: -8,
            left: 5,
          },
          android: {
            top: 0,
            right: 0,
            bottom: 8,
            left: 5,
          },
        })}
        style={{
          flex: 1,
          backgroundColor: 'rgba(0,0,0,0)',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 'auto',
          opacity: 1,
        }}
        region={{
          latitude: initialLatLng.latitude,
          longitude: initialLatLng.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }}
        // onRegionChangeComplete={(e) => {
        //   if (validationState === 'INZONE' && addressLatLng) {
        //     const distanceBetweenMapCenterAndMarker = haversine(addressLatLng, {
        //       lat: e.latitude,
        //       lng: e.longitude,
        //     });
        //     if (distanceBetweenMapCenterAndMarker > 25) {
        //       map.current?.animateCamera({
        //         center: addressLatLng,
        //         zoom: 100,
        //         altitude: 450,
        //       });
        //     }
        //   }
        // }}
        zoomControlEnabled={false}
        camera={{
          center: addressLatLng ?? initialLatLng,
          pitch: 0,
          heading: 0,
          altitude: 450,
          zoom: 100,
        }}
        options={{
          disableDefaultUI: true,
        }}
      >
        {/* This marker will be accurate. Use to validate position of "Fixed marker", with is manually positioned*/}
        {/* <Marker coordinate={currentLatLng} /> */}

        <GeoJsonFeaturesFromHubDeliveryZones />
      </MapView>

      {/* Fixed marker */}
      <FixedMapMarker
        mapMarkerAnimatedStyle={mapMarkerAnimatedStyle}
        mapMarkerPopoverAnimatedStyle={mapMarkerPopoverAnimatedStyle}
      />

      {/* Back Button */}
      {flowType !== 'fromLanding' && checkoutAddress !== null ? (
        <Pressable
          onPress={() => {
            handlePressBack();
          }}
          style={{
            position: 'absolute',
            width: dimensions.width - 40,
            top: insets.top + 20,
            flexDirection: 'row',
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            style={{
              width: 13,
              height: 13,
              marginRight: 6,
              transform: [
                {
                  rotate: '0deg',
                },
              ],
            }}
            source={require('../../assets/images/chevron-white.png')}
          />
          <Text
            style={{
              lineHeight: 23,
              color: 'white',
            }}
          >
            Back
          </Text>
        </Pressable>
      ) : null}

      {/* Search */}
      <SearchInput
        isBeneathOverlay={
          validationState === 'OUTSIDE' ||
          validationState === 'CONFIRMING' ||
          validationState === 'SAVING' ||
          validationState === 'SAVED'
        }
        ref={searchInput}
        setIsOverlayShowing={setIsOverlayShowing}
        initialLatLng={addressLatLng}
        onPress={(_data, details = null) => {
          if (details) {
            const newLatLng = {
              longitude: details.geometry.location.lng,
              latitude: details.geometry.location.lat,
            };

            map.current?.animateCamera({
              center: newLatLng,
              zoom: 100,
              // altitude: 450,
            });

            const addressFormatted = transformGooglePlaceDetailToAddressItem(
              details,
              customer,
            );

            trackEvent('address_suggestion_pressed', {
              ...addressFormatted,
              ...newLatLng,
            });

            setAddress(addressFormatted);
            setAddressLatLng(newLatLng);

            const closestHub = findClosestDeliveryHub(hubs, newLatLng);
            setHubInView(closestHub);
          }
        }}
      />

      {/* Address confirmation */}
      {address ? (
        <AddressConfirmationCard
          address={address}
          ref={addressConfirmationCardRef}
          animatedStyle={addressConfirmationCardAnimatedStyle}
          onNextPress={() => {
            setValidationState('CONFIRMING');
          }}
          onConfirmPress={async (specificNumber) => {
            setValidationState('SAVING');

            setValidationState('SAVED');
            navigate('TabNavigator');

            const newAddress = {
              ...address,
              address1: `${specificNumber ? specificNumber + ' ' : ''}${
                address.address1
              }`,
            };

            setAddress(newAddress);
            setCheckoutAddress(newAddress);

            // const address1 = specificNumber + ' ' + address.address1;
            // let customerUserErrors;
            // let graphQLErrors;

            // if (hasCurrentAddress) {
            //   const editAddressResult = await editAddress({
            //     variables: {
            //       id: currentAddress?.id,
            //       customerAccessToken: authToken,
            //       address: {
            //         country: address.country,
            //         province: address.province,
            //         city: address.city,
            //         zip: address.zip,
            //         address1: address1,
            //         firstName: customerData?.customer?.firstName,
            //         lastName: customerData?.customer?.lastName,
            //         phone: customerData?.customer?.phone,
            //       },
            //     },
            //   });

            //   customerUserErrors =
            //     editAddressResult?.data?.customerAddressUpdate
            //       ?.customerUserErrors;

            //   graphQLErrors = editAddressResult?.errors;
            // } else {
            //   const addNewAddressResult = await addNewAddress({
            //     variables: {
            //       customerAccessToken: authToken,
            //       address: {
            //         country: address.country,
            //         province: address.province,
            //         city: address.city,
            //         zip: address.zip,
            //         address1: address1,
            //         firstName: customerData?.customer?.firstName,
            //         lastName: customerData?.customer?.lastName,
            //         phone: customerData?.customer?.phone,
            //       },
            //     },
            //   });

            //   customerUserErrors =
            //     addNewAddressResult?.data?.customerAddressCreate
            //       ?.customerUserErrors;

            //   graphQLErrors = addNewAddressResult?.errors;
            // }

            // if (customerUserErrors && customerUserErrors.length > 0) {
            //   Alert.alert(
            //     'Unable to save address',
            //     customerUserErrors[0].message,
            //   );
            // } else if (graphQLErrors && graphQLErrors.length > 0) {
            //   Alert.alert(
            //     'Unable to save address',
            //     'An unknown error occurred. Please check the values provided and try again.',
            //   );
            // }
          }}
          deliveryAreasLinkComponent={
            <DeliveryAreasLink
              onPress={() => {
                setValidationState('EMPTY');
              }}
            />
          }
          validationState={validationState}
        />
      ) : null}

      {/* Out of zone */}
      <OutOfZoneCard
        animatedStyle={outOfZoneAnimatedStyle}
        onClosePress={() => {
          setValidationState('EMPTY');
        }}
        onGetNotifiedPress={() => {
          setValidationState('EMPTY');
          trackEvent('get_notified_button_press');
          Linking.openURL('https://teddy.nz?#email-form');
        }}
        deliveryAreasLinkComponent={
          <DeliveryAreasLink
            onPress={() => {
              setValidationState('EMPTY');
            }}
          />
        }
      />
      {/* Delivery zone information */}
      <Animated.View
        style={[
          deliveryZoneInformationAnimatedStyle,
          {
            backgroundColor: 'white',
            marginHorizontal: 10,
            paddingBottom: 14,
            borderRadius: 6,
            position: 'absolute',
            width: dimensions.width - 20,
            bottom:
              insets.bottom + Platform.select({ android: 50, default: 40 }), //  Fix for Android transparent nav being weird
            zIndex: isOverlayShowing ? 0 : 10,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            opacity: 0.5,
            padding: 14,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          }}
          onTouchStart={() => {
            reset();
          }}
        >
          <Image
            source={require('../../assets/images/info.png')}
            style={{ width: 16, height: 16 }}
          />
          <Text
            style={{
              marginLeft: 4,
            }}
          >
            Delivery areas
          </Text>
        </View>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal
          pagingEnabled
          snapToInterval={150}
        >
          {hubs.map((hub, id) => (
            <HubInformation
              key={id}
              isFirst={id === 0}
              hub={hub}
              map={map.current}
              isSelected={hub.id === hubInView?.id}
              onPress={() => {
                const hubDeliveryLatLng =
                  getLatLngsFromHubGeoJsonProperties(hub);

                if (hubDeliveryLatLng.length === 0) {
                  // zoomToDeliveryZone([
                  //   {
                  //     latitude: -36.82457465542726,
                  //     longitude: 179.15039003106352,
                  //   },
                  //   {
                  //     latitude: -46.51710765973783,
                  //     longitude: 165.4111561102097,
                  //   },
                  // ]); // Pass variable to avoid state lag
                  zoomOutOverNz();
                  return;
                }

                setHubInView(hub);

                zoomToDeliveryZone(hub); // Pass variable to avoid state lag
              }}
            />
          ))}
        </ScrollView>
        <View
          style={{
            marginTop: 14,
            paddingVertical: 4,
            paddingHorizontal: 14,
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Pressable
            onPress={() => {
              searchInput.current?.clear();
              searchInput.current?.focus();
              trackEvent('pressed_find_your_address_button');
            }}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 16,
              backgroundColor: 'rgba(0,0,0,1)',
              borderRadius: 6,
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Image
              source={require('../../assets/images/searchImage.png')}
              style={{ width: 22, height: 22, marginRight: 10 }}
            />
            <Text style={{ color: 'white', fontSize: 16, lineHeight: 16 }}>
              Find your address
            </Text>
          </Pressable>
        </View>
      </Animated.View>

      {/* Overlay */}
      <Animated.View
        pointerEvents="none"
        style={[
          overlayAnimatedStyle,
          {
            flex: 1,
            backgroundColor: 'rgba(0,0,0,.85)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            zIndex: 5,
          },
        ]}
      />
    </KeyboardAvoidingView>
  );
}

function transformGooglePlaceDetailToAddressItem(
  details: GooglePlaceDetail,
  customer: Customer | null,
): MailingAddressInput {
  const { formatted_address } = details;
  const formatted_address_without_country = formatted_address.replace(
    ', New Zealand',
    '',
  );
  const formatted_address_without_country_and_zip =
    formatted_address_without_country.split(',').slice(0, -1).join(',');

  const address1 = formatted_address_without_country_and_zip
    .split(',')
    .slice(0, 2)
    .join(',');
  const address2 = formatted_address_without_country_and_zip
    .split(',')
    .slice(2)
    .join(',');
  return {
    //id: '',
    firstName: customer?.firstName || 'Teddy', // Placeholder
    lastName: customer?.lastName || 'Teddy', // Placeholder
    address1,
    address2,
    city:
      details.address_components.find(
        (component) => component.types[0] === 'locality',
      )?.long_name || '',
    province:
      details.address_components.find(
        (component) => component.types[0] === 'administrative_area_level_1',
      )?.long_name || '',
    zip:
      details.address_components.find(
        (component) => component.types[0] === 'postal_code',
      )?.long_name || '',
    country: 'New Zealand',
    phone: '',
  };
}
