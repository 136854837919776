import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { COLORS } from '../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import { PaymentDetailsProps } from '../types/types';

type Props = {
  data: Array<PaymentDetailsProps>;
  containerStyle?: StyleProp<ViewStyle>;
};

export default function PaymentDetails(props: Props) {
  const { data } = props;

  return (
    <View style={styles.container}>
      {data.map((item, index) => {
        const { name, value } = item;
        if (data.length - 1 === index) {
          return (
            <View
              style={[styles.innerPaymentDetailsContainer, styles.border]}
              key={name}
            >
              <Text style={styles.total}>{name}</Text>
              <Text weight="medium" style={styles.total}>
                {value}
              </Text>
            </View>
          );
        } else {
          return (
            <View style={styles.innerPaymentDetailsContainer} key={name}>
              <Text style={[styles.mediumText, styles.marginBottom]}>
                {name}
              </Text>
              <Text style={styles.mediumText}>{value}</Text>
            </View>
          );
        }
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 18,
  },
  innerPaymentDetailsContainer: {
    paddingVertical: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mediumText: {
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
    fontFamily: FONT_FAMILY.REGULAR,
  },
  total: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: FONT_SIZE.large,
  },
  border: {
    borderTopWidth: 1,
    paddingVertical: 16,
    borderTopColor: COLORS.lightGrey,
  },
  marginBottom: {
    marginBottom: 6,
  },
});
