import { useAtomValue, useSetAtom } from 'jotai';
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'teddy-next';
import React, { Fragment } from 'react';
import { Image, Pressable, View } from 'react-native';
import { range } from 'lodash';
import { Text } from '../core-ui';
import {
  areRecommendationsLoadingAtom,
  basketRecommendationsAtom,
  dismissAndRefreshRecommendationsAtom,
} from '../hooks/basketRecommendationsAtoms';
import { customerAtom } from '../hooks/customerAtoms';
import { trackEvent } from '../analytics';
import { branding } from '../branding';
import {
  ProductHorizontalScroller,
  ProductHorizontalScrollerLoaderPlaceholder,
} from './CollectionHorizontalScroller';
import {
  CollectionSectionHeader,
  CollectionSectionHeaderLoaderPlaceholder,
} from './CollectionSectionHeader';

export type Recommendations =
  inferRouterOutputs<AppRouter>['basketRecommendations'];

export const BasketRecommendations = ({
  placement,
  useProductQueueSync, // determines whether to sync product with Shopify queue. In most case, it should be turned off to prevent race condition. Turn it on at ShoppingCartScene
}: {
  placement: string;
  useProductQueueSync?: boolean;
}) => {
  const recommendations = useAtomValue(basketRecommendationsAtom);
  const areRecommendationsLoading = useAtomValue(areRecommendationsLoadingAtom);
  const dismissAndRefreshRecommendations = useSetAtom(
    dismissAndRefreshRecommendationsAtom,
  );
  const customer = useAtomValue(customerAtom);
  const showDebugSearchTerms =
    customer && customer?.email.indexOf('teddy.nz') !== -1;

  // Disable for non-teddy
  // if (branding.name !== 'teddy') {
  //   return null;
  // }

  if (recommendations.length === 0 && !areRecommendationsLoading) {
    return null;
  }

  return (
    <View>
      {/* Border */}
      <View
        style={{
          width: '100%',
          height: 1,
          backgroundColor: '#000',
          opacity: 0.035,
          marginTop: 30,
          borderWidth: 1,
        }}
      />

      <Pressable
        onPress={() => {
          dismissAndRefreshRecommendations();
          trackEvent('refresh_recommendations');
        }}
        style={{
          flexDirection: 'row',
          //borderWidth: 1,

          alignItems: 'center',
          paddingHorizontal: 20,
          marginTop: 40,
          marginBottom: 20,
        }}
      >
        <Image
          source={require('../../assets/images/ai-stars.png')}
          resizeMode="contain"
          style={{
            height: 26,
            width: 26,
            //borderWidth: 1,
          }}
        />
        <Text
          weight="bold"
          style={{
            //textAlign: 'center',
            fontSize: 20,
            lineHeight: 22,
            marginLeft: 10,
            //opacity: 0.3,
          }}
        >
          Need suggestions?
        </Text>
        <Image
          source={require('../../assets/images/refresh.png')}
          resizeMode="contain"
          style={{
            height: 26,
            width: 26,
            //borderWidth: 1,
            marginLeft: 'auto',
            opacity: 0.3,
          }}
        />
      </Pressable>
      {!areRecommendationsLoading &&
        recommendations.map((recommendation, i) => {
          return (
            <Fragment key={recommendation.title + i}>
              <View style={{ paddingLeft: 10, marginTop: 16 }}>
                <CollectionSectionHeader title={recommendation.title} />
              </View>

              <ProductHorizontalScroller
                key={i}
                collectionTitle={`[AI] ${recommendation.title}`}
                placement={placement}
                listingNumberVisible={3.8}
                products={recommendation.products}
                productsQueueSync={useProductQueueSync}
              />

              {showDebugSearchTerms && (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginVertical: 8,
                    backgroundColor: 'rgba(76, 37, 225, .15)',
                    padding: 9,
                    paddingHorizontal: 10,
                    borderRadius: 6,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../assets/images/ai-stars.png')}
                    resizeMode="contain"
                    style={{
                      height: 15,
                      width: 15,
                      marginRight: 10,
                      //borderWidth: 1,
                    }}
                  />
                  <Text
                    numberOfLines={3}
                    style={{
                      fontSize: 10,
                      lineHeight: 12,
                      color: 'rgba(76, 37, 225, 1)',
                      flexShrink: 1,
                    }}
                  >
                    {recommendation.searchTerms.join(', ')}
                  </Text>
                </View>
              )}
            </Fragment>
          );
        })}

      {areRecommendationsLoading && (
        <>
          {range(0, 5).map((i) => {
            return (
              <Fragment key={i}>
                <View style={{ paddingLeft: 10, marginTop: 16 }}>
                  <CollectionSectionHeaderLoaderPlaceholder title={'Loading'} />
                </View>

                <ProductHorizontalScrollerLoaderPlaceholder
                  listingNumberVisible={3.8}
                />
              </Fragment>
            );
          })}
        </>
      )}
    </View>
  );
};

//ProductHorizontalScrollerLoaderPlaceholder
