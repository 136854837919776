import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { Suspense } from 'react';
import { Pressable, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Text } from '../core-ui';
import { useShopifyAuth } from '../helpers/useShopifyAuth';
import {
  associateCustomerWithShopifyCheckoutAtom,
  associateShippingAddressWithShopifyCheckoutAtom,
  availableShippingRatesAtom,
  checkoutIdAtom,
  hasAssociatedAddressWithShopifyCheckoutAtom,
  hasAssociatedCustomerWithShopifyCheckoutAtom,
  isCheckoutDebugEnabledAtom,
  lineItemsVariantIdsInCartAtom,
  shippingRateAtom,
} from '../hooks/checkoutAtoms';
import {
  addressAtom,
  addressLatLngAtom,
  setAddressAtom,
  setAddressLatLngAtom,
} from '../hooks/addressAtoms';
import { hubAtom } from '../hooks/hubsAtoms';
import { inventoryRecordsAtom } from '../hooks/inventoryAtoms';

import {
  customerAccessTokenAtom,
  customerAtom,
  hasCustomerProvidedPersonalDetailsAtom,
  notificationPermissionsDeniedTimestampAtom,
} from '../hooks/customerAtoms';
import { SimpleButton } from './SimpleButton';

export const StateDebug = () => {
  const [showDebugState, setShowDebugState] = useAtom(
    isCheckoutDebugEnabledAtom,
  );

  const hub = useAtomValue(hubAtom);
  const inventory = useAtomValue(inventoryRecordsAtom);
  const customer = useAtomValue(customerAtom);
  const hasCustomerProvidedPersonalDetails = useAtomValue(
    hasCustomerProvidedPersonalDetailsAtom,
  );

  const checkoutId = useAtomValue(checkoutIdAtom);
  const shippingRate = useAtomValue(shippingRateAtom);
  const availableShippingRates = useAtomValue(availableShippingRatesAtom);
  const customerAccessToken = useAtomValue(customerAccessTokenAtom);
  const notificationPermissionsDeniedTimestamp = useAtomValue(
    notificationPermissionsDeniedTimestampAtom,
  );
  const address = useAtomValue(addressAtom);
  const setAddress = useSetAtom(setAddressAtom);
  const addressLatLng = useAtomValue(addressLatLngAtom);
  const setAddressLatLng = useSetAtom(setAddressLatLngAtom);
  const setNotificationPermissionsDeniedTimestamp = useSetAtom(
    notificationPermissionsDeniedTimestampAtom,
  );

  const lineItemsVariantIds = useAtomValue(lineItemsVariantIdsInCartAtom);
  const hasAssociatedAddressWithShopifyCheckout = useAtomValue(
    hasAssociatedAddressWithShopifyCheckoutAtom,
  );
  const hasAssociatedCustomerWithShopifyCheckout = useAtomValue(
    hasAssociatedCustomerWithShopifyCheckoutAtom,
  );
  const associateCustomerWithShopifyCheckout = useSetAtom(
    associateCustomerWithShopifyCheckoutAtom,
  );
  const associateShippingAddressWithShopifyCheckout = useSetAtom(
    associateShippingAddressWithShopifyCheckoutAtom,
  );

  const { logOut } = useShopifyAuth();

  const reset = () => {
    associateCustomerWithShopifyCheckout(false);
    associateShippingAddressWithShopifyCheckout(false);
    setAddressLatLng(null);
    setAddress(null);
    logOut();
  };

  if (!showDebugState) {
    return null;
  }

  return (
    <Suspense fallback={<View />}>
      <ScrollView style={{ height: 180 }}>
        <Pressable
          onLongPress={() => {
            setShowDebugState(false);
          }}
          style={{
            borderWidth: 0,
            paddingHorizontal: 10,
            paddingVertical: 20,
            backgroundColor: 'rgba(0,0,0,.1)',
          }}
        >
          <Text weight="bold" style={{ fontSize: 20 }}>
            Debug
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            Hub
          </Text>
          <Text style={{ fontSize: 9 }}>{hub?.id}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            Address LatLng
          </Text>
          <Text style={{ fontSize: 9 }}>{JSON.stringify(addressLatLng)}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            Inventory count
          </Text>
          <Text style={{ fontSize: 9 }}>{inventory.length}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            Customer:
          </Text>
          <Text style={{ fontSize: 9 }}>
            {JSON.stringify(customer, null, 2)}
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            hasCustomerProvidedPersonalDetails
          </Text>
          <Text style={{ fontSize: 9 }}>
            {hasCustomerProvidedPersonalDetails === null
              ? 'null'
              : hasCustomerProvidedPersonalDetails
              ? 'yes'
              : 'no'}
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            customerAccessToken:
          </Text>
          <Text style={{ fontSize: 9 }}>{customerAccessToken}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            checkoutId:
          </Text>
          <Text style={{ fontSize: 9 }}>{checkoutId}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            lineItem Count:
          </Text>
          <Text style={{ fontSize: 9 }}>{lineItemsVariantIds.length}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            address
          </Text>
          <Text style={{ fontSize: 9 }}>
            {JSON.stringify(address, null, 2)}
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            available shipping rates
          </Text>
          <Text>{JSON.stringify(availableShippingRates, null, 2)}</Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            shipping rate
          </Text>
          <Text style={{ fontSize: 9 }}>
            {JSON.stringify(shippingRate, null, 2)}
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            hasAssociatedAddressWithShopifyCheckout
          </Text>
          <Text style={{ fontSize: 9 }}>
            {hasAssociatedAddressWithShopifyCheckout ? 'yes' : 'no'}
          </Text>
          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            hasAssociatedCustomerWithShopifyCheckout
          </Text>
          <Text style={{ fontSize: 9 }}>
            {hasAssociatedCustomerWithShopifyCheckout ? 'yes' : 'no'}
          </Text>

          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            notificationPermissionsDeniedTimestamp
          </Text>
          <Text style={{ fontSize: 9 }}>
            {notificationPermissionsDeniedTimestamp}
          </Text>

          <Text weight="bold" style={{ marginTop: 10, fontSize: 10 }}>
            hermes
          </Text>
          <Text style={{ fontSize: 9 }}>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              !!global?.HermesInternal ? 'Yes' : 'No'
            }
          </Text>

          <View style={{ marginTop: 20 }}>
            <SimpleButton
              onPress={() => {
                logOut();
              }}
            >
              Logout
            </SimpleButton>
          </View>

          <View style={{ marginTop: 20 }}>
            <SimpleButton
              onPress={() => {
                reset();
              }}
            >
              Reset
            </SimpleButton>
          </View>

          <Pressable
            style={{
              padding: 6,
              backgroundColor: 'white',
              margin: 4,
              borderRadius: 4,
            }}
            onPress={() => {
              setNotificationPermissionsDeniedTimestamp(null);
            }}
          >
            <Text>Reset notification</Text>
          </Pressable>

          <Pressable
            style={{
              padding: 6,
              backgroundColor: 'white',
              margin: 4,
              borderRadius: 4,
            }}
            onPress={() => {
              associateShippingAddressWithShopifyCheckout();
            }}
          >
            <Text>UpdateAddress</Text>
          </Pressable>

          <Pressable
            style={{
              padding: 6,
              backgroundColor: 'white',
              margin: 4,
              borderRadius: 4,
            }}
            onPress={() => {
              if (addressLatLng) {
                setAddressLatLng(null);
              } else {
                setAddressLatLng({
                  longitude: 174.76666132,
                  latitude: -36.8446678,
                });
              }
            }}
          >
            <Text>AddressLatLng </Text>
          </Pressable>
        </Pressable>
      </ScrollView>
    </Suspense>
  );
};
