import color from 'color';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { ScrollView } from 'react-native-gesture-handler';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { View, StatusBar, Image, Pressable, Platform } from 'react-native';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';

import { includes } from 'lodash';

import {
  AllScreensNavigationProp,
  AllScreensRouteProp,
} from '../../navigation/types';
import { CollectionHorizontalScroller } from '../../components/CollectionHorizontalScroller';
import ProductItem from '../../components/ProductItem';
import { allCollectionsAtom } from '../../hooks/collectionsAtoms';
import { availableProductsAtom, Product } from '../../hooks/productsAtoms';
import { BasketRecommendations } from '../../components/BasketRecommendations';
import {
  addCategoryBrowsedAtom,
  refreshRecommendationsAtom,
} from '../../hooks/basketRecommendationsAtoms';
import { FlatListWithHeader } from '../../components/ScrollViewWithHeader';
import {
  collectionActiveFiltersAtomFamily,
  CollectionFilter,
  collectionWithFilterAtomFamily,
  resetCollectionFiltersAtomFamily,
} from '../../hooks/collectionWithFilterAtom';
import { Text } from '../../core-ui';
import { branding } from '../../branding';
import { CollectionFilterBottomSheet } from '../../components/CollectionFilterBottomSheet';
import { trackEvent } from '../../analytics';
import { MediaHeader } from './components/MediaHeader';

export default function ProductCollectionScene() {
  const { params } =
    useRoute<
      AllScreensRouteProp<
        | 'ProductCollection'
        | 'ProductCollectionLevel2'
        | 'ProductCollectionLevel3'
      >
    >();
  const { collectionHandle, navigationLevel } = params;
  const refreshRecommendations = useSetAtom(refreshRecommendationsAtom);
  const addCategoryBrowsed = useSetAtom(addCategoryBrowsedAtom);

  const collections = useAtomValue(allCollectionsAtom);
  const collection = collections.find(
    (collection) => collection.handle === collectionHandle,
  );
  const navigate =
    useNavigation<
      AllScreensNavigationProp<
        | 'ProductCollection'
        | 'ProductCollectionLevel2'
        | 'ProductCollectionLevel3'
      >
    >();

  let backgroundColor = '#fff';

  switch (collectionHandle) {
    case 'bacardi':
      backgroundColor = '#fffbf3';
      break;
    case 'gin-riblet':
    //backgroundColor = 'rgb(12, 12, 12)';
    default:
      break;
  }

  const isDarkBackground = color(backgroundColor).isDark();

  // Add delay to rendering to improve page transition performance on Android
  const [isLoaded, setIsLoaded] = useState(false);

  // Reset filter on first page show
  const reset = useSetAtom(resetCollectionFiltersAtomFamily(collectionHandle));
  useFocusEffect(
    useCallback(() => {
      if (!isLoaded) {
        return;
      }

      reset();
    }, [isLoaded]),
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 0);
  }, []);

  useEffect(() => {
    StatusBar.setBarStyle(isDarkBackground ? 'light-content' : 'dark-content');
  }, [isDarkBackground]);

  useFocusEffect(
    useCallback(() => {
      if (collection?.title) {
        addCategoryBrowsed(collection?.title);
      }

      refreshRecommendations();
    }, [collectionHandle]),
  );

  if (!collection) {
    return null;
  }

  const subCategories = collection.subcategories
    .map((id) => {
      return collections.find((col) => col.id === id);
    })
    .filter((collection) => collection !== undefined) as (typeof collection)[];

  if (!collection) {
    return null;
  }

  if (!isLoaded) {
    return null;
  }

  return subCategories.length > 1 ? (
    <ProductsSubcategoryFlatList
      collectionHandle={collectionHandle}
      backgroundColor={backgroundColor}
      isDarkBackground={isDarkBackground}
      goBack={() => {
        const index = navigate.getState().index;

        if (index === 0) {
          navigate.navigate('Home');
        } else {
          navigate.goBack();
        }
      }}
      navigationLevel={navigationLevel}
    />
  ) : (
    <ProductsFlatList
      collectionHandle={collectionHandle}
      backgroundColor={backgroundColor}
      isDarkBackground={isDarkBackground}
      goBack={() => {
        const index = navigate.getState().index;

        if (index === 0) {
          navigate.navigate('Home');
        } else {
          navigate.goBack();
        }
      }}
    />
  );
}

const ProductsSubcategoryFlatList = memo(
  ({
    collectionHandle,
    backgroundColor,
    isDarkBackground,
    goBack,
    navigationLevel,
  }: {
    collectionHandle: string;
    backgroundColor: string;
    isDarkBackground: boolean;
    goBack: () => void;
    navigationLevel: number;
  }) => {
    const { navigate } =
      useNavigation<AllScreensNavigationProp<'HomeTab' | 'SearchTab'>>();
    const collections = useAtomValue(allCollectionsAtom);
    const collection = collections.find(
      (collection) => collection.handle === collectionHandle,
    );
    const [isFilterBottomSheetVisible, setIsFilterBottomSheetVisible] =
      useState(false);

    if (!collection) {
      return null;
    }

    const subCategories = collection.subcategories
      .map((id) => {
        return collections.find((col) => col.id === id);
      })
      .filter(
        (collection) => collection !== undefined,
      ) as (typeof collection)[];

    return (
      <FlatListWithHeader
        isDarkBackground={isDarkBackground}
        onBackButtonPress={goBack}
        headerText={collection.title}
        rightHamburgerMenuActions={[]}
        data={subCategories}
        horizontal={false}
        keyExtractor={(item) => item?.id || item?.handle || item?.title || ''}
        numColumns={1}
        style={{
          backgroundColor,
          flex: 1,
        }}
        renderItem={({ item: subCategory }) => {
          if (!subCategory) {
            return null;
          }

          return (
            <View key={subCategory.id} style={{ marginTop: 0 }}>
              <CollectionHorizontalScroller
                parentCollection={collection}
                collection={subCategory}
                isDarkBackground={isDarkBackground}
                placement="collection-subcategory-screen"
                onShowAllPress={(() => {
                  switch (navigationLevel) {
                    case 1:
                      return () => {
                        navigate('ProductCollectionLevel2', {
                          collectionHandle: subCategory.handle,
                          navigationLevel: 2,
                        });
                      };
                    case 2:
                      return () => {
                        navigate('ProductCollectionLevel3', {
                          collectionHandle: subCategory.handle,
                          navigationLevel: 3,
                        });
                      };
                    default:
                      return undefined; // Do not let navigation happen beyond 3 levels
                  }
                })()}
              />
            </View>
          );
        }}
        ListHeaderComponent={
          <>
            <MediaHeader collectionTitle={collection.title} />
            <FilterControl
              collectionHandle={collection.handle}
              quickFilters={collection.quick_filters || []}
              openFilterBottomSheet={() => {
                trackEvent('show_filter', {
                  collectionHandle: collection.handle,
                });
                setIsFilterBottomSheetVisible(true);
              }}
            />
          </>
        }
        ListFooterComponent={
          <>
            <BasketRecommendations placement="collection-screen" />
            <CollectionFilterBottomSheet
              collectionHandle={collection.handle}
              isVisible={isFilterBottomSheetVisible}
              onDismiss={() => {
                setIsFilterBottomSheetVisible(false);
              }}
            />
            <View style={{ height: 40 }} />
          </>
        }
        showsVerticalScrollIndicator={true}
      />
    );
  },
);
const ProductsFlatList = memo(
  ({
    collectionHandle,
    backgroundColor,
    isDarkBackground,
    goBack,
  }: {
    collectionHandle: string;
    backgroundColor: string;
    isDarkBackground: boolean;
    goBack: () => void;
  }) => {
    const products = useAtomValue(availableProductsAtom);
    const { activeFilters, availableProducts } = useAtomValue(
      collectionActiveFiltersAtomFamily(collectionHandle),
    );
    const collections = useAtomValue(allCollectionsAtom);
    const collection = collections.find(
      (collection) => collection.handle === collectionHandle,
    );
    const [isFilterBottomSheetVisible, setIsFilterBottomSheetVisible] =
      useState(false);
    if (!collection) {
      return null;
    }
    const productsInCollection = collection.products.nodes
      .filter((product) => {
        if (activeFilters.length === 0) {
          //console.log('No active filters', collection.title);
          return true;
        }
        const notFiltered = availableProducts.some(
          (availableProduct) => availableProduct.id === product.id,
        );
        return notFiltered;
      })
      .map((product) => {
        const productInCollection = products.find(
          (productInProducts) => productInProducts.id === product.id,
        );
        return productInCollection;
      })
      .filter((product) => product) as Product[];

    return (
      <FlatListWithHeader
        isDarkBackground={isDarkBackground}
        onBackButtonPress={goBack}
        headerText={collection.title}
        rightHamburgerMenuActions={[]}
        data={productsInCollection}
        horizontal={false}
        keyExtractor={(item) => item?.id || item?.handle || item?.title || ''}
        numColumns={3}
        columnWrapperStyle={{
          paddingHorizontal: 12,
        }}
        style={{
          backgroundColor,
        }}
        renderItem={({ item }) => {
          const productItem = (
            <ProductItem
              numColumns={3}
              product={item}
              collectionTitle={collection.title}
              placement="collection-screen"
              isDarkBackground={isDarkBackground}
            />
          );
          return productItem;
        }}
        ListHeaderComponent={
          <>
            <MediaHeader collectionTitle={collection.title} />
            <FilterControl
              collectionHandle={collection.handle}
              quickFilters={collection.quick_filters || []}
              openFilterBottomSheet={() => {
                setIsFilterBottomSheetVisible(true);
              }}
            />
          </>
        }
        ListFooterComponent={
          <>
            <BasketRecommendations placement="collection-screen" />
            <CollectionFilterBottomSheet
              collectionHandle={collectionHandle}
              isVisible={isFilterBottomSheetVisible}
              onDismiss={() => {
                setIsFilterBottomSheetVisible(false);
              }}
            />
            <View style={{ height: 40 }} />
          </>
        }
        showsVerticalScrollIndicator={true}
      />
    );
  },
);

const FilterControl = memo(
  ({
    collectionHandle,
    openFilterBottomSheet,
    quickFilters,
  }: {
    collectionHandle: string;
    openFilterBottomSheet: () => void;
    quickFilters: string[];
  }) => {
    const { activeFilters, availableProductsCount, totalProductsCount } =
      useAtomValue(collectionActiveFiltersAtomFamily(collectionHandle));
    const { filters } = useAtomValue(
      collectionWithFilterAtomFamily(collectionHandle),
    );
    const reset = useSetAtom(
      resetCollectionFiltersAtomFamily(collectionHandle),
    );
    const hasActiveFilters = activeFilters.length > 0;
    const matchingFilters = quickFilters
      .map((valueString) => {
        let value = valueString;
        const [filterId, valuePart] = value.split(':');
        if (valuePart) {
          value = valuePart;
        }

        if (filterId === 'price') {
          return {
            filter: filters.find((filter) => filter.id === 'price'),
            value: parseInt(value.replace('<', ''), 10),
            label: value.replace('<', 'Less than $'),
          };
        }

        const filter = filters.find((filter) =>
          includes([...filter.values], value),
        );

        if (filter?.displayType === 'scale') {
          return {
            filter: filters.find((filter) => filter.id === 'price'),
            value: parseInt(value.replace('<', ''), 10),
            label: value.replace('<', 'Less than $'),
          };
        }

        if (filter) {
          return {
            filter,
            value,
            label: value,
          };
        }

        return null;
      })
      .filter((filter) => filter !== null) as {
      filter: CollectionFilter;
      value: string | number;
      label: string;
    }[];

    if (filters.length === 1 && filters[0].id === 'price') {
      return null;
    }

    if (matchingFilters.length === 0) {
      return (
        <FilterControlWithNoShortcuts
          collectionHandle={collectionHandle}
          openFilterBottomSheet={openFilterBottomSheet}
          reset={reset}
        />
      );
    }

    return (
      <>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={{
            marginBottom: 10,
          }}
          contentContainerStyle={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: 20,
          }}
        >
          <Pressable
            onPress={() => {
              openFilterBottomSheet();
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 12,
              // borderWidth: 1,
              width: 34,
              height: 34,
            }}
          >
            <Icon color={branding.buttonTextColor} name={'filter'} size={24} />
            <View
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                backgroundColor: '#333',
                width: 14,
                height: 14,
                borderRadius: 7,
                justifyContent: 'center',
                alignItems: 'center',
                display: hasActiveFilters ? 'flex' : 'none',
              }}
            >
              <Text
                weight="bold"
                style={{
                  textAlign: 'center',
                  fontSize: 10,
                  display: hasActiveFilters ? 'flex' : 'none',
                  color: 'white',
                  //borderWidth: 1,
                  // width: 14,
                  // height: 14,
                }}
              >
                {activeFilters.length}
              </Text>
            </View>
          </Pressable>

          <FilterValueControlButtonDisplay
            label="All"
            isToggled={!hasActiveFilters}
            onPress={() => {
              if (!hasActiveFilters) {
                openFilterBottomSheet();
                return;
              }
              reset();
            }}
          />

          {matchingFilters.map((matchingFilters) => {
            return (
              <FilterValueControlButton
                key={matchingFilters.filter.id + matchingFilters.value}
                // onPress={onPress}
                filter={matchingFilters.filter}
                label={matchingFilters.label}
                value={matchingFilters.value}
                collectionHandle={collectionHandle}
              />
            );
          })}
        </ScrollView>
        <View>
          {availableProductsCount === 0 ? (
            <Text
              style={{
                textAlign: 'center',
                marginTop: 60,
                marginBottom: 60,
                opacity: 0.7,
              }}
            >
              No products found
            </Text>
          ) : (
            <Text
              style={{
                textAlign: 'center',
                marginBottom: 12,
                fontSize: 12,
                opacity: 0.7,
              }}
            >
              {hasActiveFilters
                ? `Showing ${availableProductsCount} of ${totalProductsCount} products`
                : ' '}
            </Text>
          )}
        </View>
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.collectionHandle === nextProps.collectionHandle,
);

const FilterControlWithNoShortcuts = memo(
  ({
    collectionHandle,
    openFilterBottomSheet,
    reset,
  }: {
    collectionHandle: string;
    openFilterBottomSheet: () => void;
    reset: () => void;
  }) => {
    const { activeFilters, availableProductsCount, totalProductsCount } =
      useAtomValue(collectionActiveFiltersAtomFamily(collectionHandle));
    const hasActiveFilters = activeFilters.length > 0;
    return (
      <Pressable
        onPress={openFilterBottomSheet}
        style={{
          //paddingHorizontal: 10,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 12,
        }}
      >
        <View
          style={{
            height: 30,
            justifyContent: hasActiveFilters ? 'flex-start' : 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderRadius: 4,
            backgroundColor: branding.buttonBackgroundColor,
            minWidth: 120,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon
              color={branding.buttonTextColor}
              name={'filter'}
              size={18}
              style={{
                top: -1,
                paddingLeft: 8,
              }}
            />
            <Text
              weight="bold"
              style={{
                color: branding.buttonTextColor,
                // color: 'white',
                textAlign: 'center',
                fontSize: 10,
                marginLeft: 2,
                top: Platform.select({
                  ios: -1,
                  default: 0,
                }),
                display: hasActiveFilters ? 'flex' : 'none',
              }}
            >
              {activeFilters.length}
            </Text>
            {/* </View> */}
          </View>
          <View
            style={{
              width: 1,
              height: '100%',
              opacity: 0.15,
              backgroundColor: branding.buttonTextColor,
              marginHorizontal: 8,
              display: hasActiveFilters ? 'flex' : 'none',
            }}
          />
          <Text
            style={{
              color: branding.buttonTextColor,
              paddingRight: 12,
              paddingLeft: hasActiveFilters ? 0 : 10,
              top: -1,
            }}
          >
            {hasActiveFilters
              ? `Showing ${availableProductsCount} of ${totalProductsCount} products`
              : 'Filter'}
          </Text>

          <Pressable
            onPress={(e) => {
              e.stopPropagation();
              reset();
            }}
            style={{
              paddingRight: 4,
              flexDirection: 'row',
              overflow: 'visible',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: hasActiveFilters ? 'flex' : 'none',
            }}
          >
            <View
              style={{
                width: 1,
                height: '100%',
                opacity: 0.15,
                backgroundColor: branding.buttonTextColor,
                marginHorizontal: 4,
                //display: hasActiveFilters ? 'flex' : 'none',
              }}
            />
            <Image
              source={require('../../../assets/images/x.png')}
              resizeMode="contain"
              style={{
                width: 22,
                height: 22,
                opacity: 0.6,
              }}
            />
          </Pressable>
        </View>
      </Pressable>
    );
  },
);

const FilterValueControlButtonDisplay = memo(
  ({
    label,
    isToggled,
    onPress,
  }: {
    label: string;
    isToggled: boolean;
    onPress: () => void;
  }) => {
    return (
      <Pressable
        onPress={onPress}
        style={{
          paddingHorizontal: 12,
          paddingVertical: 4,
          borderWidth: 1,
          borderRadius: 60,
          //width: 120,
          marginRight: 6,
          borderColor: isToggled ? branding.selectionAccentColor : '#ccc',
          backgroundColor: isToggled ? branding.selectionAccentColor : 'white',
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            lineHeight: 14,
            fontSize: 13,
          }}
        >
          {label}
        </Text>
      </Pressable>
    );
  },
);

const FilterValueControlButton = memo(
  ({
    collectionHandle,
    filter,
    label,
    value,
  }: {
    collectionHandle: string;
    filter: CollectionFilter;
    label: string;
    value: string | number;
  }) => {
    const reset = useSetAtom(
      resetCollectionFiltersAtomFamily(collectionHandle),
    );
    const [values, setGlobalValue] = useAtom(filter.stateAtom);
    let isToggled;
    if (filter.displayType === 'scale') {
      isToggled = values[1] === value;
    } else {
      isToggled = [...values].includes(value);
    }

    const onPress = () => {
      trackEvent('toggle_short_filter', {
        collectionHandle: collectionHandle,
        filterId: filter.id,
        value: value.toString(),
      });
      if (filter.displayType === 'scale') {
        const isSelected = values[1] === value;
        if (isSelected) {
          setGlobalValue([filter.minValue, filter.maxValue]); // Reset
        } else {
          reset();
          setGlobalValue([0, Number(value)]);
        }
      } else {
        if ([...values].includes(value)) {
          setGlobalValue([...values].filter((v) => v !== value) as string[]);
        } else {
          reset();
          setGlobalValue([value] as string[]);
        }
      }
    };

    return (
      <FilterValueControlButtonDisplay
        label={label}
        isToggled={isToggled}
        onPress={onPress}
      />
    );
  },
);
