import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { Image, Pressable, View } from 'react-native';
import numeral from 'numeral';
import { Text } from '../../../core-ui';
import {
  availableShippingRatesAtom,
  isAssociatingAddressWithShopifyCheckoutAtom,
  isSyncingLineItemsWithShopifyAtom,
  shippingRateAtom,
  updateShippingRateAtom,
  syncNoteWithShopifyAtom,
} from '../../../hooks/checkoutAtoms';
import { trackEvent } from '../../../analytics';
import {
  availableDeliveryOptionsAtom,
  DeliveryOption,
  isScheduledAtom,
} from '../../../hooks/scheduledTimeAtoms';
import { branding } from '../../../branding';

const Loader = () => {
  return (
    <View
      style={{
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,.1)',
        height: 110,
        width: '100%',
        borderRadius: 6,
        marginBottom: 10,
        padding: 15,
      }}
    >
      <View
        style={{
          width: 100,
          height: 18,
          backgroundColor: 'black',
          opacity: 0.05,
          borderRadius: 6,
        }}
      />
      <View
        style={{
          width: 140,
          height: 12,
          backgroundColor: 'black',
          opacity: 0.04,
          borderRadius: 4,
          marginTop: 4,
        }}
      />

      <View
        style={{
          width: 200,
          height: 22,
          backgroundColor: 'black',
          opacity: 0.05,
          borderRadius: 10,
          marginTop: 18,
        }}
      />
    </View>
  );
};

export function ShippingRateSelector({
  onScheduledPress,
}: {
  onScheduledPress: () => void;
}) {
  const [selectedShippingRate] = useAtom(shippingRateAtom);
  const [, setSelectedShippingRate] = useAtom(updateShippingRateAtom);
  const [availableShippingRates] = useAtom(availableShippingRatesAtom);
  const availableDeliveryOptions = useAtomValue(availableDeliveryOptionsAtom);
  const [isAssociatingAddressWithShopifyCheckout] = useAtom(
    isAssociatingAddressWithShopifyCheckoutAtom,
  );
  const [isSyncingLineItemsWithShopify] = useAtom(
    isSyncingLineItemsWithShopifyAtom,
  );
  const [isScheduled, setIsScheduled] = useAtom(isScheduledAtom);
  const syncNote = useSetAtom(syncNoteWithShopifyAtom);

  const isLoading =
    availableShippingRates.length === 0 ||
    isAssociatingAddressWithShopifyCheckout ||
    isSyncingLineItemsWithShopify;

  // Set default choice
  useEffect(() => {
    if (!isLoading && availableDeliveryOptions.length === 0) {
      trackEvent('checkout_unexpected_error', {
        error: 'no_shipping_rates',
      });
    }

    if (isLoading || availableDeliveryOptions.length === 0) {
      return;
    }

    const firstShippingRate = availableDeliveryOptions[0];
    setSelectedShippingRate(firstShippingRate.shopifyShippingRate);
    syncNote();
    if (firstShippingRate.title === 'Scheduled') {
      setIsScheduled(true);
    } else {
      setIsScheduled(false);
    }
  }, [isLoading, availableDeliveryOptions.length]);

  if (isLoading) {
    return (
      <View
        style={{
          height: 362,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
        <Loader />
        <Loader />
      </View>
    );
  }

  if (availableDeliveryOptions.length === 0) {
    return (
      <View
        style={{
          height: 362,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{ textAlign: 'center' }}>
          We are not able to deliver to your address at the moment. Please try
          again later.
        </Text>
      </View>
    );
  }

  return (
    <View>
      {availableDeliveryOptions.map((deliveryOption) => {
        // We are grafting "deliveryOptions" over shopify "ShippingRates", which is why this selc
        let isSelected = false;
        if (
          deliveryOption.title === 'Turbo' &&
          selectedShippingRate?.title.includes('Turbo')
        ) {
          isSelected = true;
        } else if (selectedShippingRate?.title.includes('Standard')) {
          // "Scheduled" deliveryOption is "standard" shippingRate + scheduled slot
          if (deliveryOption.title === 'Standard') {
            isSelected = !isScheduled;
          }

          if (deliveryOption.title === 'Scheduled') {
            isSelected = isScheduled;
          }
        }

        return (
          <DeliveryOptionSelectionItem
            isSelected={isSelected}
            deliveryOption={deliveryOption}
            key={deliveryOption.title}
            onPress={() => {
              if (deliveryOption.title === 'Scheduled') {
                setIsScheduled(true);
                setSelectedShippingRate(deliveryOption.shopifyShippingRate);
                //setIsScheduledTimePickerVisible(true);
                setTimeout(
                  () => {
                    onScheduledPress(); // This will open scheduled time modal
                  },
                  isSelected ? 0 : 340,
                );
              }

              if (deliveryOption.title === 'Standard') {
                setIsScheduled(false);
                setSelectedShippingRate(deliveryOption.shopifyShippingRate);
              }

              if (deliveryOption.title === 'Turbo') {
                setIsScheduled(false);
                setSelectedShippingRate(deliveryOption.shopifyShippingRate);
              }

              trackEvent('shipping_rate_selected', {
                shipping_rate: deliveryOption.title,
                delivery_option: deliveryOption.title,
              });

              syncNote(); // Adds expected time to note
            }}
          />
        );
      })}
    </View>
  );
}

function DeliveryOptionSelectionItem({
  deliveryOption,
  isSelected,
  onPress,
}: {
  deliveryOption: DeliveryOption;
  isSelected: boolean;
  onPress: () => void;
}) {
  const { title, isAvailable, subtitle, displayTime, price } = deliveryOption;
  return (
    <Pressable
      onPress={isAvailable ? onPress : () => {}}
      style={{
        borderWidth: 2,
        borderColor: isSelected
          ? branding.selectionAccentColor
          : 'rgba(0,0,0,0.1)',
        backgroundColor: isSelected ? 'white' : 'rgba(0,0,0,0)',
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
        display: 'flex',
        opacity: isAvailable ? 1 : 0.5,
      }}
    >
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          display: 'flex',
          flex: 1,
          padding: 15,
        }}
      >
        <View style={{ flex: 1 }}>
          <Text weight="bold" style={{ fontSize: 16, lineHeight: 16 }}>
            {title}
          </Text>
          <Text
            style={{ marginTop: 6, fontSize: 12, lineHeight: 12, opacity: 0.7 }}
          >
            {subtitle}
          </Text>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 16,
              backgroundColor: title === 'Turbo' ? '#333' : '#efefef',
              flexGrow: 0,
              flexShrink: 1,
              paddingHorizontal: 8,
              paddingVertical: title === 'Turbo' ? 5 : 6,
              borderRadius: 16,
              left: -3,
            }}
          >
            {(() => {
              switch (title) {
                case 'Standard':
                  return <ClockIcon />;
                case 'Turbo':
                  return <BoltIcon />;
                case 'Scheduled':
                  return <CalendarIcon />;
              }
            })()}
            <Text
              weight={title === 'Turbo' ? 'bold' : 'normal'}
              style={{
                marginLeft: 6,
                fontSize: 12,
                lineHeight: 12,
                color: title === 'Turbo' ? 'white' : 'black',
                flexShrink: 1,
                flexGrow: 0,
              }}
            >
              {displayTime}
            </Text>
          </View>
        </View>

        <View
          style={{
            flex: 0.3,
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <Text style={{ fontSize: 16 }}>
            {numeral(price).format('$0,00.00')}
          </Text>
        </View>
      </View>
    </Pressable>
  );
}

function BoltIcon() {
  return (
    <Image
      source={require('../../../../assets/images/bolt.png')}
      style={{ width: 16, height: 16, opacity: 0.85 }}
    />
  );
}

function ClockIcon() {
  return (
    <Image
      source={require('../../../../assets/images/clock.png')}
      style={{ width: 15, height: 15, opacity: 0.85, top: 0 }}
    />
  );
}

function CalendarIcon() {
  return (
    <Image
      source={require('../../../../assets/images/calendar.png')}
      style={{ width: 15, height: 15, opacity: 0.85, top: -1 }}
    />
  );
}
