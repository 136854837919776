import { LatLng } from 'react-native-maps';
import haversine from 'haversine-distance';

import { Hub } from '../hooks/hubsAtoms';

export function isPointInFeatureCollection(
  latLat: LatLng,
  featureCollection: GeoJSON.FeatureCollection,
): boolean {
  const { features } = featureCollection;

  const polygonFeatures = features.filter(
    (feature) => feature.geometry.type === 'Polygon',
  );

  for (const polygonFeature of polygonFeatures) {
    if (polygonFeature.geometry.type !== 'Polygon') {
      continue;
    }
    const polygonPoints = polygonFeature.geometry.coordinates[0].map(
      (point) => ({ latitude: point[1], longitude: point[0] } as LatLng),
    );
    const isInside = isPointInPolygon(latLat, polygonPoints);

    if (isInside) {
      return true;
    }
  }

  return false;
}

function isPointInPolygon(point: LatLng, polygonPoints: LatLng[]) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  const x = point.latitude;
  const y = point.longitude;

  let inside = false;
  for (
    let i = 0, j = polygonPoints.length - 1;
    i < polygonPoints.length;
    j = i++
  ) {
    const xi = polygonPoints[i].latitude;
    const yi = polygonPoints[i].longitude;
    const xj = polygonPoints[j].latitude;
    const yj = polygonPoints[j].longitude;

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

// export type DeliveryRegion = {
//   id: string;
//   name: string;
//   status: 'active' | 'coming_soon';
//   shortText: string;
//   latLng: LatLng;
//   zoom: number;
//   altitude: number;
// };

// export const deliveryRegions: DeliveryRegion[] = [
//   {
//     name: 'Auckland CBD',
//     id: 'auckland',
//     status: 'active',
//     shortText: 'ORDER 12-9pm',
//     latLng: {
//       latitude: -36.8984597,
//       longitude: 174.7633315,
//     },
//     zoom: 12,
//     altitude: 44000,
//   },
//   {
//     name: 'Queenstown',
//     id: 'queenstown',
//     status: 'active',
//     shortText: 'ORDER 12-9pm',
//     latLng: {
//       latitude: -45.0311621,
//       longitude: 168.6626435,
//     },
//     zoom: 12,
//     altitude: 11000,
//   },
//   {
//     name: 'Wellington',
//     id: 'wellington',
//     status: 'coming_soon',
//     shortText: 'Mid 2023',
//     latLng: {
//       latitude: -41.2864603,
//       longitude: 174.776236,
//     },
//     zoom: 12,
//     altitude: 36000,
//   },
// ];

// export function findClosestDeliveryRegion(latLng: LatLng): DeliveryRegion {
//   const closest = deliveryRegions.reduce((prev, curr) => {
//     const prevDistance = haversine(latLng, prev.latLng);
//     const currDistance = haversine(latLng, curr.latLng);
//     return prevDistance < currDistance ? prev : curr;
//   });

//   return closest;
// }

export const getLatLngsFromHubGeoJsonProperties = (hub: Hub): LatLng[] => {
  return (
    hub.standard_restricted_delivery_zones?.features
      .map((x) => x.geometry.coordinates)[0][0]
      .map((y) => {
        return { longitude: y[0], latitude: y[1] };
      }) ?? []
  );
};

export const findClosestDeliveryHub = (hubs: Hub[], latLng: LatLng): Hub => {
  const closest = hubs.reduce((prev, curr) => {
    const prevLatLng = getLatLngsFromHubGeoJsonProperties(prev);
    const currLatLng = getLatLngsFromHubGeoJsonProperties(curr);

    if (prevLatLng.length > 0 && currLatLng.length > 0) {
      const prevDistance = haversine(latLng, prevLatLng[0]); // comparing it with the 1st latLng of the prev hub
      const currDistance = haversine(latLng, currLatLng[0]); // comparing it with the 1st latLng of the curr hub
      return prevDistance < currDistance ? prev : curr;
    }

    return hubs[0];
  });

  return closest;
};
