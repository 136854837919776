import React from 'react';
import { Image, Pressable } from 'react-native';
import { Text } from '../../core-ui';

export const DeliveryAreasLink = ({ onPress }: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomColor: 'rgba(0,0,0,.2)',
        margin: 'auto',
        marginTop: 20,
        width: 132,
        paddingRight: 4,
        opacity: 0.5,
      }}
    >
      <Image
        source={require('../../../assets/images/info.png')}
        style={{ width: 17, height: 17, borderWidth: 0 }}
      />
      <Text
        style={{
          lineHeight: 20,
          marginLeft: 6,
        }}
      >
        Delivery map
      </Text>
    </Pressable>
  );
};
