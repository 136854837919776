import type { Env } from './env.d';

export const env: Env = {
  BRAND: 'gooddrop',
  STOREFRONT_ACCESS_TOKEN: '20c8903d02239ca960c426314e95dbbc',
  STOREFRONT_API_URL: 'https://653a34-2.myshopify.com/api/2023-04/graphql.json',
  SHOPIFY_SHOP_ID: '77939835178',
  KEYCHAIN_ACCESS_GROUP: 'P72357Q2FS.nz.co.gooddrop',
  GOOGLE_PLACES_API_KEY: 'AIzaSyA7Wj5Nyh8xQ83Lztp-X_NsG5KFd6EPMV4',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDwcI9KuRlUpuC4aIQlueuXCv_zI1yZZck',
  STRIPE_PUBLISHABLE_KEY:
    'pk_live_51NS9VBBaCi5UuE0JdudyGWeJrknbh3iFsLEwOE2YTvvK2o35dywSt4st1CI3MHJ4MTqjYjAAYAf3cUPzH2YnCdNF00eIK5H4TE',
  STRIPE_ACCOUNT_ID: 'acct_1NS9VBBaCi5UuE0J',
  USE_FIREBASE_EMULATORS: false,
  FIREBASE_EMULATORS_HOST: null,
  APPLE_MERCHANT_ID: 'merchant.nz.co.gooddrop.storefront',
  MIXPANEL_TOKEN: '2703b2f55c99089e4f7278248a624a7a',
  TRPC_URL: 'https://gooddrop-next-v2.vercel.app/api/trpc',
  TALKJS_APP_ID: 'sSrOUBUJ',
  FACEBOOK_APP_ID: '247113868140101',
  FIREBASE_WEB_CONFIG: {
    apiKey: 'AIzaSyDHNxuuKJnJN3OUxwhVP0BBtlbmDhCayxY',
    authDomain: 'good-drop-nz.firebaseapp.com',
    projectId: 'good-drop-nz',
    storageBucket: 'good-drop-nz.appspot.com',
    messagingSenderId: '30683461674',
    appId: '1:30683461674:web:637bac35a9685f144b6b53',
    measurementId: 'G-FPY2PX0KHT',
  },
  GOOGLE_MAP_PROXY_URL: 'https://gooddrop-next-v2.vercel.app/api/google-map',
};

export const {
  BRAND,
  STOREFRONT_ACCESS_TOKEN,
  STOREFRONT_API_URL,
  SHOPIFY_SHOP_ID,
  KEYCHAIN_ACCESS_GROUP,
  GOOGLE_PLACES_API_KEY,
  GOOGLE_MAPS_API_KEY,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_ACCOUNT_ID,
  USE_FIREBASE_EMULATORS,
  FIREBASE_EMULATORS_HOST,
  APPLE_MERCHANT_ID,
  MIXPANEL_TOKEN,
  TRPC_URL,
  TALKJS_APP_ID,
  FACEBOOK_APP_ID,
  FIREBASE_WEB_CONFIG,
  GOOGLE_MAP_PROXY_URL,
} = env;
