import React, { ComponentProps } from 'react';
import {
  Platform,
  StyleSheet,
  TextInput as NativeTextInput,
  View,
} from 'react-native';
import { IconButton } from 'react-native-paper';

import { COLORS } from '../constants/colors';
import { FONT_SIZE, FONT_FAMILY, LINE_HEIGHT } from '../constants/fonts';

import TextInput from './TextInput';

type Props = ComponentProps<typeof NativeTextInput>;

export default function SearchInput(props: Props) {
  let { style, ...otherProps } = props;

  return (
    <View style={styles.textInputContainer}>
      <TextInput
        containerStyle={[styles.textInput, style]}
        labelStyle={{ padding: 0 }}
        inputStyle={{
          paddingLeft: 0,
          borderWidth: 0,
          fontSize:
            Platform.OS === 'android' ? FONT_SIZE.extraSmall : FONT_SIZE.medium,
          lineHeight: LINE_HEIGHT.medium,
          fontFamily: FONT_FAMILY.REGULAR,
        }}
        clearButtonMode="while-editing"
        {...otherProps}
      />
      <IconButton icon="magnify" color={COLORS.grey} style={{ top: -2 }} />
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    // paddingVertical: 10,
    paddingHorizontal: 12,
    height: '100%',
    lineHeight: LINE_HEIGHT.medium,
    fontSize: FONT_SIZE.small,
    fontFamily: FONT_FAMILY.BOLD,
    flex: 1,
  },
  textInputContainer: {
    height: 45,
    borderColor: '#bbb',
    backgroundColor: COLORS.white,
    borderRadius: 8,
    borderWidth: 1,
    flex: 1,
    marginVertical: 16,
    display: 'flex',
    flexDirection: 'row',
  },
});
