import React, { useEffect } from 'react';
import { StyleProp, TextStyle, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { Text } from '../core-ui';

export const FancyPriceDisplay = ({
  children,
  weight,
  style,
  deemphasised,
  isSyncing,
}: {
  children: string | React.ReactNode;
  weight?: 'bold' | 'medium' | 'normal';
  style: StyleProp<TextStyle>;
  isSyncing?: boolean;
  deemphasised?: boolean;
}) => {
  const scale = useSharedValue(1);
  const translateY = useSharedValue(0);
  const opacity = useSharedValue(1);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      transform: [{ translateY: translateY.value }, { scale: scale.value }],
    };
  });

  useEffect(() => {
    if (isSyncing) {
      if (deemphasised) {
        opacity.value = withTiming(0.26, { duration: 80 });
      } else {
        opacity.value = withTiming(0.26, { duration: 80 });
        scale.value = withTiming(0.97, { duration: 1200 });
      }
      //translateY.value = withTiming(-5, { duration: 100 });
    } else {
      if (deemphasised) {
        opacity.value = withTiming(1, { duration: 300 });
      } else {
        opacity.value = withTiming(1, { duration: 80 });
        scale.value = withSpring(1, { mass: 0.5 });
      }

      //translateY.value = withTiming(0, { duration: 100 });
    }
  }, [isSyncing]);

  return (
    <View style={{ borderBottomWidth: 0 }}>
      <Animated.View style={[animatedStyle, { borderBottomWidth: 0 }]}>
        <Text weight={weight} style={[{ borderWidth: 0 }, style]}>
          {children}
        </Text>
      </Animated.View>
    </View>
  );
};
