import React, { useRef, useEffect } from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native';
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import { typography, CustomTheme } from '../constants/theme';

interface Props {
  onChangeText(text: string): void;
  cellCount: number;
  error: boolean;
  value: string;
  setValue(text: string): void;
  onFulfill(): void;
}

export default function CodeInput({
  onChangeText,
  cellCount,
  error,
  value,
  setValue,
  onFulfill,
}: Props) {
  const inputRef = useRef<TextInput>(null);

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  useEffect(() => {
    if (value && value.length === cellCount) {
      const inputInstance = inputRef.current;

      if (inputInstance) {
        inputInstance.blur();
        onFulfill();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, cellCount]);

  return (
    <CodeField
      ref={inputRef}
      {...props}
      caretHidden={false}
      value={value}
      onChangeText={onChangeText}
      cellCount={cellCount}
      rootStyle={styles.codeFieldRoot}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      renderCell={({ index, symbol, isFocused }) => (
        <View
          key={index}
          style={[
            styles.cell,
            error
              ? styles.cellError
              : index <= value.length
              ? styles.cellFocusFilled
              : styles.cellEmpty,
          ]}
          onLayout={getCellOnLayoutHandler(index)}
        >
          <Text style={[typography.largeBody, styles.centeredText]}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Text>
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  codeFieldRoot: {
    width: '100%',
    alignSelf: 'center',
  },
  cell: {
    width: 40,
    height: 60,
    borderWidth: 2,
    borderColor: 'transparent',
    backgroundColor: '#F6F6F6',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellError: {
    borderBottomColor: CustomTheme.colors.error,
  },
  cellFocusFilled: {
    borderBottomColor: CustomTheme.colors.primary,
  },
  cellEmpty: {
    borderBottomColor: 'black',
  },
  centeredText: {
    textAlign: 'center',
  },
});
