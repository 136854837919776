import React from 'react';
import { Image, View, useWindowDimensions } from 'react-native';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text } from '../core-ui';
import { branding } from '../branding';
import { BottomSheetModal } from './BottomSheetModal';
import { SimpleButton } from './SimpleButton';

type Props = {
  isVisible: boolean;
  onDismiss: () => void;
};
export function AlcoholCartLimitExceededBottomSheet({
  isVisible,
  onDismiss,
}: Props) {
  const insets = useSafeAreaInsets();
  const { height } = useWindowDimensions();

  return (
    <BottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingHorizontal: 32,
          paddingBottom: 22 + insets.bottom,
        }}
      >
        <View
          style={{
            minHeight: height - 200,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            style={{
              width: 130,
              height: 130,
              borderWidth: 0,
              marginTop: -30,
              marginBottom: -10,
            }}
            source={require('../../assets/images/alcoholWarning.png')}
          />
          <Text
            weight="bold"
            style={{ fontSize: 22, marginBottom: 20, textAlign: 'center' }}
          >
            Alcohol limit exceeded
          </Text>

          <Text
            style={{
              marginTop: 0,
              textAlign: 'center',
              paddingHorizontal: 20,
              lineHeight: 18,
            }}
          >
            Orders are limited to {branding.alcoholLimitDescription}
          </Text>

          <Text
            weight="bold"
            style={{
              marginTop: 20,
              textAlign: 'center',
              paddingHorizontal: 20,
              lineHeight: 17,
            }}
          >
            Please reduce the alcohol in your cart.
          </Text>
        </View>

        <SimpleButton
          onPress={() => {
            onDismiss();
          }}
          width={260}
        >
          OK
        </SimpleButton>
      </View>
    </BottomSheetModal>
  );
}
