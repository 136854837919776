import { CountryCode as PickerCountryCode } from 'react-native-country-picker-modal';

import { teddyMonoEmblem } from '../../assets/images';
import { AddressItem, ProductDetails } from '../types/types';
import { CountryCode, CurrencyCode } from '../generated/server/globalTypes';

export const emptyAddress: AddressItem = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  id: '',
  name: '',
  firstName: '',
  lastName: '',
  phone: '',
  province: '',
  zip: '',
};

export const newAddress: Omit<AddressItem, 'id' | 'name'> = {
  address1: '',
  address2: '',
  city: '',
  country: '',
  firstName: '',
  lastName: '',
  phone: '',
  province: '',
  zip: '',
};

export const emptyProduct: ProductDetails = {
  hasImage: false,
  variantId: '',
  quantityAvailable: 0,
  id: '',
  images: [teddyMonoEmblem],
  title: '',
  description: '',
  handle: '',
  price: 0,
  discount: 0,
  url: '',
  availableForSale: false,
};

export const preferredCountries: Array<PickerCountryCode> = [
  'NZ',
  'AU',
  'US',
  'GB',
];

export type CountryData = {
  countryCode: CountryCode;
  currencyCode: CurrencyCode;
  currencySymbol: string;
};
export const COUNTRY_DATA: CountryData = {
  countryCode: CountryCode.NZ,
  currencyCode: CurrencyCode.NZD,
  currencySymbol: '$',
};
