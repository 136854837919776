import { atom } from 'jotai';
import MiniSearch from 'minisearch';
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from 'teddy-next';

import { client as trpcClient } from '../api/trpc';
import { retryQuery } from '../utils/retryQuery';
import { timeEvent, trackEvent } from '../analytics';
import { inventoryRecordsAtom } from './inventoryAtoms';

/**
 * Products
 */

export type Product = inferRouterOutputs<AppRouter>['products'][number];

const allProductsAtom = atom(async () => {
  timeEvent('load_products');

  const products = await retryQuery(
    () => trpcClient.products.query(),
    'products query',
  );

  trackEvent('load_products', {
    count: products.length,
  });

  return products;
});

const productSearchDbAtom = atom(
  new MiniSearch<{ id: string }>({
    fields: ['title', 'productType', 'search_keywords'], // fields to index for full-text search
    storeFields: ['id'], // fields to return with search results
  }),
);

// only products which are available in the inventory
const availableProductsAtom = atom((get) => {
  const products = get(allProductsAtom);
  const inventoryRecords = get(inventoryRecordsAtom);
  const productSearchDb = get(productSearchDbAtom);

  const availableProducts = products.filter((product) => {
    const shopifyProductId = product.id;
    const existingInventoryRecord = inventoryRecords.find(
      (inventoryRecord) =>
        `gid://shopify/Product/${inventoryRecord.product_shopify_id}` ===
        shopifyProductId,
    );

    return !!existingInventoryRecord;
  });

  productSearchDb.removeAll();
  productSearchDb.addAll(
    availableProducts.map((product) => {
      return {
        id: product.id,
        title: product.title,
        search_keywords: product.generated_search_keywords?.value ?? '',
        productType: product.productType,
      };
    }),
  );

  return availableProducts;
});

const recentPurchasedLineItemsIdAtom = atom<string[]>([]);
const recentPurchasedLineItemsAtom = atom(async (get) => {
  const availableProducts = get(availableProductsAtom);
  const recentPurchasedLineItemIds = get(recentPurchasedLineItemsIdAtom);

  const recentlyPurchasedLineItems = availableProducts
    .filter((product) => recentPurchasedLineItemIds.indexOf(product.id) > -1)
    .sort(
      (a, b) =>
        recentPurchasedLineItemIds.indexOf(a.id) -
        recentPurchasedLineItemIds.indexOf(b.id),
    );

  return recentlyPurchasedLineItems;
});
const setRecentPurchasedLineItemsIdAtom = atom(
  null,
  async (_get, set, recentPurchasedLineItemIds: string[]) => {
    set(recentPurchasedLineItemsIdAtom, recentPurchasedLineItemIds);
  },
);

export {
  allProductsAtom,
  availableProductsAtom,
  productSearchDbAtom,
  recentPurchasedLineItemsAtom,
  setRecentPurchasedLineItemsIdAtom,
};
