import { useSafeAreaInsets } from 'react-native-safe-area-context';

import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
import { View, ScrollView, Pressable, Platform } from 'react-native';
import { GestureDetector, Gesture } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import _, { chunk, groupBy, round } from 'lodash';
import { branding } from '../branding';
import { Text } from '../core-ui';

import {
  collectionWithFilterAtomFamily,
  collectionActiveFiltersAtomFamily,
  CollectionScaleFilter,
  CollectionCheckboxFilter,
  resetCollectionFiltersAtomFamily,
} from '../hooks/collectionWithFilterAtom';
import { triggerHaptic } from '../utils/haptic';
import { trackEvent } from '../analytics';
import { BottomSheetModal } from './BottomSheetModal';
import { SimpleButton } from './SimpleButton';

type Props = {
  collectionHandle: string;
  isVisible: boolean;
  onDismiss: () => void;
};
export function CollectionFilterBottomSheet({
  collectionHandle,
  isVisible,
  onDismiss,
}: Props) {
  const { availableProducts } = useAtomValue(
    collectionActiveFiltersAtomFamily(collectionHandle),
  );
  const reset = useSetAtom(resetCollectionFiltersAtomFamily(collectionHandle));
  return (
    <BottomSheetModal
      isVisible={isVisible}
      onDismiss={() => {
        if (availableProducts.length === 0) {
          reset();
        }
        onDismiss();
      }}
      snapPointsOverride={['65%', '66%']}
    >
      <CollectionFilter
        collectionHandle={collectionHandle}
        onDonePress={onDismiss}
      />
    </BottomSheetModal>
  );
}

const CollectionFilter = memo(
  ({
    collectionHandle,
    onDonePress,
  }: {
    collectionHandle: string;
    onDonePress: () => void;
  }) => {
    const { filters } = useAtomValue(
      collectionWithFilterAtomFamily(collectionHandle),
    );
    const inset = useSafeAreaInsets();

    const groupedByTitle = groupBy(filters, 'title');

    return (
      <View style={{ borderWidth: 0, flex: 1 }}>
        <ScrollView
          nestedScrollEnabled
          contentContainerStyle={{
            borderWidth: 0,
            paddingTop: 0,
            paddingBottom: 182 + inset.bottom,
          }}
        >
          <Text
            weight="bold"
            style={{
              marginTop: 12,
              paddingHorizontal: 12,
              paddingBottom: 24,
              fontSize: 24,
              textAlign: 'center',
            }}
          >
            Filter
          </Text>
          {_.map(groupedByTitle, (filters, title) => {
            return (
              <Fragment key={title}>
                <Text
                  weight="bold"
                  style={{
                    paddingHorizontal: 14,
                    //paddingBottom: 24,
                    fontSize: 18,
                    marginBottom: 12,
                  }}
                >
                  {title}
                </Text>
                {filters.map((filter) => {
                  return filter.displayType === 'scale' ? (
                    <ScaleFilterDisplayer key={filter.id} filter={filter} />
                  ) : (
                    <CheckboxFilterDisplay key={filter.id} filter={filter} />
                  );
                })}
              </Fragment>
            );
          })}
        </ScrollView>

        <View
          style={{
            position: 'absolute',
            bottom: 22 + inset.bottom,
            //justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <PrimaryButton
            collectionHandle={collectionHandle}
            onPress={onDonePress}
          />
        </View>
      </View>
    );
  },
  (prevProps, nextProps) =>
    prevProps.collectionHandle === nextProps.collectionHandle,
);

const PrimaryButton = memo(
  ({
    collectionHandle,
    onPress,
  }: {
    collectionHandle: string;
    onPress: () => void;
  }) => {
    const { availableProducts, activeFilters } = useAtomValue(
      collectionActiveFiltersAtomFamily(collectionHandle),
    );
    const reset = useSetAtom(
      resetCollectionFiltersAtomFamily(collectionHandle),
    );
    const text = `See ${availableProducts.length} Items `;

    const primaryButtonOpacity = useSharedValue(1);
    const primaryButtonY = useSharedValue(0);
    const primaryButtonAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: primaryButtonOpacity.value,
        transform: [{ translateY: primaryButtonY.value }],
      };
    });

    const secondaryButtonOpacity = useSharedValue(0);
    const secondaryButtonY = useSharedValue(100);
    const secondaryButtonAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: secondaryButtonOpacity.value,
        transform: [{ translateY: secondaryButtonY.value }],
      };
    });

    useEffect(() => {
      const springOptions = {
        mass: 0.3,
      };
      if (availableProducts.length === 0) {
        primaryButtonOpacity.value = withTiming(0);
        primaryButtonY.value = withSpring(100, springOptions);
        secondaryButtonOpacity.value = withTiming(1);
        secondaryButtonY.value = withSpring(-200, springOptions);
      } else {
        primaryButtonOpacity.value = withTiming(1);
        primaryButtonY.value = withSpring(-100, springOptions);
        secondaryButtonOpacity.value = withTiming(0);
        secondaryButtonY.value = withSpring(100, springOptions);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableProducts.length]);

    return (
      <View
        style={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          overflow: 'visible',
        }}
      >
        <Animated.View style={primaryButtonAnimatedStyle}>
          <SimpleButton
            shadow={true}
            onPress={() => {
              trackEvent('collection_filter_applied', {
                collectionHandle,
                filters: JSON.stringify(activeFilters, null, 2),
              });

              onPress();
            }}
          >
            {text}
          </SimpleButton>
        </Animated.View>
        <Animated.View
          style={[
            secondaryButtonAnimatedStyle,
            {
              backgroundColor: 'white',
              padding: 14,
              paddingHorizontal: 20,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.15,
              shadowRadius: 3.84,
              elevation: 5,
            },
          ]}
        >
          <Text
            style={{
              textAlign: 'center',
              marginBottom: 10,
              opacity: 0.6,
              //borderWidth: 1,
            }}
          >
            NO ITEMS MATCHING
          </Text>
          <SimpleButton
            onPress={() => {
              //onPress();
              reset();
            }}
            bgColor="#f8f8f8"
            color="black"
            fontSize={14}
          >
            RESET FILTER
          </SimpleButton>
        </Animated.View>
      </View>
    );
  },
);

const ScaleFilterDisplayer = ({
  filter,
}: {
  filter: CollectionScaleFilter;
}) => {
  const maxValue = filter.maxValue;

  const scaleToPercentage = (value: number) => (value / maxValue) * 100;
  const scaleFromPercentage = (percentage: number) =>
    (percentage / 100) * maxValue;

  // Global state which is the source of truth and affects filter
  const [[globalA, globalB], setGlobalValueState] = useAtom(filter.stateAtom);
  const setGlobalValue = ([a, b]: [number, number]) =>
    setGlobalValueState([
      round(scaleFromPercentage(a), 1),
      round(scaleFromPercentage(b), 1),
    ]);

  // Local state for the slider display
  // 0–100
  const [[percentageA, percentageB], setPercentagePoints] = useState([
    scaleToPercentage(globalA),
    scaleToPercentage(globalB),
  ]);
  const [endToDrag, setEndToDrag] = useState<'a' | 'b'>('b');
  const [width, setWidth] = useState(0);

  const scaleWidth = percentageB - percentageA;
  const offset = percentageA;
  const minimumBufferBetweenEnds = 10;

  let label1 = filter.label1;
  let label2 = filter.label2;

  if (filter.id === 'price') {
    label1 = `$${round(scaleFromPercentage(percentageA), 0)}.00`;
    label2 = `$${round(scaleFromPercentage(percentageB), 0)}.00`;
  }

  if (filter.id === 'abv') {
    label1 = `${round(scaleFromPercentage(percentageA), 1)}%`;
    label2 = `${round(scaleFromPercentage(percentageB), 1)}%`;
  }

  useEffect(() => {
    setPercentagePoints([
      scaleToPercentage(globalA),
      scaleToPercentage(globalB),
    ]);
  }, [globalA, globalB]);

  const adjustPosition = useCallback(
    (x: number, endToDrag: 'a' | 'b') => {
      if (endToDrag === 'a') {
        const percentage = round(
          Math.max(
            0,
            Math.min(percentageB - minimumBufferBetweenEnds, (x / width) * 100),
          ),
          0,
        );
        if (round(percentage, 0) !== round(percentageA, 0)) {
          triggerHaptic('selection');
          setPercentagePoints([percentage, percentageB]);
        }
      } else {
        const percentage = Math.max(
          percentageA + minimumBufferBetweenEnds,
          Math.min(100, (x / width) * 100),
        );
        if (round(percentage, 0) !== round(percentageB, 0)) {
          triggerHaptic('selection');
          setPercentagePoints([percentageA, percentage]);
        }
      }
    },
    [percentageB, percentageA, width],
  );

  // const TOUCH_SLOP = 5;
  // const TIME_TO_ACTIVATE_PAN = 10;
  // const touchStart = useSharedValue({ x: 0, y: 0, time: 0 });
  const gesture = Gesture.Pan()
    .onBegin((e) => {
      // Choose between A or B point to drag
      const { x } = e;
      const percentage = (x / width) * 100;

      const distanceFromA = Math.abs(percentage - percentageA);
      const distanceFromB = Math.abs(percentage - percentageB);
      const endToDrag = distanceFromA < distanceFromB ? 'a' : 'b';

      runOnJS(setEndToDrag)(endToDrag);
      runOnJS(adjustPosition)(x, endToDrag);
    })
    .onUpdate((e) => {
      const { x } = e;
      runOnJS(adjustPosition)(x, endToDrag);
    })
    .hitSlop({ top: 6, bottom: 6, left: 6, right: 6 })
    //.shouldCancelWhenOutside(true)
    .cancelsTouchesInView(false)
    .onEnd(() => {})
    .onFinalize(() => {
      runOnJS(setGlobalValue)([percentageA, percentageB]);
    });
  return (
    <View
      style={{
        paddingHorizontal: 12,
        paddingBottom: 12,
        marginBottom: 24,
      }}
      onStartShouldSetResponder={() => true}
      onStartShouldSetResponderCapture={() => true}
      onMoveShouldSetResponderCapture={() => true}
      onMoveShouldSetResponder={() => true}
    >
      <View
        style={{
          marginHorizontal: 2,
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 6,
          //display: filter.label1 && filter.label2 ? 'flex' : 'none',
        }}
      >
        <Text>{label1}</Text>
        {/* <Text>{globalA}</Text>
        <Text>{globalB}</Text>
        <Text>{maxValue}</Text> */}

        <Text>{label2}</Text>
      </View>
      <GestureDetector gesture={gesture}>
        <View
          onLayout={(e) => {
            setWidth(e.nativeEvent.layout.width);
          }}
          style={{
            marginHorizontal: Platform.select({ ios: 12, android: 24 }),
          }}
        >
          {/* Distribution indicator points */}
          {_(filter.values)
            .groupBy()
            .map((values, key) => {
              const value = Number(key);
              const isActive =
                value >= scaleFromPercentage(percentageA) &&
                value <= scaleFromPercentage(percentageB);
              const percentage =
                (scaleToPercentage(value) / 100) * (width - 0) + 0;
              const count = values.length;
              const size = 2 * Math.min(count, 4);
              return (
                <View
                  key={value}
                  style={{
                    position: 'absolute',
                    left: percentage,
                    opacity: isActive ? 0.8 : 0.3,
                    top: 0,
                    overflow: 'visible',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: 1,
                    height: 1,
                  }}
                >
                  <View
                    style={{
                      height: size,
                      width: size,
                      borderRadius: size * 0.5,
                      backgroundColor: branding.buttonBackgroundColor,
                    }}
                  />
                </View>
              );
            })
            .value()}

          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
              //borderWidth: 1,
              height: 25,
            }}
          >
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: branding.buttonBackgroundColor,
                position: 'absolute',
              }}
            />
            <View
              style={{
                position: 'absolute',
                top: 0,
                left: offset + '%',
                width: scaleWidth + '%',
                flexDirection: 'row',
                //borderWidth: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 25,
              }}
            >
              <Pressable
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: 12,
                  right: -12,
                  height: 25,
                  borderRadius: 2,
                  backgroundColor: branding.buttonBackgroundColor,
                  alignSelf: 'flex-start',
                }}
              />
              <View
                style={{
                  height: 5,
                  width: '100%',
                  backgroundColor: branding.buttonBackgroundColor,
                }}
              />
              <Pressable
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 12,
                  height: 25,
                  borderRadius: 2,
                  backgroundColor: branding.buttonBackgroundColor,
                  alignSelf: 'flex-end',
                }}
              />
            </View>
          </View>
        </View>
      </GestureDetector>
    </View>
  );
};

const CheckboxFilterDisplay = ({
  filter,
}: {
  filter: CollectionCheckboxFilter;
}) => {
  const chunkedValues = chunk(filter.values, 3);
  const [values, setGlobalValue] = useAtom(filter.stateAtom);

  return (
    <ScrollView
      horizontal
      nestedScrollEnabled
      showsHorizontalScrollIndicator={false}
      style={{
        paddingHorizontal: 12,
        //paddingBottom: 24,
        marginBottom: 22,
      }}
    >
      {chunkedValues.map((valuesChunk, i) => (
        <View key={i}>
          {valuesChunk.map((value) => {
            const isSelected = values.includes(value);
            const areAnySelected = values.length > 0;
            return (
              <View key={value} style={{ width: 140 }}>
                <Pressable
                  onPress={() => {
                    setGlobalValue(
                      values.includes(value)
                        ? values.filter((v) => v !== value)
                        : [...values, value],
                    );
                  }}
                  style={{
                    padding: 4,
                    paddingHorizontal: 8,
                    backgroundColor: values.includes(value)
                      ? branding.buttonBackgroundColor
                      : 'rgba(0,0,0,.035)',
                    borderRadius: 4,
                    margin: 2,
                    opacity: areAnySelected && !isSelected ? 0.65 : 1,
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      color: isSelected ? branding.buttonTextColor : '#000',
                      fontSize: 14,
                    }}
                  >
                    {value}
                  </Text>
                </Pressable>
              </View>
            );
          })}
        </View>
      ))}
    </ScrollView>
  );
};
