import React, { useEffect, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { Text } from '../core-ui';
import { branding } from '../branding';
import { triggerHaptic } from '../utils/haptic';

export const SimpleButton = ({
  children,
  onPress,
  color = branding.buttonTextColor,
  bgColor = branding.buttonBackgroundColor,
  icon,
  width = 170,
  fontWeight = 'bold',
  fontSize = 18,
  forcedPressedState = false,
  shadow = false,
}: {
  children: string | React.ReactNode;
  onPress: () => void;
  color?: string;
  bgColor?: string;
  icon?: string;
  width?: number | string;
  fontSize?: number;
  fontWeight?: 'bold' | 'medium' | 'normal';
  forcedPressedState?: boolean;
  shadow?: boolean;
}) => {
  const [wasPressed, setWasPressed] = useState(false);
  const scale = useSharedValue(1);
  const translateY = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }, { scale: scale.value }],
    };
  });

  const DisplayIcon = () => {
    if (icon) {
      return <View style={{ marginRight: 10, height: 22, width: 22 }} />;
    }

    return null;
  };

  useEffect(() => {
    if (forcedPressedState) {
      scale.value = withTiming(0.9, { duration: 100 });
    } else {
      scale.value = withSpring(1);
    }
  }, [forcedPressedState]);

  return (
    <Animated.View style={animatedStyle}>
      <Pressable
        style={[
          {
            width,
            height: 52,
            borderRadius: 6,
            //overflow: 'hidden',
            // marginHorizontal: 4,
            // marginVertical: 9,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: bgColor,
          },
          shadow
            ? {
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.15,
                shadowRadius: 3,
                elevation: 2,
              }
            : {},
        ]}
        onPressIn={() => {
          scale.value = withTiming(0.9, { duration: 25 });
          //playMenuClose();
          //doLightHaptic();
        }}
        onPressOut={() => {
          if (wasPressed) {
            return;
          }
          scale.value = withSpring(1, {
            damping: 10,
            mass: 0.2,
            stiffness: 100,
          });
        }}
        onPress={() => {
          setWasPressed(true);
          scale.value = withSpring(1, {
            damping: 10,
            mass: 0.2,
            stiffness: 100,
          });
          onPress();
          triggerHaptic();
        }}
      >
        <DisplayIcon />

        {/* {icon} */}

        {typeof children === 'string' ? (
          <Text
            weight={fontWeight}
            style={{
              textAlign: 'center',
              fontSize,
              color,
              marginRight: icon ? 10 : 0,

              // Weird fix where the text is being clipped on Android
              lineHeight: fontSize,
              top: Platform.select({ android: 0.5, ios: 0 }),
            }}
          >
            {children}
          </Text>
        ) : (
          children
        )}
      </Pressable>
    </Animated.View>
  );
};
