import { trackEvent } from '../analytics';

export const retryQuery = async <T>(
  queryCallback: () => T,
  queryTitle: string,
  retryAttempt?: number,
): Promise<T> => {
  const retry = retryAttempt ?? 0;
  const maxRetries = 5;

  if (retry > maxRetries) {
    trackEvent('retry_query_failed', { queryTitle });
    throw new Error(`Query ${queryTitle} failed`);
  }

  try {
    const result = await queryCallback();
    return result;
  } catch (err) {
    trackEvent('retry_query', { queryTitle, retry: retry + 1 });

    const result = await retryQuery(queryCallback, queryTitle, retry + 1);
    return result;
  }
};
