import _ from 'lodash';
import { atom, type PrimitiveAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { availableProductsAtom, Product } from './productsAtoms';
import { allCollectionsAtom } from './collectionsAtoms';

type CollectionScaleFilterState = [number, number];
type CollectionCheckboxFilterState = string[];

export type CollectionScaleFilter = {
  id: string;
  title: string;
  label1: string | null;
  label2: string | null;
  displayType: 'scale';
  productCount: number;
  stateAtom: PrimitiveAtom<CollectionScaleFilterState>;
  values: number[];
  maxValue: number;
  minValue: number;
};

export type CollectionCheckboxFilter = {
  id: string;
  title: string;
  label1: null;
  label2: null;
  displayType: 'checkbox';
  productCount: number;
  stateAtom: PrimitiveAtom<CollectionCheckboxFilterState>;
  values: string[];
};

export type CollectionFilter = CollectionCheckboxFilter | CollectionScaleFilter;

/**
 * Stores the state of a filter for a collection.
 */
const collectionCheckboxFilterStateFamilyAtom = atomFamily((id) =>
  atom<CollectionCheckboxFilterState>([]),
);

const collectionScaleFilterStateFamilyAtom = atomFamily(
  ({
    id,
    initialValue,
  }: {
    id: CollectionFilter['id'];
    initialValue: CollectionScaleFilterState;
  }) => atom<CollectionScaleFilterState>(initialValue),
  (a, b) => a.id === b.id,
);

const extractFiltersFromProductList = (
  collectionHandle: string,
  products: Product[],
) => {
  return (
    _(products)
      .flatMap((product) => product.filters)
      .groupBy('id') // Group by filter id
      .map((values, id: string) => {
        // Special case for ABV
        let maxValue = 10;
        if (id === 'abv') {
          maxValue = Math.max(
            ...values
              .map((v) => Number(v.value))
              .filter((v) => !Number.isNaN(v)),
          );
        }

        return {
          id,
          title: values[0].title,
          label1: values[0].label1,
          label2: values[0].label2,
          displayType: values[0].displayType,
          productCount: values.length,
          stateAtom:
            values[0].displayType === 'scale'
              ? collectionScaleFilterStateFamilyAtom({
                  id: `${collectionHandle}${id}`,
                  initialValue: [0, maxValue],
                })
              : collectionCheckboxFilterStateFamilyAtom(
                  `${collectionHandle}${id}`,
                ),
          values: _(values)
            .map((v) =>
              values[0].displayType === 'scale'
                ? Number(v.value)
                : String(v.value),
            )
            // Filter out any NaNs
            .filter((v) => !Number.isNaN(v))
            .uniq()
            .sort()
            .value(),
          ...(values[0].displayType === 'scale'
            ? {
                maxValue, // Math.max(...values.map((v) => Number(v.value))),
                minValue: 0, // Math.min(...values.map((v) => Number(v.value))),
              }
            : {}),
        } as CollectionFilter;
      })
      .filter((filter) => filter.productCount > 1)
      // Move Profile to the top, then sort alphabetically
      .sort((a, b) => {
        if (a.title === 'ABV') {
          return -1;
        }

        if (b.title === 'ABV') {
          return 1;
        }

        if (a.title === 'Profile') {
          return -1;
        }

        if (b.title === 'Profile') {
          return 1;
        }

        return a.title.localeCompare(b.title);
      })
      .value()
  );
};

/**
 * Extracts filters for a collection
 */
const collectionWithFilterAtomFamily = atomFamily(
  (collectionHandle: string) => {
    return atom((get) => {
      const allCollections = get(allCollectionsAtom);
      const allAvailableProducts = get(availableProductsAtom);
      const collection = allCollections.find(
        (collection) => collection.handle === collectionHandle,
      );

      const subCategories = collection?.subcategories
        .map((id) => {
          return allCollections.find((col) => col.id === id);
        })
        .filter(
          (collection) => collection !== undefined,
        ) as (typeof collection)[];

      let allProductIds;
      if (subCategories.length > 0) {
        allProductIds = collection?.subcategories
          .map((id) => allCollections.find((col) => col.id === id))
          .filter((collection) => collection !== undefined)
          .flatMap((collection) =>
            collection?.products?.nodes.map((product) => product.id),
          );
      } else {
        allProductIds = collection?.products.nodes
          .map((product) => product.id)
          .filter((product) => product);
      }

      // Collect all products
      const products = _.chain(allProductIds)
        .uniq()
        .map((id) => allAvailableProducts.find((product) => product.id === id))
        .filter((product) => product !== undefined)
        .value() as Product[];

      // Extract filter options for products
      const filters = extractFiltersFromProductList(collectionHandle, products);

      // Add price filter
      const prices = products.map(
        (product) => +product.variants.nodes[0].priceV2.amount,
      );
      let maxPrice = Math.max(...prices) + 1;

      // Monkeypatch fix – sometimes the price is -Infinity for yet-to-be-understood reasons
      if (!isFinite(maxPrice)) {
        maxPrice = 500;
      }

      const priceFilter = {
        id: 'price',
        title: 'Price',
        label1: `$0.00`, // `$${Math.min(...prices)}`,
        label2: `$${maxPrice}`,
        displayType: 'scale',
        productCount: products.length,
        stateAtom: collectionScaleFilterStateFamilyAtom({
          id: `${collectionHandle}price`,
          initialValue: [0, maxPrice],
        }),
        values: prices,
        minValue: 0, //Math.min(...prices),
        maxValue: maxPrice,
      } as CollectionScaleFilter;

      return {
        filters: [priceFilter, ...filters],
        products,
      };
    });
  },
  (a, b) => a === b,
);

export type ActiveCollectionScaleFilter = {
  id: string;
  displayType: 'scale';
  state: CollectionScaleFilterState;
};

export type ActiveCollectionCheckboxFilter = {
  id: string;
  displayType: 'checkbox';
  state: CollectionCheckboxFilterState;
};

const collectionActiveFiltersAtomFamily = atomFamily(
  (collectionHandle: string) => {
    return atom((get) => {
      const { filters, products } = get(
        collectionWithFilterAtomFamily(collectionHandle),
      );
      const activeFilters = filters
        .filter((filter) => {
          if (filter.displayType === 'scale') {
            const [min, max] = get(filter.stateAtom);
            return min !== 0 || max !== filter.maxValue;
          } else {
            const values = get(filter.stateAtom);
            return values.length > 0;
          }
        })
        .map((filter) => {
          if (filter.displayType === 'scale') {
            return {
              id: filter.id,
              displayType: 'scale',
              state: get(filter.stateAtom),
            } as ActiveCollectionScaleFilter;
          } else {
            return {
              id: filter.id,
              displayType: 'checkbox',
              state: get(filter.stateAtom),
            } as ActiveCollectionCheckboxFilter;
          }
        });

      // Find all available products that match the active filters
      const availableProducts = products.filter((product) => {
        return activeFilters.every((filter) => {
          // Price
          if (filter.displayType === 'scale' && filter.id === 'price') {
            const [min, max] = filter.state;
            const price = +product.variants.nodes[0].priceV2.amount;
            return price >= min && price <= max;
          }

          // Scale
          if (filter.displayType === 'scale') {
            const [min, max] = filter.state;
            const value = product.filters.find(
              (f) => f.id === filter.id,
            )?.value;
            return (
              value !== undefined &&
              Number(value) >= min &&
              Number(value) <= max
            );
          }

          // Checkbox
          const values = filter.state;
          const value = product.filters.find((f) => f.id === filter.id)?.value;
          return value !== undefined && values.includes(String(value));
        });
      });

      // Find all filters that are available for the available products
      // const availableProductFilters = extractFiltersFromProductList(
      //   collectionHandle,
      //   availableProducts,
      // );

      // const irrelevantFilters = filters.filter(
      //   (filter) => !availableProductFilters.find((f) => f.id === filter.id),
      // );

      return {
        activeFilters,
        availableProducts,
        totalProductsCount: products.length,
        availableProductsCount: availableProducts.length,
      };
    });
  },
);

const resetCollectionFiltersAtomFamily = atomFamily(
  (collectionHandle: string) => {
    return atom(null, (get, set) => {
      const { filters } = get(collectionWithFilterAtomFamily(collectionHandle));

      filters.forEach((filter) => {
        if (filter.displayType === 'scale') {
          set(filter.stateAtom, [0, filter.maxValue]);
        } else {
          set(filter.stateAtom, []);
        }
      });
    });
  },
);

export {
  collectionWithFilterAtomFamily,
  collectionActiveFiltersAtomFamily,
  resetCollectionFiltersAtomFamily,
};
