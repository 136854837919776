import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

import { Image, Pressable, View } from 'react-native';
import { Text } from '../core-ui';
import {
  customerAccessTokenAtom,
  customerAtom,
  setCustomerAtom,
} from '../hooks/customerAtoms';
import { useUpdateCustomer } from '../hooks/api/useCustomer';
import { trackEvent } from '../analytics';
import { branding } from '../branding';

export const MarketingEmailOptIn = () => {
  const [checked, setChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const customerAccessToken = useAtomValue(customerAccessTokenAtom);
  const customer = useAtomValue(customerAtom);
  const setCustomer = useSetAtom(setCustomerAtom);
  const { updateCustomerData } = useUpdateCustomer();

  useEffect(() => {
    if (customer && !customer?.acceptsMarketing) {
      trackEvent('shown_marketing_re_optin');
      setIsVisible(true);
    }
  }, [customer?.id]);

  if (!isVisible) {
    return null;
  }

  // Don't show if initial render customer doesn't accept marketing

  const handlePress = () => {
    if (customer !== null) {
      setChecked(!checked);
      updateCustomerData({
        variables: {
          customerAccessToken,
          ...customer,
          acceptsMarketing: !checked,
        },
      });
      setCustomer({ ...customer, acceptsMarketing: !checked });
      if (!checked) {
        trackEvent('clicked_marketing_re_optin');
      } else {
        trackEvent('clicked_marketing_re_optout');
      }
    }
  };

  return (
    <Pressable
      onPress={handlePress}
      style={{
        borderWidth: 1,
        borderRadius: 4,
        backgroundColor: '#111',
        flexDirection: 'row',
        alignItems: 'center',
        //justifyContent: 'center',
        justifyContent: 'space-between',
        paddingLeft: 14,
      }}
    >
      <View>
        <Text
          style={{
            color: 'white',
            marginTop: 16,
            marginBottom: 14,
            padding: 0,
            borderWidth: 0,
          }}
          weight="bold"
        >
          Missing out on discounts?
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 14,
            //borderWidth: 1,
          }}
        >
          <View
            style={{
              width: 20,
              height: 20,
              borderWidth: 2,
              borderColor: 'white',
              borderRadius: 4,
              marginRight: 7,
              top: -1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {checked && (
              <Image
                style={{ width: 42, height: 42, margin: 12, borderWidth: 0 }}
                source={require('../../assets/images/checkmark.png')}
              />
            )}
          </View>

          <Text style={{ color: 'white' }} numberOfLines={2}>
            Email me {branding.displayName} promotions
          </Text>
        </View>
      </View>
      <Image
        style={{ width: 36, height: 36, margin: 12, borderWidth: 0 }}
        source={require('../../assets/images/email.png')}
      />
    </Pressable>
  );
};
