import React, { useEffect } from 'react';
import {
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import { IconButton } from 'react-native-paper';
import { useRoute, useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useAtom, useAtomValue } from 'jotai';

import { Text } from '../../core-ui';
import { StackRouteProp } from '../../types/Navigation';
import { COLORS } from '../../constants/colors';
import { HORIZONTAL_GUTTER } from '../../constants/theme';
import { lineItemAtomFamily } from '../../hooks/checkoutAtoms';
import { allProductsAtom } from '../../hooks/productsAtoms';
import { AllScreensNavigationProp } from '../../navigation/types';
import useCurrencyFormatter from '../../hooks/api/useCurrencyFormatter';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../../constants/fonts';
import { useDimensions } from '../../helpers/dimensions';

import { branding } from '../../branding';
import { ProductBadge } from '../../components/ProductBadge';
import { hubAtom } from '../../hooks/hubsAtoms';
import { AddToCart } from './components/AddToCart';

export default function ProductDetailsScene({}) {
  const insets = useSafeAreaInsets();

  const navigation =
    useNavigation<AllScreensNavigationProp<'ProductDetails'>>();

  const {
    params: { productHandle },
  } = useRoute<StackRouteProp<'ProductDetails'>>();

  const [products] = useAtom(allProductsAtom);
  const hub = useAtomValue(hubAtom);
  // find variantId by handle
  const product = products.find((product) => product.handle === productHandle);
  const variant = product?.variants.nodes?.[0];
  const variantId = variant?.id;
  const price = Number(variant?.priceV2.amount || 0);
  const compareAtPrice = Number(variant?.compareAtPriceV2?.amount || 0);
  const discount = compareAtPrice
    ? Math.round(100 - (price / compareAtPrice) * 100)
    : 0;
  const formatCurrency = useCurrencyFormatter();

  const lineItemAtom = lineItemAtomFamily({ variantID: variantId || '' });
  const [lineItem, setLineItem] = useAtom(lineItemAtom);

  // This is a hack to "populate" the line item atom with data
  useEffect(() => {
    setLineItem({ quantity: lineItem.quantity });
  }, [lineItem.variantID]);

  const isAlcoholic =
    lineItem.alcoholBeerOrCiderUnitCount > 0 ||
    lineItem.alcoholWineBottleCount > 0;

  const { width } = useDimensions();

  if (!product) {
    return null;
  }

  const [mainTitle, subtitle] = product.title.split(' | ');

  return (
    <View
      style={{
        height: '100%',
      }}
    >
      <ScrollView>
        <View style={[styles.imageWrap, { paddingTop: insets.top }]}>
          <View style={styles.imageContainer}>
            <Image
              source={{ uri: product.images.nodes[0]?.urlLarge }}
              style={{
                width: width,
                aspectRatio: 16 / 9,
              }}
              resizeMode="contain"
            />
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 0,
                position: 'absolute',
                top: 18,
                left: 22,
              }}
            >
              <ProductBadge product={product} size="large" />
            </View>
            {/* {discount > 0 ? (
              <DiscountBadge
                value={discount}
                containerStyle={{
                  position: 'absolute',
                  left: 16,
                  top: 16,
                }}
              />
            ) : null} */}
          </View>
        </View>
        <TouchableOpacity
          style={[styles.closeButton, { top: 8 + insets.top }]}
          onPress={() => {
            navigation.goBack();
          }}
          activeOpacity={0.9}
        >
          {Platform.OS === 'web' ? (
            <IconButton
              icon="close"
              color={COLORS.black}
              size={25}
              onPress={() => {
                if (!hub?.id) {
                  navigation.navigate('AddressEntry', {
                    flowType: 'fromLanding',
                  });
                  return;
                }

                if (
                  navigation.getState().routes[0].name === 'Landing' &&
                  navigation.getState().routes[1].name === 'ProductDetails'
                ) {
                  navigation.navigate('TabNavigator');
                  return;
                }

                navigation.goBack();
              }}
            />
          ) : (
            <IconButton icon="close" color={COLORS.black} size={25} />
          )}
        </TouchableOpacity>
        <>
          <View style={styles.padding}>
            <Text style={styles.productInfoTitle}>{mainTitle}</Text>
            {subtitle ? (
              <Text style={styles.productInfoSubtitle}>{subtitle}</Text>
            ) : null}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 15,
              }}
            >
              {discount > 0 ? (
                <>
                  <Text weight="bold" style={styles.productInfoPrice}>
                    {formatCurrency(price)}
                  </Text>
                  <Text weight="bold" style={styles.productInfoOriginalPrice}>
                    {formatCurrency(compareAtPrice)}
                  </Text>
                </>
              ) : (
                <Text weight="bold" style={styles.productInfoPrice}>
                  {formatCurrency(price)}
                </Text>
              )}
            </View>
            <AddToCart product={product} />
          </View>
          <View style={[styles.paddingHorizontal, styles.description]}>
            <Text style={styles.labelStyle}>{'Description'}</Text>
            <Text style={styles.descriptionText}>
              {product.description || 'No description'}
            </Text>
          </View>
        </>

        {isAlcoholic ? (
          <View
            style={{
              paddingHorizontal: 26,
              paddingVertical: 12,
              marginBottom: 14,
            }}
          >
            <View
              style={{
                borderWidth: 2,
                borderColor: 'rgba(255,200,58,1)',
                backgroundColor: 'rgba(255,200,58,.2)',
                padding: 4,
                borderRadius: 6,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  width: 54,
                  height: 54,
                  marginLeft: -6,
                  marginVertical: -6,
                }}
                source={require('../../../assets/images/alcoholWarning.png')}
              />
              <View
                style={{
                  flexGrow: 0,
                  flexShrink: 1,
                  borderWidth: 1,
                  borderColor: 'transparent',
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    borderWidth: 1,
                    borderColor: 'transparent',
                    lineHeight: Platform.select({
                      default: 14,
                      android: 15,
                    }),
                  }}
                  weight="bold"
                >
                  {branding.displayName} takes its alcohol responsibilities
                  seriously.
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    borderWidth: 1,
                    borderColor: 'transparent',
                    lineHeight: Platform.select({
                      default: 12,
                      android: 13,
                    }),
                  }}
                >
                  We can only deliver alcohol if the recipient is present,
                  sober, over 18 with valid ID, and has not ordered more than{' '}
                  {branding.alcoholLimitDescription}.
                </Text>
              </View>
            </View>
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    borderRadius: 30,
    right: HORIZONTAL_GUTTER,
    backgroundColor: COLORS.white,
  },
  imageWrap: {
    backgroundColor: COLORS.neutralGrey,
  },
  padding: {
    padding: 24,
  },
  paddingHorizontal: {
    paddingHorizontal: 24,
  },
  productInfoTitle: {
    fontSize: FONT_SIZE.extraLarge,
    lineHeight: LINE_HEIGHT.extraLarge + 4,
    fontFamily: FONT_FAMILY.BOLD,
    marginBottom: 8,
  },
  productInfoSubtitle: {
    fontSize: FONT_SIZE.medium,
    lineHeight: LINE_HEIGHT.medium + 4,
    fontFamily: FONT_FAMILY.BOLD,
    opacity: 0.45,
  },
  productInfoPrice: {
    marginTop: 8,
    fontSize: FONT_SIZE.large,
    lineHeight: LINE_HEIGHT.large,
  },
  productInfoOriginalPrice: {
    //paddingLeft: 8,
    color: COLORS.priceGrey,
    textDecorationLine: 'line-through',
    marginTop: 8,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
    marginLeft: 10,
  },
  labelStyle: {
    marginBottom: 8,
    fontFamily: FONT_FAMILY.BOLD,
  },
  description: {
    marginTop: 8,
    marginBottom: 24,
  },
  descriptionText: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small + 3,
  },
  imageContainer: {
    flex: 1,
    // padding: 15,
    paddingVertical: 30,
    backgroundColor: COLORS.neutralGrey,
  },
});
