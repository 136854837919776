import React, { useState } from 'react';

import { useAtomValue } from 'jotai';
import { useNavigation } from '@react-navigation/native';

import { CategoryList } from '../../core-ui';
import { AllScreensNavigationProp } from '../../navigation/types';
import { AlcoholConditionsBottomSheet } from '../../components/AlcoholConditionsBottomSheet';
import { CategoryItem } from '../../types/types';
import { categoriesCollectionsAtom } from '../../hooks/collectionsAtoms';
import { ProductSearch } from '../../components/ProductSearch';
import { CollectionSectionHeader } from '../../components/CollectionSectionHeader';
import { branding } from '../../branding';

export default function CategoriesCollectionScene() {
  const { navigate } = useNavigation<AllScreensNavigationProp<'Search'>>();
  const categoriesCollections = useAtomValue(categoriesCollectionsAtom);

  const [onAlcoholConfirmPress, setOnAlcoholConfirmPress] = useState<
    (() => void) | undefined
  >(undefined);
  const [isAlcoholConditionsModalVisible, setIsAlcoholConditionsModalVisible] =
    useState(false);

  return (
    <ProductSearch>
      <CollectionSectionHeader title={'Categories'} />
      <CategoryList
        listKey="categories"
        categories={categoriesCollections.map((collection) => {
          return {
            __type: 'Collection',
            id: collection.id,
            title: collection.title,
            handle: collection.handle,
            cursor: '',
            image: collection.image?.url,
            tags: collection.tags,
          };
        })}
        onSelect={(collection: CategoryItem) => {
          const containsAlcohol = collection.tags?.includes('contains_alcohol');
          const navigateToCategory = () =>
            navigate('ProductCollection', {
              collectionHandle: collection.handle,
              collectionTitle: collection.title,
              navigationLevel: 1,
            });

          if (containsAlcohol && branding.categoryAlcoholConditionsEnabled) {
            setIsAlcoholConditionsModalVisible(true);
            setOnAlcoholConfirmPress(() => navigateToCategory);
            return;
          }

          navigateToCategory();
        }}
      />
      <AlcoholConditionsBottomSheet
        isVisible={isAlcoholConditionsModalVisible}
        onDismiss={() => setIsAlcoholConditionsModalVisible(false)}
        onConditionsAccepted={() => {
          onAlcoholConfirmPress?.();
          setIsAlcoholConditionsModalVisible(false);
        }}
      />
    </ProductSearch>
  );
}
