import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';

import { tabBarHeight, tabBarOptions } from '../constants/theme';

export function getTabBarOptions(inset: number) {
  const height = tabBarHeight + inset;
  const tabOptions: BottomTabNavigationOptions = { ...tabBarOptions };

  if (tabOptions.tabBarStyle !== undefined) {
    tabOptions.tabBarStyle.height = height;
  }
  return tabOptions;
}
