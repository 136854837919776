import React from 'react';
import { Image, Platform, SafeAreaView, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { Text } from '../../core-ui';
import { COLORS } from '../../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../../constants/fonts';
import { trackEvent } from '../../analytics';
import { SimpleButton } from '../../components/SimpleButton';
import { AllScreensNavigationProp } from '../../navigation/types';
import { branding } from '../../branding';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const cartPlaceholder = branding.shoppingCartGraphic;

export const EmptyShoppingCart = () => {
  const { navigate } = useNavigation<AllScreensNavigationProp<'CartTab'>>();

  const onButtonPress = () => {
    trackEvent('start_shopping_button_pressed');
    navigate('HomeTab');
  };

  return (
    <SafeAreaView style={[styles.flex, styles.container]}>
      <View style={styles.center}>
        <View style={{ alignItems: 'center' }}>
          <Image
            source={cartPlaceholder}
            resizeMode={'cover'}
            style={styles.emptyCartImage}
          />
        </View>
        <Text style={styles.heading}>{'Your cart is empty'}</Text>
        <Text style={styles.subtitle}>{"Let's go shopping"}</Text>

        <SimpleButton width={190} onPress={onButtonPress}>
          Start shopping
        </SimpleButton>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  flex: {
    flex: 1,
    padding: 100,
    paddingTop: Platform.select({
      android: 190,
      default: 120,
    }),
    backgroundColor: 'rgba(240, 240, 240, 1)',
  },
  container: {},
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 80,
  },
  emptyCartImage: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
  },
  heading: {
    fontFamily: FONT_FAMILY.BOLD,
    fontSize: FONT_SIZE.large,
    lineHeight: LINE_HEIGHT.large,
    marginTop: 8,
    marginBottom: 6,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.large,
    color: COLORS.grey6,
    textAlign: 'center',
    marginBottom: 40,
  },
});
