import React, { useEffect } from 'react';
import { Image } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withRepeat,
  withSequence,
} from 'react-native-reanimated';
import { branding } from '../branding';

// A simple loading indicator component of the Teddy brand logo
export const TeddyLoaderIndicator = () => {
  // Looping animation
  const opacity = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });

  useEffect(() => {
    opacity.value = 0;
    opacity.value = withRepeat(
      withSequence(
        withTiming(0.7, { duration: 600 }),
        withTiming(0.3, { duration: 1000 }),
      ),
      -1,
      true,
    );
  });

  return (
    <Animated.View
      style={[animatedStyle, { borderWidth: 0, alignItems: 'center' }]}
    >
      <Image source={branding.emblemGraphic} style={{ opacity: 0.8 }} />
    </Animated.View>
  );
};
