import {
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import React from 'react';

import { CategoryItem as CategoryItemType } from '../types/types';
import { ImageWrapper } from '../components/ImageWrapper';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  item: CategoryItemType;
  onSelect: (category: CategoryItemType) => void;
};

export default function OccassionItem({
  item,
  onSelect,
  containerStyle,
}: Props) {
  return (
    <TouchableOpacity
      style={containerStyle}
      onPress={() => onSelect(item)}
      activeOpacity={0.4}
    >
      <ImageWrapper
        source={{ uri: item?.image, priority: 'normal' }}
        style={styles.image}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    aspectRatio: 1,
    borderRadius: 10,
  },
});
