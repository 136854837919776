import React, { useState } from 'react';
import { Image, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { COLORS } from '../constants/colors';
import { FONT_FAMILY, FONT_SIZE, LINE_HEIGHT } from '../constants/fonts';
import { Text } from '../core-ui';
import useCurrencyFormatter from '../hooks/api/useCurrencyFormatter';
import { LineItem } from '../types/types';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  orderItem: LineItem;
};

export default function OrderItem(props: Props) {
  const { title, image, priceAfterDiscount } = props.orderItem;
  const { containerStyle } = props;
  const [itemPrice] = useState(priceAfterDiscount);
  const formatCurrency = useCurrencyFormatter();
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.imageContainer}>
        <Image
          source={{
            uri: image,
          }}
          style={styles.image}
        />
      </View>

      <View style={styles.infoContainer}>
        <View style={styles.titleAndQuantity}>
          <Text weight="normal" style={styles.title}>
            {title}
          </Text>
          <Text style={styles.quantity}>
            Quantity: {props.orderItem.quantity}
          </Text>
        </View>
        <View style={styles.price}>
          <View>
            <Text weight="bold" style={styles.priceText}>
              {formatCurrency(itemPrice * props.orderItem.quantity)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 6,
    flexDirection: 'row',
  },
  imageContainer: {
    flex: 1,
    justifyContent: 'center',
    maxWidth: 44,
    // borderRadius: 5,
    // padding: 6,
    marginRight: 4,
  },
  image: {
    width: 36,
    height: 36,
  },
  infoContainer: {
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  price: {
    // flexDirection: 'row',
    alignItems: 'center',
    //borderWidth: 1,
  },
  titleAndQuantity: {
    display: 'flex',
    maxWidth: '70%',
    flexDirection: 'column',
  },
  title: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.small,
    lineHeight: LINE_HEIGHT.small,
  },
  quantity: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.extraSmall,
    lineHeight: LINE_HEIGHT.extraSmall,
    marginTop: 4,
    opacity: 0.55,
  },
  priceText: {
    fontFamily: FONT_FAMILY.REGULAR,
    fontSize: FONT_SIZE.extraSmall,
    lineHeight: LINE_HEIGHT.extraSmall,
  },
});
