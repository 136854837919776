import React, { useEffect, useState } from 'react';
import { FlatListProps, Image, Pressable, View } from 'react-native';
import Animated, {
  Extrapolate,
  interpolate,
  runOnJS,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Text } from '../core-ui';

export function ScrollViewWithHeader({
  onBackButtonPress,
  headerText,
  rightHamburgerMenuActions,
  children,
}: {
  headerText: string;
  onBackButtonPress?: () => void;
  rightHamburgerMenuActions?: {
    label: string;
    onPress: () => void;
    onLongPress: () => void;
  }[];
  children: React.ReactNode;
}) {
  const scrollY = useSharedValue(0);
  const [isHidden, setIsHidden] = useState(false);
  const insets = useSafeAreaInsets();
  const yThreshold = 20;
  const disappearOnScrollAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollY.value,
        [0, 16, yThreshold],
        [1, 1, 0],
        Extrapolate.CLAMP,
      ),
    };
  });
  const backButtonStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollY.value,
        [0, 16, 30],
        [0.4, 0.4, 1],
        Extrapolate.CLAMP,
      ),
    };
  });

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      if (event.contentOffset.y > yThreshold && !isHidden) {
        runOnJS(setIsHidden)(true);
      } else {
        runOnJS(setIsHidden)(false);
      }

      scrollY.value = event.contentOffset.y;
    },
  });

  const [isHamburgerMenuShowing, setIsHamburgerMenuShowing] = useState(false);
  const hamburgerMenuTranslateY = useSharedValue(0);
  const hamburgerMenuOpacity = useSharedValue(0);
  const hamburgerMenuAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: hamburgerMenuOpacity.value,
      transform: [
        {
          translateY: hamburgerMenuTranslateY.value,
        },
      ],
    };
  });

  useEffect(() => {
    if (isHamburgerMenuShowing) {
      hamburgerMenuOpacity.value = withTiming(1, { duration: 100 });
      hamburgerMenuTranslateY.value = withSpring(0, { mass: 0.55 });
    } else {
      hamburgerMenuOpacity.value = withTiming(0, { duration: 100 });
      hamburgerMenuTranslateY.value = withSpring(-24, { mass: 0.55 });
    }
  }, [isHamburgerMenuShowing]);

  return (
    <>
      {/* Header */}
      <View
        style={{
          position: 'absolute',
          flexDirection: 'row',
          marginBottom: 30,
          marginTop: 28,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          zIndex: 2,
        }}
      >
        {/* Back Button */}
        {onBackButtonPress ? (
          <Pressable
            onPress={onBackButtonPress}
            style={[
              backButtonStyle,
              {
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 14,
                position: 'absolute',
                left: 15,
                backgroundColor: 'rgba(240, 240, 240, 1)',
              },
            ]}
          >
            <Image
              source={require('../../assets/images/chevron.png')}
              style={{
                width: 32,
                height: 32,
                transform: [{ rotate: '270deg' }],
              }}
            />
          </Pressable>
        ) : null}

        {/* Header */}
        <Animated.View style={disappearOnScrollAnimatedStyle}>
          <Text
            style={{
              fontSize: 30,
            }}
            weight="bold"
          >
            {headerText}
          </Text>
        </Animated.View>

        {/* Hamburger Menu */}
        {rightHamburgerMenuActions && rightHamburgerMenuActions.length > 0 ? (
          <Animated.View
            style={[
              disappearOnScrollAnimatedStyle,
              {
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 14,
                position: 'absolute',
                right: 15,
                backgroundColor: 'rgba(240, 240, 240, 1)',
              },
            ]}
          >
            {/* Hamburger button */}
            <Pressable
              onPress={() => {
                setIsHamburgerMenuShowing(!isHamburgerMenuShowing);
              }}
              style={{
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(240, 240, 240, 1)',
                zIndex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  lineHeight: 22,
                  top: -5.5,
                  //borderWidth: 1,
                }}
              >
                ...
              </Text>
            </Pressable>

            {/* Hamburger Menu */}
            <Animated.View
              style={[
                hamburgerMenuAnimatedStyle,
                {
                  position: 'absolute',
                  width: 160,

                  bottom: -72,
                  right: 0,
                  padding: 10,
                  backgroundColor: 'rgba(240, 240, 240, 1)',
                  borderRadius: 8,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.15,
                },
              ]}
            >
              <View
                style={{
                  position: 'absolute',
                  right: 14,
                  top: -6,
                  width: 12,
                  height: 12,
                  backgroundColor: 'rgba(240, 240, 240, 1)',
                  transform: [{ rotate: '45deg' }],
                }}
              />

              {rightHamburgerMenuActions?.map(
                ({ label, onPress, onLongPress }) => {
                  return (
                    <Pressable
                      key={label}
                      onPress={() => {
                        if (isHidden) {
                          return;
                        }
                        onPress();
                        setIsHamburgerMenuShowing(false);
                      }}
                      onLongPress={() => {
                        if (isHidden) {
                          return;
                        }
                        onLongPress();
                        setIsHamburgerMenuShowing(false);
                      }}
                      style={{
                        padding: 7,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: 'rgba(0,0,0,0.2)',
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                      }}
                    >
                      <Image
                        source={require('../../assets/images/delete_sweep.png')}
                      />
                      <Text style={{ fontSize: 12, marginLeft: 8 }}>
                        {label}
                      </Text>
                    </Pressable>
                  );
                },
              )}
            </Animated.View>
          </Animated.View>
        ) : null}
      </View>
      <Animated.ScrollView
        keyboardShouldPersistTaps="handled"
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        style={{
          flex: 1,
          paddingTop: 80 + insets.top,
          backgroundColor: 'rgba(0,0,0,0.025)',
        }}
        showsVerticalScrollIndicator={false}
      >
        {children}
      </Animated.ScrollView>
    </>
  );
}

/**
 * Same component with a FlatList
 * It's quite complicated to abstract this to be DRY, so it's copy and paste
 * @param param0
 * @returns
 */
export function FlatListWithHeader<T>({
  isDarkBackground,
  onBackButtonPress,
  headerText,
  rightHamburgerMenuActions,
  ...props
}: FlatListProps<T> & {
  headerText: string;
  isDarkBackground: boolean;
  onBackButtonPress?: () => void;
  rightHamburgerMenuActions?: {
    label: string;
    onPress: () => void;
    onLongPress: () => void;
  }[];
}) {
  const scrollY = useSharedValue(0);
  const [isHidden, setIsHidden] = useState(false);
  const insets = useSafeAreaInsets();
  const yThreshold = 20;
  const disappearOnScrollAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollY.value,
        [0, 16, yThreshold],
        [1, 1, 0],
        Extrapolate.CLAMP,
      ),
    };
  });
  const backButtonStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(
        scrollY.value,
        [0, 16, 30],
        [0.4, 0.4, 1],
        Extrapolate.CLAMP,
      ),
    };
  });

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      if (event.contentOffset.y > yThreshold && !isHidden) {
        runOnJS(setIsHidden)(true);
      } else {
        runOnJS(setIsHidden)(false);
      }

      scrollY.value = event.contentOffset.y;
    },
  });

  const [isHamburgerMenuShowing, setIsHamburgerMenuShowing] = useState(false);
  const hamburgerMenuTranslateY = useSharedValue(0);
  const hamburgerMenuOpacity = useSharedValue(0);
  const hamburgerMenuAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: hamburgerMenuOpacity.value,
      transform: [
        {
          translateY: hamburgerMenuTranslateY.value,
        },
      ],
    };
  });

  useEffect(() => {
    if (isHamburgerMenuShowing) {
      hamburgerMenuOpacity.value = withTiming(1, { duration: 100 });
      hamburgerMenuTranslateY.value = withSpring(0, { mass: 0.55 });
    } else {
      hamburgerMenuOpacity.value = withTiming(0, { duration: 100 });
      hamburgerMenuTranslateY.value = withSpring(-24, { mass: 0.55 });
    }
  }, [isHamburgerMenuShowing]);

  return (
    <>
      {/* Header */}
      <View
        style={{
          position: 'absolute',
          flexDirection: 'row',
          marginBottom: 30,
          marginTop: 28,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          zIndex: 2,
        }}
      >
        {/* Back Button */}
        {onBackButtonPress ? (
          <Pressable
            onPress={onBackButtonPress}
            style={[
              backButtonStyle,
              {
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 14,
                position: 'absolute',
                left: 15,
                backgroundColor: 'rgba(240, 240, 240, 1)',
                zIndex: 2,
              },
            ]}
          >
            <Image
              source={require('../../assets/images/chevron.png')}
              style={{
                width: 32,
                height: 32,
                transform: [{ rotate: '270deg' }],
              }}
            />
          </Pressable>
        ) : null}

        {/* Header */}
        <Animated.View
          style={[
            {
              //borderWidth: 1,
              width: '100%',
              paddingHorizontal: 60,
            },
            disappearOnScrollAnimatedStyle,
          ]}
        >
          <Text
            style={{
              fontSize: 30,
              color: isDarkBackground ? 'white' : 'black',
              opacity: 0.85,
              textAlign: 'center',
              flex: 1,
            }}
            weight="bold"
          >
            {headerText}
          </Text>
        </Animated.View>

        {/* Hamburger Menu */}
        {rightHamburgerMenuActions && rightHamburgerMenuActions.length > 0 ? (
          <Animated.View
            style={[
              disappearOnScrollAnimatedStyle,
              {
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 14,
                position: 'absolute',
                right: 15,
                backgroundColor: 'rgba(240, 240, 240, 1)',
              },
            ]}
          >
            {/* Hamburger button */}
            <Pressable
              onPress={() => {
                setIsHamburgerMenuShowing(!isHamburgerMenuShowing);
              }}
              style={{
                width: 42,
                height: 42,
                borderRadius: 21,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(240, 240, 240, 1)',
                zIndex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 22,
                  lineHeight: 22,
                  top: -5.5,
                  //borderWidth: 1,
                }}
              >
                ...
              </Text>
            </Pressable>

            {/* Hamburger Menu */}
            <Animated.View
              style={[
                hamburgerMenuAnimatedStyle,
                {
                  position: 'absolute',
                  width: 160,

                  bottom: -72,
                  right: 0,
                  padding: 10,
                  backgroundColor: 'rgba(240, 240, 240, 1)',
                  borderRadius: 8,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.15,
                },
              ]}
            >
              <View
                style={{
                  position: 'absolute',
                  right: 14,
                  top: -6,
                  width: 12,
                  height: 12,
                  backgroundColor: 'rgba(240, 240, 240, 1)',
                  transform: [{ rotate: '45deg' }],
                }}
              />

              {rightHamburgerMenuActions?.map(
                ({ label, onPress, onLongPress }) => {
                  return (
                    <Pressable
                      key={label}
                      onPress={() => {
                        if (isHidden) {
                          return;
                        }
                        onPress();
                        setIsHamburgerMenuShowing(false);
                      }}
                      onLongPress={() => {
                        if (isHidden) {
                          return;
                        }
                        onLongPress();
                        setIsHamburgerMenuShowing(false);
                      }}
                      style={{
                        padding: 7,
                        backgroundColor: 'white',
                        borderWidth: 1,
                        borderColor: 'rgba(0,0,0,0.2)',
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                      }}
                    >
                      <Image
                        source={require('../../assets/images/delete_sweep.png')}
                      />
                      <Text style={{ fontSize: 12, marginLeft: 8 }}>
                        {label}
                      </Text>
                    </Pressable>
                  );
                },
              )}
            </Animated.View>
          </Animated.View>
        ) : null}
      </View>
      <Animated.FlatList
        {...props}
        keyboardShouldPersistTaps="handled"
        onScroll={scrollHandler}
        scrollEventThrottle={16}
        contentContainerStyle={{
          paddingTop: (headerText.length > 18 ? 110 : 76) + insets.top,
        }}
        style={[
          {
            backgroundColor: 'rgba(0,0,0,0.025)',
          },
          ...(props.style ? [props.style] : []),
        ]}
        showsVerticalScrollIndicator={false}
      />
    </>
  );
}
