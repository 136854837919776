import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useAtomValue } from 'jotai';
import React, { useCallback } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  Pressable,
  Share,
  StatusBar,
  View,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { trackEvent } from '../analytics';
import { PromoContent } from '../components/PromoContent';
import { SimpleButton } from '../components/SimpleButton';
import { Text } from '../core-ui';
import { customerAtom } from '../hooks/customerAtoms';
import { AllScreensNavigationProp } from '../navigation/types';
import { branding } from '../branding';

export function CustomerReferralScene() {
  const { navigate } = useNavigation<AllScreensNavigationProp<'Home'>>();
  const customer = useAtomValue(customerAtom);
  const insets = useSafeAreaInsets();

  useFocusEffect(
    useCallback(() => {
      StatusBar.setBarStyle('light-content');
      if (Platform.OS === 'android') {
        StatusBar.setTranslucent(true);
        changeNavigationBarColor('transparent', false, false);
      }
    }, []),
  );

  const onPress = async () => {
    trackEvent('share_referral_code_click');
    Share.share(
      {
        message: `${branding.displayName} delivers groceries and alcohol in minutes. Use my code ${customer?.referralCode} at checkout and get $10 off. Download the app ${branding.appLinkUrl}/?r=fgf`,
      },
      {
        dialogTitle: 'Share your referral link',
      },
    );
  };

  const navigateToLoginPage = () => {
    if (!customer) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      navigate('SmsAuthentication', {
        screen: 'PhoneInput',
        params: { flowType: 'fromProfile' },
      });
    }
  };

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <ImageBackground
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        source={require('../../assets/images/background-pattern.png')}
      />

      {/* Close button */}
      <Pressable
        onPress={() => {
          StatusBar.setBarStyle('dark-content');
          if (Platform.OS === 'android') {
            StatusBar.setTranslucent(true);
            StatusBar.setBackgroundColor('rgba(0,0,0,0)');
            changeNavigationBarColor('#fafafa', true, false);
          }
          navigate('TabNavigator');
        }}
        style={{
          width: 28,
          height: 28,
          borderRadius: 14,
          backgroundColor: 'rgba(218,13 ,0,1)',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 'auto',
          marginTop: 20 + insets.top,
          marginRight: 20,
          alignSelf: 'flex-end',
          zIndex: 10,
        }}
      >
        <Image
          source={require('../../assets/images/x-white.png')}
          style={{ width: 22, height: 22 }}
        />
      </Pressable>

      {/* Promo graphic */}
      <View
        style={{
          justifyContent: 'center',
          marginTop: 20,
          flex: 1,
        }}
      >
        <PromoContent
          placement="referral-screen"
          placeholderColor="transparent"
        />
      </View>

      {/* Share */}
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: insets.bottom + 40,
        }}
      >
        {customer ? (
          <Pressable
            style={{
              backgroundColor: 'rgba(218,13 ,0,1)',
              borderRadius: 8,
              margin: 12,
              padding: 16,
              width: 220,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={onPress}
          >
            <Text
              style={{
                textAlign: 'center',
                paddingBottom: 4,
                fontSize: 9,
                color: 'white',
                opacity: 0.6,
              }}
              weight="bold"
            >
              YOUR CODE TO SHARE
            </Text>
            <Text
              style={{
                color: 'white',
                marginHorizontal: 12,
                fontSize: 18,
                textAlign: 'center',
                marginBottom: 12,
              }}
              weight="bold"
            >
              {customer?.referralCode}
            </Text>
            <SimpleButton bgColor="#222" onPress={onPress}>
              Share
            </SimpleButton>
          </Pressable>
        ) : (
          <Pressable
            style={{
              borderRadius: 8,
              margin: 12,
              padding: 16,
              width: 220,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={navigateToLoginPage}
          >
            <Text
              style={{
                textAlign: 'center',
                paddingBottom: 10,
                fontSize: 9,
                color: 'white',
                opacity: 1,
              }}
              weight="bold"
            >
              Join to invite your friends
            </Text>
            <SimpleButton bgColor="#222" onPress={navigateToLoginPage}>
              Join
            </SimpleButton>
          </Pressable>
        )}
      </View>
    </View>
  );
}
