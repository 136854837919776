import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import numeral from 'numeral';

import { Text, TextInput } from '../../../core-ui';
import { SimpleButton } from '../../../components/SimpleButton';
import {
  discountCodeAtom,
  discountCodeErrorMessageAtom,
  isDiscountCodeSyncingAtom,
  discountCodeIsValidAtom,
  syncDiscountCodeWithShopifyAtom,
  lineItemsDiscountTotalAtom,
  shippingDiscountTotalAtom,
  discountTotalAtom,
} from '../../../hooks/checkoutAtoms';
import { cartContainsAlcoholAtom } from '../../../hooks/alcoholRestrictionsAtoms';
import { trackEvent } from '../../../analytics';

export function DiscountCodeInput() {
  const [discountCode, setDiscountCode] = useAtom(discountCodeAtom);
  const [isDiscountCodeValid] = useAtom(discountCodeIsValidAtom);
  let [discountCodeErrorMessage] = useAtom(discountCodeErrorMessageAtom);
  const [isDiscountCodeLoading] = useAtom(isDiscountCodeSyncingAtom);
  const [containsAlcohol] = useAtom(cartContainsAlcoholAtom);

  const [lineItemsDiscountTotal] = useAtom(lineItemsDiscountTotalAtom);
  const [shippingDiscountTotal] = useAtom(shippingDiscountTotalAtom);
  const [discountTotal] = useAtom(discountTotalAtom);

  const [isMessageShowing, setIsMessageShowing] = React.useState(false);

  const [, syncDiscountCodeWithShopify] = useAtom(
    syncDiscountCodeWithShopifyAtom,
  );

  let message = 'Discount applied';

  // Override error message if discount code is valid but no discount is applied
  if (isDiscountCodeValid && numeral(discountTotal).value() === 0) {
    discountCodeErrorMessage =
      'This code is not valid for this basket, please check the terms of the code';
  }

  if (discountCodeErrorMessage) {
    message = discountCodeErrorMessage;
  } else if (shippingDiscountTotal && shippingDiscountTotal.value()) {
    message = 'Free delivery';
  }

  const messageOpacity = useSharedValue(0);
  const messageScale = useSharedValue(1);
  const messageTranslateY = useSharedValue(0);
  const messageAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: messageOpacity.value,
      transform: [
        { translateY: messageTranslateY.value },
        { scale: messageScale.value },
      ],
    };
  });

  useEffect(() => {
    if (isMessageShowing) {
      messageScale.value = withSpring(1, { mass: 1 });
      messageOpacity.value = withTiming(1, { duration: 120 });
    } else {
      messageScale.value = withSpring(0.96, { mass: 0.4 });
      messageOpacity.value = withTiming(0, { duration: 120 });
    }
  }, [isMessageShowing]);

  useEffect(() => {
    if (isDiscountCodeLoading) {
      setIsMessageShowing(false);
      return;
    }
    if (discountCodeErrorMessage) {
      trackEvent('discount_code_error_message_shown', {
        discount_code: discountCodeErrorMessage,
      });
      setIsMessageShowing(true);
      return;
    }

    if (shippingDiscountTotal && shippingDiscountTotal.value()) {
      setIsMessageShowing(true);
      return;
    }

    if (lineItemsDiscountTotal && lineItemsDiscountTotal.value()) {
      setIsMessageShowing(true);
      return;
    }

    if (isDiscountCodeValid && numeral(lineItemsDiscountTotal).value() === 0) {
      setIsMessageShowing(true);
      return;
    }

    setIsMessageShowing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    discountCodeErrorMessage,
    isDiscountCodeLoading,
    lineItemsDiscountTotal,
    shippingDiscountTotal,
  ]);

  return (
    <View style={{ borderWidth: 0, paddingTop: 34, paddingBottom: 14 }}>
      <View
        style={{
          flexDirection: 'row',
          //justifyContent: 'center',
        }}
      >
        <Text weight="bold">Discount</Text>
        {/* {containsAlcohol ? (
          <View
            style={{
              marginLeft: 'auto',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ExclamationMark size={12} marginRight={3} />
            <Text style={{ fontSize: 9 }}>
              Alcohol is excluded from discounts
            </Text>
          </View>
        ) : null} */}
      </View>
      <View
        style={{
          marginTop: 12,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // borderWidth: 1,
          height: 45,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingRight: 20,
          }}
          pointerEvents={
            isDiscountCodeLoading || isDiscountCodeValid ? 'none' : 'auto'
          }
        >
          <TextInput
            autoCapitalize="none"
            returnKeyType="done"
            value={discountCode}
            placeholder="Voucher or referral code"
            onChangeText={(text) => {
              setDiscountCode(text);
            }}
            style={{
              flex: 1,
              lineHeight: 14,
              fontSize: 12,
            }}
            containerStyle={{
              flex: 1,
            }}
            errorMessage={''}
            errorMessageStyle={styles.errorMessage}
          />
        </View>

        <SimpleButton
          width={100}
          color="black"
          bgColor="rgba(0,0,0,0.03)"
          fontWeight="normal"
          forcedPressedState={isDiscountCodeLoading}
          onPress={() => {
            if (isDiscountCodeLoading) {
              return;
            }
            if (isDiscountCodeValid) {
              trackEvent('discount_code_remove', {
                discount_code: discountCode,
              });
              setDiscountCode('');
              syncDiscountCodeWithShopify();
            } else {
              trackEvent('discount_code_submit', {
                discount_code: discountCode,
              });
              syncDiscountCodeWithShopify();
            }
          }}
        >
          {(() => {
            if (isDiscountCodeLoading) {
              return <ActivityIndicator size="small" color="black" />;
            }
            if (isDiscountCodeValid) {
              return 'Remove';
            } else {
              return 'Apply';
            }
          })()}
        </SimpleButton>
      </View>

      <View
        style={{
          height: 60,
        }}
      >
        <Animated.View
          style={[
            messageAnimatedStyle,
            {
              padding: 10,
              marginTop: 15,
              borderRadius: 4,
              borderColor: 'rgba(0,0,0,0.1)',
              backgroundColor: 'rgba(0,0,0,0.025)',
              flexDirection: 'row',
              alignItems: 'center',
              position: 'absolute',
              width: '100%',
            },
          ]}
        >
          {discountCodeErrorMessage ? (
            <ExclamationMark size={17} marginRight={9} />
          ) : (
            <Tick />
          )}
          <Text style={{ fontSize: 12, lineHeight: 14 }}>{message}</Text>

          {lineItemsDiscountTotal.value() ? (
            <Text style={{ marginLeft: 'auto' }}>
              -{lineItemsDiscountTotal.format('$0,00.00')}
            </Text>
          ) : null}
        </Animated.View>
      </View>
    </View>
  );
}

const ExclamationMark = (
  { size, marginRight } = { size: 17, marginRight: 3 },
) => {
  return (
    <Image
      style={{
        width: size,
        height: size,
        marginLeft: 3,
        marginRight,
      }}
      source={require('../../../../assets/images/exclamation-mark.png')}
    />
  );
};

const Tick = () => {
  return (
    <Image
      style={{
        width: 22,
        height: 22,
        marginRight: 5,
      }}
      source={require('../../../../assets/images/tick.png')}
    />
  );
};

const styles = StyleSheet.create({
  errorMessage: {
    padding: 0,
    marginTop: 14,
  },
});
