import React, { memo } from 'react';
import { View } from 'react-native';

import color from 'color';
import { COLORS } from '../constants/colors';
import { Text } from '../core-ui';
import type { Product } from '../hooks/productsAtoms';
import { branding } from '../branding';

const colours = {
  white: '#fff',
  darkpurple: '#4B4794',
  lightpurple: '#D1C7FC',
  darkgreen: '#23504E',
  lightgreen: '#D6FFED',
  orange: '#f06B3D',
  red: '#FF0000',
  blue: '#0080FF',
} as const;

export const ProductBadge = memo(
  ({ product, size }: { product: Product; size: 'small' | 'large' }) => {
    let text = product.badge_text;
    let colour = product.badge_colour as keyof typeof colours;

    if (!colour) {
      colour = branding.name === 'teddy' ? 'red' : 'lightgreen';
    }

    const colourToRender = colours[colour];
    const isDark = color(colourToRender).isDark();

    // Discount badge
    if (text === null || text === undefined || text === '') {
      const variant = product.variants.nodes?.[0];
      const price = Number(variant?.priceV2.amount || 0);
      const compareAtPrice = Number(variant?.compareAtPriceV2?.amount || 0);
      const discount = compareAtPrice
        ? Math.round(100 - (price / compareAtPrice) * 100)
        : 0;

      if (discount <= 0) {
        return null;
      }

      text = `${discount}% off`;
    }

    return (
      <View
        style={{
          backgroundColor: colourToRender,
          borderRadius: 5,
          padding: size === 'small' ? 4 : 6,
          paddingHorizontal: size === 'small' ? 8 : 14,
          maxWidth: size === 'small' ? 100 : 140,
          transform: [{ rotate: '0deg' }],
        }}
      >
        <Text
          weight="bold"
          style={{
            color: isDark ? COLORS.white : COLORS.black,
            textAlign: 'center',
            fontSize: size === 'small' ? 12 : 16,
            lineHeight: size === 'small' ? 14 : 18,
          }}
          numberOfLines={2}
        >
          {text}
        </Text>
      </View>
    );
  },
  (prevProps, nextProps) =>
    prevProps.product.id === nextProps.product.id &&
    prevProps.size === nextProps.size,
);
